import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import SearchIcon from '@mui/icons-material/Search'
import { FormControl, FormHelperText, Input, InputAdornment, InputLabel, LinearProgress } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import OperationHeader from '../headers/operation.header'
import { DataGrid, GridColDef, GridSelectionModel, GridToolbar } from '@mui/x-data-grid'
import theme from '../../theme'
import styles from './ContractManagementFinancial.module.scss'
import lclService from '../../services/lcl.service'
import { toFloat, toInt, toLowerCase, toStringFloat } from '../../utilities/auxiliary-functions'
import { CONTRACT_SITUATION_ACTIVED, CONTRACT_SITUATION_CANCELED, CONTRACT_SITUATION_INACTIVE } from '../../utilities/constants'
import { getContractSituation } from '../../utilities/types'
import { floatMask } from '../../utilities/masks'
import { isEmpty } from '../../utilities/validators'

interface Props {
    translate: any
    partner: any
    onClick_ShowDefinedPartner: any
}

function ContractManagementFinancial(props: Props) {
    
    const display = props.translate.data;

    const title = display.title.contract_management;
    const description = display.description.contract_management;

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);

    const [ selectionModel, setSelectionModel ] = useState<GridSelectionModel>([]);
    const [ pageSize, setPageSize ] = React.useState(10);
    const [ records, setRecords ] = useState([] as any);

    const [ textSearch, setTextSearch ] = useState('');

    const [ active, setActive ] = useState(0);
    const [ inactive, setInactive ] = useState(0);
    const [ canceled, setCanceled ] = useState(0);

    const [ sumDelay, setSumDelay ] = useState(0);
    const [ sumPaid, setSumPaid ] = useState(0);
    const [ sumParcel, setSumParcel ] = useState(0);
    const [ sumWaiting, setSumWaiting ] = useState(0);

    const columns: GridColDef[] = [
        { field: 'identifyKey', headerName: 'Chave ID', minWidth: 110, align: 'center', headerAlign: 'center' },
        { field: 'contractKey', headerName: 'Contrato', maxWidth: 110, align: 'center', headerAlign: 'center' },
        {
            field: 'ownerName',
            headerName: 'Cliente',
            minWidth: 150,
            flex: 1,
            align: 'center',
            headerAlign: 'center'
        },{
            field: 'vesselName',
            headerName: 'Embarcação',
            minWidth: 150,
            flex: 1,
            align: 'center',
            headerAlign: 'center'

        },{
            field: 'situation',
            headerName: 'Status',
            width: 110,
            align: 'center',
            headerAlign: 'center',
            valueGetter: getSituationDef
        },{
            field: 'sumParcel',
            headerName: 'Total previsto',
            width: 120,
            align: 'center',
            headerAlign: 'center',
            valueGetter: getParcelDef
        },{
            field: 'sumWaiting',
            headerName: 'À vencer',
            width: 120,
            align: 'center',
            headerAlign: 'center',
            valueGetter: getWaitingDef
        }
    ];

    function getSituationDef(param: any) {
        const { situation } = param.row;
        return display.label[toLowerCase(getContractSituation(situation).name)];
    }

    function getParcelDef(param: any) {
        const { sumParcel } = param.row;
        return floatMask(sumParcel);
    }

    function getWaitingDef(param: any) {
        const { sumWaiting } = param.row;
        return floatMask(sumWaiting);
    }

    const searchfilter = async () => {
        setLoading(true);
        const attributes = [] as any;
        const where = {} as any;
        const order = [] as any;

        /* Sempre incluir o partner na pesquisa */
        where.partnerId = props.partner.id;

        if (!isEmpty(textSearch)) {
            columns.forEach((column: any) => {
                where[column.field] = { like : '%' + textSearch +'%' }
            });
        }
        const contracts = await lclService.contractsManager(attributes, where, order);

        let active = 0;
        let inactive = 0;
        let canceled = 0;

        let delay = 0;
        let paid = 0;
        let parcel = 0;
        let waiting = 0;
        for (var idx = 0; idx < contracts.length; idx++) {
            if (toInt(contracts[idx].situation) === CONTRACT_SITUATION_ACTIVED) 
                active++;
            else if (toInt(contracts[idx].situation) === CONTRACT_SITUATION_INACTIVE) 
                inactive++;
            else if (toInt(contracts[idx].situation) === CONTRACT_SITUATION_CANCELED) 
                canceled++;

            delay += toFloat(contracts[idx].sumDelay);
            paid  += toFloat(contracts[idx].sumPaid);
            parcel  += toFloat(contracts[idx].sumParcel);
            waiting  += toFloat(contracts[idx].sumWaiting);
        }

        setActive(active);
        setInactive(inactive);
        setCanceled(canceled);
        setSumDelay(delay);
        setSumPaid(paid);
        setSumParcel(parcel);
        setSumWaiting(waiting);

        setRecords(contracts);
        setLoading(false);
    }

    function onClick_Search(event: any) {
        event.preventDefault();
        searchfilter();
    }

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            searchfilter()
        }
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage
        }
    }, []) // useEffect

    function viewPage() {
        return (
            <Container fluid className={`page-body movements ${styles.contracts}`}>
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card style={{ height: 570}}>
                            <Card.Header>
                                <div className="card-actions float-right d-flex justify-content-end">
                                    <div className="card-actions-time" />
                                </div>
                                <Card.Title>Contratos</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <FormControl fullWidth sx={{width: 350 }} variant="standard">
                                        <InputLabel htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                                        <Input id="standard-adornment-amount"
                                            value={textSearch}
                                            onChange={(e) => setTextSearch(e.target.value)}
                                            endAdornment = {
                                                <InputAdornment position="end">
                                                    <SearchIcon className='isClickable' onClick={(e) => onClick_Search(e)} />
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText id="component-helper-text">
                                            Número do contrato, nome do cliente ou nome da embarcação
                                        </FormHelperText>
                                    </FormControl>
                                    <section className='d-flex'>
                                        <div className='d-flex flex-column align-items-center me-5'>
                                            <h6>Ativos</h6>
                                            <h5>{active}</h5>
                                        </div>
                                        <div className='d-flex flex-column align-items-center me-5'>
                                            <h6>Inativos</h6>
                                            <h5>{inactive}</h5>
                                        </div>
                                        <div className='d-flex flex-column align-items-center'>
                                            <h6>Cancelados</h6>
                                            <h5>{canceled}</h5>
                                        </div>
                                    </section>
                                    <section className='d-flex'>
                                        <div className='me-5'>
                                            <div className='d-flex flex-column mb-2'>
                                                <h6>Total previsto</h6>
                                                <h5>R$ {toStringFloat(sumParcel)}</h5>
                                            </div>
                                            <div className='d-flex flex-column'>
                                                <h6>Em atraso</h6>
                                                <h5>R$ {toStringFloat(sumDelay)}</h5>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='d-flex flex-column mb-2'>
                                                <h6>Pago</h6>
                                                <h5>R$ {toStringFloat(sumPaid)}</h5>
                                            </div>
                                            <div className='d-flex flex-column'>
                                                <h6>À vencer</h6>
                                                <h5>R$ {toStringFloat(sumWaiting)}</h5>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <ThemeProvider theme={theme}>
                                    <div style={{ height: 402, width: '100%', marginTop: 0 }}>
                                        <DataGrid
                                            rows={records}
                                            rowCount={records.length}
                                            getRowId={row => row.id}
                                            loading={isLoading}
                                            columns={columns}
                                            pageSize={pageSize}
                                            onPageSizeChange={newPage => setPageSize(newPage)}
                                            rowsPerPageOptions={[10, 25, 50, 75, 100]}
                                            disableSelectionOnClick
                                            onCellClick={(e) => console.log(e)}
                                            onSelectionModelChange={(newSelectionModel: any) => {
                                                setSelectionModel(newSelectionModel)
                                            }}
                                            components={{
                                                Toolbar: GridToolbar,
                                                LoadingOverlay: LinearProgress
                                            }}
                                            selectionModel={selectionModel}
                                        />
                                    </div>
                                </ThemeProvider>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <div className="page">
            <OperationHeader
                title={title}
                description={description}
                onClick_ShowDefinedPartner={props.onClick_ShowDefinedPartner}
            />
            {viewPage()}
        </div>
    )
}

export default ContractManagementFinancial
