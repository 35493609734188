import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CadastreHeader from "../headers/cadastre.header";
import ProductCadastreForm from "./forms/product.cadastre.form";
import ProductCadastreList from "./lists/product.cadastre.list";

interface Props {
    translate: any,
    partner: any,

    onClick_ShowDefinedPartner: any
}

function ProductCadastre(props: Props) {

    const { productId } = useParams();
    const display = props.translate.data;

    const title = display.title.product;
    const description =  '';

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ recordSelected, setRecordSelected ] = useState({ param : productId || '', selected : ((productId === 'new') || (productId === '0') || (!Number.isNaN(Number.parseInt(productId ||'')))) });

    function setSelected(isRecord: boolean, recordId: string) {
        setRecordSelected({ param : recordId, selected : isRecord });
    }

    async function initialize() {
    }
    
    useEffect(() => {
        if (!isLoading && !reloadPage) {
            initialize();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []);
    
    return (
        <div className="page">
            <CadastreHeader title={title} description={description} onClick_ShowDefinedPartner={props.onClick_ShowDefinedPartner} />
            {(recordSelected.selected) ? (
                <ProductCadastreForm 
                    translate={props.translate} 
                    partner={props.partner}
                    title={title}
                    productId={recordSelected.param}
                    setRecord={(event: any) => { 
                        event.preventDefault();
        
                        setSelected(false, '0'); 
                    }} />
            ) : (
                <ProductCadastreList
                    translate={props.translate} 
                    partner={props.partner}
                    title={title}
                    setRecord={(event: any, recordId: string) => { 
                        event.preventDefault();
        
                        setSelected(true, recordId); 
                    }} />
            )}
        </div>
    )
}

export default ProductCadastre;
