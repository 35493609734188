import { useEffect, useMemo, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import TableContainer from "../../components/table-container.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import cduService from "../../services/cdu.service";
import { cnpjMask } from "../../utilities/masks";
import { isEmpty } from "../../utilities/validators";
import { Button } from '@mui/material'
import { FaTimes } from "react-icons/fa";
import { AlertMessageEnum } from "../../utilities/types";

interface Props {
    translate: any,
    show: any,
    onClick_Close: any,
    onClick_Confirm: any,
}

function CompanyFilter(props: Props) {
    
    const alertContext = useAlertMessageContext();

    const display = props.translate.data;

    const title : string = display.title.company_filter;

    const [record, setRecord] = useState({} as any);
    const [records, setRecords ] = useState([]);

    const columnsRecord = useMemo(() => [{
        Header: display.legend.partner,
        columns: [
            {
                Header: display.label.social_reason,
                accessor: "socialReason"
            }, {
                Header: display.label.fantasy,
                accessor: "fantasy"
            }, {
                Header: display.label.registration_pj,
                accessor: "registrationPj",
                Cell: (row: any) => (<div className="white-space">{cnpjMask(row.value)}</div>)
            }
        ]
        }, {
            Header: display.legend.address,
            columns: [
                {
                    Header: display.label.city,
                    accessor: "address.city"
                }, {
                    Header: display.label.state,
                    accessor: "address.state"
                }
            ]
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }], []
    ); // columnsRecord

    async function initialize() {
        try {
            if (props.show) {
                const attributes = [] as any;
                const where = {} as any;
                const order = [] as any;

                const companies = await cduService.companyFilter(attributes, where, order);
                setRecords(companies);
                setRecord({} as any);
            }
        } catch(error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
        }
    }

    useEffect(() => {
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]); // useEffect

    return (
        <Modal
            size="lg"
            show={props.show}
            centered
        >
            <Modal.Header>
                <Modal.Title><FaSearch />{title}</Modal.Title>
                <div className="card-actions float-right">
                    <FaTimes className="isClickable" size={22} onClick={props.onClick_Close} />
                </div>
            </Modal.Header>
            <Modal.Body style={{ minHeight : 400 }}>
                <Row>
                    <Col>
                        <TableContainer columns={ columnsRecord } data={ records } viewFilter={true} viewPagination={true} setSelectedRow={ 
                            (event: any, dataRow: any) => { 
                                setRecord(dataRow); 
                            }}
                        />
                    </Col>
                </Row>                    
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col sm="8">
                            <div className="filter-selectred"><strong>{display.label.selected_record}:</strong> <span>{ record.socialReason }</span></div>
                        </Col>
                        <Col sm="4">
                            <Button variant="contained" color="secondary" className="ms-2" onClick={ (e) => props.onClick_Close(e) } >{display.buttom.cancel}</Button>{' '}
                            <Button variant="contained" color="primary" onClick={ (e) => props.onClick_Confirm(e, record) }  disabled={isEmpty(record)}>{display.buttom.confirm}</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    )
}

export default CompanyFilter;
