import { Button } from "@mui/material";
import ptBR from 'date-fns/locale/pt-BR';
import { Col, Form, FormControl, FormGroup, InputGroup, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { BsSliders } from "react-icons/bs";
import { FaPlusSquare, FaSearch } from "react-icons/fa";
import { PickerInput } from "../../../../components/sub-render.component";
import TableContainer from "../../../../components/table-container.component";
import { dateToString, stringFormat, toDate, toFloat, toLowerCase, toString, toStringFloat } from "../../../../utilities/auxiliary-functions";
import { PERSON_PESSOA_JURIDICA } from "../../../../utilities/constants";
import { floatMask, phoneMask } from "../../../../utilities/masks";
import { BudgetSituationType, PaymentConditionsType, PaymentType } from "../../../../utilities/types";
import { isEmpty } from "../../../../utilities/validators";

interface Props {
    translate: any,
    title: any,
    isEditMode: boolean,

    budgetItems: any,
    columnsBudgetItems: any,

    partnerId?: any,
    code?: any,
    prohibited?: any, 
    departure?: any,
    vessel?: any,
    comments?: any,
    mechanicId?: any,
    mechanic?: any,
    situation?: any,
    paymentForm?: any,
    paymentConditions?: any,
    productCost?: any,
    officeCost?: any,
    discountCost?: any,
    orderCost?: any,
    created?: any,
    updated?: any,

    setPartnerId?: any,
    setCode?: any,
    setProhibited?: any,
    setDeparture?: any,
    setVessel?: any,
    setComments?: any,
    setMechanicId?: any,
    setMechanic?: any,
    setSituation?: any,
    setPaymentForm?: any,
    setPaymentConditions?: any,
    setProductCost?: any,
    setOfficeCost?: any,
    setDiscountCost?: any,
    setOrderCost?: any,
    setCreated?: any,
    setUpdated?: any,

    listMechanic?: any,

    searchVessel?: any,
    setSearchVessel?: any,
    filterVessel?: any,

    filterStock?: any,
    calculatedOrder?: any,
    showFilterVesselModal?: any

}

function BudgetRecord(props: Props) {

    const display = props.translate.data;

    function onChange_Prohibited(event: any, date: any) {
        //event.preventDefault();
        props.setProhibited(dateToString(date, 'dd/mm/yyyy HH:MM') +':00');
    }

    function onChange_Departure(event: any, date: any) {
        //event.preventDefault();
        props.setDeparture(dateToString(date, 'dd/mm/yyyy HH:MM') +':59');
    }

    function onClick_SearchVessel(event: any) {
        event.preventDefault();
        props.filterVessel(event);
    }

    function onClick_FilterStock(event: any) {
        event.preventDefault();
        props.filterStock(event);
    }

    function onChange_Discount(event: any) {
        let value = event.target.value;
        if (!isEmpty(value)) {
            if (toFloat(value) > 100)
                value = 100;
        }
        props.setDiscountCost(value);
    }

    function onBlur_Discount(event: any) {
        event.preventDefault();
        props.setDiscountCost(toStringFloat(event.target.value));
        props.calculatedOrder(event);
    }

    function onChange_Mechanic(event: any) {
        event.preventDefault();

        for (var i = 0; i < props.listMechanic.length; i++) {
            const mechanic = props.listMechanic[i];

            if (mechanic.id === Number(event.target.value)) {
                props.setMechanic(mechanic);
                break;
            }
        }
        props.setMechanicId(event.target.value)
    }

    function onChange_Conditions(event: any) {
        event.preventDefault();
        props.setPaymentConditions(event.target.value);
    }

    function onChange_Vessel(fieldname: string, event: any) {
        event.preventDefault();
    }

    const handleFilterVesselModal = (e: any) => {
        props.showFilterVesselModal(e)
    }
    
    return (
        <>
            <Row>
                <Col sm="7">
                    <fieldset>
                        <legend>Dados da Ordem de Serviço</legend>
                        <Row>
                            <Col sm="4">
                                <FormGroup>
                                    <Form.Label htmlFor="form-code">{display.label.os}</Form.Label>
                                    <FormControl type="text" id="form-code" name="code" placeholder={display.example.code} required
                                        value={toString(props.code)}
                                        onChange={e => props.setCode(e.target.value)}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="4">
                                <FormGroup>
                                    <Form.Label htmlFor="form-prohibited">{display.label.prohibited}</Form.Label>
                                    <DatePicker
                                        locale={ptBR}
                                        selected={toDate(props.prohibited, 'dd/mm/yyyy HH:MM')}
                                        onChange={(date: any, e: any) => onChange_Prohibited(e, date)}
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        customInput={<PickerInput />}
                                        // minDate={new Date()}
                                        showTimeSelect
                                        minTime={new Date(0, 0, 0, 7, 0, 0)}
                                        maxTime={new Date(0, 0, 0, 19, 0, 0)}
                                        timeFormat="HH:mm"
                                        timeIntervals={60}
                                        timeCaption="Hora" 

                                        popperClassName="some-custom-class"
                                        popperPlacement="top-end"
                                        popperModifiers={[{
                                                name : "offset",
                                                options : {
                                                    offset : [5, 10]
                                                }
                                            },{
                                                name : "preventOverflow",
                                                options : {
                                                    rootBoundary : "viewport",
                                                    tether : false,
                                                    altAxis : true
                                                }
                                        }]}

                                        readOnly={props.isEditMode}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="4">
                                <FormGroup>
                                    <Form.Label htmlFor="form-departure">{display.label.departure}</Form.Label>
                                    <DatePicker
                                        locale={ptBR}
                                        selected={toDate(props.departure, 'dd/mm/yyyy HH:MM')}
                                        onChange={(date: any, e: any) => onChange_Departure(e, date)}
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        customInput={<PickerInput />}
                                        // minDate={new Date()}
                                        showTimeSelect
                                        minTime={new Date(0, 0, 0, 7, 0, 0)}
                                        maxTime={new Date(0, 0, 0, 19, 0, 0)}
                                        timeFormat="HH:mm"
                                        timeIntervals={60}
                                        timeCaption="Hora" 

                                        popperClassName="some-custom-class"
                                        popperPlacement="top-end"
                                        popperModifiers={[{
                                                name : "offset",
                                                options : {
                                                    offset : [5, 10]
                                                }
                                            },{
                                                name : "preventOverflow",
                                                options : {
                                                    rootBoundary : "viewport",
                                                    tether : false,
                                                    altAxis : true
                                                }
                                        }]}
                                        minDate={toDate(props.prohibited, 'dd/mm/yyyy')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
                <Col sm="5">
                    {!isEmpty(props.vessel) ?
                        <div>
                            {(props.vessel.responsibles && props.vessel.responsibles.length > 0) ? 
                                <>
                                    <div><strong>Nome: </strong>{props.vessel.responsibles[0].owner.fullname}</div>
                                    <div><strong>E-mail: </strong>{props.vessel.responsibles[0].owner.user?.email}</div>
                                    <div><strong>Telefone: </strong>{
                                        (props.vessel.responsibles[0].owner.phones.length > 0) ?
                                            phoneMask(props.vessel.responsibles[0].owner.phones[0].connection) : ''
                                    }</div>
                                </>
                                :
                                <>
                                    <div><strong>Nome: </strong>{
                                        props.vessel.ownerType === PERSON_PESSOA_JURIDICA ?
                                            props.vessel.owner.socialReason : props.vessel.responsibles[0].owner.socialReason
                                    }</div>
                                    <div><strong>E-mail: </strong>{props.vessel.responsibles[0].owner.email}</div>
                                    <div><strong>Telefone: </strong>{phoneMask(props.vessel.responsibles[0].owner.telephone)}</div>
                                </>
                            }
                        </div>
                        :
                        <div></div>
                    }
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <fieldset>
                    <div className="d-flex align-items-center justify-content-start mt-2">

                        <legend style={{width: 'fit-content'}}>{display.legend.vessel_identification}</legend>
                            <div className="float-right">
                                    <Button className="mb-2 ms-3" size="small" variant="contained" color="secondary" onClick={ (e: any) => handleFilterVesselModal(e)  }>
                                        {display.legend.search_vessel} <BsSliders className="ms-3" />
                                    </Button>
                            </div>
                            </div>
                        <Row>
                            <Col sm="3">
                                <FormGroup>
                                    <Form.Label htmlFor="form-enrollment">{display.label.enrollment}</Form.Label>
                                    <InputGroup className="mb-2">
                                        <FormControl id="form-enrollment" name="enrollment" placeholder={display.example.enrollment} required
                                            value={toString(props.searchVessel)}
                                            onChange={ e => props.setSearchVessel(e.target.value) }
                                        />
										<InputGroup.Text>
											<a href="/#" onClick={(e) => onClick_SearchVessel(e)}>
												<FaSearch />
											</a>
										</InputGroup.Text>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col sm="4">
                                <FormGroup>
                                    <Form.Label htmlFor="form-vessel-name">{display.label.vessel}</Form.Label>
                                    <FormControl id="form-vessel-name" name="vesselName" placeholder={display.example.vessel_name}
                                        value={props.vessel && toString(props.vessel.name)}
                                        onChange={(e => onChange_Vessel('name', e))}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="1">
                                <FormGroup>
                                    <Form.Label htmlFor="form-vessel-color">{display.label.color}</Form.Label>
                                    <Form.Control type="color" id="form-vessel-color" name="vesselColor" 
                                        value={!isEmpty(props.vessel) ? !isEmpty(props.vessel.color) ? `${props.vessel.color}` : '#000000' : '#000000'}
                                        onChange={(e => onChange_Vessel('color', e))}
                                        title="Selecione uma cor" 
                                        disabled
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="2">
                                <FormGroup>
                                    <Form.Label htmlFor="form-vessel-brand">{display.label.brand}</Form.Label>
                                    <FormControl id="form-vessel-brand" name="vesselBrand" placeholder={display.example.brand}
                                        value={props.vessel && toString(props.vessel.brand)}
                                        onChange={(e => onChange_Vessel('brand', e))}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="2">
                                <FormGroup>
                                    <Form.Label htmlFor="form-vessel-model">{display.label.model}</Form.Label>
                                    <FormControl id="form-vessel-model" name="vesselModel" placeholder={display.example.model}
                                        value={props.vessel && toString(props.vessel.model)}
                                        onChange={(e => onChange_Vessel('model', e))}
                                        readOnly
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <fieldset>
                        <legend>{display.legend.general_budget_information}</legend>
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <Form.Label htmlFor="form-comments">{display.legend.customer_complaint}</Form.Label>
                                    <Form.Control as="textarea" rows={3} type="" id="form-comments" name="comments" placeholder={display.example.comments} required
                                        value={toString(props.comments)}
                                        onChange={e => props.setComments(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <hr />
                                <FormGroup>
                                    <Form.Label htmlFor="form-stocks-item">{display.legend.products_and_services}</Form.Label>
                                    <div className="card-actions float-right">
                                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.add} {display.tooltips.responsible}</Tooltip>}>
                                            <a href="/#" onClick={(e) => { onClick_FilterStock(e) }}>
                                                <FaPlusSquare size={18} />
                                            </a>
                                        </OverlayTrigger>    
                                    </div>
                                    <TableContainer columns={props.columnsBudgetItems} data={props.budgetItems} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
            </Row>
            <Row>
                <Col sm="6">
                    <fieldset>
                        <legend>{display.legend.from_the_budget}</legend>
                        <Row>
                            <Col sm="12">
                                <FormGroup as={Row} className="form-row-divider">
                                    <Form.Label column htmlFor="form-mechanic">{display.label.mechanic}</Form.Label>
                                    <Col sm="8">
                                        <Form.Select id="form-mechanic" name="mechanic" 
                                            value={toString(props.mechanicId)}
                                            onChange={(e) => onChange_Mechanic(e)}
                                        >
                                            <option value={0}>...</option>
                                            {props.listMechanic.map((mechanic: any, index: number) => (
                                                <option key={index} value={mechanic.id}>{mechanic.people.fullname}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <FormGroup as={Row} className="form-row-divider">
                                    <Form.Label column htmlFor="form-situation">Situação</Form.Label>
                                    <Col sm="8">
                                        <Form.Select id="form-situation" name="situation" 
                                            value={toString(props.situation)}
                                            onChange={(e) => props.setSituation(e.target.value)}
                                            disabled
                                        >
                                            {isEmpty(props.situation) && <option value={0}>...</option>}
                                            {BudgetSituationType.map((iterator: any, idx: number) => (
                                                <option key={idx} value={iterator.id}>{iterator.description}</option>
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <FormGroup as={Row} className="form-row-divider">
                                    <Form.Label column htmlFor="form-payment-form">Pagamento</Form.Label>
                                    <Col sm="8">
                                        <Form.Select id="form-payment_form" name="paymentForm"
                                            value={props.paymentForm}
                                            onChange={(e: any) => props.setPaymentForm(e.target.value)}
                                        >
                                            {isEmpty(props.paymentForm) && <option value="">...</option>}
                                            {PaymentType.map((iterator: any, idx: any) => (<option key={idx} value={iterator.name}>{display.label[toLowerCase(iterator.name)]}</option>))}
                                        </Form.Select>
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <FormGroup as={Row} className="form-row-divider">
                                    <Form.Label column htmlFor="form-payment-conditions">Condições</Form.Label>
                                    <Col sm="8">
                                        <Form.Select id="form-payment-conditions" name="conditionsPayment" 
                                            value={toString(props.paymentConditions)}
                                            onChange={(e) => onChange_Conditions(e)}
                                        >
                                            {isEmpty(props.paymentConditions) && <option value="">...</option>}
                                            {PaymentConditionsType.map((iterator: any, idx: any) => (
                                                iterator.id === 0 ? (
                                                    <option key={idx} value={iterator.id}>{display.label[toLowerCase(iterator.name)]}</option>
                                                ) : (
                                                    <option key={idx} value={iterator.id}>{stringFormat(display.label[toLowerCase(iterator.name)], iterator.id)}</option>
                                                )
                                            ))}
                                        </Form.Select>
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
                <Col sm="6">
                    <Row>
                        <Col sm="12">
                            <fieldset>
                                <legend>{display.legend.general_values}</legend>
                                <Row>
                                    <Col sm="12">
                                        <FormGroup as={Row} className="form-row-divider">
                                            <Form.Label column htmlFor="form-product-cost">{display.label.products}</Form.Label>
                                            <Col>
                                                <Form.Control type="text" id="form-product-cost" name="productCost" placeholder={display.example.product_cost} required
                                                    className="text-right"
                                                    value={toString(props.productCost)}
                                                    onChange={e => props.setProductCost(floatMask(e.target.value))}
                                                    readOnly
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <FormGroup as={Row} className="form-row-divider">
                                            <Form.Label column htmlFor="form-office-cost">{display.label.services}</Form.Label>
                                            <Col>
                                                <Form.Control type="text" id="form-office-cost" name="officeCost" placeholder={display.example.office_cost} required
                                                    className="text-right"
                                                    value={toString(props.officeCost)}
                                                    onChange={e => props.setOfficeCost(floatMask(e.target.value))}
                                                    readOnly
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <FormGroup as={Row} className="form-row-divider">
                                            <Form.Label column htmlFor="form-discount-cost">{display.label.discounts} (%)</Form.Label>
                                            <Col>
                                                <Form.Control type="text" id="form-discount-cost" name="discountCost" placeholder={display.example.discount_cost} required
                                                    className="text-right"
                                                    value={toString(props.discountCost)}
                                                    onChange={e => onChange_Discount(e)}
                                                    onBlur={e => onBlur_Discount(e)}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <FormGroup as={Row} className="form-row-divider">
                                            <Form.Label column htmlFor="form-order-cost">{display.label.amount}</Form.Label>
                                            <Col>
                                                <Form.Control type="text" id="form-order-cost" name="orderCost" placeholder={display.example.order_cost} required
                                                    className="text-right"
                                                    value={toString(props.orderCost)}
                                                    onChange={e => props.setOrderCost(floatMask(e.target.value))}
                                                    readOnly
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );

}

export default BudgetRecord;
