import { toInt } from "./auxiliary-functions";

export function isEmail(value: string): boolean {
    var mail_regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/;
    return mail_regex.test(value);
}

export function isPassword(value: string): boolean {

    if (isEmpty(value))
        return false;
    if (value.length < 8)
        return false;
    if (value.length > 10)
        return false;
    if (value.search(/(?=.*[A-Z])/) < 0)
        return false;
    if (value.search(/(?=.*[a-z])/) < 0)
        return false;
    if (value.search(/([0-9])/) < 0)
        return false;
    if (value.search(/(?=.*[!@#$%&? "])/) < 0)
        return false;

    return true;
}

export function isDateFromMask(value: string): Boolean {
    var date_regex = /\d{2}\/\d{2}\/\d{4}\s[0-2][0-3]:[0-5][0-9]:[0-5][0-9]/gm;
    return date_regex.test(value);
}

export function isDate(value: any): boolean {
    var result = false;

    switch (typeof value) {
        case 'number':
            result = true;
            break;
        case 'string':
            result = !isNaN(Date.parse(value));
            break;
        case 'object':
            if (value instanceof Date) {
                result = !isNaN(value.getTime());
            }
            break;
        default:
            result = false;
    }
    return result;
}

export function isEmpty(value: any): boolean {

    if (typeof value === 'undefined')
        return true;

    if ((value === null) || (value === 'null'))
        return true;

    if (typeof value === 'string') {
        if (value.trim() === '')
            return true;
    } else {
        if (typeof value === 'object') {
            if (!(value instanceof Error) && !(value instanceof File))
                return Object.keys(value).length === 0;
        }
    }

    return false;
}

function calcDigitsPositions(digits: string, positions: number = 10, sumDigits: number = 0 ) {
    // Garante que o valor é uma string
    digits = digits.toString();

    // Faz a soma dos dígitos com a posição
    // Ex. para 10 posições:
    //   0    2    5    4    6    2    8    8   4
    // x10   x9   x8   x7   x6   x5   x4   x3  x2
    //   0 + 18 + 40 + 28 + 36 + 10 + 32 + 24 + 8 = 196
    for (let i = 0; i < digits.length; i++) {
        // Preenche a soma com o dígito vezes a posição
        let caracter: number = toInt(digits[i]);
        sumDigits = sumDigits + (caracter * positions);

        // Subtrai 1 da posição
        positions--;
        // Parte específica para CNPJ
        // Ex.: 5-4-3-2-9-8-7-6-5-4-3-2
        if ( positions < 2 ) {
            // Retorno a posição para 9
            positions = 9;
        }
    }

    // Captura o resto da divisão entre soma_digitos dividido por 11
    // Ex.: 196 % 11 = 9
    sumDigits = sumDigits % 11;
    // Verifica se soma_digitos é menor que 2
    if ( sumDigits < 2 ) {
        // soma_digitos agora será zero
        sumDigits = 0;
    } else {
        // Se for maior que 2, o resultado é 11 menos soma_digitos
        // Ex.: 11 - 9 = 2
        // Nosso dígito procurado é 2
        sumDigits = 11 - sumDigits;
    }

    // Concatena mais um dígito aos primeiro nove dígitos
    // Ex.: 025462884 + 2 = 0254628842
    let cpf = digits +''+ sumDigits;
    // Retorna
    return cpf;
} // calcDigitsPositions

/*
 Valida CPF
 Valida se for CPF
 @param  string cpf O CPF com ou sem pontos e traço
 @return bool True para CPF correto - False para CPF incorreto
*/
export function isCpf(value: string): boolean {

    let cpfOriginal = value.toString();
    cpfOriginal = cpfOriginal.replace(/[^0-9]/g, '');
    
    if (cpfOriginal === '00000000000' ||
        cpfOriginal === '11111111111' ||
        cpfOriginal === '22222222222' ||
        cpfOriginal === '33333333333' ||
        cpfOriginal === '44444444444' ||
        cpfOriginal === '55555555555' ||
        cpfOriginal === '66666666666' ||
        cpfOriginal === '77777777777' ||
        cpfOriginal === '88888888888' ||
        cpfOriginal === '99999999999')
        return false;

    let digits = cpfOriginal.substr(0, 9);
    // Faz o cálculo dos 9 primeiros dígitos do CPF para obter o primeiro dígito
    let newCpf = calcDigitsPositions( digits );
    // Faz o cálculo dos 10 dígitos do CPF para obter o último dígito
    newCpf = calcDigitsPositions( newCpf, 11 );

    if (newCpf === cpfOriginal) {
        return true;
    } else {
        return false;
    }
    
} // isValidCpf

/*
 valida_cnpj
 Valida se for um CNPJ
 @param string cnpj
 @return bool true para CNPJ correto
*/
export function isCnpj(value: string): boolean {

    value = value.toString();
    value = value.replace(/[^0-9]/g, '');

    let cnpjOriginal = value;

    if (cnpjOriginal === '00000000000000' ||
        cnpjOriginal === '11111111111111' ||
        cnpjOriginal === '22222222222222' ||
        cnpjOriginal === '33333333333333' ||
        cnpjOriginal === '44444444444444' ||
        cnpjOriginal === '55555555555555' ||
        cnpjOriginal === '66666666666666' ||
        cnpjOriginal === '77777777777777' ||
        cnpjOriginal === '88888888888888' ||
        cnpjOriginal === '99999999999999')
        return false;

    // Captura os primeiros 12 números do CNPJ
    let firstNumbersCnpj = value.substr(0, 12);
    // Faz o primeiro cálculo
    let firstCalculus = calcDigitsPositions(firstNumbersCnpj, 5);
    // O segundo cálculo é a mesma coisa do primeiro, porém, começa na posição 6
    let secondCalculus = calcDigitsPositions(firstCalculus, 6);
    // Concatena o segundo dígito ao CNPJ
    let cnpj = secondCalculus;

    // Verifica se o CNPJ gerado é idêntico ao enviado
    if (cnpj === cnpjOriginal) {
        return true;
    }
    // Retorna falso por padrão
    return false;
} // isValidCnpj

function checkCpfCnpj (value: string) {
    value = value.toString();
    value = value.replace(/[^0-9]/g, '');

    if (value.length === 11) {
        return 'CPF';
    } else if (value.length === 14) {
        return 'CNPJ';
    } else {
        return false;
    }
} // checkCpfCnpj

/*
 valida_cpf_cnpj
 Valida o CPF ou CNPJ
 @access public
 @return bool true para válido, false para inválido
*/
export function isValidCpfCnpj(value: string): boolean {
    let check = checkCpfCnpj(value);

    value = value.toString();
    value = value.replace(/[^0-9]/g, '');
    
    if ( check === 'CPF' ) {
        return isCpf(value);
    } else if ( check === 'CNPJ' ) {
        return isCnpj(value);
    } else {
        return false;
    }    
} // isValidCpfCnpj

