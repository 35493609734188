export enum SituationAppEnum {
    ACTIVE = 'Active',
    MAINTENANCE = 'Maintenance'
}
export const SITUATION_APP_ACTIVE = SituationAppEnum.ACTIVE;
export const SITUATION_APP_MAINTENANCE = SituationAppEnum.MAINTENANCE;

export enum UserProfileEnum {
    MASTER = '00', // Com poderes absoluto (configurações de ambiente, regras)

    ADMINISTRATOR = '01', // (Administração geral e de autorizações especificas)
    REGISTRATION = '02', // (Responsavel pelas manutenções cadastrais da aplicação)
    SUPPORT = '10', // (Atendimento diretos as marinas)
    COMMERCIAL = '20', // (Apoio as marinas, aprovações dos anuncios, ...)
    DISTRIBUTOR = '30', // (Cuida dos interesses do Partner)

    ASSOCIATE = '50', // (Com poderes absoluto da empresa)
    ASSOCIATE_OPERATOR_FINANCIAL = '51', // (Responsavel pela area financeira)
    ASSOCIATE_OPERATOR_SERVICE = '56', // (Serviço que envolve restaurante, lavagem)
    ASSOCIATE_OPERATOR_TRACK = '61', // (Responsaveis de pista "disponibilidade e logistica")
    ASSOCIATE_SUPERVISOR = '66', // (Aprovações e cancelamentos dos seviços)
    ASSOCIATE_MANAGER = '70', // (Visões operacionais)

    PROPRIETOR = '80', // (Visões e autorizações de servisos das embarcações)

    MONITORING = '90', // (Visões gerais das aplicações)
    ANY = '99' // Acesso restrito, funcionalidades especificas
};

export const USER_PROFILE_MASTER = UserProfileEnum.MASTER;
export const USER_PROFILE_ADMINISTRATOR = UserProfileEnum.ADMINISTRATOR;
export const USER_PROFILE_REGISTRATION = UserProfileEnum.REGISTRATION;
export const USER_PROFILE_SUPPORT = UserProfileEnum.SUPPORT;
export const USER_PROFILE_COMMERCIAL = UserProfileEnum.COMMERCIAL;
export const USER_PROFILE_DISTRIBUTOR = UserProfileEnum.DISTRIBUTOR;
export const USER_PROFILE_ASSOCIATE = UserProfileEnum.ASSOCIATE;
export const USER_PROFILE_ASSOCIATE_OPERATOR_FINANCIAL = UserProfileEnum.ASSOCIATE_OPERATOR_FINANCIAL;
export const USER_PROFILE_ASSOCIATE_OPERATOR_SERVICE = UserProfileEnum.ASSOCIATE_OPERATOR_SERVICE;
export const USER_PROFILE_ASSOCIATE_OPERATOR_TRACK = UserProfileEnum.ASSOCIATE_OPERATOR_TRACK;
export const USER_PROFILE_ASSOCIATE_SUPERVISOR = UserProfileEnum.ASSOCIATE_SUPERVISOR;
export const USER_PROFILE_ASSOCIATE_MANAGER = UserProfileEnum.ASSOCIATE_MANAGER;
export const USER_PROFILE_PROPRIETOR = UserProfileEnum.PROPRIETOR;
export const USER_PROFILE_MONITORING = UserProfileEnum.MONITORING;
export const USER_PROFILE_ANY = UserProfileEnum.ANY;

export enum UserSituationEnum {
    REGISTERED = 0, // registered user awaiting activation
    MODIFIED = 1, // modified token awaiting input of new password
    ACTIVED = 5, // active user
    DISCLAIMER = 6, // active user, contact the administration
    INACTIVE = 9 // inactive user, no access to the system
};

export const USER_SITUATION_REGISTERED = UserSituationEnum.REGISTERED;
export const USER_SITUATION_MODIFIED = UserSituationEnum.MODIFIED;
export const USER_SITUATION_ACTIVED = UserSituationEnum.ACTIVED;
export const USER_SITUATION_DISCLAIMER = UserSituationEnum.DISCLAIMER;
export const USER_SITUATION_INACTIVE = UserSituationEnum.INACTIVE;

export enum PartnerSituationEnum {
    REGISTERED = 0,
    ACTIVED = 5,
    INACTIVE = 9
};

export const PARTNER_SITUATION_REGISTERED = PartnerSituationEnum.REGISTERED;
export const PARTNER_SITUATION_ACTIVED = PartnerSituationEnum.ACTIVED;
export const PARTNER_SITUATION_INACTIVE = PartnerSituationEnum.INACTIVE;

export enum MemberSituationEnum {
    ACTIVED = 5,
    DISCLAIMER = 6,
    INACTIVE = 9
};

export const MEMBER_SITUATION_ACTIVED = MemberSituationEnum.ACTIVED;
export const MEMBER_SITUATION_DISCLAIMER = MemberSituationEnum.DISCLAIMER;
export const MEMBER_SITUATION_INACTIVE = MemberSituationEnum.INACTIVE;

export enum ContractSituationEnum {
    NOT_DEFINED = 0,
    INACTIVE = 1,
    ACTIVED = 2,
    CANCELED = 3
};

export const CONTRACT_SITUATION_NOT_DEFINED = ContractSituationEnum.NOT_DEFINED;
export const CONTRACT_SITUATION_INACTIVE = ContractSituationEnum.INACTIVE;
export const CONTRACT_SITUATION_ACTIVED = ContractSituationEnum.ACTIVED;
export const CONTRACT_SITUATION_CANCELED = ContractSituationEnum.CANCELED;

export enum GenderEnum {
    MASCULINE = 'M',
    FEMININE = 'F'
}

export const GENDER_MASCULINE = GenderEnum.MASCULINE;
export const GENDER_FEMININE = GenderEnum.FEMININE;

export enum PersonEnum {
    PESSOA_FISICA = 'PF',
    PESSOA_JURIDICA = 'PJ'
}

export const PERSON_PESSOA_FISICA = PersonEnum.PESSOA_FISICA;
export const PERSON_PESSOA_JURIDICA = PersonEnum.PESSOA_JURIDICA;

export enum ContractProgressEnum {
    ELABORATION = 1,
    FINANCIAL = 2,
    SIGNATURE = 3,
    FINISHED = 4
}

export const CONTRACT_PROGRESS_ELABORATION = ContractProgressEnum.ELABORATION;
export const CONTRACT_PROGRESS_FINANCIAL = ContractProgressEnum.FINANCIAL;
export const CONTRACT_PROGRESS_SIGNATURE = ContractProgressEnum.SIGNATURE;
export const CONTRACT_PROGRESS_FINISHED = ContractProgressEnum.FINISHED;

export enum BudgetSituationEnum {
    ACTIVED = 1,
    CANCELED = 5,
    MAINTENANCE = 7,
    DELIVERED = 9
};

export const BUDGET_SITUATION_ACTIVED = BudgetSituationEnum.ACTIVED;
export const BUDGET_SITUATION_CANCELED = BudgetSituationEnum.CANCELED;
export const BUDGET_SITUATION_MAINTENANCE = BudgetSituationEnum.MAINTENANCE;
export const BUDGET_SITUATION_DELIVERED = BudgetSituationEnum.DELIVERED;

export const BILLET_MODE_NONE = 0;
export const BILLET_MODE_NEW = 1;
export const BILLET_MODE_VIEW = 2;
export const BILLET_MODE_EDIT = 3;

export const FILTER_LAUNCHER_BY_CONTRACT = 1;
export const FILTER_LAUNCHER_BY_EXPIRATION = 2;
export const FILTER_LAUNCHER_BY_PAYMENT = 3;

export const TAM_USERNAME = 30;
export const TAM_EMAIL = 70;
export const TAM_TOKEN = 10;
export const TAM_AUTHORIZATION = 255;
export const TAM_PASSWORD = 60;
export const TAM_IP = 64;
export const TAM_DEVICE = 100;

export const TAM_PLACE = 100;
export const TAM_ZIPCODE = 8;
export const TAM_STATE = 2;
export const TAM_CITY = 50;
export const TAM_DISTRICT = 50;

export const TAM_DEFAULT = 70;
export const TAM_TRATMENT = 5;
export const TAM_NAME = 100;
export const TAM_GENDER = 1;
export const TAM_BLOOD = 10;
export const TAM_ETHNICITY = 30;
export const TAM_BREED = 30;
export const TAM_PHYSICAL_CHARACTERISTICS = 100;
export const TAM_CPF = 11;
export const TAM_REGISTRATION = 15; 
export const TAM_ENROLLMENT = 15; 
export const TAM_ISSUER = 10;
export const TAM_DRIVERS_LICENSE = 15;
export const TAM_DRIVERS_LICENSE_CATEGORY = 10;
export const TAM_LANDLINE = 10;
export const TAM_MOBILE = 11;
export const TAM_HABITATION = 10;
export const TAM_COMPLEMENT = 255;

export const TAM_SOCIAL_NORM = 15;
export const TAM_SOCIAL_ADDRESS = 70;
export const TAM_PHONE_NORM = 20;
export const TAM_PHONE_CONNECTION = 30;
export const TAM_OPERATOR = 5;
export const TAM_ADDRESS_NORM = 15;

export const TAM_COMMUNICATION_SOURCE = 10;
export const TAM_COMMUNICATION_DOCUMENT = 15;
export const TAM_COMMUNICATION_TITLE = 30;
export const TAM_COMMUNICATION_CONTENT = 255;

export const TAM_CNPJ = 14;
export const TAM_TELEPHONE = 11;

export const TAM_SECTOR = 30;
export const TAM_OFFICE = 30;
export const TAM_WORKDAY = 5;

export const TAM_TITLE = 30;
export const TAM_SUBTITLE = 30;
export const TAM_IMAGES = 255;
export const TAM_VIEWS = 20;
export const TAM_PATH = 100;
export const TAM_THEME = 10;
export const TAM_LOGO = 100;
export const TAM_COLOR = 15;

export const TAM_TYPE_VESSEL = 2;
export const TAM_CLASSIFICATION = 100;
export const TAM_HARBOR = 100;
export const TAM_HULL_MATERIAL = 100;
export const TAM_NUMBER_TIE = 15;
export const TAM_MAKER = 30;
export const TAM_SERIAL_NUMBER = 30;
export const TAM_TYPE_AND_MODEL = 100;

export const TAM_INSURANCE_NAME = 100;
export const TAM_POLICY_NUMBER = 30;
export const TAM_INSURANCE_CONTACT = 50;
export const TAM_TYPE_PEOPLE = 2;

export const TAM_VESSEL_MODEL = 30;
export const TAM_VESSEL_CATEGORY = 20;
export const TAM_GROUP = 30;
export const TAM_CATEGORY = 30;
export const TAM_LIMITS = 100;
export const TAM_COMMENTS = 255;
export const TAM_LOCATION = 100;

export const TAM_YEAR = 4;

export const TAM_UNIT = 10;
export const TAM_STATUS = 30;
export const TAM_ESTIMATED = 5;

export const TAM_BRAND = 100;
export const TAM_MODEL = 100;
export const TAM_SERIAL = 100;
export const TAM_ACTIVE = 100;
export const TAM_RESPONSIBLE = 100;

export enum PermissionEnum {
    DASHBOARD = 1,
    DASHBOARD_FINANCIAL = 11,
    DASHBOARD_MARITIME = 12,
    DASHBOARD_MONITOR = 13,
    DASHBOARD_MOVEMENT = 14,

    CADASTRE = 2,

    CADASTRE_CATEGORY = 21,
    CADASTRE_CATEGORY_ADD = 211,
    CADASTRE_CATEGORY_EDIT = 212,
    CADASTRE_CATEGORY_REMOVE = 213,
    CADASTRE_CATEGORY_VIEW = 214,

    CADASTRE_PRODUCT = 22,
    CADASTRE_PRODUCT_ADD = 221,
    CADASTRE_PRODUCT_EDIT = 222,
    CADASTRE_PRODUCT_REMOVE = 223,
    CADASTRE_PRODUCT_VIEW = 224,

    CADASTRE_PARTNER = 23,
    CADASTRE_PARTNER_ADD = 231,
    CADASTRE_PARTNER_EDIT = 232,
    CADASTRE_PARTNER_EDIT_SOCIETY = 2321,
    CADASTRE_PARTNER_EDIT_EMPLOYEE = 2322,
    CADASTRE_PARTNER_REMOVE = 233,
    CADASTRE_PARTNER_VIEW = 234,

    CADASTRE_MEMBER = 24,
    CADASTRE_MEMBER_ADD = 241,
    CADASTRE_MEMBER_EDIT = 242,
    CADASTRE_MEMBER_REMOVE = 243,
    CADASTRE_MEMBER_VIEW = 244,

    CADASTRE_VESSEL = 25,
    CADASTRE_VESSEL_ADD = 251,
    CADASTRE_VESSEL_EDIT = 252,
    CADASTRE_VESSEL_EDIT_AVATAR = 2521,
    CADASTRE_VESSEL_EDIT_RESTRICTION = 2522,
    CADASTRE_VESSEL_EDIT_GENERAL = 2523,
    CADASTRE_VESSEL_EDIT_ENGINE = 2524,
    CADASTRE_VESSEL_EDIT_INSURANCE = 2525,
    CADASTRE_VESSEL_EDIT_COST = 2526,
    CADASTRE_VESSEL_EDIT_TRACKER = 2527,
    CADASTRE_VESSEL_EDIT_OWNER = 2528,
    CADASTRE_VESSEL_EDIT_DRIVER = 2529,
    CADASTRE_VESSEL_EDIT_EQUIPMENT = 2530,
    CADASTRE_VESSEL_EDIT_DOCUMENTATION = 2531,
    CADASTRE_VESSEL_EDIT_DOCUMENTATION_VIEW = 25311,
    CADASTRE_VESSEL_EDIT_DOCUMENTATION_UPDATE = 25312,
    CADASTRE_VESSEL_EDIT_PHOTO = 2532,
    CADASTRE_VESSEL_REMOVE = 254,
    CADASTRE_VESSEL_VIEW = 255,

    CADASTRE_PURVEYOR = 26,
    CADASTRE_PURVEYOR_ADD = 261,
    CADASTRE_PURVEYOR_EDIT = 262,
    CADASTRE_PURVEYOR_REMOVE = 263,
    CADASTRE_PURVEYOR_VIEW = 264,

    CADASTRE_MECHANIC = 27,
    CADASTRE_MECHANIC_ADD = 271,
    CADASTRE_MECHANIC_EDIT = 272,
    CADASTRE_MECHANIC_REMOVE = 273,
    CADASTRE_MECHANIC_VIEW = 274,
    
    CADASTRE_EMPLOYEE = 28,
    CADASTRE_EMPLOYEE_ADD = 281,
    CADASTRE_EMPLOYEE_EDIT = 282,
    CADASTRE_EMPLOYEE_REMOVE = 283,
    CADASTRE_EMPLOYEE_VIEW = 284,

    CADASTRE_SOCIETY = 29,
    CADASTRE_SOCIETY_ADD = 291,
    CADASTRE_SOCIETY_EDIT = 292,
    CADASTRE_SOCIETY_REMOVE = 293,
    CADASTRE_SOCIETY_VIEW = 294,

    OPERATION = 3,

    OPERATION_MOVEMENT = 31,
    OPERATION_MOVEMENT_ADD = 311,
    OPERATION_MOVEMENT_EDIT = 312,
    OPERATION_MOVEMENT_EDIT_GENERAL = 3121,
    OPERATION_MOVEMENT_EDIT_CREW = 3122,
    OPERATION_MOVEMENT_EDIT_PASSENGER = 3123,
    OPERATION_MOVEMENT_EDIT_CONFERENCE = 3124,
    OPERATION_MOVEMENT_REMOVE = 313,
    OPERATION_MOVEMENT_VIEW = 314,
    
    OPERATION_HISTORY = 32,
    OPERATION_KEEP_BOAT = 33,
    OPERATION_ASSESSMENT = 34,

    OFFICE = 4,

    OFFICE_SCHEDULE = 41,
    OFFICE_SCHEDULE_ADD = 411,
    OFFICE_SCHEDULE_EDIT = 412,
    OFFICE_SCHEDULE_REMOVE = 413,
    OFFICE_SCHEDULE_VIEW = 414,
    
    OFFICE_BUDGET = 42,
    OFFICE_BUDGET_ADD = 421,
    OFFICE_BUDGET_EDIT = 422,
    OFFICE_BUDGET_REMOVE = 423,
    OFFICE_BUDGET_VIEW = 424,

    FINANCIAL = 5,

    FINANCIAL_CONTRACT = 51,
    FINANCIAL_CONTRACT_CREATE = 511,
    FINANCIAL_CONTRACT_EDIT = 512,
    FINANCIAL_CONTRACT_VIEW = 513,
    
    FINANCIAL_CONTRACT_MANAGEMENT = 52,
    FINANCIAL_CONTRACT_CUSTOMER = 53,
    
    FINANCIAL_LAUNCHER = 54,
    FINANCIAL_LAUNCHER_ADD = 541,
    FINANCIAL_LAUNCHER_MAKE_PAYMENT = 542,
    FINANCIAL_LAUNCHER_TRACK_STATUS = 543,
    FINANCIAL_LAUNCHER_GENERATE_TICKET = 544,
    FINANCIAL_LAUNCHER_VIEW_RECEIPT = 545,
    FINANCIAL_LAUNCHER_BILLET = 546,
    FINANCIAL_LAUNCHER_BILLET_UPDATE = 5461,
    FINANCIAL_LAUNCHER_BILLET_GENERATE = 5462,
    FINANCIAL_LAUNCHER_BILLET_VIEW = 5463,
    FINANCIAL_LAUNCHER_PAYMENT = 547,

    PROFILE = 6,
    CONFIGURATION = 7
}

export enum PaymentEnum {
    CASH = 'CASH',
    CARTON = 'CARTON',
    PIX = 'PIX',
    BILLET = 'BILLET'
}

export const PAYMENT_CASH = PaymentEnum.CASH;
export const PAYMENT_CARTON = PaymentEnum.CARTON;
export const PAYMENT_PIX = PaymentEnum.PIX;
export const PAYMENT_BILLET = PaymentEnum.BILLET;

export enum PaymentConditionsEnum {
    IN_CASH = 'IN_CASH',
    PARCEL = 'PARCEL'
}

export const PAYMENT_CONDITIONS_IN_CASH = PaymentConditionsEnum.IN_CASH;
export const PAYMENT_CONDITIONS_PARCEL = PaymentConditionsEnum.PARCEL;

export enum CategoryEnum {
    VESSEL = 1,
    PRODUCT = 2,
    SERVICE = 3
};

export const CATEGORY_VESSEL = CategoryEnum.VESSEL;
export const CATEGORY_PRODUCT = CategoryEnum.PRODUCT;
export const CATEGORY_SERVICE = CategoryEnum.SERVICE;

export const VESSEL_IATE = 30;
export const VESSEL_JETSKI = 33;
export const VESSEL_LANCHA = 34;
export const VESSEL_BARCO = 35;
export const VESSEL_JETBOAT = 36;
export const VESSEL_FLEXBOAT = 37;
export const VESSEL_VOADEIRA = 38;
export const VESSEL_VELEIRO = 39;

export const MOVE_WAIT = 0;
export const MOVE_DOWN = 1;
export const MOVE_UP = 2;
