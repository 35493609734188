import React from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../services/auth.service';

function MenuItem(props: any) {

    const display = props.translate.data;

    const navigate = useNavigate();

    const { name, subMenus, iconClassName, onClick, to, expanded } = props;

    function onClick_Item(event: any, link: string) {
        event.preventDefault();
        let force: boolean = false;
        if (typeof link === 'string') {
            if (link.indexOf('dashboard') > -1)
                force = true
            if (link.indexOf('schedule') > -1)
                force = true
            if (link.indexOf('settings') > -1)
                force = true
        }
        props.onCloseSideMenu(force);
        navigate(link);
    }

    return (
        <>
            {subMenus.length === 0 ? (
                <li className="sidebar-item ">
                    <span className="sidebar-link" onClick={(e: any) => onClick_Item(e, to) }>
                        {iconClassName}
                        <span className="align-middle">{display.label[name]}</span>
                    </span>
                </li>
            ) : (
                <li className="sidebar-item " onClick={onClick}>
                    <span data-toggle="collapse" className={`sidebar-link ${expanded && !expanded.isOpened ? "collapsed" : ""}`} aria-expanded={expanded && expanded.isOpened}>
                        {iconClassName}
                        <span className="align-middle">{display.label[name]}</span>
                    </span>
                    <div className={`collapse ${expanded && expanded.isOpened ? "show" : ""}`}>
                        {subMenus && subMenus.length > 0 ? (
                        <ul id="item" className="sidebar-dropdown list-unstyled">
                            {subMenus.map((menu: any, index: any) => (
                                !menu.divider ? (
                                    authService.permissionIn(name +'_'+ menu.name) && <li className="sidebar-item" key={index}>
                                        <span className="sidebar-link" onClick={(e: any) => onClick_Item(e, menu.to) }>
                                            {menu.iconClassName}
                                            <span className="align-middle">{display.label[menu.name+'_menu']}</span>
                                        </span>
                                    </li>
                                ) : <li className="sidebar-item" key={index}><span className="sidebar-link"><span className="sidebar-divider"></span></span></li>
                            ))}
                        </ul>
                        ) : null}
                    </div>
                </li>
            )}
        </>
    );

};

export default MenuItem;
