import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import { useAlertMessageContext } from "../../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../../contexts/confirm-message.context";
import cduService from "../../../services/cdu.service";
import lclService from "../../../services/lcl.service";
import { dateToString, notNullToDate } from "../../../utilities/auxiliary-functions";
import { cpfMask, numberMask, phoneMask, removeFormatDate, removeMask, zipcodeMask } from "../../../utilities/masks";
import { isCpf, isEmpty } from "../../../utilities/validators";
import MemberRecord from "./records/member.record";
import { Button } from '@mui/material'
import { AlertMessageEnum } from "../../../utilities/types";
import { openDocument } from "../../../utilities/view-document";

interface Props {
    translate: any,
    partner: any

    title: string,

    memberId: string,
    setRecord: any
}

function MemberCadastreForm(props: Props) {

    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();

    const display = props.translate.data;

    const [ invalidatedForm, setInvalidatedForm ] = useState(false);
    const [ isEditForm, setEditForm ] = useState(true);

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ originRecord, setOriginRecord ] = useState({} as any);
    const [ sending, setSending ] = useState(false);

    /* TRATAMENTO PARTICULAR DO OBJETO QUE ESTA SENDO MANTIDO */
    // Member
    const [ memberId, setMemberId ] = useState(0);
    const [ situation, setSituation ] = useState(0);
    const [ created, setCreated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ updated, setUpdated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));

    // PersonalData
    const [ peopleId, setPeopleId ] = useState(0); 
    const [ tratament, setTratament ] = useState(''); 
    const [ fullname, setFullname ] = useState(''); 
    const [ gender, setGender ] = useState('');
    const [ birth, setBirth ] = useState('');

    // Phone
    const [ phoneId, setPhoneId ] = useState(0);
    const [ norm, setNorm ] = useState('');
    const [ operator, setOperator ] = useState('');
    const [ connection, setConnection ] = useState('');
    const [ sms, setSms ] = useState(0);
    const [ zap, setZap ] = useState(0);
    const [ major, setMajor ] = useState(0);

    const [ nationality, setNationality ] = useState('');
    const [ naturalness, setNaturalness ] = useState('');

    // PersonalDocument
    const [ registrationPf, setRegistrationPf ] = useState('');
    const [ registrationIdentity, setRegistrationIdentity ] = useState('');
    const [ issuerIdentity, setIssuerIdentity ] = useState('');
    const [ stateIdentity, setStateIdentity ] = useState('');
    const [ expeditionIdentity, setExpeditionIdentity ] = useState('');
    const [ driversLicense, setDriversLicense ] = useState('');
    const [ categoryDriversLicense, setCategoryDriversLicense ] = useState('');
    const [ validityDriversLicense, setValidityDriversLicense ] = useState('');
    // Maritime License
    const [ documentNauticalId, setDocumentNauticalId ] = useState(0);
    const [ categoryNaval, setCategoryNaval ] = useState('');
    const [ enrollmentNaval, setEnrollmentNaval ] = useState('');
    const [ validityNaval, setValidityNaval ] = useState('');
    const [ limitsNaval, setLimitsNaval ] = useState('');
    const [ commentsNaval, setCommentsNaval ] = useState('');
    const [ locationNaval, setLocationNaval ] = useState('');
    const [ expeditionNaval, setExpeditionNaval ] = useState('');
    // Address
    const [ addressId, setAddressId ] = useState(0);
    const [ zipcode, setZipcode ] = useState('');
    const [ state, setState ] = useState('');
    const [ city, setCity ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ place, setPlace ] = useState('');
    const [ habitation, setHabitation ] = useState('');
    const [ complement, setComplement ] = useState('');

    type ArtifactType = { id : number, norm : string, source : string, identy : string, document : string, filename : string };
    const DEFAULT_ARTIFACT: ArtifactType = { id : 0, norm : '', source : '', identy : '', document : '', filename : '' };

    const [ driversLicenseDocument, setDriversLicenseDocument ] = useState(DEFAULT_ARTIFACT);
    const [ proofOfResidenceDocument, setProofOfResidenceDocument ] = useState(DEFAULT_ARTIFACT);
    const [ image, setImage ] = useState(''); 
    const [ email, setEmail ] = useState(''); 
    const [ userId, setUserId ] = useState(0); 

    function fillRecord(member: any) {

        if (!isEmpty(member)) {
            setMemberId(member.id);
            setSituation(member.situation);
            setCreated(notNullToDate(member.created, 'dd/mm/yyyy HH:MM:ss'));  
            setUpdated(notNullToDate(member.updated, 'dd/mm/yyyy HH:MM:ss'));  
            setPeopleId(member.peopleId)

            fillRecordPeople(member.people);
        }
    } // fillRecord

    function fillRecordPeople(people: any) {

        if (!isEmpty(people)) {
            setPeopleId(people.id);
            setTratament(people.tratament);
            setFullname(people.fullname);
            setGender(people.gender);
            setBirth(notNullToDate(people.birth, 'dd/mm/yyyy HH:MM:ss'));
            setEmail(people.email);
            setImage(people.image);
            setUserId(people.userId);

            if (!isEmpty(people.phones)) {
                const phone = people.phones[0];

                setPhoneId(phone.id);
                setNorm(phone.norm);
                setOperator(phone.operator);
                setConnection(phoneMask(phone.connection));
                setSms(phone.sms);
                setZap(phone.zap);
                setMajor(phone.major);
            }

            if (!isEmpty(people.documentsNautical)) {
                const documentNautical = people.documentsNautical[0];
                
                setDocumentNauticalId(documentNautical.id);
                setCategoryNaval(documentNautical.category);
                setEnrollmentNaval(documentNautical.enrollment);
                setValidityNaval(notNullToDate(documentNautical.validity, 'dd/mm/yyyy'));
                setLimitsNaval(documentNautical.limits);
                setCommentsNaval(documentNautical.comments);
                setLocationNaval(documentNautical.location);
                setExpeditionNaval(notNullToDate(documentNautical.expedition, 'dd/mm/yyyy'));
            }

            setNationality(people.nationality);
            setNaturalness(people.naturalness);

            setRegistrationPf(cpfMask(people.registrationPf));
            setRegistrationIdentity(numberMask(people.registrationIdentity));
            setIssuerIdentity(people.issuerIdentity);
            setStateIdentity(people.stateIdentity);
            setExpeditionIdentity(notNullToDate(people.expeditionIdentity, 'dd/mm/yyyy'));
            setDriversLicense(numberMask(people.driversLicense));
            setCategoryDriversLicense(people.categoryDriversLicense);
            setValidityDriversLicense(notNullToDate(people.validityDriversLicense, 'dd/mm/yyyy'));
            
            if (!isEmpty(people.adresses)) {
                const addressPeople = people.adresses[0];

                setAddressId(addressPeople.addressId);
                setZipcode(zipcodeMask(addressPeople.address.zipcode));
                setState(addressPeople.address.state);
                setCity(addressPeople.address.city);
                setDistrict(addressPeople.address.district);
                setPlace(addressPeople.address.place);

                setHabitation(addressPeople.habitation);
                setComplement(addressPeople.complement);
            }
        }
    }

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(fullname))
            emptyRequired = true;
        if (isEmpty(registrationPf))
            emptyRequired = true;
        if ((!isEmpty(registrationPf)) && (!isCpf(registrationPf))) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, display.message.invalid.registration_pf);
            result = false;
        }

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedForm(!result);
        return result;
    } // isValidForm

    function onClick_CancelMember(event: any) {
        event.preventDefault();
        props.setRecord(event, 0);
    } // onClick_CancelMember

    function fillMember() {

        const adresses = [];
        adresses[0] = {
            peopleId : peopleId,
            addressId : addressId,
            address : {
                id : addressId,
                zipcode : removeMask(zipcode),
                state : state,
                city : city,
                district : district,
                place : place
            },
            correspondence : 1,
            norm : 'residential',
            habitation,
            complement
        };

        const phones = [];
        phones[0] = {
            id : phoneId,
            norm, 
            operator,
            connection : removeMask(connection),
            sms,
            zap,
            major
        };

        const documentsNautical = [];
        documentsNautical[0] = {
            id : documentNauticalId,
            category : categoryNaval,
            enrollment : enrollmentNaval,
            validity : removeFormatDate(validityNaval),
            limits : limitsNaval,
            comments : commentsNaval,
            location : locationNaval,
            expedition : removeFormatDate(expeditionNaval)
        };

        const dataMember = {
            id : memberId,
            situation, 
            created : removeFormatDate(created), 
            updated : removeFormatDate(updated),
            partnerId : props.partner.id,
            peopleId,
            people : {
                id: peopleId,
                tratament,
                fullname, 
                gender, 
                birth : removeFormatDate(birth), 
                email,
                phones, 
                documentsNautical,
                nationality, 
                naturalness, 
                registrationPf : removeMask(registrationPf),
                registrationIdentity : removeMask(registrationIdentity),
                issuerIdentity,
                stateIdentity,
                expeditionIdentity : removeFormatDate(expeditionIdentity), 
                driversLicense : removeMask(driversLicense),
                categoryDriversLicense,
                validityDriversLicense : removeFormatDate(validityDriversLicense), 
                adresses,
                image,
                userId
            }
        };

        return dataMember;
    } // fillMember

    async function onClick_SaveMember(event: any) {
        event.preventDefault();
        
        const isValid = await isValidForm();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(props.title, display.message.confirm_record);
                if (isConfirmed) {
                    setSending(true);

                    const member = fillMember();
                    if (isEditForm)
                        await lclService.saveMember(member.id, member);
                    else
                        await lclService.createMember(member);

                    const dataArtifacts = [] as any;
                    dataArtifacts.push(driversLicenseDocument);
                    dataArtifacts.push(proofOfResidenceDocument);

                    for ( let i = 0; i < dataArtifacts.length; i++) {
                        const dataArtifact = dataArtifacts[i];

                        if (dataArtifact) {
                            dataArtifact.source = 'PEOPLE';
                            dataArtifact.identy = String(member.peopleId);
        
                            if ((dataArtifact.id > 0) && !isEmpty(dataArtifact.document)) {
                                await cduService.saveArtifact(dataArtifact.id, dataArtifact);
                            } else if ((dataArtifact.id > 0) && isEmpty(dataArtifact.document)) {
                                await cduService.artifactDeleteById(dataArtifact.id);
                            } else if ((dataArtifact.id === 0) && !isEmpty(dataArtifact.document)) {
                                await cduService.createArtifact(dataArtifact);
                            }
                        }
                    }

                    setSending(false);
                    await alertContext.show(AlertMessageEnum.SUCCESS, props.title, display.message.the_record_has_been_saved_successfully);
                    props.setRecord(event, 0);
                }
            } catch (error: any) {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
            }
        }
    } // onClick_SaveMember

    async function onClick_SearchPeople(event: any) {
        event.preventDefault();

        if (!isEmpty(registrationPf)) {
            const registration = removeMask(registrationPf);
            if (isCpf(registration)) {
                const people = await cduService.peopleByRegistration(registration);
                fillRecordPeople(people);
                if (!isEmpty(people)) {
                    const attributes = [] as any;
                    const where = { peopleId : people.id };
                    const order = [] as any;
                    const members = await lclService.memberFilter(attributes, where, order);
                    if (members.length > 0) {
                        await alertContext.show(AlertMessageEnum.SUCCESS, props.title, 'CPF já cadastrado.');
                        props.setRecord(event, 0);
                    }
                } else 
                    await alertContext.show(AlertMessageEnum.SUCCESS, props.title, 'CPF não localizado. Efetue o cadastro completo.');
            } else {
                await alertContext.show(AlertMessageEnum.FAIL, props.title, 'CPF Inválido !');
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, 'Insira um CPF válido !');
        }
    }

    async function searchParam() {
        try {
            if (!Number.isNaN(Number.parseInt(props.memberId ||''))) {
                setEditForm(true);

                const member = await lclService.memberById(Number(props.memberId));
                setOriginRecord(member);
                fillRecord(member);
                
                cduService.artifactByNorm('PEOPLE', member.people.id, 'FILE_DRIVER').then((artifact: any) => {
                    if (artifact) {
                        artifact.filename = `Atualizado em ${notNullToDate(artifact.updated, 'dd/mm/yyyy HH:MM:ss')}`
                        setDriversLicenseDocument(artifact);
                    }
                });
                cduService.artifactByNorm('PEOPLE', member.people.id, 'FILE_RESIDENCE').then((artifact: any) => {
                    if (artifact) {
                        artifact.filename = `Atualizado em ${notNullToDate(artifact.updated, 'dd/mm/yyyy HH:MM:ss')}`
                        setProofOfResidenceDocument(artifact);
                    }
                });
            } else {
                setEditForm(false);
            }
        } catch(error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
        }
    } // searchParam

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            searchParam();
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []); // useEffect

    function changeDriversLicenseDocument(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let artfact: ArtifactType = {
                    id : driversLicenseDocument.id,
                    norm : 'FILE_DRIVER',
                    source : 'PEOPLE',
                    identy : String(peopleId),
                    document : e.target.result,
                    filename : event.target.files[0].name
                }
                setDriversLicenseDocument(artfact);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            let artifact: ArtifactType = { id : driversLicenseDocument.id, norm : 'FILE_DRIVER', source : 'PEOPLE', identy : String(peopleId), document : '', filename : '' };
            setDriversLicenseDocument(artifact);
        }
    }

    function changeProofOfResidenceDocument(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let artfact: ArtifactType = {
                    id : proofOfResidenceDocument.id,
                    norm : 'FILE_RESIDENCE',
                    source : 'PEOPLE',
                    identy : String(peopleId),
                    document : e.target.result,
                    filename : event.target.files[0].name
                }
                setProofOfResidenceDocument(artfact);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            let artifact: ArtifactType = { id : proofOfResidenceDocument.id, norm : 'FILE_RESIDENCE', source : 'PEOPLE', identy : String(peopleId), document : '', filename : '' };
            setProofOfResidenceDocument(artifact);
        }
    }

    function clickViewDocument(event: any, title: string, url: any) {
        event.preventDefault();
        openDocument(title, url);
    }

    function changeImage(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                setImage(e.target.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            setImage('');
        }
    }

    function viewCadastreForm() {

        return (
            <Container fluid className="page-body member">
                <Row>
                    <Col sm={12} className="page-sweet">
                        <Card>
                            <Card.Header>
                                {(originRecord.created || originRecord.updated) && (
                                    <div className="form-identy-registry float-right">
                                        <span className="white-space"><b>Criação: </b> {dateToString(originRecord.created, 'dd/mm/yyyy HH:MM:ss')}</span>
                                        <span className="white-space"><b>Atualização: </b> {dateToString(originRecord.updated, 'dd/mm/yyyy HH:MM:ss')}</span>
                                    </div>
                                )}
                                <Card.Title>{display.legend.record}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form className="form" id="form-cadastre" validated={invalidatedForm}>
                                    <MemberRecord
                                        translate={props.translate}

                                        title={props.title}
                                        isEdit={isEditForm}

                                        created={created}
                                        updated={updated}

                                        tratament={tratament}
                                        fullname={fullname}
                                        gender={gender}
                                        birth={birth}
                                        norm={norm}
                                        operator={operator}
                                        connection={connection}
                                        sms={sms}
                                        zap={zap}
                                        major={major}
                                        nationality={nationality}
                                        naturalness={naturalness}
                                        registrationPf={registrationPf}
                                        registrationIdentity={registrationIdentity}
                                        issuerIdentity={issuerIdentity}
                                        stateIdentity={stateIdentity}
                                        expeditionIdentity={expeditionIdentity}
                                        driversLicense={driversLicense}
                                        categoryDriversLicense={categoryDriversLicense}
                                        validityDriversLicense={validityDriversLicense}
                                        zipcode={zipcode}
                                        state={state}
                                        city={city}
                                        district={district}
                                        place={place}
                                        habitation={habitation}
                                        complement={complement}
                                        email={email}
                                        categoryNaval={categoryNaval}
                                        enrollmentNaval={enrollmentNaval}
                                        validityNaval={validityNaval}
                                        limitsNaval={limitsNaval}
                                        commentsNaval={commentsNaval}
                                        locationNaval={locationNaval}
                                        expeditionNaval={expeditionNaval}
                                        image={image}
                                        setCreated={(value: any) => { setCreated(value); }}
                                        setUpdated={(value: any) => { setUpdated(value); }}
                                        setTratament={(value: any) => { setTratament(value); }}
                                        setFullname={(value: any) => { setFullname(value); }}
                                        setGender={(value: any) => { setGender(value); }}
                                        setBirth={(value: any) => { setBirth(value); }}
                                        setNorm={(value: any) => { setNorm(value); }}
                                        setOperator={(value: any) => { setOperator(value); }}
                                        setConnection={(value: any) => { setConnection(value); }}
                                        setSms={(value: any) => { setSms(value); }}
                                        setZap={(value: any) => { setZap(value); }}
                                        setMajor={(value: any) => { setMajor(value); }}
                                        setNationality={(value: any) => { setNationality(value); }}
                                        setNaturalness={(value: any) => { setNaturalness(value); }}
                                        setRegistrationPf={(value: any) => { setRegistrationPf(value); }}  
                                        setRegistrationIdentity={(value: any) => { setRegistrationIdentity(value); }}  
                                        setIssuerIdentity={(value: any) => { setIssuerIdentity(value); }}  
                                        setStateIdentity={(value: any) => { setStateIdentity(value); }}  
                                        setExpeditionIdentity={(value: any) => { setExpeditionIdentity(value); }}  
                                        setDriversLicense={(value: any) => { setDriversLicense(value); }}  
                                        setCategoryDriversLicense={(value: any) => { setCategoryDriversLicense(value); }} 
                                        setValidityDriversLicense={(value: any) => { setValidityDriversLicense(value); }} 
                                        setZipcode={(value: any) => { setZipcode(value); }}
                                        setState={(value: any) => { setState(value); }}
                                        setCity={(value: any) => { setCity(value); }}
                                        setDistrict={(value: any) => { setDistrict(value); }}
                                        setPlace={(value: any) => { setPlace(value); }}
                                        setHabitation={(value: any) => { setHabitation(value); }}
                                        setComplement={(value: any) => { setComplement(value); }}
                                        setEmail={(value: any) => { setEmail(value); }}
                                        setCategoryNaval={(value: any) => { setCategoryNaval(value); }}
                                        setEnrollmentNaval={(value: any) => { setEnrollmentNaval(value); }}
                                        setValidityNaval={(value: any) => { setValidityNaval(value); }}
                                        setLimitsNaval={(value: any) => { setLimitsNaval(value); }}
                                        setCommentsNaval={(value: any) => { setCommentsNaval(value); }}
                                        setLocationNaval={(value: any) => { setLocationNaval(value); }}
                                        setExpeditionNaval={(value: any) => { setExpeditionNaval(value); }}

                                        onClick_SearchPeople={(value: any) => { onClick_SearchPeople(value); }}

                                        driversLicenseDocument={driversLicenseDocument}
                                        proofOfResidenceDocument={proofOfResidenceDocument}
                                        changeDriversLicenseDocument={(event: any) => { changeDriversLicenseDocument(event); }}
                                        changeProofOfResidenceDocument={(event: any) => { changeProofOfResidenceDocument(event); }}
                                        clickViewDocument={clickViewDocument}

                                        changeImage={(event: any) => { changeImage(event); }} 
                                    />
                                </Form>
                            </Card.Body>
                            <Card.Footer className="text-right">
                                <Button className="me-2" onClick={ (e) => onClick_CancelMember(e) } variant="contained" color="secondary">{display.buttom.cancel}</Button>
                                <Button onClick={ (e) => onClick_SaveMember(e) } disabled={sending} variant="contained" color="primary">
                                    { sending ? <Spinner className="me-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSave className="me-2" size={22} /> } {' '}
                                    {display.buttom.save}
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewCadastreForm

    return (
        <>
            { viewCadastreForm() }
        </>
    );

}

export default MemberCadastreForm;
