import { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import SettingHeader from "../headers/settings.header";

interface Props {
    translate: any,
    
    onClick_ShowDefinedPartner: any
}

function SettingsPage(props: Props) {

    const display = props.translate.data;
    const title = display.title.settings;
    const description = display.description.settings;

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);

    async function initialize() {

    }

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            initialize();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []);

    function viewPage() {

        return (
            <Container fluid className="page-body settings">
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Card.Title>Grupo de Configuração</Card.Title>
                            </Card.Header>
                            <Card.Body>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );    
    } // viewPage

    return (
        <div className="page">
            <SettingHeader title={title} description={description} onClick_ShowDefinedPartner={props.onClick_ShowDefinedPartner} />
            { viewPage() }
        </div>
    )
}

export default SettingsPage;
