import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CadastreHeader from "../headers/cadastre.header";
import PartnerCadastreForm from "./forms/partner.cadastre.form";
import PartnerCadastreList from "./lists/partner.cadastre.list";
import authService from "../../services/auth.service";

interface Props {
    translate: any,
    partner: any,

    onClick_ShowDefinedPartner: any
}

function PartnerCadastre(props: Props) {

    const { partnerId } = useParams();
    const display = props.translate.data;

    const title = display.title.partner;
    const description = '';
    
    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ recordSelected, setRecordSelected ] = useState({ param : partnerId || '', selected : ((partnerId === 'new') || (partnerId === '0') || (!Number.isNaN(Number.parseInt(partnerId ||'')))) });

    function setSelected(isRecord: boolean, recordId: string) {
        setRecordSelected({ param : recordId, selected : isRecord });
    }

    async function initialize() {
        let selected = { param : '', selected : false } as any;
        if (authService.isAssociate()) {
            selected.param = props.partner.id;
            selected.selected = true;
        }
        setRecordSelected(selected);
    }
  
    useEffect(() => {
        if (!isLoading && !reloadPage) {
            initialize();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []);
    
    return (
        <div className="page">
            <CadastreHeader title={title} description={description} onClick_ShowDefinedPartner={props.onClick_ShowDefinedPartner} />
            {(recordSelected.selected) ? (
                <PartnerCadastreForm 
                    translate={props.translate} 
                    partner={props.partner}
                    title={title}
                    partnerId={recordSelected.param}
                    setRecord={(event: any) => { 
                        event.preventDefault();
        
                        setSelected(false, '0'); 
                    }} />
            ) : (
                <PartnerCadastreList 
                    translate={props.translate} 
                    partner={props.partner}
                    title={title}
                    setRecord={(event: any, recordId: string) => { 
                        event.preventDefault();
        
                        setSelected(true, recordId); 
                    }} />
            )}
        </div>
    )
}

export default PartnerCadastre;
