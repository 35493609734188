import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CadastreHeader from "../headers/cadastre.header";
import MechanicCadastreForm from "./forms/mechanic.cadastre.form";
import MechanicCadastreList from "./lists/mechanic.cadastre.list";

interface Props {
    translate: any,
    partner: any,

    onClick_ShowDefinedPartner: any
}

function MechanicCadastre(props: Props) {

    const { mechanicId } = useParams();
    const display = props.translate.data;

    const title = display.title.mechanic;
    const description = '';

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ recordSelected, setRecordSelected ] = useState({ param : mechanicId || '', selected : ((mechanicId === 'new') || (mechanicId === '0') || (!Number.isNaN(Number.parseInt(mechanicId ||'')))) });

    function setSelected(isRecord: boolean, recordId: string) {
        setRecordSelected({ param : recordId, selected : isRecord });
    }

    async function initialize() {
    }
    
    useEffect(() => {
        if (!isLoading && !reloadPage) {
            initialize();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []);
    
    return (
        <div className="page">
            <CadastreHeader title="Mecânicos" description={description} onClick_ShowDefinedPartner={props.onClick_ShowDefinedPartner} />
            {(recordSelected.selected) ? (
                <MechanicCadastreForm 
                    translate={props.translate} 
                    partner={props.partner}
                    title={title}
                    mechanicId={recordSelected.param}
                    setRecord={(event: any) => { 
                        event.preventDefault();
        
                        setSelected(false, '0'); 
                    }} />
            ) : (
                <MechanicCadastreList
                    translate={props.translate} 
                    partner={props.partner}
                    title={title}
                    setRecord={(event: any, recordId: string) => { 
                        event.preventDefault();
        
                        setSelected(true, recordId); 
                    }} />
            )}
        </div>
    )
}

export default MechanicCadastre;
