import { useEffect, useMemo, useState } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import TableContainer from "../../components/table-container.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import cduService from "../../services/cdu.service";
import { toLowerCase } from "../../utilities/auxiliary-functions";
import { AlertMessageEnum, getCategory } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";
import { Button } from '@mui/material'
import { FaTimes } from "react-icons/fa";


interface Props {
    translate: any,
    team: any,
    categoryNotInList: any,

    show: any,
    onClick_Close: any,
    onClick_Confirm: any,
}

function CategoryFilter(props: Props) {
    
    const alertContext = useAlertMessageContext();

    const display = props.translate.data;
    const title : string = display.title.category_filter +' '+ display.label[toLowerCase(getCategory(props.team).name)];

    const [record, setRecord] = useState({} as any);
    const [records, setRecords ] = useState([] as any);

    const columnsRecord = useMemo(() => [{
        Header: display.label.group,
        accessor: "group"
    },{
        Header: display.label.subgroup,
        accessor: "subgroup"
    },{
        Header: display.label.name,
        accessor: "name",
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }], []); // columnsRecord

    async function initialize() {
        try {
            if (props.show) {
                const attributes = [] as any;
                const where = {
                    team : props.team
                } as any;
                const order = [] as any;

                where['team'] = props.team;

                let categories = await cduService.categoryFilter(attributes, where, order);
                setRecords(categories);
                setRecord({} as any);
            }
        } catch(error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
        }
    }

    useEffect(() => {
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]); // useEffect
    
    return (
        <>
            <Modal
                size="lg"
                show={props.show}
                centered
            >
                <Modal.Header>
                    <Modal.Title><FaSearch />{title}</Modal.Title>
                    <div className="card-actions float-right">
                        <FaTimes className="isClickable" size={22} onClick={props.onClick_Close} />
                    </div>
                </Modal.Header>
                <Modal.Body style={{ minHeight : 400 }}>
                    <Row>
                        <Col>
                            <TableContainer columns={ columnsRecord } data={ records } viewFilter={true} viewPagination={true} setSelectedRow={ 
                                (event: any, dataRow: any) => { 
                                    setRecord(dataRow); 
                                }}
                            />
                        </Col>
                    </Row>                    
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col sm="8">
                                <div className="filter-selectred"><strong>{display.label.selected_record}:</strong> <span>{!isEmpty(record) ? record.group +' \\ '+ (isEmpty(record.subgroup) ? record.name : record.subgroup +' \\ '+ record.name) : ''}</span></div>
                            </Col>
                            <Col sm="4">
                                <Button variant="contained" color="secondary" className="ms-2" onClick={ (e) => props.onClick_Close(e) } >{display.buttom.cancel}</Button>{' '}
                                <Button variant="contained" color="primary" onClick={ (e) => props.onClick_Confirm(e, record) }  disabled={isEmpty(record)}>{display.buttom.confirm}</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CategoryFilter;
