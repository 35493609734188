export function openDocument(title: string, url: any) {

    const type = url.substring(5, url.indexOf('base64') - 1);
    if ((type === "application/pdf")
        || (type === "image/jpeg")
        || (type === "image/png")) {
        var win = window.open('', '',
            (type === "application/pdf")
            ? `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=yes,width=800,height=830,top=0,left=0`
            : `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=yes,width=800,height=600,top=0,left=0`
        );
        
        if (win) {
            win.document.write(`<html>`);
            win.document.write(`<head>`);
            win.document.write(`<title>${title}</title>`);
            win.document.write(`<meta http-equiv="Content-Type" content="text/html; charset=iso-8859-1" />`);
            win.document.write(`<style rel="stylesheet" type="text/css">body { font-size: 1em; color: #000000; background: #303A4A; margin: 0; padding: ${(type === "application/pdf") ? '0' : '5px'}; }</style>`);
            win.document.write(`</head>`);
            win.document.write(`<body>`);
            win.document.write(`<object type="${type}" width="100%" height="100%" data="${url}">The “object” tag is not supported by your browser.</object>`);
            win.document.write(`</body>`);
            win.document.write(`</html>`);
        }
    } else {
        let extension = 'html';
        // == word
        if (type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")
            extension = 'docx';
        if (type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
            extension = 'xlsx';
        if (type === "text/xml")
            extension = 'xml';
        if (type === "text/plain")
            extension = 'txt';

        const downloadLink = document.createElement("a");
        const fileName = `filedownload.${extension}`;
    
        downloadLink.href = url;
        downloadLink.download = fileName;
        downloadLink.click();
    }
} 
