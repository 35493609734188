import { Card, Col, Container, Row } from "react-bootstrap";

import DashboardHeader from "../headers/dashboard.header";

interface Props {
    translate: any,
    partner: any,

    onClick_ShowDefinedPartner: any
}

function MovementDashboard(props: Props) {

    const title = "Administração Geral";
    const description = "Acompanhamento das Atividades Gerais";
  
    function viewDashboard() {
        return (
            <Container fluid className="dashboard-body">
                <Row>
                    <Col sm="12" className="dashboard-sweet">
                            <Row>
                                <Col sm={3}>
                                    <Card>
                                        <Card.Header>Usuarios</Card.Header>
                                        <Card.Body></Card.Body>
                                        <Card.Footer></Card.Footer>
                                    </Card>
                                </Col>
                                <Col sm={3}>
                                    <Card>
                                        <Card.Header>Parceiros</Card.Header>
                                        <Card.Body></Card.Body>
                                        <Card.Footer></Card.Footer>
                                    </Card>
                                </Col>
                                <Col sm={6}>
                                    <Card>
                                        <Card.Header>Mensagens</Card.Header>
                                        <Card.Body></Card.Body>
                                        <Card.Footer></Card.Footer>
                                    </Card>
                                </Col>
                            </Row>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <div className="dashboard">
            <DashboardHeader title={title} description={description} onClick_ShowDefinedPartner={props.onClick_ShowDefinedPartner} />
            {viewDashboard()}
        </div>
    );
}

export default MovementDashboard;
