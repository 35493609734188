import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CadastreHeader from "../headers/cadastre.header";
import EmployeeCadastreForm from "./forms/employee.cadastre.form";
import EmployeeCadastreList from "./lists/employee.cadastre.list";

interface Props {
    translate: any,
    partner: any,

    onClick_ShowDefinedPartner: any
}

function EmployeeCadastre(props: Props) {

    const { employeeId } = useParams();

    const title = 'Funcionários';
    const description = '';

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ recordSelected, setRecordSelected ] = useState({ param : employeeId || '', selected : ((employeeId === 'new') || (employeeId === '0') || (!Number.isNaN(Number.parseInt(employeeId ||'')))) });

    function setSelected(isRecord: boolean, recordId: string) {
        setRecordSelected({ param : recordId, selected : isRecord });
    }

    async function initialize() {
    }
    
    useEffect(() => {
        if (!isLoading && !reloadPage) {
            initialize();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []);

    
    return (
        <div className="page">
            <CadastreHeader title="Funcionários" description={description} onClick_ShowDefinedPartner={props.onClick_ShowDefinedPartner} />
            {(recordSelected.selected) ? (
                <EmployeeCadastreForm 
                    translate={props.translate}
                    partner={props.partner}
                    title={title}
                    employeeId={recordSelected.param}
                    setRecord={(event: any) => {
                        event.preventDefault();

                        setSelected(false, '0');
                    } } />
            ) : (
                <EmployeeCadastreList
                    translate={props.translate} 
                    partner={props.partner}
                    title={title}
                    setRecord={(event: any, recordId: string) => { 
                        event.preventDefault();
        
                        setSelected(true, recordId); 
                    }} />
            )}
        </div>
    )
}

export default EmployeeCadastre;
