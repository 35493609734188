import { useEffect, useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import { notNullToDate } from "../../utilities/auxiliary-functions";
import { cpfMask, numberMask, phoneMask, removeMask, zipcodeMask } from "../../utilities/masks";
import { isCpf, isEmpty } from "../../utilities/validators";
import SocietyRecord from "../cadastres/forms/records/society.record";
import { FaTimes } from "react-icons/fa";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../contexts/confirm-message.context";
import { AlertMessageEnum } from "../../utilities/types";
import { Button } from '@mui/material';
import { openDocument } from "../../utilities/view-document";
import cduService from "../../services/cdu.service";

interface Props {
    translate: any,
    isEdit: boolean,
    show: boolean,
    partner: any,
    record: any,
    onSubmitModal: any,
    onCancelModal: any
}

function SocietyCadastreModal( props : Props ) {

    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();
    
    const display = props.translate.data;
    const title : string = display.title.society_cadastre;

    const [ invalidatedCadastreForm, setInvalidatedCadastreForm ] = useState(false);
    const [ sending, setSending ] = useState(false);

    // Society
    const [ id, setId ] = useState(0);
    const [ companyId, setCompanyId ] = useState(0);
    const [ societyType, setSocietyType ] = useState('0');
    const [ created, setCreated ] = useState('');
    const [ updated, setUpdated ] = useState('');

    // PersonalData
    const [ peopleId, setPeopleId ] = useState(0); 
    const [ tratament, setTratament ] = useState(''); 
    const [ fullname, setFullname ] = useState(''); 
    const [ gender, setGender ] = useState('');
    const [ birth, setBirth ] = useState('');

    // Phone
    const [ phoneId, setPhoneId ] = useState(0);
    const [ norm, setNorm ] = useState('');
    const [ operator, setOperator ] = useState('');
    const [ connection, setConnection ] = useState('');
    const [ sms, setSms ] = useState(0);
    const [ zap, setZap ] = useState(0);
    const [ major, setMajor ] = useState(0);

    const [ nationality, setNationality ] = useState('');
    const [ naturalness, setNaturalness ] = useState('');

    // PersonalDocument
    const [ registrationPf, setRegistrationPf ] = useState('');
    const [ registrationIdentity, setRegistrationIdentity ] = useState('');
    const [ issuerIdentity, setIssuerIdentity ] = useState('');
    const [ stateIdentity, setStateIdentity ] = useState('');
    const [ expedition, setExpedition ] = useState('');
    const [ driversLicense, setDriversLicense ] = useState('');
    const [ categoryDriversLicense, setCategoryDriversLicense ] = useState('');
    const [ validityDriversLicense, setValidityDriversLicense ] = useState('');

    // Address
    const [ addressId, setAddressId ] = useState(0);
    const [ zipcode, setZipcode ] = useState('');
    const [ state, setState ] = useState('');
    const [ city, setCity ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ place, setPlace ] = useState('');
    const [ habitation, setHabitation ] = useState('');
    const [ complement, setComplement ] = useState('');

    type ArtifactType = { id : number, norm : string, source : string, identy : string, document : string, filename : string };
    const DEFAULT_ARTIFACT: ArtifactType = { id : 0, norm : '', source : '', identy : '', document : '', filename : '' };
    
    const [ proofOfResidenceDocument, setProofOfResidenceDocument ] = useState(DEFAULT_ARTIFACT);

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(fullname))
            emptyRequired = true;

        if (isEmpty(registrationPf))
            emptyRequired = true;
        if ((!isEmpty(registrationPf)) && (!isCpf(registrationPf))) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.registration_pf);
            result = false;
        }

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedCadastreForm(!result);
        return result;
    }

    async function onClick_Confirm(event : any) {
        event.preventDefault();

        const isValid = await isValidForm();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(title, display.message.confirm_record);
                if (isConfirmed) {
                    setSending(false);
                    const society = fillSociety();

                    if (proofOfResidenceDocument) {
                        proofOfResidenceDocument.source = 'PEOPLE';
                        proofOfResidenceDocument.identy = String(society.peopleId);
    
                        if ((proofOfResidenceDocument.id > 0) && !isEmpty(proofOfResidenceDocument.document)) {
                            await cduService.saveArtifact(proofOfResidenceDocument.id, proofOfResidenceDocument);
                        } else if ((proofOfResidenceDocument.id > 0) && isEmpty(proofOfResidenceDocument.document)) {
                            await cduService.artifactDeleteById(proofOfResidenceDocument.id);
                        } else if ((proofOfResidenceDocument.id === 0) && !isEmpty(proofOfResidenceDocument.document)) {
                            await cduService.createArtifact(proofOfResidenceDocument);
                        }   
                    }
                    props.onSubmitModal(event, society);
                }
            } catch (error: any) {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }

    async function onClick_Cancel(event : any) {
        event.preventDefault();

        setInvalidatedCadastreForm(false);
        props.onCancelModal(event);
    }

    function clearSociety() {

        setId(0);
        setCompanyId(0);
        setSocietyType('0');
        setCreated('');
        setUpdated('');

        setPeopleId(0);
        setTratament('');
        setFullname('');
        setGender('');
        setBirth('');

        setPhoneId(0);
        setNorm('');
        setOperator('');
        setConnection('');
        setSms(0);
        setZap(0);
        setMajor(0);

        setNationality('');
        setNaturalness('');

        setRegistrationPf('');
        setRegistrationIdentity('');
        setIssuerIdentity('');
        setStateIdentity('');
        setExpedition('');
        setDriversLicense('');
        setCategoryDriversLicense('');
        setValidityDriversLicense('');

        setAddressId(0);
        setZipcode('');
        setState('');
        setCity('');
        setDistrict('');
        setPlace('');
        setHabitation('');
        setComplement('');
    }

    function fillDataForm(society: any) {

        if (society != null) {
            setId(society.id);
            setCompanyId(society.companyId);
            setSocietyType(society.societyType);
            setCreated(society.created);
            setUpdated(society.updated);
            setPeopleId(society.peopleId);

            fillRecordPeople(society.people);
/*
            if (society.people != null) {
                setTratament(society.people.tratament);
                setFullname(society.people.fullname);
                setGender(society.people.gender);
                setBirth(notNullToDate(society.people.birth, 'dd/mm/yyyy HH:MM:ss'));
                if (!isEmpty(society.people.phones)) {
                    const phone = society.people.phones[0];

                    setPhoneId(phone.id);
                    setNorm(phone.norm);
                    setOperator(phone.operator);
                    setConnection(phoneMask(phone.connection));
                    setSms(phone.sms);
                    setZap(phone.zap);
                    setMajor(phone.major);
                }
                setNationality(society.people.nationality);
                setNaturalness(society.people.naturalness);

                setRegistrationPf(cpfMask(society.people.registrationPf));
                setRegistrationIdentity(numberMask(society.people.registrationIdentity));
                setIssuerIdentity(society.people.issuerIdentity);
                setStateIdentity(society.people.stateIdentity);
                setExpedition(notNullToDate(society.people.expedition, 'dd/mm/yyyy'));
                setDriversLicense(numberMask(society.people.driversLicense));
                setCategoryDriversLicense(society.people.categoryDriversLicense);
                setValidityDriversLicense(notNullToDate(society.people.validityDriversLicense, 'dd/mm/yyyy'));
                
                if (!isEmpty(society.people.address)) {
                    const addressPeople = society.people.address[0];

                    setAddressId(addressPeople.addressId);
                    setZipcode(zipcodeMask(addressPeople.address.zipcode));
                    setState(addressPeople.address.state);
                    setCity(addressPeople.address.city);
                    setDistrict(addressPeople.address.district);
                    setPlace(addressPeople.address.place);

                    setHabitation(addressPeople.habitation);
                    setComplement(addressPeople.complement);
                }
            }
        */
        }
    }

    function fillRecordPeople(people: any) {

        if (people != null) {

            setPeopleId(people.id);
            setTratament(people.tratament);
            setFullname(people.fullname);
            setGender(people.gender);
            setBirth(notNullToDate(people.birth, 'dd/mm/yyyy'));
            if (!isEmpty(people.phones)) {
                const phone = people.phones[0];

                setPhoneId(phone.id);
                setNorm(phone.norm);
                setOperator(phone.operator);
                setConnection(phoneMask(phone.connection));
                setSms(phone.sms);
                setZap(phone.zap);
                setMajor(phone.major);
            }
            setNationality(people.nationality);
            setNaturalness(people.naturalness);

            setRegistrationPf(cpfMask(people.registrationPf));
            setRegistrationIdentity(numberMask(people.registrationIdentity));
            setIssuerIdentity(people.issuerIdentity);
            setStateIdentity(people.stateIdentity);
            setExpedition(notNullToDate(people.expedition, 'dd/mm/yyyy'));
            setDriversLicense(numberMask(people.driversLicense));
            setCategoryDriversLicense(people.categoryDriversLicense);
            setValidityDriversLicense(notNullToDate(people.validityDriversLicense, 'dd/mm/yyyy'));

            if (!isEmpty(people.adresses)) {
                const addressPeople = people.adresses[0];

                setAddressId(addressPeople.addressId);
                setZipcode(zipcodeMask(addressPeople.address.zipcode));
                setState(addressPeople.address.state);
                setCity(addressPeople.address.city);
                setDistrict(addressPeople.address.district);
                setPlace(addressPeople.address.place);

                setHabitation(addressPeople.habitation);
                setComplement(addressPeople.complement);
            }
        
        }
    } // fillRecordPeople

    function fillSociety() {

        const address = [];
        address[0] = {
            peopleId : peopleId,
            addressId : addressId,
            address : {
                id : addressId,
                zipcode : removeMask(zipcode),
                state : state,
                city : city,
                district : district,
                place : place
            },
            correspondence : 1,
            norm : 'residential',
            habitation,
            complement
        };

        const phones = [];
        phones[0] = {
            id : phoneId,
            peopleId,
            norm, 
            operator,
            connection : removeMask(connection),
            sms,
            zap,
            major
        };

        const dataSociety = {
            id,
            companyId,
            societyType : removeMask(societyType, '0'), 
            created, 
            updated,
            peopleId,
            people : {
                id : peopleId,
                tratament,
                fullname, 
                gender, 
                birth, 
                phones, 
                nationality, 
                naturalness, 
                registrationPf,
                registrationIdentity,
                issuerIdentity,
                stateIdentity,
                expedition,
                driversLicense,
                categoryDriversLicense,
                validityDriversLicense,
                address
            }
        };

        return dataSociety;
    }

    async function initialize() {
        if (props.isEdit) {
            fillDataForm(props.record);
            
            cduService.artifactByNorm('PEOPLE', props.record?.people.id, 'FILE_RESIDENCE').then((artifact: any) => {
                if (artifact)
                    setProofOfResidenceDocument(artifact);
            });
        } else {
            clearSociety();
        }
    }

    useEffect(() => {
        if (props.show)
            initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    function changeProofOfResidenceDocument(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let artfact: ArtifactType = {
                    id : proofOfResidenceDocument.id,
                    norm : 'FILE_RESIDENCE',
                    source : 'PEOPLE',
                    identy : String(peopleId),
                    document : e.target.result,
                    filename : event.target.files[0].name
                }
                setProofOfResidenceDocument(artfact);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            let artifact: ArtifactType = { id : proofOfResidenceDocument.id, norm : 'FILE_RESIDENCE', source : 'PEOPLE', identy : String(peopleId), document : '', filename : '' };
            setProofOfResidenceDocument(artifact);
        }
    }

    function clickViewDocument(event: any, title: string, url: any) {
        event.preventDefault();
        openDocument(title, url);
    }

    async function onClick_SearchPeople(event: any) {
        event.preventDefault();
        const search = registrationPf;
        clearSociety();
        setRegistrationPf(search);
        if (!isEmpty(search)) {
            const registration = removeMask(search);
            if (isCpf(registration)) {
                const people = await cduService.peopleByRegistration(registration);
                fillRecordPeople(people);
                if (!isEmpty(people)) {
                    const society = await cduService.societyByCompanyAndPeople(props.partner.companyId, people.id);
                    if (society) {
                        await alertContext.show(AlertMessageEnum.SUCCESS, title, 'CPF já cadastrado.');
                        props.onCancelModal(event);
                    }
                } else 
                    await alertContext.show(AlertMessageEnum.SUCCESS, title, 'CPF não localizado. Efetue o cadastro completo.');
            } else {
                await alertContext.show(AlertMessageEnum.FAIL, title, 'CPF Inválido !');
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Insira um CPF válido !');
        }
    }

    function viewCadastreForm() {
        return (
            <>
                <Modal show={props.show}
                    size="lg"
                    aria-labelledby="modal-cadastre-society-form"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="modal-cadastre-society-form">{title}</Modal.Title>
                        <FaTimes className="isClickable" onClick={ onClick_Cancel } size={26}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className="form" id="form-cadastre-society" validated={invalidatedCadastreForm}>
                            <SocietyRecord
                                translate={props.translate}
                                title={title}
                                isEdit={props.isEdit}

                                societyType={societyType}
                                created={created}
                                updated={updated}

                                tratament={tratament}
                                fullname={fullname}
                                gender={gender}
                                birth={birth}
                                norm={norm}
                                operator={operator}
                                connection={connection}
                                sms={sms}
                                zap={zap}
                                major={major}
                                nationality={nationality}
                                naturalness={naturalness}
                                registrationPf={registrationPf}
                                registrationIdentity={registrationIdentity}
                                issuerIdentity={issuerIdentity}
                                stateIdentity={stateIdentity}
                                expeditionIdentity={expedition}
                                driversLicense={driversLicense}
                                categoryDriversLicense={categoryDriversLicense}
                                validityDriversLicense={validityDriversLicense}
                                zipcode={zipcode}
                                state={state}
                                city={city}
                                district={district}
                                place={place}
                                habitation={habitation}
                                complement={complement}
                                setSocietyType={(value: any) => { setSocietyType(value); }}
                                setCreated={(value: any) => { setCreated(value); }}
                                setUpdated={(value: any) => { setUpdated(value); }}
                                setTratament={(value: any) => { setTratament(value); }}
                                setFullname={(value: any) => { setFullname(value); }}
                                setGender={(value: any) => { setGender(value); }}
                                setBirth={(value: any) => { setBirth(value); }}
                                setNorm={(value: any) => { setNorm(value); }}
                                setOperator={(value: any) => { setOperator(value); }}
                                setConnection={(value: any) => { setConnection(value); }}
                                setSms={(value: any) => { setSms(value); }}
                                setZap={(value: any) => { setZap(value); }}
                                setMajor={(value: any) => { setMajor(value); }}
                                setNationality={(value: any) => { setNationality(value); }}
                                setNaturalness={(value: any) => { setNaturalness(value); }}
                                setRegistrationPf={(value: any) => { setRegistrationPf(value); }}  
                                setRegistrationIdentity={(value: any) => { setRegistrationIdentity(value); }}  
                                setIssuerIdentity={(value: any) => { setIssuerIdentity(value); }}  
                                setStateIdentity={(value: any) => { setStateIdentity(value); }}  
                                setExpeditionIdentity={(value: any) => { setExpedition(value); }}  
                                setDriversLicense={(value: any) => { setDriversLicense(value); }}  
                                setCategoryDriversLicense={(value: any) => { setCategoryDriversLicense(value); }} 
                                setValidityDriversLicense={(value: any) => { setValidityDriversLicense(value); }} 
                                setZipcode={(value: any) => { setZipcode(value); }}
                                setState={(value: any) => { setState(value); }}
                                setCity={(value: any) => { setCity(value); }}
                                setDistrict={(value: any) => { setDistrict(value); }}
                                setPlace={(value: any) => { setPlace(value); }}
                                setHabitation={(value: any) => { setHabitation(value); }}
                                setComplement={(value: any) => { setComplement(value); }}

                                onClick_SearchPeople={(value: any) => { onClick_SearchPeople(value); }}

                                proofOfResidenceDocument={proofOfResidenceDocument}
                                changeProofOfResidenceDocument={changeProofOfResidenceDocument}
                                clickViewDocument={clickViewDocument}
                            />
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={ onClick_Cancel } variant="contained" color="secondary">{display.buttom.cancel}</Button>
                        <Button  variant="contained" color="primary" onClick={ onClick_Confirm } disabled={sending}>
                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                            { display.buttom.confirm }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
        { viewCadastreForm() }
        </>
    )
}

export default SocietyCadastreModal
