import React from 'react';
import authService from '../../services/auth.service';
import { cnpjMask, phoneMask } from '../../utilities/masks';
import { Tooltip as TooltipMaterial } from '@mui/material';


interface Props {
    title: any,
    description: any,
    
    onClick_ShowDefinedPartner: any
}

function SettingsHeader(props: Props) {

    const title = props.title;
    const description = props.description;    
    const partner = authService.currentPartner();

    return (
        <div className="page-header">
            <div className='title'>
                <h4 className="page-title">{title}</h4>
                <div className="page-reserved ">{description}</div>
            </div>
            <TooltipMaterial title="Empresas do grupo">
                <div className="page-corporation isClickable" onClick={(e) => props.onClick_ShowDefinedPartner(e) } >
                    {partner && partner.company && <div>
                        <div><strong>{partner.company.fantasy}</strong>{' - '}{cnpjMask(partner.company.registrationPj)}{' - '}{phoneMask(partner.company.telephone)}</div>
                    </div>}  
                </div>
            </TooltipMaterial>
        </div>
    )
}

export default SettingsHeader;
