import { dateToString } from "../utilities/auxiliary-functions";

import { Shelf } from "./keep-shelf.model";

export class Bookcase {

    id: number = 0;
    partnerId: number = 0;
    name: string = '';
    order: string = '';
    shelves: Array<Shelf> = [];
    created: string = dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss');
    updated: string = dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss');

}