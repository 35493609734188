import axios from "axios";
import authHeader from "./auth-header";

export abstract class ServiceCommon {
    
    name: string;

    constructor(name: string) {
        this.name = name;
    }

    getName() {
        return this.name;
    }

    treatError(error: any) {
        let messageError = (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString();
        
        let message = messageError;
        if (messageError.indexOf('Controller') > 0) {
            message = messageError.substring(messageError.indexOf('Controller') + 13);
        }
        return message;
    }

    async getReturnObject(url: string) {
        
        return axios
            .get(url, { 
                headers : authHeader() 
            }).then((response: any) => {
                let data = response.data; 
                if (data.success) {
                    return data.object;
                } else {
                    throw new Error(data.message);
                }
            }).catch((error: any) => {
                const responseMessage = this.treatError(error);
                throw new Error(responseMessage);
            });
    } // getReturnObject

    async postReturnObject(url: string, data: any) {

        return axios
            .post(url,  
                data,
                { headers : authHeader() }
            ).then((response: any) => {
                let data = response.data; 
                if (data.success) {
                    return data.object;
                } else {
                    throw new Error(data.message);
                }
            }).catch((error: any) => {
                const responseMessage = this.treatError(error);
                throw new Error(responseMessage);
            });
    } // postReturnObject

    async putReturnObject(url: string, data: any) {

        return axios
            .put(url,
                data, 
                { headers : authHeader() }
            ).then((response: any) => {
                let data = response.data; 
                if (data.success) {
                    return data.object;
                } else {
                    throw new Error(data.message);
                }
            }).catch((error: any) => {
                const responseMessage = this.treatError(error);
                throw new Error(responseMessage);
            });

    } // putReturnObject

    async dataReturnObject(url: string, data: any) {

        return axios
            .post(url,  
                data,
                { headers : authHeader(true) }
            ).then((response: any) => {
                let data = response.data; 
                if (data.success) {
                    return data.object;
                } else {
                    throw new Error(data.message);
                }
            }).catch((error: any) => {
                const responseMessage = this.treatError(error);
                throw new Error(responseMessage);
            });
    } // dataReturnObject

    async deleteReturnObject(url: string) {

        return axios
            .delete(url,
                { headers : authHeader() }
            ).then((response: any) => {
                let data = response.data; 
                if (data.success) {
                    return data.object;
                } else {
                    throw new Error(data.message);
                }
            }).catch((error: any) => {
                const responseMessage = this.treatError(error);
                throw new Error(responseMessage);
            });

    } // deleteReturnObject

}