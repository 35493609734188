import WarehouseIcon from '@mui/icons-material/Warehouse';
import { Button, Tooltip } from '@mui/material';
import { useEffect, useMemo, useState } from "react";
import { ButtonGroup, Card, Col, Container, Image, Row, Spinner } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import Lancha from '../../../assets/Lancha.svg';
import { useNavigate } from "react-router-dom";
import Jet from '../../../assets/Jetski.svg';
import { VesselOwner } from "../../../components/sub-render.component";
import TableContainer from "../../../components/table-container.component";
import { useAlertMessageContext } from "../../../contexts/alert-message.context";
import { useDeleteMessageContext } from "../../../contexts/delete-message.context";
import authService from "../../../services/auth.service";
import lclService from "../../../services/lcl.service";
import { PermissionEnum, VESSEL_JETSKI } from "../../../utilities/constants";
import { AlertMessageEnum, getManufacturerName } from "../../../utilities/types";
import VesselImporterModal from "../../modals/vessel-importer.modal";
import AddIcon from '@mui/icons-material/Add';
import { toInt } from '../../../utilities/auxiliary-functions';
import cduService from '../../../services/cdu.service';

interface Props {
    translate: any,
    partner: any,

    title: string,
    setRecord: any
}

function VesselCadastreList(props: Props) {
    
    const alertContext = useAlertMessageContext();
    const deleteContext = useDeleteMessageContext();
    
    const navigator = useNavigate();
    const display = props.translate.data;

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ showFilter, setShowFilter]  = useState(false);
    const [ sending, setSending ] = useState(false);

    const [ showImported, setShowImported ] = useState(false);

    const [records, setRecords ] = useState([]);
    const columnsRecord = useMemo(() => [{
        Header: display.label.key,
        accessor: "identifyKey"
    },{
        Header: display.label.name,
        accessor: "name",
    },{
        Header: display.label.type,
        Cell: (props: any) => {
            const vessel = props.row.original;

            if (toInt(vessel.typeVessel) === VESSEL_JETSKI) {
                return (<div className="d-flex justify-content-center">
                    <Image src={Jet} className="jet" style={{
                        height: 32}}/>
                    <small className="ms-1">10'</small>
                </div>);
            } else {
                return (<div className="d-flex justify-content-center">
                    <Image src={Lancha} className="jet" style={{
                        height: 32}}/>
                    <small className="ms-1">{`${vessel.vesselSize || 0}'`}</small>
                </div>);
            }
        }
    },{
        Header: display.label.drawer,
        //accessor: "bookcase",
        Cell: (props: any) => {
            const bookcase = props.row.original.bookcase;

            if (bookcase) {
                return (
                    <Tooltip className="isClickable d-flex text-center justify-content-center w-100" title={`${bookcase.name} | ${bookcase.shelves[0].name} | ${bookcase.shelves[0].drawers[0].name}`}>
                        <WarehouseIcon color="inherit" className="mt-1" />
                    </Tooltip>
                );
            }
        }
    },{
        Header: display.label.color,
        accessor: "color",
        Cell: (row: any) => (<div className="white-space">
             <div className="table-column-color d-flex justify-content-center">
              <span className="table-column-block30x30" style={{backgroundColor: `${row.value}`}}></span>
          </div>
        </div>)
    },{
        Header: display.label.enrollment,
        accessor: "enrollment",
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    },{
        Header: display.label.manufacturer,
        accessor: "manufacturer",
        Cell: (row: any) => (<div className="white-space">{getManufacturerName(row.value)}</div>)
    },{
        Header: display.label.owner,
        Cell: (props: any) => <VesselOwner translate={props.translate} values={props} />
    },{
        Header: display.legend.actions,
        accessor: "actions",
        Cell: (props: any) => {
            let vesselRows = [] as any;
            const originals = props.rows;
            for (var o = 0; o < originals.length; o++) {
                vesselRows[vesselRows.length] = originals[o].original;
            }
            const vesselRow = props.row.original;

            return (
                <div className="white-space table-actions">
                    {authService.hasPermission(PermissionEnum.CADASTRE_VESSEL_EDIT) && (
                       <Tooltip title={display.tooltips.toEdit}>
                            <span onClick={(e) => onClick_EditRecord(e, vesselRow)}>
                                <FaEdit size={18} />
                            </span>
                       </Tooltip>
                        
                    )
                       
                    }
                    {authService.hasPermission(PermissionEnum.CADASTRE_VESSEL_REMOVE) && 
                        <Tooltip title={display.tooltips.delete}>
                            <span onClick={(e) => onClick_DeleteRecord(e, vesselRows, vesselRow)}>
                                <FaTrash size={18} />
                            </span>
                        </Tooltip>
                        
                    }
                </div>
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }], []); // columnsRecord

    function onClick_Filter(event: any) {
        event.preventDefault();
        
        setSending(false);
        setShowFilter(false);
        searchfilter();
    } // onClick_Filter

    function onClick_AddRecord(event: any) {
        event.preventDefault();

        setShowFilter(false);
        setSending(true);
        props.setRecord(event, 'new');
    } // onClick_AddRecord

    function onClick_EditRecord(event: any, record: any) {
        event.preventDefault();
        props.setRecord(event, record.id);
    } // onClick_EditRecord

    async function onClick_DeleteRecord(event: any, records: any, record: any) {
        event.preventDefault();
        const isConfirmed = await deleteContext.show(props.title, display.message.delete_record, true);
        if (isConfirmed && isConfirmed.result) {
            try {
                await lclService.vesselDeleteById(record.id, isConfirmed.message);
                searchfilter();
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
            }
        }
    } // onClick_DeleteRecord
    
    async function searchfilter() {
        const attributes = [] as any;
        /* Sempre incluir o partner na pesquisa */
        if (props.partner) {
            const where = { partnerId : props.partner.id } as any;
            if (authService.isProprietor()) {
                const user = authService.currentUser();
                const people = await cduService.peopleByUser(user.id);
                where['ownerId'] = people.id;
            }
            const order = [] as any;

            try {
                const vessels = await lclService.vesselList(attributes, where, order);
                setRecords(vessels);
                setSending(false);
                setShowFilter(false);
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, 'Não foi definido uma Marina para exibir o cadastro !');
            navigator(`/portal`);
        }
    } // searchfilter

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            searchfilter();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // useEffect

    function viewCadastreList() {
        return (
            <Container fluid className="page-body vessel">
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card>
                            <Card.Header>
                                <div className="card-actions float-right">
                                    <ButtonGroup>
                                        {authService.hasPermission(PermissionEnum.CADASTRE_VESSEL_ADD) && 
                                            <Tooltip title={`${display.tooltips.add} ${display.tooltips.vessel}`}>

                                                <Button size='small' variant="contained" color="primary" onClick={ onClick_AddRecord } disabled={sending}>
                                                    { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <AddIcon /> }
                                                    Embarcação
                                                </Button>
                                            </Tooltip>
                                        }
                                    </ButtonGroup>
                                </div>
                                <Card.Title>{display.legend.listing}</Card.Title>
                            </Card.Header>
                            <Card.Body style={{ minHeight : 500 }}>
                                <div className={`card-filter ${showFilter ? "active" : ""}`} >
                                    <div className="card-filter-header">{display.legend.filter}</div>
                                    <div className="card-filter-body">
                                        <Container>
                                            <Row>
                                                <Col>{display.legend.filter_scheme}</Col>
                                            </Row>
                                        </Container>
                                    </div>
                                    <div className="card-filter-footer">
                                        <Button onClick={ onClick_Filter } disabled={sending} variant="contained" color="primary">
                                            { sending ? <Spinner className="me-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }
                                            {display.buttom.filter}
                                        </Button>
                                    </div>
                                </div>
                                <TableContainer className={'table-list-record'} columns={columnsRecord} data={records} viewFilter={!showFilter}/>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewCadastreList

    return (
        <>
            { viewCadastreList() }
            <VesselImporterModal 
                translate={props.translate} 
                partner={props.partner}
                show={showImported}
                onSubmitModal={(event: any, record: any) => {
                    event.preventDefault();
                    setShowImported(false);
                }}
                onCancelModal={(event: any) => {
                    event.preventDefault();
                    setShowImported(false);
                }}
            />

        </>
    );

}

export default VesselCadastreList;
