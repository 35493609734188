import logoNauti from '../assets/icon_logo.png';

import React, {useContext, useRef, useState} from "react";
import Modal from "react-bootstrap/Modal";
import { TranslateContext } from "./translate.context";
import { Image } from 'react-bootstrap';
import { Button } from '@mui/material'

type UseModalShowReturnType = {
    show: boolean;
    setShow: (value: boolean) => void;
    onHide: () => void;
}

const useModalShow = (): UseModalShowReturnType => {
    const [show, setShow] = useState(false);

    const handleOnHide = () => {
        setShow(false);
    };

    return {
        show,
        setShow,
        onHide: handleOnHide,
    }
};

type ModalContextType = {
    show: (title: string, message: string | JSX.Element) => Promise<boolean>;
};

type ConfirmMessageContextProviderProps = {
    children: React.ReactNode
}

const ConfirmMessageContext = React.createContext<ModalContextType>({} as ModalContextType);

const ConfirmMessageContextProvider: React.FC<ConfirmMessageContextProviderProps> = (props) => {

    const { translate } = useContext(TranslateContext) as any;
    const display = translate.data;

    const { setShow, show, onHide } = useModalShow();
    const [ content, setContent ] = useState<{ title: string, message: string | JSX.Element} | null>();
    const resolver = useRef<Function>();

    const onShow = (title: string, message: string | JSX.Element): Promise<boolean> => {
        setContent({
            title,
            message
        });
        setShow(true);
        return new Promise(function (resolve) {
            resolver.current = resolve;
        });
    };

    const modalContext: ModalContextType = {
        show : onShow
    };

    const onClick_Ok = () => {
        resolver.current && resolver.current(true);
        onHide();
    };

    const onClick_Cancel = () => {
        resolver.current && resolver.current(false);
        onHide();
    };

    return (
        <ConfirmMessageContext.Provider value={modalContext}>
            {props.children}

            {content &&
                <Modal 
                    show={show} 
                    onHide={onHide} 
                    dialogClassName={`modal-md`}
                    backdrop="static"
                    className="message-context"
                >
                    <Modal.Header>
                        <Modal.Title className='d-flex'><Image className='me-3' src={logoNauti} style={{width: "23px", marginTop: "-5px"}} /> {content.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>{content.message}</label>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="contained" color="secondary" onClick={onClick_Cancel}>{display.buttom.cancel}</Button>
                        <Button variant="contained" color="primary" onClick={onClick_Ok}>{display.buttom.okay}</Button>
                    </Modal.Footer>
                </Modal>}
        </ConfirmMessageContext.Provider>
    )
};

const useConfirmMessageContext = (): ModalContextType => useContext(ConfirmMessageContext);

export {
    useModalShow,
    useConfirmMessageContext,
}

export default ConfirmMessageContextProvider;