import React from 'react';
import authService from '../../services/auth.service';
import { cnpjMask, phoneMask } from '../../utilities/masks';
import { Tooltip as TooltipMaterial } from '@mui/material';

interface Props {
    title: any,
    description: any,
    
    onClick_ShowDefinedPartner: any
}

function DashboardHeader(props: Props) {

    const title = props.title;
    const description = props.description;
    const partner = authService.currentPartner();

    return (
        <div className="dashboard-header">
            <div className='title'>
                <h4 className="dashboard-title">{title}</h4>
                <div className="dashboard-reserved ">{description}</div>
            </div>
            <TooltipMaterial title="Empresas do grupo">
                <div className="dashboard-corporation isClickable" onClick={(e) => props.onClick_ShowDefinedPartner(e) } >
                    {partner && partner.company && <div>
                        <div><strong>{partner.company.fantasy}</strong>{' - '}{cnpjMask(partner.company.registrationPj)}{' - '}{phoneMask(partner.company.telephone)}</div>
                    </div>}  
                </div>
            </TooltipMaterial>
        </div>
    )
}

export default DashboardHeader;
