import AddIcon from '@mui/icons-material/Add';
import { Button as ButtonMaterial } from '@mui/material';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useEffect, useState } from "react";
import { Calendar, momentLocalizer, View } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Button, Card, Col, Container, Modal, Row, Spinner } from "react-bootstrap";
import { FaSpinner, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../contexts/confirm-message.context";
import authService from "../../services/auth.service";
import lclService from "../../services/lcl.service";
import srvService from "../../services/srv.service";
import { dateToString, toDate } from "../../utilities/auxiliary-functions";
import { PermissionEnum } from "../../utilities/constants";
import { removeFormatDate } from "../../utilities/masks";
import { AlertMessageEnum, getBudgetSituation } from "../../utilities/types";
import { isDate } from "../../utilities/validators";
import OfficeHeader from "../headers/office.header";
import BudgetCadastreModal from "../modals/budget.cadastre.modal";
import ScheduleCadastreModal from "../modals/schedule.cadastre.modal";

interface Props {
    translate: any,
    partner: any,

    onClick_ShowDefinedPartner: any
}

function ScheduleOffice(props: Props) {
    
    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();

    const navigator = useNavigate();
    const display = props.translate.data;

    const title = display.title.schedule;
    const description = display.description.schedule;
    
    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ sending, setSending ] = useState(false);

    const localizer = momentLocalizer(moment);
    const [ records, setRecords ] = useState([] as any);
    const [ dateTimeStart, setDateTimeStart ] = useState(dateToString(new Date(), 'dd/mm/yyyy') + ' 00:00:00');
    const [ dateTimeEnd, setDateTimeEnd ] = useState(dateToString(new Date(), 'dd/mm/yyyy') + ' 23:59:59');

    const [ taskList, setTaskList ] = useState([] as any);

    /* TASK */
    const [ task, setTask ] = useState({} as any);
    const [ showTask, setShowTask ] = useState(false);

    /* SCHEDULE */
    const [ scheduleId, setScheduleId ] = useState('');
    const [ schedule, setSchedule ] = useState({} as any);
    const [ isEditSchedule, setEditSchedule ] = useState(false);
    const [ showSchedule, setShowSchedule ] = useState(false);

    /* BUDGET */
    const [ budgetId, setBudgetId ] = useState('');
    const [ budget, setBudget ] = useState({} as any);
    const [ isEditBudget, setEditBudget ] = useState(false);
    const [ showBudget, setShowBudget ] = useState(false);

    const dateFormat = (date: any, culture: any, localizer: any) => localizer.format(date, 'DD', culture);
    const dayFormat = (date: any, culture: any, localizer: any) => localizer.format(date, 'DD ddd', culture);
    const weekdayFormat = (date: any, culture: any, localizer: any) => localizer.format(date, 'ddd', culture);
    const monthHeaderFormat = (date: any, culture: any, localizer: any) => localizer.format(date, 'MMMM \\d\\e YYYY', culture);
    const dayHeaderFormat = (date: any, culture: any, localizer: any) => localizer.format(date, 'dddd, DD \\d\\e MMMM \\d\\e YYYY', culture);

    async function searchfilter(dateTimeStart: string, dateTimeEnd: string) {
        if (props.partner) {
            setDateTimeStart(dateTimeStart);
            setDateTimeEnd(dateTimeEnd);

            if (isDate(toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')) && isDate(toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss'))) {
                const attributes = [] as any;
                const where = { prohibited : { start : removeFormatDate(dateTimeStart), end : removeFormatDate(dateTimeEnd) } } as any;
                const order = [ [ 'prohibited', 'ASC' ] ] as any;

                try {
                    /* Sempre incluir o partner na pesquisa */
                    where['partnerId'] = props.partner.id;

                    const schedules = await srvService.scheduleFilter(attributes, where, order);
                    setRecords(schedules);

                    const taskList = [] as any;
                    for (var i = 0; i < schedules.length; i++) {
                        const schedule = schedules[i];

                        schedule.prohibited = toDate(schedule.prohibited, 'yyyy-mm-ddTHH:MM:ss.sssZ');
                        schedule.departure = toDate(schedule.departure, 'yyyy-mm-ddTHH:MM:ss.sssZ');
                        if (schedule.sourceLink === 'Vessel') {
                            const vessel = await lclService.vesselById(schedule.numberLink);
                            schedule['backgroundColor'] = '#4169E1';
                            schedule['vesselt'] = vessel;
                            schedule['color'] = '#FFFFFF';
                        } else
                        if (schedule.sourceLink === 'Budget') {
                            const budget = await srvService.budgetById(schedule.numberLink);
                            schedule['backgroundColor'] = getBudgetSituation(budget.situation).color;
                            schedule['budget'] = budget;
                            schedule['color'] = '#FFFFFF';
                        }
                        if (schedule.sourceLink === 'Task') {
                            schedule['backgroundColor'] = schedule.color;
                            schedule['color'] = '#FFFFFF';
                        }

                        taskList[taskList.length] = schedule;
                    }
                    setTaskList(taskList);
                } catch(error: any) {
                    await alertContext.show(AlertMessageEnum.FAIL, title, error);
                }
            } else {
                await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.an_invalid_date_was_reported);
            }
            setSending(false);
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi definido uma Marina para exibir o agendamento !');
            navigator(`/portal`);
        }
    } // searchfilter

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            let inicio = moment().day(0); // domingo desta semana
            let fim = moment().day(6); // sábado desta semana

            let startDate = inicio.format('DD/MM/YYYY 00:00:00');
            let endDate = fim.format('DD/MM/YYYY 23:59:59');

            searchfilter(startDate, endDate);
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []); // useEffect

    function onClick_RefreshSchedule(event: any) {
        event.preventDefault();

        setSending(true);
        searchfilter(dateTimeStart, dateTimeEnd);
    } // onClick_RefreshBudget
    
    function onClick_AddRecord(event: any) {
        event.preventDefault();

        setEditSchedule(false);
        setScheduleId('new');
        setSchedule({} as any);
        setShowSchedule(true);
    } // onClick_AddRecord

    function rangeChangeEvent(range: Date[] | { start: Date; end: Date }, view?: View) {
        let start = new Date();
        let end = new Date();

        if (Array.isArray(range)) {
            start = range[0];
            end = toDate(dateToString(range[range.length - 1], 'dd/mm/yyyy'), 'dd/mm/yyyy 23:59:59');
        } else {
            start = range.start;
            end = toDate(dateToString(range.end, 'dd/mm/yyyy'), 'dd/mm/yyyy 23:59:59');
        }
        const dateTimeStart = dateToString(start, 'dd/mm/yyyy HH:MM:ss');
        const dateTimeEnd = dateToString(end, 'dd/mm/yyyy HH:MM:ss');
        searchfilter(dateTimeStart, dateTimeEnd);
    }

    function taskSelected(task: any, event: React.SyntheticEvent<HTMLElement>) {
        event.preventDefault();
        setTask(task);

        if (task.sourceLink === "Task") {
            setShowTask(true);
        } else if (task.sourceLink === "Budget") {
            setEditBudget(true);
            setBudgetId(task.budget.id);
            setBudget(task.budget);
            setShowBudget(true);
        }
    }

    function onClick_Task(event: any) {
        event.preventDefault();
        setShowTask(false);
    }

    async function onClick_RemoveTask(event: any) {
        event.preventDefault();

        try {
            const isConfirmed = await confirmContext.show(title, display.message.delete_record);
            if (isConfirmed) {
                setSending(true);

                await srvService.scheduleDeleteById(task.id);

                setSending(false);
                await alertContext.show(AlertMessageEnum.SUCCESS, title, display.message.the_record_has_been_saved_successfully);
                setShowTask(false);
                searchfilter(dateTimeStart, dateTimeEnd);
            }
        } catch (error: any) {
            setSending(false);
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
        }
    }

    function taskGetter(task: any) {
        const backgroundColor = task.backgroundColor ? task.backgroundColor : '#4682B4';
        const color = task.color ? task.color : '#FFFFFF';
        return { style: { backgroundColor, color } }
    }

    function viewTask() {
        return (
            <Modal show={showTask}
                aria-labelledby="modal-task"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="modal-task">{display.title.task}</Modal.Title>
                    <div className="d-flex align-items-center">
                        <FaTimes className="isClickable" onClick={ onClick_Task } size={26}/>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <fieldset className="schedule-task">
                        <legend>{task.title}</legend>
                        <Row>
                            <Col>
                                <div><strong>Período : </strong>{dateToString(task.prohibited, 'HH:MM')} - {dateToString(task.departure, 'HH:MM')}</div>
                                <span className="paragraph">{task.comments}</span>
                            </Col>
                        </Row>
                    </fieldset>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={ onClick_Task }>{display.buttom.okay}</Button>
                    <Button variant="danger" onClick={ onClick_RemoveTask }>{display.buttom.remove}</Button>
                </Modal.Footer>
            </Modal>
        );
    }

    function viewPage() {

        return (
            <Container fluid className="page-body schedules">
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card style={{ minHeight : 500 }}>
                            <Card.Header>
                                <div className="card-actions d-flex justify-content-between">
                                        <Button variant="secondary" onClick={ (e) => onClick_RefreshSchedule(e) } disabled={sending}>
                                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSpinner size={22} /> } {' '}
                                            {display.buttom.refresh}
                                        </Button>
                                        {authService.hasPermission(PermissionEnum.OFFICE_SCHEDULE_ADD) && 
                                            <ButtonMaterial size="small" variant="contained" onClick={(e) => onClick_AddRecord(e) } ><AddIcon />Tarefa</ButtonMaterial>
                                        }
                                </div>
                                <Card.Title>{display.subtitle.schedule}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="schedule-calendar">
                                    <Calendar
                                        selectable
                                        defaultView="week"
                                        defaultDate={new Date()}
                                        localizer={localizer}
                                        events={taskList}
                                        startAccessor="prohibited"
                                        endAccessor="departure"
                                        messages={{
                                            date: 'Data',
                                            time: 'Hora',
                                            event: 'Título do Evento',
                                            allDay: 'Dia todo',
                                            work_week: 'Semana de trabalho',
                                            // Range
                                            month: 'Mês',
                                            week: 'Semana',
                                            day: 'Dia',
                                            agenda: 'Agenda',
                                            // Navigate
                                            today: 'Hoje',
                                            previous: '<<',
                                            next: '>>',

                                            tomorrow: 'Amanhã',
                                            yesterday: 'Ontem',
                                            noEventsInRange: 'Não há eventos neste intervalo',
                                            showMore: total => `+${total} Mais`
                                        }}
                                        //onDoubleClickEvent={doubleTaskSelected}
                                        onRangeChange={rangeChangeEvent}
                                        formats={{
                                            dateFormat,
                                            dayFormat,
                                            weekdayFormat,
                                            monthHeaderFormat,
                                            //dayRangeHeaderFormat
                                            dayHeaderFormat
                                        }}
                                        dayLayoutAlgorithm="no-overlap"
                                        /*
                                        onSelectSlot={slotSelected}
                                        */
                                        onSelectEvent={taskSelected}
                                        eventPropGetter={taskGetter}
                                        min={new Date(0, 0, 0, 7, 0, 0)}
                                        max={new Date(0, 0, 0, 19, 0, 0)}
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewPage

    return (
        <div className="page">
            <OfficeHeader title={title} description={description} onClick_ShowDefinedPartner={props.onClick_ShowDefinedPartner} />
            { viewPage() }
            { viewTask() }
            <ScheduleCadastreModal 
                translate={props.translate} 
                partner={props.partner}
                isEdit={isEditSchedule}

                show={showSchedule}
                scheduleId={scheduleId}
                record={schedule}
                onSubmitModal={(event: any, record: any) => {
                    event.preventDefault();
                    setShowSchedule(false);
                    setEditSchedule(false);
                    searchfilter(dateTimeStart, dateTimeEnd);
                }}
                onCancelModal={(event: any) => {
                    event.preventDefault();
                    setEditSchedule(false);
                    setShowSchedule(false);
                }}
            />
            <BudgetCadastreModal 
                translate={props.translate} 
                partner={props.partner}
                isEdit={isEditBudget}

                show={showBudget}
                budgetId={budgetId}
                record={budget}
                records={records}
                onSubmitModal={(event: any, record: any) => {
                    event.preventDefault();
                    setShowBudget(false);
                    setEditBudget(false);
                    searchfilter(dateTimeStart, dateTimeEnd);
                }}
                onCancelModal={(event: any) => {
                    event.preventDefault();
                    setEditBudget(false);
                    setShowBudget(false);
                }}
            />
        </div>
    );

}

export default ScheduleOffice;