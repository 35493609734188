import { useEffect, useState } from 'react';
import { Image, Nav } from 'react-bootstrap';
import { FaBook, FaDesktop, FaFileContract, FaMoneyCheckAlt, FaRegCalendarCheck, FaShip, FaSignOutAlt, FaStoreAlt, FaTools, FaTractor, FaUserCog, FaUsers, FaWarehouse } from 'react-icons/fa';
import { MdAddBusiness, MdAssessment, MdDashboard } from 'react-icons/md';

import MenuItem from './side-menu-item.component';

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BadgeIcon from '@mui/icons-material/Badge';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import HistoryIcon from '@mui/icons-material/History';
import { BiCategory } from 'react-icons/bi';
import { ImUserTie } from 'react-icons/im';
import { RiBillLine, RiContactsBookFill } from 'react-icons/ri';
import Logo from '../assets/logo_registrada.png';
import authService from '../services/auth.service';
import styles from './side-menu.component.module.scss';
import InventoryIcon from '@mui/icons-material/Inventory';
import PersonIcon from '@mui/icons-material/Person';

export const menuItems = [
    {
        name: 'dashboard',
        to: '/portal/dashboard',
        iconClassName: <FaDesktop size={23} />,
        subMenus: [
            { name: 'management_daily', to: '/portal/dashboard/maritime', iconClassName: <MdDashboard size={23} /> },
            { name: 'management_financial', to: '/portal/dashboard/financial', iconClassName: <RiBillLine size={23} /> },
        ],
    },
    {
        name: 'cadastre',
        to: '/portal/cadastre',
        iconClassName: <FaBook size={23} />,
        subMenus: [
            { name: 'category', to: '/portal/cadastre/category', iconClassName: <BiCategory size={23} /> },
            { name: 'product', to: '/portal/cadastre/product', iconClassName: <InventoryIcon className={styles.menuIconSmall} /> },
            { divider: true },
            { name: 'partner', to: '/portal/cadastre/partner', iconClassName: <MdAddBusiness size={23} /> },
            { name: 'member', to: '/portal/cadastre/member', iconClassName: <FaUsers size={23} /> },
            { name: 'vessel', to: '/portal/cadastre/vessel', iconClassName: <FaShip size={23} /> },
            { divider: true },
            { name: 'purveyor', to: '/portal/cadastre/purveyor', iconClassName: <ImUserTie size={23} /> },
            { name: 'employee', to: '/portal/cadastre/employee', iconClassName: <PersonIcon className={styles.menuIcon} /> },
            { name: 'mechanic', to: '/portal/cadastre/mechanic', iconClassName: <FaUserCog size={23} /> }
        ],
    },
    {
        name: 'operation',
        to: '/portal/operation',
        iconClassName: <FaStoreAlt size={23} />,
        subMenus: [
            { name: 'movement', to: '/portal/operation/movement', iconClassName: <FaTractor size={23} /> },
            { name: 'history', to: '/portal/operation/movement-history', iconClassName: <HistoryIcon className={styles.menuIcon} /> },
            { name: 'keep_boat', to: '/portal/operation/keep_boat', iconClassName: <FaWarehouse size={23} /> },
            { divider: true },
            { name: 'assessment', to: '/portal/operation/assessment', iconClassName: <MdAssessment size={23} /> }
        ],
    },
    {
        name: 'office',
        to: '/portal/office',
        iconClassName: <FaTools size={23} />,
        subMenus: [
            { name: 'budget', to: '/portal/office/budget', iconClassName: <FormatListBulletedIcon className={styles.menuIcon} /> },
            { name: 'schedule', to: '/portal/office/schedule', iconClassName: <FaRegCalendarCheck size={23} /> }
            // { divider: true },
        ],
    },
    {
        name: 'financial',
        to: '/portal/financial',
        iconClassName: <AccountBalanceWalletIcon fontSize="medium" className={styles.menuIcon} />,
        subMenus: [
            { name: 'contract', to: '/portal/financial/contract', iconClassName: <FaFileContract size={23} /> },
            { name: 'contract_management', to: '/portal/financial/contract-management', iconClassName: <BadgeIcon className={styles.menuIcon} /> },
            { name: 'contract_customer', to: '/portal/financial/contract-customer', iconClassName: <RiContactsBookFill className={styles.menuIcon} /> },
            { name: 'launcher', to: '/portal/financial/launcher', iconClassName: <FaMoneyCheckAlt size={23} /> }
            //{ name: 'conciliation', to: '/portal/financial/conciliation', iconClassName: <FaCoins size={23} /> }
        ],
    }
];

function SideMenu(props: any) {

    const display = props.translate.data;

    const [ menuInactive, setMenuInactive ] = useState([{ isOpened: false, name: '' }] as any);

    const removeActiveClassFromSubMenu = () => {
        const elements = document.querySelectorAll(".sidebar-item");
        elements.forEach((element) => {
            element.classList.remove("active");
        });
    };

    function onClick_Logout(event: any) {
        event.preventDefault();

        authService.logout();
        window.location.href = '/';
    }
    
    useEffect(() => {
        let localMenuItems = document.querySelectorAll(".sidebar-item");
        localMenuItems.forEach((el) => {
            el.addEventListener("click", (e) => {
                removeActiveClassFromSubMenu();
                localMenuItems.forEach((el) => el.classList.remove("active"));
                el.classList.toggle("active");
            });
        });

        let localMenuInactive = new Array(menuItems.length);
        for (var i = 0; i < menuItems.length; i++) {
            const item = menuItems[i];
            localMenuInactive[i] = {
                isOpened: false,
                name: item.name
            }; 
        }
        setMenuInactive(localMenuInactive);
    }, []);

    return (
        <>
            <Nav className={`sidebar ${props.onCollapse ? "toggled" : ""}`}>
                <div className="sidebar-content">
                    <a className="sidebar-brand" href="/">
                        <Image src={Logo} className="logo" />
                        <span className="align-middle"></span>
                    </a>
                    <ul className="sidebar-nav">
                        {menuItems.map((menuItem, index) => (
                            authService.permissionIn(menuItem.name) &&
                            <MenuItem
                                translate={props.translate}
                                key={index}
                                name={menuItem.name}
                                to={menuItem.to}
                                subMenus={menuItem.subMenus || []}
                                iconClassName={menuItem.iconClassName}
                                onClick={(event: any) => {
                                    const updatedMenuInactive = menuInactive.map((standardItem: any, position: number) => {
                                        if ((index === position) && (event.target.innerText === display.label[standardItem.name])) {
                                            return { ...standardItem, isOpened: !standardItem.isOpened };
                                        }
                                        return standardItem;
                                    });
                                    setMenuInactive(updatedMenuInactive);
                                }}
                                expanded={menuInactive[index]}
                                onCloseSideMenu={props.onCloseSideMenu}
                            />
                        ))}
                    </ul>
                    <div className="sidebar-logout" onClick={(e) => onClick_Logout(e)}>
                        <FaSignOutAlt />{display.label.go_out}
                    </div>
                </div>
            </Nav>
        </>
    )

}

export default SideMenu;