import { Col, Form, FormControl, FormGroup, Row } from "react-bootstrap";
import { isEmpty } from "../../../../utilities/validators";
import { toString, toDate, toLowerCase } from '../../../../utilities/auxiliary-functions';
import { getPerson, PaymentType } from "../../../../utilities/types";
import { cpfCnpjMask, floatMask } from "../../../../utilities/masks";
import { PaymentEnum } from "../../../../utilities/constants";

//import ReactQuill from "react-quill-with-table";
//import "react-quill-with-table/dist/quill.snow.css";
import { MdOutlineModelTraining } from "react-icons/md";
import ReactQuill from "react-quill";

interface Props {
    translate: any,
    title: any,

    created?: any,
    updated?: any,

    situation?: any,
    confection?: any,
    partnerId?: any,
    sourceDocument?: any,
    numberDocument?: any,
    ownerType?: any,
    ownerId?: any,
    ownerName?: any,
    ownerRegistry?: any,
    description?: any,
    numParcel?: any,
    qtyParcels?: any,
    amountParcel?: any,
    expiry?: any,
    payment?: any,
    discount?: any,
    discountPerc?: any,
    addition?: any,
    additionPerc?: any,
    amount?: any,
    paymentForm?: any,
    paymentCode?: any,
    nameOnCard?: any,
    numberOnCard?: any,
    expirationOnCard?: any,
    validacionOnCard?: any,
    typePix?: any,
    keyPix?: any,
    barcode?: any,
    descriptive?: any,

    setSituation?: any,
    setConfection?: any,
    setPartnerId?: any,
    setSourceDocument?: any,
    setNumberDocument?: any,
    setOwnerType?: any,
    setOwnerId?: any,
    setOwnerName?: any,
    setOwnerRegistry?: any,
    setDescription?: any,
    setNumParcel?: any,
    setQtyParcels?: any,
    setAmountParcel?: any,
    setExpiry?: any,
    setPayment?: any,
    setDiscount?: any,
    setDiscountPerc?: any,
    setAddition?: any,
    setAdditionPerc?: any,
    setAmount?: any,
    setPaymentForm?: any,
    setPaymentCode?: any,
    setNameOnCard?: any,
    setNumberOnCard?: any,
    setExpirationOnCard?: any,
    setValidacionOnCard?: any,
    setTypePix?: any,
    setKeyPix?: any,
    setBarcode?: any,
    setDescriptive?: any,

    updatedAmount?: any,
    updatedPerc?: any,
    templateDefault?: any
}

function PaymentRecord(props: Props) {

    const display = props.translate.data;

    function onBlur_RecalculeAmount(event: any) {
        props.updatedAmount(event);
    }

    function onBlur_RecalculePerc(event: any) {
        props.updatedPerc(event);
    }

    function calculeDays() {
        const dateExpiry = toDate(props.expiry, 'dd/mm/yyyy');
        const dateNow = new Date();

        let numDays = 0;
        if (dateExpiry < dateNow) {
            numDays = Math.trunc(((dateNow.getTime() - dateExpiry.getTime()) / (1000 * 60 * 60 * 24)));
        }
        return numDays;
    }

    function onClick_TemplateDefault(event: any) {
        event.preventDefault();
        props.templateDefault(event);
    }

    return (
        <>
            <Row>
                <Col sm="12" className="padding-0-0">
                    <Row>
                        <Col sm="3">
                            <FormGroup>
                                <Form.Label htmlFor="form-confection">{display.label.confection}</Form.Label>
                                <FormControl type="text" id="form-confection" name="confection" placeholder={display.example.confection}
                                    value={props.confection}
                                    onChange={e => props.setConfection(toDate(e.target.value, 'dd/mm/yyyy'))}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="3">
                            <FormGroup>
                                <Form.Label htmlFor="form-expiry">{display.label.expiry_parcel}</Form.Label>
                                <FormControl type="text" id="form-expiry" name="expiry" placeholder={display.example.expiry}
                                    value={props.expiry}
                                    onChange={e => props.setExpiry(toDate(e.target.value, 'dd/mm/yyyy'))}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                        <Col sm="3"></Col>
                        <Col sm="3">
                            <FormGroup>
                                <Form.Label htmlFor="form-amount_parcel">{display.label.amount_parcel}</Form.Label>
                                <FormControl type="text" id="form-amount_parcel" name="amountParcel" placeholder={display.example.amount_parcel}
                                    className="text-right"
                                    value={props.amountParcel}
                                    onChange={e => props.setAmountParcel(floatMask(e.target.value))}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <FormGroup>
                                <Form.Label htmlFor="form-description">{display.label.description}</Form.Label>
                                <FormControl type="text" id="form-description" name="description" placeholder={display.example.description}
                                    value={props.description}
                                    onChange={e => props.setDescription(e.target.value)}
                                    readOnly
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3">
                            <Form.Label htmlFor="form-owner-type">{display.label.owner_type} (PF / PJ)</Form.Label>
                        </Col>
                        <Col sm="4">
                            <Form.Label htmlFor="form-owner-registry">{display.label.owner_registry} (CPF / CNPJ)</Form.Label>
                        </Col>
                        <Col sm="5">
                            <Form.Label htmlFor="form-owner-registry">{display.label.owner_name}</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="3">
                            <FormControl type="text" id="form-ownerType" name="ownerType" placeholder={display.example.ownerType}
                                value={getPerson(props.ownerType).name }
                                onChange={e => props.setOwnerType(e.target.value)}
                                readOnly
                            />
                        </Col>
                        <Col sm="4">
                            <FormControl type="hidden" id="form-owner-id" name="ownerId"
                                value={props.ownerId}
                                onChange={e => props.setOwnerId(e.target.value)}
                                readOnly
                            />
                            <FormControl id="form-owner-registry"  name="ownerRegistry" placeholder={display.example.owner_registry}
                                value={toString(props.ownerRegistry)}
                                onChange={e => props.setOwnerRegistry(cpfCnpjMask(e.target.value))}
                                readOnly
                            />
                        </Col>
                        <Col sm="5">
                            <FormControl id="form-owner-data" name="ownerName" placeholder={display.example.owner_name}
                                value={toString(props.ownerName)}
                                onChange={e => props.setOwnerName(e.target.value)}
                                readOnly
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="12"><hr/></Col>
                    </Row>
                    <Row>
                        <Col sm="12">
                            <fieldset>
                                <legend>{display.legend.payment_data}</legend>
                                <Row>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-expiry">{display.label.payment_form}</Form.Label>
                                            <Form.Select id="form-payment_form" name="paymentForm"
                                                value={props.paymentForm}
                                                onChange={(e: any) => props.setPaymentForm(e.target.value)}
                                            >
                                                { isEmpty(props.paymentForm) ? (
                                                    <option value="">...</option>
                                                ) : null }
                                                { PaymentType.map((iterator: any, idx: any) => (<option key={idx} value={iterator.name}>{display.label[toLowerCase(iterator.name)]}</option>)) }
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="8">
                                        {props.paymentForm === PaymentEnum.CARTON ? (
                                            <>
                                                <Row>
                                                    <Col sm="12">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-name_on_card">{display.label.name_on_card}</Form.Label>
                                                            <FormControl type="text" id="form-name_on_card" name="nameOnCard" placeholder={display.example.name_on_card}
                                                                value={toString(props.nameOnCard)}
                                                                onChange={e => props.setNameOnCard(e.target.value)}
                                                                readOnly
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-number_on_card">{display.label.number_on_card}</Form.Label>
                                                            <FormControl type="text" id="form-number_on_card" name="numberOnCard" placeholder={display.example.number_on_card}
                                                                value={toString(props.numberOnCard)}
                                                                onChange={e => props.setNumberOnCard(e.target.value)}
                                                                readOnly
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-expiration_on_card">{display.label.expiration_on_card}</Form.Label>
                                                            <FormControl type="text" id="form-expiration_on_card" name="expirationOnCard" placeholder={display.example.expiration_on_card}
                                                                value={toString(props.expirationOnCard)}
                                                                onChange={e => props.setExpirationOnCard(toDate(e.target.value, 'mm/yy'))}
                                                                readOnly
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-validacion_on_card">{display.label.validacion_on_card}</Form.Label>
                                                            <FormControl type="text" id="form-validacion_on_card" name="validacionOnCard" placeholder={display.example.validacion_on_card}
                                                                value={toString(props.validacionOnCard)}
                                                                onChange={e => props.setValidacionOnCard(e.target.value)}
                                                                readOnly
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : props.paymentForm === PaymentEnum.PIX ? (
                                            <>
                                                <Row>
                                                    <Col sm="4">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-type_pix">{display.label.key_pix}Tipo de Chave</Form.Label>
                                                            <FormControl type="text" id="form-type_pix" name="typePix" placeholder={display.example.type_pix}
                                                                value={toString(props.typePix)}
                                                                onChange={e => props.setTypePix(e.target.value)}
                                                                readOnly
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="8">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-key_pix">{display.label.key_pix}Chave Pix</Form.Label>
                                                            <FormControl type="text" id="form-key_pix" name="keyPix" placeholder={display.example.key_pix}
                                                                value={toString(props.keyPix)}
                                                                onChange={e => props.setKeyPix(e.target.value)}
                                                                readOnly
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : props.paymentForm === PaymentEnum.BILLET ? (
                                            <>
                                                <Row>
                                                    <Col sm="12">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-barcode">{display.label.barcode}</Form.Label>
                                                            <FormControl type="text" id="form-barcode" name="barcode" placeholder={display.example.barcode}
                                                                value={toString(props.barcode)}
                                                                onChange={e => props.setBarcode(e.target.value)}
                                                                readOnly
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </>
                                        ) : null }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="2">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-delayed_days">{display.label.delayed}</Form.Label>
                                            <FormControl type="text" id="form-delayed_days" name="delayedDays" placeholder={display.example.confection}
                                                value={calculeDays()}
                                                // onChange={e => props.setConfection(toDate(e.target.value, 'dd/mm/yyyy'))}
                                                readOnly
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-discount">{display.label.discount}</Form.Label>
                                            <div className="w-box">
                                                <FormControl type="text" id="form-discount" name="discount" placeholder={display.example.discount} 
                                                    className="text-right w-60"
                                                    value={props.discount}
                                                    onChange={e => props.setDiscount(floatMask(e.target.value))}
                                                    onBlur={e => onBlur_RecalculeAmount(e) }
                                                />
                                                <FormControl type="text" id="form-discount-perc" name="discountPerc" placeholder={display.example.discount_perc}
                                                    value={props.discountPerc}
                                                    onChange={e => props.setDiscountPerc(floatMask(e.target.value))}
                                                    onBlur={e => onBlur_RecalculePerc(e) }
                                                    className="w-40"
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-addition">{display.label.addition}</Form.Label>
                                            <div className="w-box">
                                                <FormControl type="text" id="form-addition" name="addition" placeholder={display.example.addition}
                                                    className="text-right w-60"
                                                    value={props.addition}
                                                    onChange={e => props.setAddition(floatMask(e.target.value))}
                                                    onBlur={e => onBlur_RecalculeAmount(e) }
                                                />
                                                <FormControl type="text" id="form-addition-perc" name="additionPerc" placeholder={display.example.addition_perc}
                                                    value={props.additionPerc}
                                                    onChange={e => props.setAdditionPerc(floatMask(e.target.value))}
                                                    onBlur={e => onBlur_RecalculePerc(e) }
                                                    className="w-40"
                                                />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-amount">{display.label.amount_payable}</Form.Label>
                                            <FormControl type="text" id="form-amount" name="amount" placeholder={display.example.amount_payable}
                                                className="text-right"
                                                value={props.amount}
                                                onChange={e => props.setAmount(floatMask(e.target.value))}
                                                readOnly
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </fieldset>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <fieldset className="h-100">
                        <legend>Descritivo no Recibo
                            <div className="card-actions float-right">
                                <MdOutlineModelTraining size={18} onClick={e => onClick_TemplateDefault(e)} />
                            </div>
                        </legend>
                        <Row>
                            <Col sm="12">
                                <ReactQuill theme="snow" value={props.descriptive} onChange={props.setDescriptive} />
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
            </Row>
        </>
    );

}

export default PaymentRecord;
