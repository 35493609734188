import { Fragment, useMemo } from "react";
import { Button, Col, Container, Form, FormControl, FormGroup, InputGroup, OverlayTrigger, Row, Tab, Tabs, Tooltip } from "react-bootstrap";
import { FaBarcode, FaCheckCircle, FaEdit, FaMoneyBillAlt, FaRegCircle, FaShip, FaTrash, FaTrashAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import { PickerInput, VesselOwner, VesselOwnerGender, VesselOwnerPhones } from "../../../../components/sub-render.component";
import TableContainer from "../../../../components/table-container.component";
import { dateToString, toDate, toInt, floatToString, toLowerCase, now, toFloat, toString, leftPad } from "../../../../utilities/auxiliary-functions";
import { BILLET_MODE_EDIT, BILLET_MODE_VIEW, ContractProgressEnum } from "../../../../utilities/constants";
import { cnpjMask, floatMask, numberMask, phoneMask, sizeMask, zipcodeMask } from "../../../../utilities/masks";
import { ContractProgressType, getContractSituation, getVessel } from "../../../../utilities/types";
import { isEmpty } from "../../../../utilities/validators";
import { MdLaunch } from "react-icons/md";
import { BsReceipt } from "react-icons/bs";
import ContractReceiptReport, { TContractReceiptReport } from "../../../reports/contract-receipt.report";
import { RiUserReceived2Fill } from "react-icons/ri";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import cduService from "../../../../services/cdu.service";
import UploadFileIcon from '@mui/icons-material/UploadFile';

interface Props {
    partner: any,
    translate: any,
    title: any,
    isEdit: boolean,
    isEditLaucher: boolean,
    hasBlocked: boolean,

    ships?: any,

    contractKey?: any,
    situation?: any,
    confection?: any,
    progress?: any,
    ownerType?: any,
    ownerId?: any,
    ownerName?: any,
    ownerRegistry?: any,
    vessels?: any,
    responsibles?: any,
    indCalculated?: any,
    pricePerFeet?: any,
    totalVessels?: any,
    totalOfFeet?: any,
    qtyParcels?: any,
    expected?: any,
    discount?: any,
    discountPerc?: any,
    addition?: any,
    additionPerc?: any,
    amount?: any,
    validity?: any,
    startBillingIn?: any,
    expirationDay?: any,
    generatedPlots?: any,
    accept?: any,
    signedBy?: any,
    monthlies?: any,
    totalAmountParcel?: any,
    contractualDifference?: any,
    indAverage?: any,
    setSituation?: any,
    setConfection?: any,
    setProgress?: any,
    setOwnerType?: any,
    setOwnerId?: any,
    setOwnerName?: any,
    setOwnerRegistry?: any,
    setVessels?: any,
    setResponsibles?: any,
    setIndCalculated?: any,
    setPricePerFeet?: any,
    setTotalVessels?: any,
    setTotalOfFeet?: any,
    setQtyParcels?: any,
    setExpected?: any,
    setDiscount?: any,
    setDiscountPerc?: any,
    setAddition?: any,
    setAdditionPerc?: any,
    setAmount?: any,
    setValidity?: any,
    setStartBillingIn?: any,
    setExpirationDay?: any,
    setGeneratedPlots?: any,
    setAccept?: any,
    setSignedBy?: any,
    setMonthlies?: any,
    setTotalAmountParcel?: any,
    setContractualDifference?: any,
    setIndAverage?: any,

    calculateValidity?: any,
    clearOwner?: any,
    changeOwnerType?: any,
    searchOwner?: any,
    openFilterVessel?: any,
    updatedAmount?: any,
    generateMonthly?: any,
    addResponsible?: any,
    editResponsible?: any,
    deleteResponsible?: any,
    changeAverage?: any,

    setLauncherId?: any,
    setLauncher?: any,
    setEditLauncher?: any,
    setShowLauncher?: any,
    setShowPayment?: any,
    setShowContract?: any,

    changeResponsibleSelected?: any,
    checkCalculated?: any,
    generateInstallments?: any,

    signatureDocuments: any,
    changeSignatureDocuments: any,
    clickViewDocument: any
}

function ContractRecord(props: Props) {

    const display = props.translate.data;

    const columnsVessel = useMemo(() => [{
            Header: display.label.name,
            accessor: 'name',
            minWidth: 250,
            Cell: (row: any) => (
                <div className="white-space">{row.value}</div>
            )
        },{
            Header: display.label.type,
            accessor: 'typeVessel',
            minWidth: 250,
            Cell: (row: any) => (
                <div className="white-space">{getVessel(row.value).description}</div>
            )
        },{
            Header: display.label.enrollment,
            accessor: 'enrollment',
            minWidth: 250,
            Cell: (row: any) => (
                <div className="white-space">{row.value}</div>
            )
        },{
            Header: display.label.vessel_size,
            accessor: 'vesselSize',
            minWidth: 250,
            Cell: (row: any) => (
                <div className="white-space">{sizeMask(row.value)}</div>
            )
        },{
            Header: display.label.cost,
            accessor: 'vesselCost',
            minWidth: 250,
            Cell: (row: any) => (
                <div className="white-space">{floatMask(row.value)}</div>
            )
        }],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    ); // columnsVessel

    const columnsResponsible = useMemo(() => [
        {        
            Header: 'Pagador',
            accessor: 'officialPayer',
            Cell: (props: any) => {
                let responsibleRows = [] as any;
                const originals = props.rows;
                for (var o = 0; o < originals.length; o++) {
                    responsibleRows[responsibleRows.length] = originals[o].original;
                }
                const responsibleRow = props.row.original;

                return (
                    <div onClick={e => onClick_ResponsibleSelected(e, responsibleRows, responsibleRow.id)}>
                        <span>{responsibleRow.officialPayer ? <FaCheckCircle size={18}/> : <FaRegCircle size={18}/>}</span>
                    </div>
                );
            }
        },{
            Header: display.label.fullname,
            Cell: (row: any) => <VesselOwner translate={props.translate} values={row} viewname={true} />
        },{
            Header: display.label.gender,
            Cell: (row: any) => <VesselOwnerGender translate={props.translate} values={row} />
        },{
            Header: display.label.telephone,
            Cell: (row: any) => <VesselOwnerPhones translate={props.translate} values={row} />
        },{
            Header: display.legend.actions,
            accessor: "actions",
            Cell: (props: any) => {
                let responsibleRows = [] as any;
                const originals = props.rows;
                for (var o = 0; o < originals.length; o++) {
                    responsibleRows[responsibleRows.length] = originals[o].original;
                }
                const responsibleRow = props.row.original;

                return (
                    <div className="white-space table-actions">
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.toEdit}</Tooltip>}>
                            <span onClick={(e) => onClick_EditResponsible(e, responsibleRow)}>
                                <FaEdit size={18} />
                            </span>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.delete}</Tooltip>}>
                            <span onClick={(e) => onClick_DeleteResponsible(e, responsibleRows, responsibleRow)}>
                                <FaTrash size={18} />
                            </span>
                        </OverlayTrigger>
                    </div>
                );
            },
        }],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    ); // columnsResponsible

    function onClick_ResponsibleSelected(event: any, responsibleRows: any, responsibleId: any) {
        props.changeResponsibleSelected(event, responsibleRows, responsibleId);
    }

    function onChange_Confection(event: any, date: any) {
        props.setConfection(dateToString(date, 'dd/mm/yyyy'));
        onBlur_CalculateValidity(event);
    }

    function onChange_Validity(event: any, date: any) {
        props.setValidity(dateToString(date, 'dd/mm/yyyy'));
    }

    function onBlur_CalculateValidity(event: any) {
        props.calculateValidity(event);
    }

    
    function onClick_OpenFilterVessel(event: any) {
        event.preventDefault();
        props.openFilterVessel(event);
    }
    
    function onBlur_RecalculeAmount(event: any) {
        props.updatedAmount(event);
    }
    
    function onChange_Monthly(event: any, index: number) {}
    
    function onBlur_Monthly(event: any) {}


    function onChange_QtyParcel(event: any) {
        const { value } = event.target;
        if (value < 1)
            props.setQtyParcels(1);
        else
            props.setQtyParcels(value);

        props.updatedAmount(event);
    }

    function onClick_GenerateInstallments(event: any) {
        event.preventDefault();
        props.generateInstallments();
    }

    function onClick_CheckCalculated(event: any) {
        event.preventDefault();
        props.checkCalculated(event.target.value);
    }

    function checkAverage(event: any) {
        event.preventDefault();
        props.changeAverage(event);
    }

    function onClick_AddResponsible(event: any) {
        event.preventDefault();
        props.addResponsible(event);
    }

    function onClick_EditResponsible(event: any, responsable: any) {
        event.preventDefault();
        console.log(responsable);
        props.editResponsible(event, responsable);
    }

    function onClick_DeleteResponsible(event: any, responsibles: any, responsable: any) {
        event.preventDefault();
        props.deleteResponsible(event, responsibles, responsable);
    }

    function onClick_BilletLauncher(event: any, monthly: any, modo: number) {
        event.preventDefault();  

        props.setLauncherId(monthly.id);
        props.setLauncher(monthly);
        props.setEditLauncher(modo);
        props.setShowLauncher(true);
        props.setShowContract(false);
    } 

    function onClick_PaymentLauncher(event: any, monthly: any) {
        event.preventDefault();

        props.setLauncherId(monthly.id);
        props.setLauncher(monthly);
        props.setShowPayment(true);
        props.setShowContract(false);
    }

    async function onClick_ReceiptLauncher(event: any, launcher: any) {
        event.preventDefault();

        const address = await cduService.addressById(props.partner.company.addressId);
        let receipt: TContractReceiptReport = {
            company: {
                image: props.partner.company.image,
                socialReason: props.partner.company.socialReason,
                fantasy: props.partner.company.fantasy,
                registrationPj: cnpjMask(props.partner.company.registrationPj),
                telephone: phoneMask(props.partner.company.telephone),
                email: props.partner.company.email,
                address: `${address.place} - ${address.district} - ${address.city} - ${address.state} - ${zipcodeMask(address.zipcode)}`
            },
            paying: launcher.owner.name,
            amount: floatToString(launcher.amount),
            numParcel: launcher.numParcel,
            qtyParcels: launcher.qtyParcels,
            payday: dateToString(launcher.payment, 'dd/mm/yyyy'),
            numberDocument: launcher.sourceDocument + leftPad(launcher.numberDocument, 5, '0'),
            form: display.label[toLowerCase(launcher.paymentForm)],
            vessels: props.vessels.map((vessel: any) => {
                return {
                    enrollment: vessel.enrollment,
                    name: vessel.name
                }
            })
            /*
            vessels: [{
                enrollment: budget.vessel.enrollment,
                name: budget.vessel.name
            }],
            */
        };
        ContractReceiptReport(receipt);
    }

    function onClick_ViewDocument(event: any, title: string, url: any) {
        event.preventDefault();
        props.clickViewDocument(event, title, url);
    } 

    function onChange_SignatureDocuments(event: any, identy: string) {
        event.preventDefault();
        props.changeSignatureDocuments(event, identy);
    }

    return (
        <>
            <Row>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <h5>Situação</h5>
                    <p>
                        {getContractSituation(props.situation).description}
                    </p>
                </div>
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={toInt(props.progress)} alternativeLabel>
                        {ContractProgressType.map((label: any, idx: number) => (
                        <Step key={idx}>
                            <StepLabel>{label.description}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                </Box>
                <div className="d-flex justify-content-center">
                    <Col sm="2">
                        <FormGroup className="flex-column d-flex align-items-center">
                            <Form.Label htmlFor="form-contract-key" className="mb-2 mt-3">Chave Contratual</Form.Label>
                            <FormControl type="text" id="form-contract-key" name="contractKey"
                                className="text-center"
                                value={props.contractKey}
                                readOnly
                            />
                        </FormGroup>
                    </Col>
                    <Col sm="2">
                        <FormGroup className="flex-column d-flex align-items-center">
                            <Form.Label htmlFor="form-confection" className="mb-2 mt-3">Data do contrato</Form.Label>
                            <DatePicker
                                selected={toDate(props.confection, 'dd/mm/yyyy')}
                                onChange={(date: any, e: any) => onChange_Confection(e, date)}
                                dateFormat="dd/MM/yyyy"
                                customInput={<PickerInput />}
                                // minDate={new Date()}
                                popperClassName="some-custom-class"
                                popperPlacement="top-end"
                                popperModifiers={[{
                                        name : "offset",
                                        options : {
                                            offset : [5, 10]
                                        }
                                    },{
                                        name : "preventOverflow",
                                        options : {
                                            rootBoundary : "viewport",
                                            tether : false,
                                            altAxis : true
                                        }
                                }]}
                                disabled={props.hasBlocked} 
                                required
                            />
                        </FormGroup>
                    </Col>
                </div>
            </Row>
            <Row>
                <Col sm="12"><hr/></Col>
            </Row>
            <Row>
                <Col sm="12">
                    <fieldset>
                        <legend>{display.legend.vessels}
                        {(props.progress <= ContractProgressEnum.ELABORATION) && (
                            <div className="card-actions float-right">
                                <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.add} {display.tooltips.responsible}</Tooltip>}>
                                    <a href="/#" onClick={e => onClick_OpenFilterVessel(e)}>
                                        <FaShip size={18} />
                                    </a>
                                </OverlayTrigger>
                            </div>
                        )}
                        </legend>
                        <TableContainer columns={columnsVessel} data={props.vessels} />
                    </fieldset>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <fieldset>
                        <legend>{display.legend.associates}
                        {(props.progress <= ContractProgressEnum.ELABORATION) && (
                            <div className="card-actions float-right">
                            <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.add} {display.tooltips.responsible}</Tooltip>}>
                                <a href="/#" onClick={onClick_AddResponsible}>
                                    <RiUserReceived2Fill size={18} />
                                </a>
                            </OverlayTrigger>
                            </div>
                        )}
                        </legend>
                        <TableContainer columns={columnsResponsible} data={isEmpty(props.responsibles) ? [] : props.responsibles} />
                    </fieldset>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <Tabs defaultActiveKey={toInt(props.progress) === ContractProgressEnum.FINANCIAL ? "contract-monthly" : "contract-amount"} id="tabs-contract" className="mb-3">
                        <Tab eventKey="contract-amount" title={display.legend.contract_values} className="tab-contract">
                            <Container>
                                <fieldset style={{ minHeight : 75 }}>
                                    <Row>
                                        <Col sm="3">
                                            <div className="react-switch">
                                                <FormControl type="checkbox" className="react-switch-checkbox" id="form-ind-calculated" name="indCalculated" 
                                                    checked={props.indCalculated}
                                                    onClick={(e) => onClick_CheckCalculated(e)}
                                                    onChange={(e) => onClick_CheckCalculated(e)}
                                                    disabled={props.vessels.length === 0 || props.hasBlocked}
                                                />
                                                <Form.Label className="react-switch-label" htmlFor="form-ind-calculated">
                                                    <span className={`react-switch-button`} /> 
                                                </Form.Label>
                                                <span className="react-switch-description">Valor Calculado</span>
                                            </div>
                                        </Col>
                                        <Col sm="9">
                                            { props.indCalculated ? (
                                                <Row>
                                                    <Col sm="3">
                                                        <Form.Label htmlFor="form-price_per_feet">{display.label.price_per_feet}</Form.Label>
                                                        <FormControl type="text" id="form-price_per_feet" name="pricePerFeet" placeholder={display.example.price_per_feet}
                                                            className="text-right"
                                                            value={props.pricePerFeet}
                                                            onChange={e => props.setPricePerFeet(floatMask(e.target.value))}
                                                            onBlur={e => onBlur_RecalculeAmount(e) }
                                                            disabled={props.vessels.length === 0 || props.hasBlocked}
                                                        />
                                                    </Col>
                                                    <Col sm="2">
                                                        <Form.Label htmlFor="form-total_of_feet">{display.label.total_of_feet}</Form.Label>
                                                        <FormControl type="text" id="form-total_vessels_size" name="totalOfFeet" placeholder={display.example.total_of_feet} 
                                                            readOnly
                                                            className="text-right"
                                                            value={props.totalOfFeet}
                                                            onChange={e => props.setTotalOfFeet(numberMask(e.target.value))}
                                                            onBlur={e => onBlur_RecalculeAmount(e) }
                                                            disabled={props.vessels.length === 0 || props.hasBlocked}
                                                        />
                                                    </Col>
                                                    <Col sm="3">
                                                        <Form.Label htmlFor="form-total_vessels">{display.label.total_vessels}</Form.Label>
                                                        <FormControl type="text" id="form-total_vessels" name="totalVessels" placeholder={display.example.total_vessels_size} 
                                                            readOnly
                                                            className="text-right"
                                                            value={props.totalVessels}
                                                            onChange={e => props.setTotalVessels(numberMask(e.target.value))}
                                                            onBlur={e => onBlur_RecalculeAmount(e) }
                                                            disabled={props.vessels.length === 0 || props.hasBlocked}
                                                        />
                                                    </Col>
                                                    <Col sm="4">
                                                        <Form.Label htmlFor="form-expected-value">{display.label.expected_value}</Form.Label>
                                                        <FormControl type="text" id="form-expected-value" name="expectedValue" placeholder={display.example.expected_value} 
                                                            readOnly
                                                            className="text-right"
                                                            value={props.expected}
                                                            onChange={e => props.setExpected(floatMask(e.target.value))}
                                                            onBlur={e => onBlur_RecalculeAmount(e) }
                                                            disabled={props.vessels.length === 0 || props.hasBlocked}
                                                        />
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <Row>
                                                    <Col sm="8"></Col>
                                                    <Col sm="4">
                                                        <Form.Label htmlFor="form-expected-value">{display.label.expected_value}</Form.Label>
                                                        <FormControl type="text" id="form-expected-value" name="expectedValue" placeholder={display.example.expected_value} 
                                                            className="text-right"
                                                            value={props.expected}
                                                            onChange={e => props.setExpected(floatMask(e.target.value))}
                                                            onBlur={e => onBlur_RecalculeAmount(e) }
                                                            disabled={props.vessels.length === 0 || props.hasBlocked}
                                                        />
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>
                                    </Row>
                                </fieldset>
                                <Row>
                                    <Col sm="6"></Col>
                                    <Col sm="3" className="text-right">
                                        <Form.Label htmlFor="form-qtyParcels">Qtd de meses contratuais</Form.Label>
                                    </Col>
                                    <Col sm="3">
                                        <FormControl type="number" id="form-qtyParcels" name="qtyParcels" placeholder={display.example.qty_parcels}
                                            className="text-right"
                                            value={props.qtyParcels}
                                            onChange={e => onChange_QtyParcel(e) }
                                            onBlur={e => onBlur_RecalculeAmount(e) }
                                            onKeyUp={e => onBlur_RecalculeAmount(e) }
                                            disabled={props.vessels.length === 0 || props.hasBlocked}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" className="form-row-divider"></Col>
                                </Row>
                                <Row>
                                    <Col sm="3" className="text-right">
                                        <Form.Label htmlFor="form-validity">{display.label.validity}</Form.Label>
                                    </Col>
                                    <Col sm="3">
                                        <DatePicker
                                            selected={toDate(props.validity, 'dd/mm/yyyy')}
                                            onChange={(date: any, e: any) => onChange_Validity(e, date)}
                                            dateFormat="dd/MM/yyyy"
                                            customInput={<PickerInput />}
                                            minDate={toDate(props.confection, 'dd/mm/yyyy')}

                                            popperClassName="some-custom-class"
                                            popperPlacement="top-end"
                                            popperModifiers={[{
                                                    name : "offset",
                                                    options : {
                                                        offset : [5, 10]
                                                    }
                                                },{
                                                    name : "preventOverflow",
                                                    options : {
                                                        rootBoundary : "viewport",
                                                        tether : false,
                                                        altAxis : true
                                                    }
                                            }]}
                                            disabled={props.hasBlocked}
                                            required
                                        />
                                    </Col>
                                    <Col sm="3" className="text-right">
                                        <Form.Label htmlFor="form-discount">{display.label.discount}</Form.Label>
                                    </Col>
                                    <Col sm="3">
                                        <div className="w-box">
                                            <FormControl type="text" id="form-discount" name="discount" placeholder={display.example.discount}
                                                className="text-right w-60"
                                                value={props.discount}
                                                onChange={e => props.setDiscount(floatMask(e.target.value))}
                                                onBlur={e => onBlur_RecalculeAmount(e) }
                                                disabled={props.vessels.length === 0 || props.hasBlocked}
                                            />
                                            <FormControl type="text" id="form-discount-perc" name="discountPerc" placeholder={display.example.discount_perc}
                                                className="text-right w-40"
                                                value={`${props.discountPerc}%`} 
                                                onChange={e => props.setDiscountPerc(floatMask(e.target.value))}
                                                onBlur={e => onBlur_RecalculeAmount(e) }
                                                disabled={props.vessels.length === 0 || props.hasBlocked}
                                                readOnly
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" className="form-row-divider"></Col>
                                </Row>
                                <Row>
                                    <Col sm="3" className="text-right">
                                        <Form.Label htmlFor="form-start_billing_in">{display.label.start_billing_in}</Form.Label>
                                    </Col>
                                    <Col sm="3">
                                        <FormControl type="number" id="form-start_billing_in" name="startBillingIn" placeholder={display.example.start_billing_in}
                                            className="text-right"
                                            value={props.startBillingIn}
                                            onChange={e => props.setStartBillingIn(e.target.value)}
                                            disabled={props.vessels.length === 0 || props.hasBlocked}
                                        />
                                    </Col>
                                    <Col sm="3" className="text-right">
                                        <Form.Label htmlFor="form-addition">{display.label.addition}</Form.Label>
                                    </Col>
                                    <Col sm="3">
                                        <div className="w-box">
                                            <FormControl type="text" id="form-addition" name="addition" placeholder={display.example.addition}
                                                className="text-right w-60"
                                                value={props.addition}
                                                onChange={e => props.setAddition(floatMask(e.target.value))}
                                                onBlur={e => onBlur_RecalculeAmount(e) }
                                                disabled={props.vessels.length === 0 || props.hasBlocked}
                                            />
                                            <FormControl type="text" id="form-addition-perc" name="additionPerc" placeholder={display.example.addition_perc}
                                                className="text-right w-40"
                                                value={`${props.additionPerc}%`}
                                                onChange={e => props.setAdditionPerc(floatMask(e.target.value))}
                                                onBlur={e => onBlur_RecalculeAmount(e) }
                                                disabled={props.vessels.length === 0 || props.hasBlocked}
                                                readOnly
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12" className="form-row-divider"></Col>
                                </Row>
                                <Row>
                                    <Col sm="3" className="text-right">
                                        <Form.Label htmlFor="form-expiration_day">{display.label.expiration_day}</Form.Label>
                                    </Col>
                                    <Col sm="3">
                                        <FormControl type="number" id="form-expiration_day" name="expirationDay" placeholder={display.example.expiration_day}
                                            className="text-right"
                                            value={props.expirationDay}
                                            onChange={e => props.setExpirationDay(e.target.value)}
                                            disabled={props.vessels.length === 0 || props.hasBlocked}
                                        />
                                    </Col>
                                    <Col sm="3" className="text-right">
                                        <Form.Label htmlFor="form-amount">{display.label.amount}</Form.Label>
                                    </Col>
                                    <Col sm="3">
                                        <FormControl type="text" id="form-amount" name="amount" placeholder={display.example.amount} readOnly
                                            className="text-right"
                                            value={props.amount}
                                            onChange={e => props.setAmount(floatMask(e.target.value))}
                                            onBlur={e => onBlur_RecalculeAmount(e) }
                                            disabled={props.vessels.length === 0 || props.hasBlocked}
                                        />
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        <Tab eventKey="contract-monthly" title={display.legend.tuition} className="tab-contract">
                            <Container>
                                <Row>
                                    <Col sm="5">
                                        <Row>
                                            <Col className="white-space"><strong>{display.label.qty_parcels}:</strong></Col><Col>{props.qtyParcels}</Col>
                                        </Row>
                                        <Row>
                                            <Col className="white-space"><strong>{display.label.total_installments}:</strong></Col><Col>{floatToString(toFloat(props.totalAmountParcel) / toInt(props.qtyParcels))}</Col>
                                        </Row>
                                        <Row>
                                            <Col className="white-space"><strong>{display.label.contract_value}:</strong></Col><Col>{props.amount}</Col>
                                        </Row>
                                    </Col>
                                    <Col sm="5">
                                        <div className="react-switch">
                                            <FormControl type="checkbox" className="react-switch-checkbox" id="form-ind-average" name="indAverage" 
                                                checked={props.indAverage}
                                                onChange={(e) => checkAverage(e)}
                                                disabled={props.vessels.length === 0 || props.hasBlocked}
                                            />
                                            <Form.Label className="react-switch-label" htmlFor="form-ind-average">
                                                <span className={`react-switch-button`} /> 
                                            </Form.Label>
                                            <span className="react-switch-description">Gerar as mensalidades utilizando sistema de COTA</span>
                                        </div>
                                    </Col>
                                    <Col sm="2">
                                        <Button variant={`${(props.progress > ContractProgressEnum.ELABORATION) ? 'secondary' : 'warning'}`}
                                            onClick={e => onClick_GenerateInstallments(e)}
                                            disabled={(props.progress > ContractProgressEnum.ELABORATION)}
                                        >Gerar Parcelas</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12"><hr/></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <ul className="monthly-pcls" style={{maxHeight: '160px', overflowY: 'auto'}}>
                                            {props.monthlies.map((monthly: any, index: number) => (
                                                <li className={`monthly-pcl${ monthly.payment ? '-pay' : 
                                                    toDate(monthly.expiry, 'dd/mm/yyyy') < now() ? '-late' :
                                                    monthly.status !== 'none' ? '-'+ monthly.status : ''}`} key={index}>
                                                    <span>
                                                        <Row>
                                                            <Col sm="2">
                                                                <span className="monthly-pcl-number">{monthly.numParcel}</span>
                                                            </Col>
                                                            <Col sm="5">
                                                                <Form.Label htmlFor="form-expiry">{display.label.expiry_monthly}</Form.Label>
                                                                <FormControl type="text" id="form-expiry" name="expiryMonthly" maxLength={10} placeholder={display.example.expiry_monthly}
                                                                    value={monthly.expiry} 
                                                                    onChange={e => onChange_Monthly(e, index)}
                                                                    onBlur={e => onBlur_Monthly(e)}
                                                                    readOnly 
                                                                    disabled={props.vessels.length === 0 || props.hasBlocked}
                                                                />
                                                            </Col>
                                                            <Col sm="5">
                                                                <Form.Label htmlFor="form-amount">{display.label.amount_monthly}</Form.Label>
                                                                <FormControl type="text" id="form-amount" name="amountMonthly" placeholder={display.example.amount_monthly}
                                                                    className="text-right"
                                                                    value={monthly.amountParcel}
                                                                    onChange={e => onChange_Monthly(e, index)}
                                                                    onBlur={e => onBlur_Monthly(e)}
                                                                    readOnly 
                                                                    disabled={props.vessels.length === 0 || props.hasBlocked}
                                                                />
                                                            </Col>
                                                        </Row>
                                                        {((props.progress > ContractProgressEnum.SIGNATURE) ? (
                                                                <Row className="">
                                                                    <Col sm="9">
                                                                        { monthly.payment && <div className="payment-made">
                                                                            <span>Pgto <strong>{ dateToString(monthly.payment, 'dd/mm/yyyy') }</strong> em <strong>{ display.label[toLowerCase(monthly.paymentForm)] }</strong></span>
                                                                        </div>}
                                                                    </Col>
                                                                    <Col sm="1">
                                                                        { isEmpty( monthly.paymentForm) ? ( 
                                                                                <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.make_payment}</Tooltip>}>
                                                                                    <span onClick={(e) => onClick_PaymentLauncher(e,  monthly)}>
                                                                                        <FaMoneyBillAlt size={22} />
                                                                                    </span>
                                                                                </OverlayTrigger>
                                                                        ) : null }
                                                                    </Col>
                                                                    <Col sm="1">
                                                                        { isEmpty( monthly.paymentForm) ? (
                                                                            !isEmpty( monthly.paymentCode) ? (
                                                                                    <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.track_status}</Tooltip>}>
                                                                                        <span onClick={(e) => onClick_BilletLauncher(e,  monthly, BILLET_MODE_VIEW)}>
                                                                                            <FaBarcode size={22} />
                                                                                        </span>
                                                                                    </OverlayTrigger>
                                                                            ) : (
                                                                                    <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.generate_ticket}</Tooltip>}>
                                                                                        <span onClick={(e) => onClick_BilletLauncher(e,  monthly, BILLET_MODE_EDIT)}>
                                                                                            <MdLaunch size={22} />
                                                                                        </span>
                                                                                    </OverlayTrigger>
                                                                            )
                                                                        ) : null }
                                                                    </Col>
                                                                    <Col sm="1">
                                                                        { !isEmpty(monthly.paymentForm) ? (
                                                                            <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.view_receipt}</Tooltip>}>
                                                                                <span onClick={(e) => onClick_ReceiptLauncher(e, monthly)}>
                                                                                    <BsReceipt size={22} />
                                                                                </span>
                                                                            </OverlayTrigger>
                                                                        ) : null }
                                                                    </Col>
                                                                </Row>
                                                        ) : null)}
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        {(props.progress >= ContractProgressEnum.SIGNATURE) && (
                        <Tab eventKey="contract-signature" title={display.legend.signatures} className="tab-contract">
                            <Container>
                                <Row>
                                    <Col sm="12">
                                        <fieldset>
                                            <legend>Contratos Assinados</legend>
                                            <div className="signature-documents">
                                                {props.signatureDocuments.map((signatureDocument: any, index: number) => (
                                                    <FormGroup key={`sign-${index}`}>
                                                        <Form.Label>{signatureDocument.fullname}</Form.Label>
                                                        <InputGroup>
                                                            <FormControl className="input-file-text"
                                                                id={`form-file-signature-document-${index}`} name={`signatureDocument[${index}]`}
                                                                value={toString(signatureDocument.filename)}
                                                                readOnly
                                                                style={{marginBottom: '2px'}}

                                                            />
                                                            <Form.Control type='file' id={`input-file-signature-document-${index}`} onChange={(e)=> { onChange_SignatureDocuments(e, signatureDocument.identy) }} />
                                                                <OverlayTrigger overlay={<Tooltip id="tooltip">
                                                                    {display.tooltips.upload_documents}</Tooltip>}>
                                                                    <Form.Label className="input-file-button"
                                                                        htmlFor={`input-file-signature-document-${index}`}>
                                                                        <UploadFileIcon />
                                                                    </Form.Label>
                                                                </OverlayTrigger>
                                                                {!isEmpty(signatureDocument.document) ? (
                                                                    <>
                                                                    <Form.Label
                                                                        className="open-file-button input-file-button"
                                                                        htmlFor={`file-signature-document-${index}`}
                                                                        onClick={ (e)=> { onClick_ViewDocument(e, 
                                                                            display.label.tracker_policy_document, 
                                                                            signatureDocument.document
                                                                        )
                                                                    }}>{display.buttom.visualize}</Form.Label>
                                                                    <OverlayTrigger overlay={<Tooltip id="tooltip">
                                                                        {display.tooltips.remove_documents}</Tooltip>}>
                                                                        <Form.Label className="input-file-button"
                                                                            onClick={(e)=> { onChange_SignatureDocuments(e, signatureDocument.identy) }}
                                                                        >
                                                                            <FaTrashAlt size={18} />
                                                                        </Form.Label>
                                                                    </OverlayTrigger>
                                                                    </>
                                                                ) : null}
                                                        </InputGroup>
                                                    </FormGroup>
                                                ))}
                                            </div>
                                        </fieldset>
                                    </Col>
                                </Row>
                            </Container>
                        </Tab>
                        )}
                    </Tabs>
                </Col>
            </Row>
        </>
    );

}

export default ContractRecord;


