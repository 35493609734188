import ptBR from 'date-fns/locale/pt-BR';
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Container, Image, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { FaEye, FaSpinner } from "react-icons/fa";
import Jet from '../../assets/Jetski.svg';
import Lancha from '../../assets/Lancha.svg';
import { useNavigate } from "react-router-dom";
import { PickerButtom } from "../../components/sub-render.component";
import TableContainer from "../../components/table-container.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import authService from "../../services/auth.service";
import lclService from "../../services/lcl.service";
import { arrival, dateToString, notNullToDate, toDate, toInt, toLowerCase } from "../../utilities/auxiliary-functions";
import { PermissionEnum, VESSEL_JETSKI } from "../../utilities/constants";
import { cnpjMask, phoneMask, removeFormatDate, zipcodeMask } from "../../utilities/masks";
import { AlertMessageEnum, getColorDefault } from "../../utilities/types";
import { isDate } from "../../utilities/validators";
import OperationHeader from "../headers/operation.header";
import PlanMovementReport, { TPlanMovementReport } from "../reports/plan-movement.report";
import cduService from '../../services/cdu.service';

interface Props {
    translate: any,
    partner: any,

    onClick_ShowDefinedPartner: any
}

function MovementsHistoryOperation(props: Props) {
    
    const alertContext = useAlertMessageContext();

    const navigator = useNavigate();
    const display = props.translate.data;

    const title = display.title.movement_history;
    const description = 'Histórico';
    
    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ sending, setSending ] = useState(false);

    const [ dateTimeStart, setDateTimeStart ] = useState(dateToString(new Date(), 'dd/mm/yyyy') + ' 00:00:00');
    const [ dateTimeEnd, setDateTimeEnd ] = useState(dateToString(new Date(), 'dd/mm/yyyy') + ' 23:59:59');
    
    const onChange_DateTimeStart = (date: any) => {
        const dateStop = toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss');
        if (date > dateStop) {
            setDateTimeStart(dateToString(dateStop, 'dd/mm/yyyy') + ' 00:00:00');
        } else {
            setDateTimeStart(dateToString(date, 'dd/mm/yyyy HH:MM:ss'));
        }
    }

    const onChange_DateTimeStop = (date: any) => {
        const dateStart = toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss');
        if (date < dateStart) {
            setDateTimeEnd(dateToString(dateStart, 'dd/mm/yyyy') + ' 23:59:59');
        } else {
            setDateTimeEnd(dateToString(date, 'dd/mm/yyyy HH:MM:ss'));
        }
    }

    const [records, setRecords ] = useState([] as any);
    const columnsRecord = useMemo(() => [{
        Header: 'Data',
        accessor: 'dateMovement',
        Cell: (row: any) => (<div className="white-space">{dateToString(row.value, 'dd/mm/yyyy')}</div>)
    },{
        Header: 'Chave ID',
        accessor: 'vesselIdentifyKey',
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    },{
        Header: 'Registro',
        accessor: 'vesselEnrollment',
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    },{
        Header: 'Embarcação',
        accessor: 'vesselName',
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    },{
        Header: 'Tipo',
        Cell: (props: any) => {
            const movement = props.row.original

            return (
                <>
                { toInt(movement.vesselTypeVessel) === VESSEL_JETSKI ? (
                    <div className="d-flex justify-content-center">
                        <Image src={Jet} className="jet" style={{
                            height: 32}}/>
                        <small className="ms-1">10'</small>
                    </div>
                ):(
                    <div className="d-flex justify-content-center">
                        <Image src={Lancha} className="jet" style={{
                            height: 32}}/>
                        <small className="ms-1">{`${movement.vesselSize || 0}'`}</small>
                    </div>
                )}
                </>
            )
        }
    },{
        Header: display.label.color,
        accessor: "vesselColor",
        Cell: (row: any) => (<div className="white-space">
            <div className="table-column-color">
                <span className="table-column-block30x30" style={{backgroundColor: `${row.value}`}}></span>
            </div>
        </div>)
    },{
        Header: 'Saída',
        accessor: 'departure',
        Cell: (row: any) => (<div className="white-space">{notNullToDate(row.value, 'HH:MM')}</div>)
    },{
        Header: 'Retorno Previsto',
        accessor: 'estimated',
        Cell: (props: any) => {
            const movement = props.row.original;
            const landing = toDate(removeFormatDate(arrival(movement.departure, movement.estimated)), 'yyyy-mm-ddTHH:MM:ss.sssZ');

            return (
                <div className="white-space">{dateToString(landing, 'HH:MM')}</div>
            );
        }
    },{
        Header: 'Capitão',
        accessor: 'vesselCaptain',
        Cell: (row: any) => (<div className="white-space">{row.value?.split(' ')[0]}</div>)
    },{
        Header: 'Modelo',
        accessor: 'vesselBrand',
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    },{
        Header: 'Visualizar',
        accessor: "actions",
        Cell: (props: any) => {
            let movementRows = [] as any;
            const originals = props.rows;
            for (var o = 0; o < originals.length; o++) {
                movementRows[movementRows.length] = originals[o].original;
            }
            const movementRow = props.row.original;

            return (
                <div className="white-space table-actions">
                    {authService.hasPermission(PermissionEnum.OPERATION_MOVEMENT_EDIT) && 
                        <OverlayTrigger overlay={<Tooltip id="tooltip">Visualizar Plano de Navegação</Tooltip>}>
                            <span onClick={(e) => onClick_EditMovement(e, movementRow)}>
                                <FaEye size={18} />
                            </span>
                        </OverlayTrigger>
                    }
                </div>
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }], []); // columnsRecord
    
    function onClick_RefreshMovement(event: any) {
        event.preventDefault();
        
        setSending(true);
        searchfilter(dateTimeStart, dateTimeEnd);
    } // onClick_RefreshMovement
    
    async function onClick_EditMovement(event: any, movement: any) {
        event.preventDefault();

        const currentMovement = await lclService.movementVesselById(movement.id);
        const address = await cduService.addressById(props.partner.company.addressId);
        const planMovement : TPlanMovementReport = {
            company: {
                image: props.partner.company.image,
                socialReason: props.partner.company.socialReason,
                fantasy: props.partner.company.fantasy,
                registrationPj: cnpjMask(props.partner.company.registrationPj),
                telephone: phoneMask(props.partner.company.telephone),
                email: props.partner.company.email,
                address: `${address.place} - ${address.district} - ${address.city} - ${address.state} - ${zipcodeMask(address.zipcode)}`
            },
            movement: {
                departure: dateToString(currentMovement.departure, 'dd/mm/yyyy'),
                estimated: dateToString(currentMovement.departure, 'HH:MM'),
                arrival: dateToString(toDate(removeFormatDate(arrival(currentMovement.departure, currentMovement.estimated)), 'yyyy-mm-ddTHH:MM:ss.sssZ'), 'HH:MM'),
                navigationPlan: currentMovement.navigationPlan
            },
            vessels: [{
                identifyKey: currentMovement.vessel.identifyKey,
                enrollment: currentMovement.vessel.enrollment,
                name: currentMovement.vessel.name,
                color: display.label[toLowerCase(getColorDefault(currentMovement.vessel.color).name)],
                brand: currentMovement.vessel.brand,
                model: currentMovement.vessel.model
            }],
            captain: {
                name: currentMovement?.crews[0]?.conductor?.people?.fullname,
                enrollment: currentMovement?.crews[0]?.conductor?.people?.documentsNautical[0]?.enrollment,
                expedition: dateToString(currentMovement?.crews[0]?.conductor?.people?.documentsNautical[0]?.expedition, 'dd/mm/yyyy'),
                validity: dateToString(currentMovement?.crews[0]?.conductor?.people?.documentsNautical[0]?.validity, 'dd/mm/yyyy'),
                limits: currentMovement?.crews[0]?.conductor?.people?.documentsNautical[0]?.limits
            },
            passengers: currentMovement.passengers.map((passenger: any) => {

                return {
                    name: passenger.people.fullname
                }
            }),
            payday: dateToString(new Date(), 'dd/mm/yyyy'),
        }
        PlanMovementReport(planMovement);
    } // onClick_EditRecord

    async function searchfilter(dateTimeStart: string, dateTimeEnd: string) {
        if (props.partner) {
            setDateTimeStart(dateTimeStart);
            setDateTimeEnd(dateTimeEnd);
            
            if (isDate(toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')) && isDate(toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss'))) {
                const attributes = [] as any;
                const where = { dateMovement : { start : removeFormatDate(dateTimeStart), end : removeFormatDate(dateTimeEnd) } } as any;
                const order = [ [ 'departure', 'ASC' ] ] as any;

                try {
                    /* Sempre incluir o partner na pesquisa */
                    where['partnerId'] = props.partner.id;

                    const movements = await lclService.movementVesselList(attributes, where, order);
                    setRecords(movements);
                    setSending(false);
                } catch(error: any) {
                    await alertContext.show(AlertMessageEnum.FAIL, title, error);
                }
                setSending(false);
            } else {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.an_invalid_date_was_reported);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi definido uma Marina para exibir as movimentações !');
            navigator(`/portal`);
        }
    } // searchfilter

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            let inicio = moment().day(0); // domingo desta semana
            let fim = moment().day(6); // sábado desta semana

            let startDate = inicio.format('DD/MM/YYYY 00:00:00');
            let endDate = fim.format('DD/MM/YYYY 23:59:59');

            searchfilter(startDate, endDate);
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []); // useEffect

    function viewPage() {

        return (
            <Container fluid className="page-body movements">
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card style={{ minHeight : 500 }}>
                            <Card.Header>
                                <div className="card-actions d-flex justify-content-start">
                                    <div className="card-actions-time me-2">
                                            <DatePicker
                                                locale={ptBR}
                                                selected={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                                onChange={(date: any) => onChange_DateTimeStart(date) }
                                                dateFormat="dd/MM/yyyy"
                                                customInput={<PickerButtom />}
                                                selectsStart
                                                startDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                                endDate={toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss')}

                                                popperClassName="some-custom-class"
                                                popperPlacement="top-end"
                                                popperModifiers={[
                                                    {
                                                        name : "offset",
                                                        options : {
                                                            offset : [5, 10]
                                                        }
                                                    },{
                                                        name : "preventOverflow",
                                                        options : {
                                                            rootBoundary : "viewport",
                                                            tether : false,
                                                            altAxis : true
                                                        }
                                                    }
                                                ]}
                                                
                                            /> 
                                            <DatePicker
                                                locale={ptBR}
                                                selected={toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss')}
                                                onChange={(date: any) => onChange_DateTimeStop(date) }
                                                dateFormat="dd/MM/yyyy"
                                                customInput={<PickerButtom />}
                                                selectsEnd
                                                startDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                                endDate={toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss')}
                                                minDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}

                                                popperClassName="some-custom-class"
                                                popperPlacement="top-end"
                                                popperModifiers={[
                                                    {
                                                        name : "offset",
                                                        options : {
                                                            offset : [5, 10]
                                                        }
                                                    },{
                                                        name : "preventOverflow",
                                                        options : {
                                                            rootBoundary : "viewport",
                                                            tether : false,
                                                            altAxis : true
                                                        }
                                                    }
                                                ]}
                                                
                                        />
                                     </div>
                                     <Button variant="secondary" onClick={ (e) => onClick_RefreshMovement(e) } disabled={sending}>
                                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSpinner size={22} /> } {' '}
                                            {display.buttom.refresh}
                                        </Button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <TableContainer columns={columnsRecord} data={records} viewFilter={true} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewPage

    return (
        <div className="page">
            <OperationHeader title={title} description={description} onClick_ShowDefinedPartner={props.onClick_ShowDefinedPartner} />
            { viewPage() }
        </div>
    );

}

export default MovementsHistoryOperation;