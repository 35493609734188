import { useEffect, useState } from "react";
import { Button } from '@mui/material'
import { ButtonToolbar, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { FaBarcode, FaEye } from "react-icons/fa";
import cduService from "../../services/cdu.service";
import lclService from "../../services/lcl.service";
import { copyTextToClipboard, dateToString, floatToString, toDate } from "../../utilities/auxiliary-functions";
import { BILLET_MODE_EDIT, BILLET_MODE_NEW, BILLET_MODE_VIEW, ContractSituationEnum, PermissionEnum, PersonEnum } from "../../utilities/constants";
import { cnpjMask, cpfMask, removeFormatDate, removeMask } from "../../utilities/masks";
import { isEmpty, isValidCpfCnpj } from "../../utilities/validators";
import LauncherRecord from "../cadastres/forms/records/launcher.record";
import { FaTimes } from "react-icons/fa";
import authService from "../../services/auth.service";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../contexts/confirm-message.context";
import moment from "moment";
import { AlertMessageEnum } from "../../utilities/types";
import PeopleFilter from "../filters/people.filter";
import CompanyFilter from "../filters/company.filter";
import PeopleModal from "./people.modal";
import CompanyModal from "./company.modal";

interface Props {
    translate: any,
    itemsToast: any,
    setItemsToast: any,
    partner: any,    
    isEditMode: number,
    show: boolean,
    launcherId: any,
    record: any,
    onSubmitModal: any,
    onCancelModal: any
}

function LauncherCadastreModal( props : Props ) {
    
    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();

    const display = props.translate.data;
    const title : string = display.title.launcher_cadastre;

    const [ invalidatedCadastreForm, setInvalidatedCadastreForm ] = useState(false);

    // const [ cadastreFormShow, setCadastreFormShow ] = useState(true);
    const [ showFilterPeople, setShowFilterPeople ] = useState(false);
    const [ showFilterCompany, setShowFilterCompany ] = useState(false);
    const [ showPeople, setShowPeople ] = useState(false);
    const [ showCompany, setShowCompany ] = useState(false);

    const [ sendingCreateBar, setSendingCreateBar ] = useState(false);
    const [ sending, setSending ] = useState(false);

    const [ hasBillet, setHasBillet ] = useState(false);

    // Launcher
    const [ id, setId ] = useState(0);
    const [ created, setCreated ] = useState('');
    const [ updated, setUpdated ] = useState('');

    const [ situation, setSituation ] = useState(ContractSituationEnum.ACTIVED);
    const [ confection, setConfection ] = useState(dateToString(new Date(), 'dd/mm/yyyy'));
    const [ partnerId, setPartnerId ] = useState(0);

    const [ creditAccount, setCreditAccount ] = useState('');

    const [ sourceDocument, setSourceDocument ] = useState('');
    const [ numberDocument, setNumberDocument ] = useState('');
    const [ ownerType, setOwnerType ] = useState(PersonEnum.PESSOA_FISICA);
    const [ ownerId, setOwnerId ] = useState(0);
    const [ ownerName, setOwnerName ] = useState('');
    const [ ownerRegistry, setOwnerRegistry ] = useState('');
    const [ ownerPhone, setOwnerPhone ] = useState('');
    const [ owner, setOwner ] = useState({} as any);

    const [ description, setDescription ] = useState('');

    const [ numParcel, setNumParcel ] = useState('');
    const [ qtyParcels, setQtyParcels ] = useState('');
    const [ amountParcel, setAmountParcel ] = useState('');
    const [ expiry, setExpiry ] = useState(dateToString(new Date(), 'dd/mm/yyyy'));
    const [ payment, setPayment ] = useState(dateToString(new Date(), 'dd/mm/yyyy'));
    const [ discount, setDiscount ] = useState('');
    const [ discountPerc, setDiscountPerc ] = useState('');
    const [ addition, setAddition ] = useState('');
    const [ additionPerc, setAdditionPerc ] = useState('');
    const [ amount, setAmount ] = useState('');

    const [ paymentForm, setPaymentForm ] = useState('');
    const [ paymentCode, setPaymentCode ] = useState('');

    // Payment
    const [ paymentId, setPaymentId ] = useState(0);
    const [ paymentMethod, setPaymentMethod ] = useState('');
    const [ chargeId, setChargeId ] = useState('');
    const [ customId, setCustomId ] = useState('');
    const [ status, setStatus ] = useState('');
    const [ bankingBarcode, setBankingBarcode ] = useState('');
    const [ bankingPix, setBankingPix ] = useState('');
    const [ bankingQrcode, setBankingQrcode ] = useState('');
    const [ billetLink, setBilletLink ] = useState('');
    const [ billetPdf, setBilletPdf ] = useState('');

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(amountParcel))
            emptyRequired = true;

        if (isEmpty(ownerType))
            emptyRequired = true;
        if (isEmpty(ownerRegistry))
            emptyRequired = true;
        if (isEmpty(ownerId)) {
            emptyRequired = true;
            setOwnerName('')
            setOwnerRegistry('')
        }
        
        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        } else {
            if (isEmpty(description)) {
                await alertContext.show(AlertMessageEnum.FAIL, title, 'Informe uma descrição para a mensalidade.');
                result = false;
            }
        }

        setInvalidatedCadastreForm(!result);
        return result;
    }

    async function isValidBarCode() {
        let result = true;

        const localExpiry = toDate(expiry, 'dd/mm/yyyy');
        const localNow = new Date();
        if (localExpiry.getTime() <= localNow.getTime()) {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'A data informada não pode ser menor que HOJE.');
            result = false;
        }
            
        return result;
    }

    async function onClick_Confirm(event: any) {
        event.preventDefault();
        
        const isValid = await isValidForm();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(title, display.message.confirm_record);
                if (isConfirmed) {
                    setSending(true);
                    const launcher = fillLauncher();

                    let returned = null;
                    if (launcher.id > 0)
                        returned = await lclService.saveLauncher(launcher.id, launcher);
                    else
                        returned = await lclService.createLauncher(launcher);
                    launcher.id = returned.id;

                    setSending(false);
                    await alertContext.show(AlertMessageEnum.SUCCESS, title, display.message.the_record_has_been_saved_successfully);
                    props.onSubmitModal(event, returned);
                }
            } catch (error: any) {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }

    async function onClick_Cancel(event : any) {
        event.preventDefault();

        setInvalidatedCadastreForm(false);
        props.onCancelModal(event);
    }

    function clearLauncher() {
        setHasBillet(false);

        setId(0);
        setCreated('');
        setUpdated('');
        
        setSituation(ContractSituationEnum.ACTIVED);
        setConfection(dateToString(new Date(), 'dd/mm/yyyy'));
        setPartnerId(0);

        setCreditAccount('');

        setSourceDocument('');
        setNumberDocument('');
        setOwnerType(PersonEnum.PESSOA_FISICA);
        setOwnerId(0);
        setOwnerName('');
        setOwnerRegistry('');
        setOwnerPhone('');
        setDescription('');

        setNumParcel('1');
        setQtyParcels('1');
        setAmountParcel('');
        let expiry = moment().add(2, 'days');
        setExpiry(expiry.format('DD/MM/YYYY'));
        setPayment('');
        setDiscount('');
        setDiscountPerc('');
        setAddition('');
        setAdditionPerc('');
        setAmount('');

        setPaymentForm('');
        setPaymentCode('');

        setPaymentId(0);
        setPaymentMethod('');
        setChargeId('');
        setCustomId('');
        setStatus('');
        setBankingBarcode('');
        setBankingPix('');
        setBankingQrcode('');
        setBilletLink('');
        setBilletPdf('');
    }

    function fillRecordPayment(payment: any) {

        if (payment != null) {
            setPaymentId(payment.id);
            setHasBillet(!isEmpty(payment.chargeId));
            setChargeId(payment.chargeId);
            setCustomId(payment.customId);
            setStatus(payment.status);
            setBankingBarcode(payment.bankingBarcode);
            setBankingPix(payment.bankingPix);
            setBankingQrcode(payment.bankingQrcode);
            setBilletLink(payment.billetLink);
            setBilletPdf(payment.billetPdf);
        }
    }

    function fillRecord(launcher: any) {

        if (launcher != null) {
            setHasBillet(!isEmpty(launcher.paymentData));

            setId(launcher.id);
            setCreated(launcher.created);
            setUpdated(launcher.updated);

            setSituation(launcher.situation);
            setConfection(dateToString(new Date(), 'dd/mm/yyyy'));
            setPartnerId(launcher.partnerId);

            setCreditAccount(launcher.creditAccount);

            setSourceDocument(launcher.sourceDocument);
            setNumberDocument(launcher.numberDocument);
            setOwnerType(launcher.ownerType);
            setOwnerId(launcher.ownerId);
            setOwnerName(launcher.owner.fullname);
            setOwnerRegistry(launcher.owner.registrationPf);
            setDescription(launcher.description);

            setNumParcel(launcher.numParcel);
            setQtyParcels(launcher.qtyParcels);
            setAmountParcel(floatToString(launcher.amountParcel));
            setExpiry(dateToString(launcher.expiry, 'dd/mm/yyyy'));
            setPayment(launcher.payment);
            setDiscount(floatToString(launcher.discount));
            setDiscountPerc(floatToString(launcher.discountPerc));
            setAddition(floatToString(launcher.addition));
            setAdditionPerc(floatToString(launcher.additionPerc));
            setAmount(floatToString(launcher.amount));

            setPaymentForm(launcher.paymentForm);
            setPaymentCode(launcher.paymentCode);

            fillRecordPayment(launcher.paymentData);
        }
    }

    function fillLauncher() {

        const dataPayment = {
            id : paymentId,
            paymentMethod,
            chargeId,
            customId,
            description,
            status,
            bankingBarcode,
            bankingPix,
            bankingQrcode,
            billetLink,
            billetPdf
        };

        const dataLauncher = {
            id,
            created, 
            updated,
            partnerId : props.partner.id,

            situation,
            confection : removeFormatDate(confection),
            group : 'R',
            creditAccount,
            sourceDocument,
            numberDocument,
            ownerType,
            ownerId,
            ownerName,
            ownerRegistry,
            description,

            numParcel,
            qtyParcels,
            amountParcel : removeMask(amountParcel, '0'),
            expiry : removeFormatDate(expiry),
            payment : removeFormatDate(payment),
            discount : removeMask(discount, '0'),
            discountPerc : removeMask(discountPerc, '0'),
            addition : removeMask(addition, '0'),
            additionPerc : removeMask(additionPerc, '0'),
            amount : removeMask(amount, '0'),
        
            flag : 'M',
            paymentForm,
            paymentCode,
            paymentData : dataPayment
        };

        return dataLauncher;
    }

    async function searchFilter() {
        clearLauncher();

        if ((props.isEditMode === BILLET_MODE_VIEW) || (props.isEditMode === BILLET_MODE_EDIT)) {
            try {
                const launcher = await lclService.launcherById(Number(props.launcherId));
                fillRecord(launcher);
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }

    useEffect(() => {
        if (props.show)
            searchFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    /* */

    function onClick_AddPerson(event: any) {
        event.preventDefault();

        if (ownerType === PersonEnum.PESSOA_FISICA)
            setShowPeople(true);
        else if (ownerType === PersonEnum.PESSOA_JURIDICA)
            setShowCompany(true);
    }

    function onClick_FilterPerson(event: any) {
        event.preventDefault();

        if (ownerType === PersonEnum.PESSOA_FISICA)
            setShowFilterPeople(true);
        else if (ownerType === PersonEnum.PESSOA_JURIDICA)
            setShowFilterCompany(true);
    }

    function onClick_ConfirmFilterPerson(event: any, record: any) {
        event.preventDefault();

        let sourceDocument = '';
        if (ownerType === PersonEnum.PESSOA_FISICA) {
            setOwnerId(record.id);
            setOwnerRegistry(cpfMask(record.registrationPf));
            setOwnerName(record.fullname);
            setOwner(record);
            sourceDocument = 'LPF';
        } else if (ownerType === PersonEnum.PESSOA_JURIDICA) {
            setOwnerId(record.id);
            setOwnerRegistry(cnpjMask(record.registrationPj));
            setOwnerName(record.fantasy);
            setOwner(record);
            sourceDocument = 'LPJ';
        }
        setSourceDocument(sourceDocument);
        setNumberDocument(record.id);
    }

    async function searchOwner(event: any) {
        event.preventDefault(event);

        if (!isEmpty(ownerType)) {
            let registry = removeMask(ownerRegistry);

            if (isValidCpfCnpj(registry)) {
                try {
                    let sourceDocument = '';
                    let ownerId = '';
                    if (ownerType === PersonEnum.PESSOA_FISICA) {
                        const people = await cduService.peopleByRegistration(registry);
                        ownerId = people.id;
                        setOwnerRegistry(cpfMask(people.registrationPf));
                        setOwnerName(people.fullname);
                        sourceDocument = 'LPF';
                    } else {
                        const company = await cduService.companyByRegistration(registry);
                        ownerId = company.id;
                        setOwnerRegistry(cnpjMask(company.registrationPj));
                        setOwnerName(company.socialRaseon);
                        sourceDocument = 'LPJ';
                    }
                    setOwnerId(Number(ownerId));
                    setSourceDocument(sourceDocument);
                    setNumberDocument(ownerId);
                } catch (error: any) {
                    await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.document_not_found);
                }
            } else {
                await alertContext.show(AlertMessageEnum.FAIL, title, display.message.the_document_is_not_valid);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.owner_type_not_specified);
        }
    }

    async function copyPix(event: any) {
        event.preventDefault();
        copyTextToClipboard(bankingPix);
        props.setItemsToast([...props.itemsToast, { show : true, message : 'Copiado PIX... '}]);
    }
    
    async function copyBarcode(event: any) {
        event.preventDefault();
        copyTextToClipboard(bankingBarcode);
        props.setItemsToast([...props.itemsToast, { show : true, message : 'Copiado BARCODE... '}]);
    }

    async function onClick_Barcode(event: any) {
        event.preventDefault();
        if (isEmpty(chargeId)) {
            try {
                const isValid = await isValidBarCode();
                if (isValid) {
                    setSendingCreateBar(true);
                    const generate = await lclService.createBillet(id);
                    if (generate) {
                        await alertContext.show(AlertMessageEnum.SUCCESS, title, display.message.the_billet_was_generated_successfully);
                        searchFilter();
                    }
                }
            } catch (error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
            setSendingCreateBar(false);
        }
    }

    function onClick_EyeBillet(event: any) {
        event.preventDefault();
        window.open(billetLink, "Boleto - "+ ownerName, "_blank");
    }

    function viewCadastreForm() {

        return (
            <>
                <Modal id="modal-cadastre-launcher-form" 
                     show={((props.show && !showFilterPeople) && (props.show && !showFilterCompany) && (props.show && !showPeople) && (props.show && !showCompany))}
                    size="lg"
                    aria-labelledby="modal-cadastre-launcher-form"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title >{title}</Modal.Title>
                        <FaTimes className="isClickable" onClick={ onClick_Cancel } size={26}/>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className="form" id="form-cadastre-launcher" validated={invalidatedCadastreForm} style={{ minHeight : 100 }}>
                            <Row>
                                <Col>
                                    <LauncherRecord
                                        translate={props.translate}
                                        title={title}
                                        isEditMode={props.isEditMode}

                                        created={created}
                                        updated={updated}

                                        numParcel={numParcel}
                                        qtyParcels={qtyParcels}
                                        partnerId={partnerId}
                                        sourceDocument={sourceDocument}
                                        numberDocument={numberDocument}
                                        situation={situation}
                                        confection={confection}
                                        amountParcel={amountParcel}
                                        expiry={expiry}
                                        description={description}
                                        discount={discount}
                                        discountPerc={discountPerc}
                                        addition={addition}
                                        additionPerc={additionPerc}
                                        amount={amount}
                                        paymentMethod={paymentMethod}
                                        chargeId={chargeId}
                                        customId={customId}
                                        status={status}
                                        bankingBarcode={bankingBarcode}
                                        bankingPix={bankingPix}
                                        bankingQrcode={bankingQrcode}
                                        billetLink={billetLink}
                                        billetPdf={billetPdf}
                                        ownerType={ownerType}
                                        ownerId={ownerId}
                                        ownerName={ownerName}
                                        ownerRegistry={ownerRegistry}
                                        ownerPhone={ownerPhone}
                                        setSourceDocument={(value: any) => { setSourceDocument(value); }}
                                        setNumberDocument={(value: any) => { setNumberDocument(value); }}
                                        setPartnerId={(value: any) => { setPartnerId(value); }}
                                        setNumParcel={(value: any) => { setNumParcel(value); }}
                                        setQtyParcels={(value: any) => { setQtyParcels(value); }}
                                        setSituation={(value: any) => { setSituation(value); }}
                                        setConfection={(value: any) => { setConfection(value); }}
                                        setAmountParcel={(value: any) => { setAmountParcel(value); }}
                                        setExpiry={(value: any) => { setExpiry(value); }}
                                        setDescription={(value: any) => { setDescription(value); }}
                                        setDiscount={(value: any) => { setDiscount(value); }}
                                        setDiscountPerc={(value: any) => { setDiscountPerc(value); }}
                                        setAddition={(value: any) => { setAddition(value); }}
                                        setAdditionPerc={(value: any) => { setAdditionPerc(value); }}
                                        setAmount={(value: any) => { setAmount(value); }}
                                        setChargeId={(value: any) => { setChargeId(value); }}
                                        setPaymentMethod={(value: any) => { setPaymentMethod(value); }}
                                        setCustomId={(value: any) => { setCustomId(value); }}
                                        setStatus={(value: any) => { setStatus(value); }}
                                        setBankingBarcode={(value: any) => { setBankingBarcode(value); }}
                                        setBankingPix={(value: any) => { setBankingPix(value); }}
                                        setBankingQrcode={(value: any) => { setBankingQrcode(value); }}
                                        setBilletLink={(value: any) => { setBilletLink(value); }}
                                        setBilletPdf={(value: any) => { setBilletPdf(value); }}
                                        setOwnerType={(value: any) => { setOwnerType(value); }}
                                        setOwnerId={(value: any) => { setOwnerId(value); }}
                                        setOwnerName={(value: any) => { setOwnerName(value); }}
                                        setOwnerRegistry={(value: any) => { setOwnerRegistry(value); }}

                                        setOwnerPhone={(value: any) => { setOwnerPhone(value); }}
                                        searchOwner={(event: any) => { searchOwner(event); }}
                                        filterPerson={(event: any) => { onClick_FilterPerson(event); }}
                                        addPerson={(event: any) => { onClick_AddPerson(event); }}

                                        copyPix={(event: any) => { copyPix(event); }}
                                        copyBarcode={(event: any) => { copyBarcode(event); }}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonToolbar>
                            {authService.hasPermission(PermissionEnum.FINANCIAL_LAUNCHER_BILLET_GENERATE) && 
                                <Button className="me-2" variant="contained"  onClick={(e) => onClick_Barcode(e)} disabled={(props.isEditMode === BILLET_MODE_NEW) || hasBillet}>
                                    { sendingCreateBar ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaBarcode size={22} /> }
                                </Button>
                            }
                            {authService.hasPermission(PermissionEnum.FINANCIAL_LAUNCHER_BILLET_VIEW) && 
                                <Button className="me-2" variant="contained"  disabled={props.isEditMode !== BILLET_MODE_VIEW} onClick={(e) => onClick_EyeBillet(e)}><FaEye size={22} /></Button>
                            }
                        </ButtonToolbar>
                        <div></div>
                        <div>
                            <Button className="me-2" variant="contained" color="secondary" onClick={ e => onClick_Cancel(e) } >{display.buttom.cancel}</Button>
                            <Button className="me-2" variant="contained" onClick={ e => onClick_Confirm(e) } disabled={sending || (props.isEditMode === BILLET_MODE_VIEW)}>
                                { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                                { display.buttom.save }
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
            { viewCadastreForm() }
            <PeopleFilter 
                translate={props.translate}
                show={showFilterPeople}

                onClick_Close={(event: any) => { 
                    setShowFilterPeople(false);
                }}
                onClick_Confirm={(event: any, value: any) => { 
                    setShowFilterPeople(false);
                    onClick_ConfirmFilterPerson(event, value); 
                }}
            />
            <CompanyFilter 
                translate={props.translate}
                show={showFilterCompany}

                onClick_Close={(event: any) => { 
                    setShowFilterCompany(false);
                }}
                onClick_Confirm={(event: any, value: any) => { 
                    setShowFilterCompany(false);
                    onClick_ConfirmFilterPerson(event, value); 
                }}
            />
            <PeopleModal 
                translate={props.translate}
                isEdit={false}
                show={showPeople}
                peopleId={ownerId}
                record={owner}

                registration={ownerRegistry}
            
                onSubmitModal={(event: any, record: any) => { 
                    setShowPeople(false);
                    setOwnerId(record.id);
                    setOwnerRegistry(cnpjMask(record.registrationPj));
                    setOwnerName(record.fullname);
                    setOwner(record);
                    setSourceDocument('LPF');
                    setNumberDocument(record.id);
                }}
                onCancelModal={(event: any) => { 
                    setShowPeople(false);
                }}
            />
            <CompanyModal 
                translate={props.translate}
                isEdit={false}
                show={showCompany}
                companyId={ownerId}
                record={owner}

                registration={ownerRegistry}

                onSubmitModal={(event: any, record: any) => { 
                    setShowCompany(false);
                    setOwnerId(record.id);
                    setOwnerRegistry(cnpjMask(record.registrationPj));
                    setOwnerName(record.socialRaseon);
                    setOwner(record);
                    setSourceDocument('LPJ');
                    setNumberDocument(record.id);
                }}
                onCancelModal={(event: any) => { 
                    setShowCompany(false);
                }}
            />
        </>
    )
}

export default LauncherCadastreModal
