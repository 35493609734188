import { Row, Col, Form, FormGroup, FormControl } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { dateToString, toString, toDate } from "../../../../utilities/auxiliary-functions";
import { PickerInput } from "../../../../components/sub-render.component";
import ptBR from 'date-fns/locale/pt-BR';

interface Props {
    translate: any,
    title: any,
    isEditMode: boolean,

    partnerId?: any,
    prohibited?: any, 
    departure?: any,
    titleTask?: any,
    color?: any,
    sourceLink?: any,
    numberLink?: any, 
    comments?: any,
    created?: any,
    updated?: any,

    setPartnerId?: any,
    setProhibited?: any,
    setDeparture?: any,
    setTitleTask?: any,
    setColor?: any,
    setSourceLink?: any,
    setNumberLink?: any, 
    setComments?: any,
    setCreated?: any,
    setUpdated?: any,
}

function ScheduleRecord(props: Props) {

    const display = props.translate.data;

    function onChange_Prohibited(event: any, date: any) {
        props.setProhibited(dateToString(date, 'dd/mm/yyyy HH:MM') +':00');
    }

    function onChange_Departure(event: any, date: any) {
        props.setDeparture(dateToString(date, 'dd/mm/yyyy HH:MM') +':59');
    }

    return (
        <>
            <Row>
                <Col sm="12">
                    <fieldset>
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Form.Label htmlFor="form-prohibited">{display.label.prohibited}</Form.Label>
                                    <DatePicker
                                        locale={ptBR}
                                        selected={toDate(props.prohibited, 'dd/mm/yyyy HH:MM')}
                                        onChange={(date: any, e: any) => onChange_Prohibited(e, date)}
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        customInput={<PickerInput />}
                                        // minDate={new Date()}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={20}
                                        timeCaption="Hora" 

                                        popperClassName="some-custom-class"
                                        popperPlacement="top-end"
                                        popperModifiers={[{
                                                name : "offset",
                                                options : {
                                                    offset : [5, 10]
                                                }
                                            },{
                                                name : "preventOverflow",
                                                options : {
                                                    rootBoundary : "viewport",
                                                    tether : false,
                                                    altAxis : true
                                                }
                                        }]}

                                        readOnly={props.isEditMode}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Form.Label htmlFor="form-departure">{display.label.departure}</Form.Label>
                                    <DatePicker
                                        locale={ptBR}
                                        selected={toDate(props.departure, 'dd/mm/yyyy HH:MM')}
                                        onChange={(date: any, e: any) => onChange_Departure(e, date)}
                                        dateFormat="dd/MM/yyyy HH:mm"
                                        customInput={<PickerInput />}
                                        // minDate={new Date()}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={20}
                                        timeCaption="Hora" 

                                        popperClassName="some-custom-class"
                                        popperPlacement="top-end"
                                        popperModifiers={[{
                                                name : "offset",
                                                options : {
                                                    offset : [5, 10]
                                                }
                                            },{
                                                name : "preventOverflow",
                                                options : {
                                                    rootBoundary : "viewport",
                                                    tether : false,
                                                    altAxis : true
                                                }
                                        }]}
                                        minDate={toDate(props.prohibited, 'dd/mm/yyyy')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <fieldset>
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <Form.Label htmlFor="form-title">{display.label.title}</Form.Label>
                                    <FormControl id="form-title" name="title" placeholder={display.example.title} required
                                        value={toString(props.titleTask)}
                                        onChange={(e => props.setTitleTask(e.target.value))}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12">
                                <FormGroup>
                                    <Form.Label htmlFor="form-comments">{display.label.comments}</Form.Label>
                                    <Form.Control as="textarea" rows={3} type="" id="form-comments" name="comments" placeholder={display.example.comments}
                                        value={toString(props.comments)}
                                        onChange={e => props.setComments(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
            </Row>
        </>
    );

}

export default ScheduleRecord;
