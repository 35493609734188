import AddIcon from '@mui/icons-material/Add';
import { Button as ButtonMaterial } from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Container, Image, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { FaEdit, FaSpinner, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { PickerButtom } from "../../components/sub-render.component";
import TableContainer from "../../components/table-container.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useDeleteMessageContext } from "../../contexts/delete-message.context";
import authService from "../../services/auth.service";
import lclService from "../../services/lcl.service";
import { arrivalForecast, dateToString, notNullToDate, toDate, toInt } from "../../utilities/auxiliary-functions";
import { PermissionEnum, VESSEL_JETSKI } from "../../utilities/constants";
import { removeFormatDate } from "../../utilities/masks";
import { AlertMessageEnum, getVesselStatus } from "../../utilities/types";
import { isDate, isEmpty } from "../../utilities/validators";
import OperationHeader from "../headers/operation.header";
import MovementCadastreModal from "../modals/movement-vessel.cadastre.modal";
import Jet from '../../assets/Jetski.svg';
import Lancha from '../../assets/Lancha.svg';

interface Props {
    translate: any,
    partner: any,

    onClick_ShowDefinedPartner: any
}

function MovementsOperation(props: Props) {
    
    const alertContext = useAlertMessageContext();
    const deleteContext = useDeleteMessageContext();

    const navigator = useNavigate();
    const display = props.translate.data;

    const title = display.title.movement;
    const description = '';
    
    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ sending, setSending ] = useState(false);

    const [ dateTimeStart, setDateTimeStart ] = useState(dateToString(new Date(), 'dd/mm/yyyy') + ' 00:00:00');
    const [ dateTimeEnd, setDateTimeEnd ] = useState(dateToString(new Date(), 'dd/mm/yyyy') + ' 23:59:59');
    
    const onChange_DateTimeStart = (date: any) => {
        const dateStop = toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss');
        if (date > dateStop) {
            setDateTimeStart(dateToString(dateStop, 'dd/mm/yyyy') + ' 00:00:00');
        } else {
            setDateTimeStart(dateToString(date, 'dd/mm/yyyy HH:MM:ss'));
        }
    }

    const onChange_DateTimeStop = (date: any) => {
        const dateStart = toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss');
        if (date < dateStart) {
            setDateTimeEnd(dateToString(dateStart, 'dd/mm/yyyy') + ' 23:59:59');
        } else {
            setDateTimeEnd(dateToString(date, 'dd/mm/yyyy HH:MM:ss'));
        }
    }

    const [records, setRecords ] = useState([] as any);
    const columnsRecord = useMemo(() => [{
        Header: display.label.date_movement,
        accessor: 'dateMovement',
        Cell: (row: any) => (<div className="white-space">{dateToString(row.value, 'dd/mm/yyyy')}</div>)
    },{
        Header: display.label.enrollment,
        accessor: 'vesselEnrollment',
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    },{
        Header: display.label.vessel,
        accessor: 'vesselName',
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    },{
        Header: display.label.type,
        Cell: (props: any) => {
            const movement = props.row.original

            return (
                <>
                { toInt(movement.vesselTypeVessel) === VESSEL_JETSKI ? (
                    <div className="d-flex justify-content-center">
                        <Image src={Jet} className="jet" style={{
                            height: 32}}/>
                        <small className="ms-1">10'</small>
                    </div>
                ):(
                    <div className="d-flex justify-content-center">
                        <Image src={Lancha} className="jet" style={{
                            height: 32}}/>
                        <small className="ms-1">{`${movement.vesselSize || 0}'`}</small>
                    </div>
                )}
                </>
            )
        }
    },{
        Header: display.label.color,
        Cell: (props: any) => {
            const movement = props.row.original

            return (
                <div className="white-space">
                    <div className="table-column-color d-flex justify-content-center">
                        <span className="table-column-block30x30" style={{backgroundColor: `${movement.vesselColor}`}}></span>
                    </div>
                </div>
            )
        }
    },{
        Header: display.label.departure,
        accessor: 'departure',
        Cell: (row: any) => (<div className="white-space">{notNullToDate(row.value, 'HH:MM')}</div>)
    },{
        Header: 'Navegação',
        accessor: 'estimated',
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    },{
        Header:  'Retorno Previsto',
        accessor: 'arrival',
        Cell: (props: any) => (<div className="white-space">{calculateEstimated(props.row.original)}</div>)
    },{
        Header: display.label.number_of_people,
        accessor: 'vesselQtyPeople',
        Cell: (props: any) => (<div className="white-space">{calculatePeople(props.row.original)}</div>)
    },{
        Header: display.label.quantity_people,
        accessor: 'qtyPeople',
        Cell: (row: any) => (<div className="white-space">{isEmpty(row.value) ? 0 : row.value}</div>)
    },{
        Header: display.label.quantity_children,
        accessor: 'qtyChildren',
        Cell: (row: any) => (<div className="white-space">{isEmpty(row.value) ? 0 : row.value}</div>)
    },{
        Header: 'Localizador',
        accessor: 'locator',
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    },{
        Header: display.label.status,
        accessor: 'status',
        Cell: (row: any) => (<div className="white-space">{getVesselStatus(row.value)}</div>)
    },{
        Header: display.legend.actions,
        accessor: "actions",
        Cell: (props: any) => {
            let movementRows = [] as any;
            const originals = props.rows;
            for (var o = 0; o < originals.length; o++) {
                movementRows[movementRows.length] = originals[o].original;
            }
            const movementRow = props.row.original;

            return (
                <div className="white-space table-actions">
                    {authService.hasPermission(PermissionEnum.OPERATION_MOVEMENT_EDIT) && 
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.toEdit}</Tooltip>}>
                            <span onClick={(e) => onClick_EditMovement(e, movementRow)}>
                                <FaEdit size={18} />
                            </span>
                        </OverlayTrigger>
                    }
                    {authService.hasPermission(PermissionEnum.OPERATION_MOVEMENT_REMOVE) && 
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.delete}</Tooltip>}>
                            <span onClick={(e) => onClick_DeleteMovement(e, movementRows, movementRow)}>
                                <FaTrash size={18} />
                            </span>
                        </OverlayTrigger>
                    }
                </div>
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }], []); // columnsRecord
    
    const [ movementId, setMovementId ] = useState('');
    const [ movement, setMovement ] = useState({} as any);
    const [ isEditMovement, setEditMovement ] = useState(false);
    const [ showMovement, setShowMovement ] = useState(false);

    function calculateEstimated(movement: any) {
        
        let arrival = arrivalForecast(notNullToDate(movement.dateMovement, 'dd/mm/yyyy'), notNullToDate(movement.departure, 'HH:MM'), movement.estimated);
        if (!isEmpty(movement.arrival))
            arrival = movement.arrival;
        else
            arrival = dateToString(toDate(arrival, 'dd/mm/yyyy HH:MM:ss'), 'HH:MM');
        return arrival;
    }

    function calculatePeople(movement: any) {
        return movement.vesselQtyPeople;
    }

    function onClick_RefreshMovement(event: any) {
        event.preventDefault();
        
        setSending(true);
        searchfilter(dateTimeStart, dateTimeEnd);
    } // onClick_RefreshMovement
    
    function onClick_AddRecord(event: any) {
        event.preventDefault();

        setEditMovement(false);
        setMovementId('new');
        setMovement({} as any);
        setShowMovement(true);
    } // onClick_AddRecord

    function onClick_EditMovement(event: any, movement: any) {
        event.preventDefault();

        setEditMovement(true);
        setMovementId(movement.id);
        setMovement(movement);
        setShowMovement(true);
    } // onClick_EditRecord

    async function onClick_DeleteMovement(event: any, records: any, record: any) {
        event.preventDefault();
        const isConfirmed = await deleteContext.show(title, display.message.delete_record, true);
        if (isConfirmed && isConfirmed.result) {
            try {
                await lclService.movementVesselDeleteById(record.id, isConfirmed.message);
                searchfilter(dateTimeStart, dateTimeEnd);
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    } // onClick_DeleteRecord

    async function searchfilter(dateTimeStart: string, dateTimeEnd: string) {
        if (props.partner) {
            setDateTimeStart(dateTimeStart);
            setDateTimeEnd(dateTimeEnd);
            
            if (isDate(toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')) && isDate(toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss'))) {
                const attributes = [] as any;
                const where = { dateMovement : { start : removeFormatDate(dateTimeStart), end : removeFormatDate(dateTimeEnd) } } as any;
                const order = [ [ 'departure', 'ASC' ] ] as any;

                try {
                    /* Sempre incluir o partner na pesquisa */
                    where['partnerId'] = props.partner.id;

                    const movements = await lclService.movementVesselList(attributes, where, order);
                    setRecords(movements);
                    setSending(false);
                } catch(error: any) {
                    await alertContext.show(AlertMessageEnum.FAIL, title, error);
                }
                setSending(false);
            } else {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.an_invalid_date_was_reported);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi definido uma Marina para exibir as movimentações !');
            navigator(`/portal`);
        }
    } // searchfilter

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            let inicio = moment().day(0); // domingo desta semana
            let fim = moment().day(6); // sábado desta semana

            let startDate = inicio.format('DD/MM/YYYY 00:00:00');
            let endDate = fim.format('DD/MM/YYYY 23:59:59');

            searchfilter(startDate, endDate);
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []); // useEffect

    function viewPage() {

        return (
            <Container fluid className="page-body movements">
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card style={{ minHeight : 500 }}>
                            <Card.Header>
                                <div className="card-actions d-flex justify-content-between">
                                    <div className="d-flex">

                                    <div className="card-actions-time">
                                            <DatePicker
                                                locale={ptBR}
                                                selected={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                                onChange={(date: any) => onChange_DateTimeStart(date) }
                                                dateFormat="dd/MM/yyyy"
                                                customInput={<PickerButtom />}
                                                selectsStart
                                                startDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                                endDate={toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss')}

                                                popperClassName="some-custom-class"
                                                popperPlacement="top-end"
                                                popperModifiers={[
                                                    {
                                                        name : "offset",
                                                        options : {
                                                            offset : [5, 10]
                                                        }
                                                    },{
                                                        name : "preventOverflow",
                                                        options : {
                                                            rootBoundary : "viewport",
                                                            tether : false,
                                                            altAxis : true
                                                        }
                                                    }
                                                ]}
                                            /> 
                                            <DatePicker
                                                locale={ptBR}
                                                selected={toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss')}
                                                onChange={(date: any) => onChange_DateTimeStop(date) }
                                                dateFormat="dd/MM/yyyy"
                                                customInput={<PickerButtom />}
                                                selectsEnd
                                                startDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                                endDate={toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss')}
                                                minDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}

                                                popperClassName="some-custom-class"
                                                popperPlacement="top-end"
                                                popperModifiers={[
                                                    {
                                                        name : "offset",
                                                        options : {
                                                            offset : [5, 10]
                                                        }
                                                    },{
                                                        name : "preventOverflow",
                                                        options : {
                                                            rootBoundary : "viewport",
                                                            tether : false,
                                                            altAxis : true
                                                        }
                                                    }
                                                ]}
                                        />

                                     </div>
                                        <Button variant="secondary" className='ms-2' onClick={ (e) => onClick_RefreshMovement(e) } disabled={sending}>
                                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSpinner size={22} /> } {' '}
                                            {display.buttom.refresh}
                                        </Button>
                                    </div>

                                    {authService.hasPermission(PermissionEnum.OPERATION_MOVEMENT_ADD) && 
                                        <ButtonMaterial size='small' variant="contained" onClick={(e) => onClick_AddRecord(e) } ><AddIcon />Movimentação</ButtonMaterial>
                                    }
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <TableContainer columns={columnsRecord} data={records} viewFilter={false} viewPagination />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewPage

    return (
        <div className="page">
            <OperationHeader title={title} description={description} onClick_ShowDefinedPartner={props.onClick_ShowDefinedPartner} />
            { viewPage() }
            <MovementCadastreModal 
                translate={props.translate} 
                partner={props.partner}
                isEdit={isEditMovement}

                show={showMovement}
                movementId={movementId}
                record={movement}
                records={records}
                onSubmitModal={(event: any, record: any) => {
                    event.preventDefault();

                    setMovement(record);
                    if (isEditMovement) {
                        const updatedMovements = Array.from(records) as any;
                        for ( var idx_Edit = 0; idx_Edit < updatedMovements.length; idx_Edit++) {
                            if (updatedMovements[idx_Edit].id === record.id) {
                                updatedMovements[idx_Edit] = record;
                            }
                        }
                        setRecords(updatedMovements);
                    } else {
                        setRecords([...records, record]);
                    }
                    setShowMovement(false);
                    setEditMovement(false);
                    searchfilter(dateTimeStart, dateTimeEnd);
                }}
                onCancelModal={(event: any) => {
                    setEditMovement(false);
                    setShowMovement(false);
                }}
            />
        </div>
    );

}

export default MovementsOperation;