import axios from "axios";
import env from "react-dotenv";

class ClimateService {
    
    API_CLIMA_FORECAST =  env.API_CLIMA_FORECAST

    API_CLIMA_CURRENT = env.API_CLIMA_CURRENT
    
    async history(address: string, date: string) {
        const url = `${this.API_CLIMA_FORECAST}/&q=${address}&dt=${date}`;

        return axios
            .get(url).then((response: any) => {
                return response.data;
            }).catch((error: any) => {
                const responseMessage = (
                    error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();

                throw new Error(responseMessage); 
            })
    }

    async current(address: string) {
        const url = `${this.API_CLIMA_FORECAST}/&q=${address}`;

        return axios

            .get(url).then((response: any) => {
                return response.data;
            }).catch((error: any) => {
                const responseMessage = (
                    error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();

                throw new Error(responseMessage); 
            })
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new ClimateService();


