import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Switch,
  TextField
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import LockPersonIcon from '@mui/icons-material/LockPerson'
import NautiSystem from '../assets/logoWithR.png'
import styles from './access.page.module.scss'
import authService from '../services/auth.service'
import cduService from '../services/cdu.service'
import { validEmail, validPhone } from '../utilities/auxiliary-functions'
import postOfficesService from '../services/post-offices.service'
import { states } from '../utilities/states'
import { NumberFormatDefault, PhoneFormat } from '../utilities/NumberFormat'

interface Props {}

function AccessPage(props: Props) {
  const { enqueueSnackbar } = useSnackbar()
  const [showSigninForm, setShowSigninForm] = useState(true)
  const [showSignupForm, setShowSignupForm] = useState(false)
  const [showRecoveryForm, setShowRecoveryForm] = useState(false)
  const [showNewPasswordForm, setShowNewPasswordForm] = useState(false)
  const [loggingIn, setLoggingIn] = useState(false)
  const [recovering, setRecovering] = useState(false)
  const [changingPassword, setChangingPassword] = useState(false)
  const [creatingAccount, setCreatingAccount] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false)
  const [signupForm, setSignupForm] = useState({
    name: '',
    partner: '',
    email: '',
    zipCode: '',
    phone: '',
    uf: '',
    state: '',
    city: '',
    vessel: false,
    jetSki: false,
    lockedVessels: false,
    termsAccepted: false,
    district: '',
    place: ''
  })
  const [signinForm, setSigininForm] = useState({
    user: '',
    password: ''
  })
  const [recoveryForm, setRecoveryForm] = useState({
    user: ''
  })
  const [newPasswordForm, setNewPasswordForm] = useState({
    newPassword: '',
    newPasswordConfirmation: ''
  })

  const searchZipCode = async () => {
    const address = await postOfficesService.findZipcode(signupForm.zipCode)

    setSignupForm({
      ...signupForm,
      state: states.find(state => state.value === address.uf)?.label || '',
      uf: states.find(state => state.value === address.uf)?.value || '',
      city: address.localidade,
      district: address.bairro,
      place: address.logradouro
    })
  }

  useEffect(() => {
    if (signupForm.zipCode.length === 8) {
      searchZipCode()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signupForm.zipCode])

  const handleNewAccess = () => {
    setSignupForm({
      ...signupForm,
      name: '',
      partner: '',
      email: '',
      zipCode: '',
      phone: '',
      uf: '',
      state: '',
      city: '',
      vessel: false,
      jetSki: false,
      lockedVessels: false,
      termsAccepted: false,
      district: '',
      place: ''
    })
    setShowSigninForm(false)
    setShowRecoveryForm(false)
    setShowSignupForm(true)
  }

  const handleNewPassword = () => {
    setShowSigninForm(false)
    setShowRecoveryForm(false)
    setShowSignupForm(false)
    setShowNewPasswordForm(true)
  }

  const handleAlreadyHaveAccess = () => {
    setSignupForm({
      ...signupForm,
      name: '',
      partner: '',
      email: '',
      zipCode: '',
      phone: '',
      uf: '',
      state: '',
      city: '',
      vessel: false,
      jetSki: false,
      lockedVessels: false,
      termsAccepted: false,
      district: '',
      place: ''
    })
    setShowSigninForm(true)
    setShowSignupForm(false)
    setShowRecoveryForm(false)
    setShowNewPasswordForm(false)
    setRecoveryForm({ ...recoveryForm, user: '' })
  }

  const handleRecoveryAccess = () => {
    setShowSigninForm(false)
    setShowSignupForm(false)
    setShowRecoveryForm(true)
    setRecoveryForm({ ...recoveryForm, user: '' })
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword)
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
  }

  const login = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const { user, password } = signinForm
    setLoggingIn(true)

    if (user && password) {
      try {
        const response = await authService.login(user, password)
        if (response && response?.situation !== 1) {
          window.location.href = '/portal'
        } else {
          handleNewPassword()
          setLoggingIn(false)
          setNewPasswordForm({ newPassword: '', newPasswordConfirmation: '' })
          enqueueSnackbar('Cadastre uma nova senha para acesso ao portal', {
            variant: 'info'
          })
        }
      } catch (error: any) {
        enqueueSnackbar(error?.message, {
          variant: 'error'
        })
        setLoggingIn(false)
      }
    }
  }

  const recoverAccess = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const { user } = recoveryForm
    setRecovering(true)

    if (user) {
      try {
        const response = await cduService.userGenerateToken(user)
        if (response && response === true) {
          enqueueSnackbar(
            'Um e-mail foi enviado com um token de acesso, utilize-o para realizar o acesso na plataforma',
            {
              variant: 'success'
            }
          )
        }
        setRecovering(false)
        handleAlreadyHaveAccess()
      } catch (error: any) {
        enqueueSnackbar(error?.message, {
          variant: 'error'
        })
        setRecovering(false)
      }
    }
  }

  const changePassword = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const { newPassword } = newPasswordForm
    const { user } = signinForm
    setChangingPassword(true)

    if (newPassword) {
      try {
        const response = await authService.approveEntryInApp(user, newPassword)
        if (response && response.situation === 5) {
          enqueueSnackbar(
            'Senha alterada com sucesso, utilize-a para realizar o acesso na plataforma',
            {
              variant: 'success'
            }
          )
        }
        setChangingPassword(false)
        handleAlreadyHaveAccess()
      } catch (error: any) {
        enqueueSnackbar(error?.message, {
          variant: 'error'
        })
        setChangingPassword(false)
      }
    }
  }

  const createAccount = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    const { name, email, phone, zipCode, uf, city, district, place } =
      signupForm
    setCreatingAccount(true)

    if (name) {
      try {
        const response = await cduService.solicitationOfAccess(
          name,
          'M',
          '55933454060',
          email,
          phone,
          zipCode,
          uf,
          city,
          district,
          place,
          '',
          ''
        )
        if (response && response.id > 0) {
          enqueueSnackbar(
            'Cadastro em análise, caso aprovado você receberá uma chave de acesso em até 24h no e-mail informado. Verifique também na caixa de SPAM',
            {
              variant: 'success'
            }
          )
        }
        setCreatingAccount(false)
        handleAlreadyHaveAccess()
      } catch (error: any) {
        enqueueSnackbar(error?.message, {
          variant: 'error'
        })
        setCreatingAccount(false)
      }
    }
  }

  const isValidNewPassword = () => {
    if (
      newPasswordForm.newPassword.length >= 8 &&
      newPasswordForm.newPassword === newPasswordForm.newPasswordConfirmation &&
      newPasswordForm.newPassword.match(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W+)(?=^.{8,50}$).*$/g
      )
    ) {
      return true
    }
    return false
  }

  const isValidNewAccess = () => {
    if (
      signupForm.name.length > 5 &&
      signupForm.email?.match(validEmail) &&
      signupForm.phone?.match(validPhone) &&
      signupForm.zipCode.length === 8 &&
      signupForm.termsAccepted
    ) {
      return true
    }
    return false
  }

  return (
    <div className={`container ${styles.container}`}>
      <section className={styles.formHolder}>
        <div className="row">
          {/* <!-- Brand Box --> */}
          <div className={`col-sm-6 ${styles.brand}`}>
            <img className={styles.logo} src={NautiSystem} alt="" />
          </div>

          {/* <!-- Form Box --> */}
          <div className={`col-sm-6 ${styles.form}`}>
            {/* <!-- Login Form --> */}
            <div
              className={`${styles.login} ${styles.formPeice} ${
                showSigninForm ? '' : styles.switched
              }`}
            >
              <form className="login-form" autoComplete="off">
                <TextField
                  color="primary"
                  fullWidth
                  label="E-mail"
                  variant="standard"
                  className="mb-2"
                  value={signinForm.user}
                  onChange={e =>
                    setSigininForm({
                      ...signinForm,
                      user: e.target.value
                    })
                  }
                />

                <TextField
                  color="primary"
                  fullWidth
                  label="Senha"
                  variant="standard"
                  type={showPassword ? 'text' : 'password'}
                  value={signinForm.password}
                  onChange={e =>
                    setSigininForm({
                      ...signinForm,
                      password: e.target.value
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />

                <div className="CTA">
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={`${styles.submit} mt-4`}
                    onClick={login}
                    size="large"
                    disabled={
                      loggingIn ||
                      signinForm.user === '' ||
                      signinForm.password === ''
                    }
                  >
                    {loggingIn ? 'Aguarde...' : 'Entrar'}
                  </Button>
                  <div
                    className={`d-flex justify-content-between align-items-center ${styles.accessControls}`}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      className={`${styles.submit} mt-4`}
                      onClick={handleNewAccess}
                      size="large"
                      fullWidth
                      disabled={loggingIn}
                    >
                      Novo Cadastro
                    </Button>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      className={`${styles.submit} mt-4 ms-2`}
                      onClick={handleRecoveryAccess}
                      size="large"
                      disabled={loggingIn}
                    >
                      Recuperar acesso
                    </Button>
                  </div>
                </div>
              </form>
            </div>
            {/* <!-- End Login Form --> */}

            {/* <!-- Recovery Form --> */}
            <div
              className={`${styles.login} ${styles.formPeice} ${
                showRecoveryForm ? '' : styles.switched
              }`}
            >
              <form className="login-form" autoComplete="off">
                <FormLabel>Informe o e-mail cadastrado </FormLabel>

                <TextField
                  helperText={
                    recoveryForm.user !== '' &&
                    !recoveryForm.user?.match(validEmail)
                      ? 'Digite um e-mail válido'
                      : ''
                  }
                  error={
                    recoveryForm.user !== '' &&
                    !recoveryForm.user?.match(validEmail)
                  }
                  color="primary"
                  fullWidth
                  label="E-mail"
                  variant="standard"
                  className="mb-2"
                  onChange={e => setRecoveryForm({ user: e.target.value })}
                  value={recoveryForm.user}
                />

                <div className="d-flex justify-content-between">
                  <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    className={`${styles.submit} mt-4 me-3`}
                    onClick={handleAlreadyHaveAccess}
                    size="large"
                    disabled={recovering}
                  >
                    Voltar
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={`${styles.submit} mt-4`}
                    onClick={recoverAccess}
                    size="large"
                    disabled={
                      recoveryForm.user === '' ||
                      (recoveryForm.user !== '' &&
                        !recoveryForm.user?.match(validEmail)) ||
                      recovering
                    }
                  >
                    {loggingIn ? 'Aguarde...' : 'Recuperar'}
                  </Button>
                </div>
              </form>
            </div>
            {/* <!-- End Recovery Form --> */}

            {/* <!-- New Password Form --> */}
            <div
              className={`${styles.login} ${styles.formPeice} ${
                showNewPasswordForm ? '' : styles.switched
              }`}
            >
              <form className="login-form" autoComplete="off">
                <TextField
                  color="primary"
                  className="mb-3"
                  fullWidth
                  label="Nova senha"
                  variant="standard"
                  type={showNewPassword ? 'text' : 'password'}
                  value={newPasswordForm.newPassword}
                  onChange={e =>
                    setNewPasswordForm({
                      ...newPasswordForm,
                      newPassword: e.target.value
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showNewPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <TextField
                  color="primary"
                  fullWidth
                  label="Confirmação de nova senha"
                  variant="standard"
                  type={showNewPassword ? 'text' : 'password'}
                  value={newPasswordForm.newPasswordConfirmation}
                  onChange={e =>
                    setNewPasswordForm({
                      ...newPasswordForm,
                      newPasswordConfirmation: e.target.value
                    })
                  }
                />

                <div className="d-flex justify-content-between align-items-center mb-3">
                  <Button
                    fullWidth
                    variant="outlined"
                    color="secondary"
                    className={`${styles.submit} mt-4 me-3`}
                    onClick={handleAlreadyHaveAccess}
                    size="large"
                    disabled={changingPassword}
                  >
                    Voltar
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={`${styles.submit} mt-4`}
                    onClick={changePassword}
                    size="large"
                    disabled={changingPassword || !isValidNewPassword()}
                  >
                    {changingPassword ? 'Aguarde...' : 'Salvar senha'}
                  </Button>
                </div>
                <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                    <LockPersonIcon />
                    <h6 className="mb-0 mt-1 ms-2"> A senha deve conter</h6>
                  </div>
                  <small>- Mínimo 8 caracteres </small>
                  <small>- Letras maiúsculas e minúsculas</small>
                  <small>- Números</small>
                  <small>- Caracteres especiais (@, &, $, /, ., etc)</small>
                </div>
              </form>
            </div>
            {/* <!-- End New Password Form --> */}

            {/* <!-- Signup Form --> */}

            <div
              className={`signup ${styles.formPeice} ${
                showSignupForm ? '' : styles.switched
              }`}
            >
              <form className="signup-form">
                <TextField
                  autoFocus
                  color="primary"
                  value={signupForm.name}
                  fullWidth
                  label="Nome completo"
                  variant="standard"
                  className="mb-2"
                  onChange={e =>
                    setSignupForm({
                      ...signupForm,
                      name: e.target.value
                    })
                  }
                />
                <TextField
                  value={signupForm.email}
                  color="primary"
                  fullWidth
                  label="E-mail"
                  helperText={
                    signupForm.email !== '' &&
                    !signupForm.email?.match(validEmail)
                      ? 'Digite um e-mail válido'
                      : ''
                  }
                  error={
                    signupForm.email !== '' &&
                    !signupForm.email?.match(validEmail)
                  }
                  variant="standard"
                  className="mb-2"
                  onChange={e =>
                    setSignupForm({
                      ...signupForm,
                      email: e.target.value
                    })
                  }
                />
                <TextField
                  variant="standard"
                  id="name"
                  label="Telefone"
                  name="name"
                  type="text"
                  autoComplete="off"
                  fullWidth
                  color="primary"
                  className="mb-2"
                  value={signupForm?.phone}
                  onChange={e =>
                    setSignupForm({
                      ...signupForm,
                      phone: e.target.value
                    })
                  }
                  InputProps={{
                    inputComponent: PhoneFormat as any
                  }}
                  error={
                    signupForm.phone.length > 8 &&
                    !signupForm.phone?.match(validPhone)
                  }
                  helperText={
                    signupForm.phone.length > 8 &&
                    !signupForm.phone?.match(validPhone)
                      ? 'Digite um telefone válido'
                      : ''
                  }
                />
                <TextField
                  inputProps={{ maxlegnth: 8 }}
                  color="primary"
                  fullWidth
                  label="CEP"
                  variant="standard"
                  className="mb-2"
                  value={signupForm?.zipCode}
                  onChange={e =>
                    setSignupForm({
                      ...signupForm,
                      zipCode: e.target.value
                    })
                  }
                  InputProps={{
                    inputComponent: NumberFormatDefault as any
                  }}
                />
                <TextField
                  color="primary"
                  disabled
                  fullWidth
                  label="Estado"
                  variant="standard"
                  className="mb-2"
                  value={signupForm.state}
                />

                <TextField
                  color="primary"
                  disabled
                  fullWidth
                  label="Cidade"
                  variant="standard"
                  className="mb-2"
                  value={signupForm.city}
                />
                <FormLabel className="mt-1">Tipo embarcação</FormLabel>
                <div className="d-flex justify-content-between">
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Jet ski"
                    value={signupForm.jetSki}
                    onChange={e =>
                      setSignupForm({
                        ...signupForm,
                        jetSki: !signupForm.jetSki
                      })
                    }
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Lancha"
                    value={signupForm.vessel}
                    onChange={e =>
                      setSignupForm({
                        ...signupForm,
                        vessel: !signupForm.vessel
                      })
                    }
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <FormLabel
                    onChange={e =>
                      setSignupForm({
                        ...signupForm,
                        lockedVessels: !signupForm.lockedVessels
                      })
                    }
                    className="mt-1 mb-2"
                  >{`${
                    signupForm.vessel && signupForm.jetSki
                      ? 'Possuem'
                      : 'Possui'
                  } garagem náutica?`}</FormLabel>
                </div>
                <div
                  className={`d-flex justify-content-between align-items-baseline ${styles.accessControls}`}
                >
                  <FormControlLabel
                    control={<Switch />}
                    label={signupForm.lockedVessels ? 'Sim' : 'Não'}
                    onChange={e =>
                      setSignupForm({
                        ...signupForm,
                        lockedVessels: !signupForm.lockedVessels
                      })
                    }
                  />
                  {signupForm.lockedVessels && (
                    <TextField
                      autoFocus
                      color="primary"
                      value={signupForm.partner}
                      fullWidth
                      label="Nome da marina"
                      sx={{ minWidth: 250 }}
                      variant="standard"
                      onChange={e =>
                        setSignupForm({
                          ...signupForm,
                          partner: e.target.value
                        })
                      }
                    />
                  )}
                </div>

                <FormControlLabel
                  control={<Checkbox checked={signupForm?.termsAccepted} />}
                  onChange={e =>
                    setSignupForm({
                      ...signupForm,
                      termsAccepted: !signupForm?.termsAccepted
                    })
                  }
                  label="Aceito os termos de uso"
                />

                <div
                  className={`d-flex justify-content-between align-items-center ${styles.accessControls}`}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    className={`${styles.submit} mt-3 me-2`}
                    onClick={handleAlreadyHaveAccess}
                    size="large"
                    fullWidth
                  >
                    Já possuo acesso
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    className={`${styles.submit} mt-3`}
                    onClick={createAccount}
                    size="large"
                    fullWidth
                    disabled={creatingAccount || !isValidNewAccess()}
                  >
                    {loggingIn ? 'Aguarde...' : 'Cadastrar'}
                  </Button>
                </div>
              </form>
            </div>
            {/* <!-- End Signup Form --> */}
          </div>
        </div>
      </section>
    </div>
  )
}

export default AccessPage
