import { useEffect, useState } from "react";
import { Button } from '@mui/material'
import { Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import lclService from "../../services/lcl.service";
import { dateToString, floatToString, toFloat, toLowerCase } from "../../utilities/auxiliary-functions";
import { ContractSituationEnum, PaymentEnum } from "../../utilities/constants";
import { cpfMask, floatMask, removeFormatDate, removeMask } from "../../utilities/masks";
import { isEmpty } from "../../utilities/validators";
import { FaTimes } from "react-icons/fa";
import PaymentRecord from "../cadastres/forms/records/payment.record";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../contexts/confirm-message.context";
import { AlertMessageEnum, getPayment } from "../../utilities/types";
import extenso from "extenso";

interface Props {
    translate: any,
    partner: any,
    show: boolean,
    launcherId: any,
    record: any,
    onSubmitModal: any,
    onCancelModal: any
}

const MAKE_THE_PAYMENT = "make_the_payment";

function PaymentCadastreModal( props : Props ) {
    
    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();

    const display = props.translate.data;
    const title : string = display.title.payment_cadastre;

    const [ invalidatedCadastreForm, setInvalidatedCadastreForm ] = useState(false);

    const [ sending, setSending ] = useState(false);

    // Launcher
    const [ id, setId ] = useState(0);
    const [ created, setCreated ] = useState('');
    const [ updated, setUpdated ] = useState('');

    const [ situation, setSituation ] = useState(ContractSituationEnum.INACTIVE);
    const [ confection, setConfection ] = useState(dateToString(new Date(), 'dd/mm/yyyy'));
    const [ partnerId, setPartnerId ] = useState(0);

    
    const [ sourceDocument, setSourceDocument ] = useState('');
    const [ numberDocument, setNumberDocument ] = useState('');
    const [ ownerType, setOwnerType ] = useState('');
    const [ ownerId, setOwnerId ] = useState(0);
    const [ ownerName, setOwnerName ] = useState('');
    const [ ownerRegistry, setOwnerRegistry ] = useState('');


    const [ description, setDescription ] = useState('');

    const [ numParcel, setNumParcel ] = useState('');
    const [ qtyParcels, setQtyParcels ] = useState('');
    const [ amountParcel, setAmountParcel ] = useState('');
    const [ expiry, setExpiry ] = useState(dateToString(new Date(), 'dd/mm/yyyy'));
    const [ payment, setPayment ] = useState(dateToString(new Date(), 'dd/mm/yyyy'));
    const [ discount, setDiscount ] = useState('');
    const [ discountPerc, setDiscountPerc ] = useState('');
    const [ addition, setAddition ] = useState('');
    const [ additionPerc, setAdditionPerc ] = useState('');
    const [ amount, setAmount ] = useState('');
    const [ descriptive, setDescriptive ] = useState('');

    const [ paymentForm, setPaymentForm ] = useState('');
    const [ paymentCode, setPaymentCode ] = useState('');
    const [ paymentData, setPaymentData ] = useState({} as any);

    const [ nameOnCard, setNameOnCard ] = useState('');
    const [ numberOnCard, setNumberOnCard ] = useState('');
    const [ expirationOnCard, setExpirationOnCard ] = useState('');
    const [ validacionOnCard, setValidacionOnCard ] = useState('');
    const [ typePix, setTypePix ] = useState('');
    const [ keyPix, setKeyPix ] = useState('');
    const [ barcode, setBarcode ] = useState('');

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(paymentForm))
            emptyRequired = true;

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedCadastreForm(!result);
        return result;
    }

    async function onClick_Confirm(event: any, type: string) {
        event.preventDefault();
        
        const isValid = await isValidForm();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(title, display.message.confirm_record);
                if (isConfirmed) {
                    setSending(true);
                    const launcher = fillLauncher(type);
                    
                    const returned = await lclService.saveLauncher(launcher.id, launcher);

                    setSending(false);
                    await alertContext.show(AlertMessageEnum.SUCCESS, title, display.message.the_record_has_been_saved_successfully);
                    props.onSubmitModal(event, returned);
                }
            } catch (error: any) {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }

    async function onClick_Cancel(event : any) {
        event.preventDefault();

        //setCadastreFormShow(false); 
        setInvalidatedCadastreForm(false);
        props.onCancelModal(event);
    }

    function clearLauncher() {
        setId(0);
        setCreated('');
        setUpdated('');
        
        setSituation(ContractSituationEnum.INACTIVE);
        setConfection(dateToString(new Date(), 'dd/mm/yyyy'));
        setPartnerId(0);

    
        setSourceDocument('');
        setNumberDocument('');
        setOwnerType('');
        setOwnerId(0);
        setOwnerName('');
        setOwnerRegistry('');
        setDescription('');

        setNumParcel('1');
        setQtyParcels('1');
        setAmountParcel('');
        setExpiry(dateToString(new Date(), 'dd/mm/yyyy'));
        setPayment(dateToString(new Date(), 'dd/mm/yyyy'));
        setDiscount('');
        setDiscountPerc('');
        setAddition('');
        setAdditionPerc('');
        setAmount('');
        setDescriptive('');

        setPaymentForm(PaymentEnum.CASH);
        setPaymentCode('');
        setPaymentData({});

        setNameOnCard('');
        setNumberOnCard('');
        setExpirationOnCard('');
        setValidacionOnCard('');
        setTypePix('');
        setKeyPix('');
        setBarcode('');
    }

    function fillRecord(launcher: any) {
        if (launcher != null) {
            setId(launcher.id);
            setCreated(launcher.created);
            setUpdated(launcher.updated);

            setSituation(launcher.situation);
            setConfection(dateToString(launcher.confection, 'dd/mm/yyyy'));
            setPartnerId(launcher.partnerId);


            setSourceDocument(launcher.sourceDocument);
            setNumberDocument(launcher.numberDocument);
            setOwnerType(launcher.ownerType);
            setOwnerId(launcher.ownerId);
            setOwnerName(launcher.owner.fullname);
            setOwnerRegistry(cpfMask(launcher.owner.registrationPf));
            setDescription(launcher.description);

            launcher.amount = launcher.amountParcel;
            setNumParcel(launcher.numParcel);
            setQtyParcels(launcher.qtyParcels);
            setAmountParcel(floatToString(launcher.amountParcel));
            setExpiry(dateToString(launcher.expiry, 'dd/mm/yyyy'));
            setPayment(launcher.payment ? dateToString(launcher.payment, 'dd/mm/yyyy') : dateToString(new Date(), 'dd/mm/yyyy'));
            setDiscount(floatToString(launcher.discount));
            setDiscountPerc(floatToString(launcher.discountPerc));
            setAddition(floatToString(launcher.addition));
            setAdditionPerc(floatToString(launcher.additionPerc));
            setAmount(floatToString(launcher.amount));
            // setDescriptive(launcher.descriptive);

            setPaymentForm(launcher.paymentForm ? launcher.paymentForm : PaymentEnum.CASH);
            setPaymentCode(launcher.paymentCode);
            setPaymentData(launcher.paymentData);
        }
    }

    function fillLauncher(type: string) {

        const dataLauncher = {
            id,
            created, 
            updated,
            partnerId : props.partner.id,
            
            situation,
            confection : removeFormatDate(confection),
            group : 'R',
            creditAccount : '',
            sourceDocument,
            numberDocument,
            ownerType,
            ownerId,
            ownerName,
            ownerRegistry,
            description,

            numParcel,
            qtyParcels,
            amountParcel : removeMask(amountParcel, '0'),
            expiry : removeFormatDate(expiry),
            discount : removeMask(discount, '0'),
            discountPerc : removeMask(discountPerc, '0'),
            addition : removeMask(addition, '0'),
            additionPerc : removeMask(additionPerc, '0'),
            descriptive,
        
            flag : 'M',
            paymentForm,
            paymentCode,
            paymentData
        } as any;

        if (type === MAKE_THE_PAYMENT) {
            dataLauncher['payment'] = removeFormatDate(payment);
            dataLauncher['amount'] = removeMask(amount, '0');
            dataLauncher['paymentForm'] = paymentForm;
        } else {
            dataLauncher['payment'] = '';
            dataLauncher['amount'] = '0';
            dataLauncher['paymentForm'] = '';
        }

        return dataLauncher;
    }

    async function searchFilter() {
        clearLauncher();

        try {
            if (props.launcherId) {
                const launcher = await lclService.launcherById(Number(props.launcherId));
                if (launcher.sourceDocument === 'CTT') {
                    launcher.contract = await lclService.contractById(launcher.numberDocument);
                }
                fillRecord(launcher);
            }
        } catch(error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
        }
    }

    useEffect(() => {
        if (props.show)
            searchFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    /* */
   

    function updatedAmount(event: any) {
        let localAmountParcel = toFloat(amountParcel);

        let localDiscount = toFloat(discount) || 0.00;
        let localAddition = toFloat(addition) || 0.00;

        let localAmount = ((localAmountParcel - localDiscount) + localAddition);

        let localPercDiscount = 100 - (((localAmountParcel - localDiscount) / localAmountParcel) * 100);
            localPercDiscount = toFloat(localPercDiscount.toFixed(2));
        setDiscountPerc(floatMask(String(localPercDiscount)));
        let localPercAddition = 100 - (((localAmountParcel - localAddition) / localAmountParcel) * 100);
            localPercAddition = toFloat(localPercAddition.toFixed(2));
        setAdditionPerc(floatMask(String(localPercAddition)));

        setAmount(floatMask(localAmount.toFixed(2)));
    }

    function updatedPerc(event: any) {
        let localAmountParcel = toFloat(amountParcel);
        let localPercDiscount = toFloat(discountPerc);
        let localPercAddition = toFloat(additionPerc);

        let localDiscount = (localAmountParcel * (localPercDiscount / 100));
        setDiscount(floatMask(localDiscount.toFixed(2)));
        setDiscountPerc(floatMask(localPercDiscount.toFixed(2)));

        let localAddition = (localAmountParcel * (localPercAddition / 100));
        setAddition(floatMask(localAddition.toFixed(2)));
        setAdditionPerc(floatMask(localPercAddition.toFixed(2)));

        let localAmount = ((localAmountParcel - localDiscount) + localAddition);
        setAmount(floatMask(localAmount.toFixed(2)));
    }

    function templateDefault(event: any) {
        let template = '<p>Recebemos de <strong>%name%</strong> portador do documento <strong>%registry%</strong> à importância de R$ <strong>%amount%</strong>'
            +' ( <strong>%extensive%</strong> ) referente a <strong>%description%</strong> de vencimento <strong>%expiry%</strong>.</p>'
            +'<p><br></p><p>Pagamento realizado por: <strong>%paymentForm%</strong>.&nbsp;</p>';
            
        template = template.replace('%name%', ownerName);
        template = template.replace('%registry%', (ownerType === "PF" ? 'CPF ' : 'CNPJ ')+ ownerRegistry);
        template = template.replace('%amount%', amount);
        template = template.replace('%extensive%', extenso(amount));
        template = template.replace('%description%', description);
        template = template.replace('%expiry%', expiry);
        template = template.replace('%paymentForm%', display.label[toLowerCase(getPayment(paymentForm).name)]);

        setDescriptive(template);
    }

    function viewCadastreForm() {

        return (
            <>
                <Modal id="modal-cadastre-payment-form" show={props.show}
                    size="lg"
                    aria-labelledby="modal-cadastre-payment-form"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title >{title}</Modal.Title>
                        <div className="float-right d-flex justify-content-end">
                            <FaTimes className="isClickable" onClick={ onClick_Cancel } size={26}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className="form" id="form-cadastre-payment" validated={invalidatedCadastreForm} style={{ minHeight : 300 }}>
                            <Row>
                                <Col>
                                    <PaymentRecord
                                        translate={props.translate}
                                        title={title}

                                        created={created}
                                        updated={updated}
                                        situation={situation}
                                        confection={confection}
                                        partnerId={partnerId}
                                        sourceDocument={sourceDocument}
                                        numberDocument={numberDocument}
                                        ownerType={ownerType}
                                        ownerId={ownerId}
                                        ownerName={ownerName}
                                        ownerRegistry={ownerRegistry}
                                        description={description}
                                        numParcel={numParcel}
                                        qtyParcels={qtyParcels}
                                        amountParcel={amountParcel}
                                        expiry={expiry}
                                        payment={payment}
                                        discount={discount}
                                        discountPerc={discountPerc}
                                        addition={addition}
                                        additionPerc={additionPerc}
                                        amount={amount}
                                        paymentForm={paymentForm}
                                        paymentCode={paymentCode}
                                        nameOnCard={nameOnCard}
                                        numberOnCard={numberOnCard}
                                        expirationOnCard={expirationOnCard}
                                        validacionOnCard={validacionOnCard}
                                        typePix={typePix}
                                        keyPix={keyPix}
                                        barcode={barcode}
                                        descriptive={descriptive}

                                        setSituation={(value: any) => { setSituation(value); }}
                                        setConfection={(value: any) => { setConfection(value); }}
                                        setPartnerId={(value: any) => { setPartnerId(value); }}
                                        setSourceDocument={(value: any) => { setSourceDocument(value); }}
                                        setNumberDocument={(value: any) => { setNumberDocument(value); }}
                                        setOwnerType={(value: any) => { setOwnerType(value); }}
                                        setOwnerId={(value: any) => { setOwnerId(value); }}
                                        setOwnerName={(value: any) => { setOwnerName(value); }}
                                        setOwnerRegistry={(value: any) => { setOwnerRegistry(value); }}
                                        setDescription={(value: any) => { setDescription(value); }}
                                        setNumParcel={(value: any) => { setNumParcel(value); }}
                                        setQtyParcels={(value: any) => { setQtyParcels(value); }}
                                        setAmountParcel={(value: any) => { setAmountParcel(value); }}
                                        setExpiry={(value: any) => { setExpiry(value); }}
                                        setPayment={(value: any) => { setPayment(value); }}
                                        setDiscount={(value: any) => { setDiscount(value); }}
                                        setDiscountPerc={(value: any) => { setDiscountPerc(value); }}
                                        setAddition={(value: any) => { setAddition(value); }}
                                        setAdditionPerc={(value: any) => { setAdditionPerc(value); }}
                                        setAmount={(value: any) => { setAmount(value); }}
                                        setPaymentForm={(value: any) => { setPaymentForm(value); }}
                                        setPaymentCode={(value: any) => { setPaymentCode(value); }}
                                        setNameOnCard={(value: any) => { setNameOnCard(value); }}
                                        setNumberOnCard={(value: any) => { setNumberOnCard(value); }}
                                        setExpirationOnCard={(value: any) => { setExpirationOnCard(value); }}
                                        setValidacionOnCard={(value: any) => { setValidacionOnCard(value); }}
                                        setTypePix={(value: any) => { setTypePix(value); }}
                                        setKeyPix={(value: any) => { setKeyPix(value); }}
                                        setBarcode={(value: any) => { setBarcode(value); }}
                                        setDescriptive={(value: any) => { setDescriptive(value); }}

                                        updatedAmount={(value: any) => { updatedAmount(value); }}
                                        updatedPerc={(value: any) => { updatedPerc(value); }}
                                        templateDefault={(value: any) => { templateDefault(value); }}
                                    />
                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div></div>
                        <div>
                            <Button className="me-2" variant="contained" color="secondary" onClick={ onClick_Cancel }>{display.buttom.cancel}</Button>
                            <Button className="me-2" variant="contained" color="success" onClick={ e => onClick_Confirm(e, MAKE_THE_PAYMENT) } disabled={sending}>
                                { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                                Pagar
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
        { viewCadastreForm() }
        </>
    )
}

export default PaymentCadastreModal
