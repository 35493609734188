import { useEffect, useMemo, useState } from "react";
import { Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { FaEdit, FaSave, FaTrash } from "react-icons/fa";
import { DocumentsNauticalCategory, DocumentsNauticalEnrollment, Phones, VesselOwner, VesselOwnerGender, VesselOwnerPhones } from "../../../components/sub-render.component";
import cduService from "../../../services/cdu.service";
import lclService from "../../../services/lcl.service";
import { dateToString, floatToString, notNullToDate, toFloat, toLowerCase, toString } from "../../../utilities/auxiliary-functions";
import { PersonEnum } from "../../../utilities/constants";
import { cnpjMask, cpfMask, floatMask, numberMask, removeFormatDate, removeMask, sizeMask } from "../../../utilities/masks";
import { AlertMessageEnum, getHarbor, getScheduleTitleType } from "../../../utilities/types";
import { isEmpty, isValidCpfCnpj } from "../../../utilities/validators";
import VesselConductorCadastreModal from "../../modals/vessel-conductor.cadastre.modal";
import VesselResponsibleCadastreModal from "../../modals/vessel-responsible.cadastre.modal";
import VesselRecord from "./records/vessel.record";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useConfirmMessageContext } from "../../../contexts/confirm-message.context";
import { useAlertMessageContext } from "../../../contexts/alert-message.context";
import marcadguant from '../../../assets/marcadguant.png';
import moment from "moment";
import srvService from "../../../services/srv.service";
import { useDeleteMessageContext } from "../../../contexts/delete-message.context";
import { Button } from '@mui/material'
import { MdOutlineStar, MdOutlineStarBorder } from "react-icons/md";

interface Props {
    translate: any,
    partner: any,

    title: string,

    vesselId: string,
    setRecord: any
}

function VesselCadastreForm(props: Props) {

    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();
    const deleteContext = useDeleteMessageContext();

    const display = props.translate.data;

    const [ invalidatedForm, setInvalidatedForm ] = useState(false);
    const [ isEditForm, setEditForm ] = useState(true);

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ originRecord, setOriginRecord ] = useState({} as any);
    const [ sending, setSending ] = useState(false);

    /* TRATAMENTO PARTICULAR DO OBJETO QUE ESTA SENDO MANTIDO */
    // Member
    const [ vesselId, setVesselId ] = useState(0);
    // const [ situation, setSituation ] = useState(0);
    const [ created, setCreated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ updated, setUpdated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));

    // Dados Gerais
    const [ indAlert, setIndAlert ] = useState(false);
    const [ indRestriction, setIndRestriction ] = useState(false);
    const [ identifyKey, setIdentifyKey] = useState('');
    const [ name, setName ] = useState('');
    const [ year, setYear ] = useState('');
    const [ typeVessel, setTypeVessel ] = useState('');
    const [ hasTrailer, setHasTrailer ] = useState('0');
    const [ typeTrailer, setTypeTrailer ] = useState('0');
    const [ plateTrailer, setPlateTrailer ] = useState('');
    const [ classification, setClassification ] = useState('');
    const [ harborState, setHarborState ] = useState('');
    const [ harbor, setHarbor ] = useState('');
    const [ enrollment, setEnrollment ] = useState('');
    const [ manufacturer, setManufacturer ] = useState('');
    const [ color, setColor ] = useState('');
    const [ brand, setBrand ] = useState('');
    const [ model, setModel ] = useState('');
    const [ hullMaterial, setHullMaterial ] = useState('');
    const [ quantityPeople, setQuantityPeople ] = useState(1);
    const [ potency, setPotency ] = useState('');
    const [ vesselSize, setVesselSize ] = useState('');
    const [ numberTie, setNumberTie ] = useState('');
    const [ validityTie, setValidityTie ] = useState('');
    const [ validityTr, setValidityTr ] = useState('');
    // Motor
    const [ engines, setEngines ] = useState([] as any);
    // Dados do Seguro
    const [ insuranceName, setInsuranceName ] = useState('');
    const [ insuranceNumber, setInsuranceNumber ] = useState('');
    const [ insuranceCost, setInsuranceCost ] = useState('');
    const [ insuranceContact, setInsuranceContact ] = useState('');
    const [ insuranceValidity, setInsuranceValidity ] = useState('');
    // Valor da Embarcação
    const [ vesselCost, setVesselCost ] = useState('');
    const [ hullCost, setHullCost ] = useState('');
    const [ engineCost, setEngineCost ] = useState('');
    const [ generatorsCost, setGeneratorsCost ] = useState('');
    const [ soundsystemCost, setSoundsystemCost ] = useState('');
    const [ accessoriesCost, setAccessoriesCost ] = useState('');
    const [ othersCost, setOthersCost ] = useState('');
    const [ sailsAndMastsCost, setSailsAndMastsCost ] = useState('');
    // Proprietario e Autorizações
    const [ ownerType, setOwnerType ] = useState('');
    const [ ownerId, setOwnerId ] = useState(0);
    const [ ownerName, setOwnerName ] = useState('');
    const [ ownerRegistry, setOwnerRegistry ] = useState('');

    const [ responsibleId, setResponsibleId ] = useState('');
    const [ responsible, setResponsible ] = useState({} as any);
    const [ isEditResponsible, setEditResponsible ] = useState(false);
    const [ showResponsible, setShowResponsible ] = useState(false);
    const [ responsibles, setResponsibles ] = useState([] as any);

    // Tracker
    const [ trackerId, setTrackerId ] = useState(0);
    const [ trackerBrand, setTrackerBrand ] = useState('');
    const [ trackerModel, setTrackerModel ] = useState('');
    const [ trackerSerial, setTrackerSerial ] = useState('');
    const [ trackerActive, setTrackerActive ] = useState('');
    const [ trackerResponsible, setTrackerResponsible ] = useState('');
    const [ image, setImage] = useState(marcadguant);

    const [ conductorId, setConductorId ] = useState('');
    const [ conductor, setConductor ] = useState({} as any);
    const [ isEditConductor, setEditConductor ] = useState(false);
    const [ showConductor, setShowConductor ] = useState(false);
    const [ conductors, setConductors ] = useState([] as any);

    const [ schedules, setSchedules ] = useState([] as any);
    
    const [ scheduleProhibited, setScheduleProhibited ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ scheduleTitle, setScheduleTitle ] = useState('');
    const [ scheduleComments, setScheduleComments ] = useState('');

    const [ keep, setKeep ] = useState({} as any);

    type ArtifactType = { id : number, norm : string, source : string, identy : string, document : string, filename : string };
    const DEFAULT_ARTIFACT: ArtifactType = { id : 0, norm : '', source : '', identy : '', document : '', filename : '' };
    
    const [ prowImage, setProwImage ] = useState({ id : 0, norm : 'PROW', source : 'VESSEL', identy : '', document : '', filename : '' });
    const [ sternImage, setSternImage ] = useState({ id : 0, norm : 'STERN', source : 'VESSEL', identy : '', document : '', filename : '' });
    const [ portboardImage, setPortboardImage ] = useState({ id : 0, norm : 'PORTBOARD', source : 'VESSEL', identy : '', document : '', filename : '' });
    const [ starboardImage, setStarboardImage ] = useState({ id : 0, norm : 'STARBOARD', source : 'VESSEL', identy : '', document : '', filename : '' });
    const [ panelImage, setPanelImage ] = useState({ id : 0, norm : 'PANEL', source : 'VESSEL', identy : '', document : '', filename : '' });
    const [ interiorImage, setInteriorImage ] = useState({ id : 0, norm : 'INTERIOR', source : 'VESSEL', identy : '', document : '', filename : '' });
    const [ cabinImage, setCabinImage ] = useState({ id : 0, norm : 'CABIN', source : 'VESSEL', identy : '', document : '', filename : '' });

    // Documentos
    const [ vesselDocument, setVesselDocument ] = useState(DEFAULT_ARTIFACT);
    const [ insurancePolicyDocument, setInsurancePolicyDocument ] = useState(DEFAULT_ARTIFACT);
    const [ driversLicenseDocument, setDriversLicenseDocument ] = useState(DEFAULT_ARTIFACT);
    const [ isChangedVesselDocument, setChangedVesselDocument ] = useState(false);
    const [ isInsurancePolicyDocument, setChangedInsurancePolicyDocument ] = useState(false);
    const [ isDriversLicenseDocument, setChangedDriversLicenseDocument ] = useState(false);

    const columnsResponsible = useMemo(() => [
        {
            Header: display.label.fullname,
            Cell: (row: any) => <VesselOwner translate={props.translate} values={row} />
        }, {
            Header: display.label.gender,
            Cell: (row: any) => <VesselOwnerGender translate={props.translate} values={row} />
        },{
            Header: display.label.average,
            accessor: 'average',
            Cell: (row: any) => (<div className="white-space">{floatMask(row.value)}</div>)
        },{
            Header: display.label.major,
            accessor: 'principal',
            Cell: (row: any) => (<div className="white-space">{row.value === 1 ? <MdOutlineStar size={24} /> : <MdOutlineStarBorder size={24} />}</div>)
        },{
            Header: display.label.telephone,
            Cell: (row: any) => <VesselOwnerPhones translate={props.translate} values={row} />
        },{
            Header: display.legend.actions,
            accessor: "actions",
            Cell: (props: any) => {
                let responsibleRows = [] as any;
                const originals = props.rows;
                for (var o = 0; o < originals.length; o++) {
                    responsibleRows[responsibleRows.length] = originals[o].original;
                }
                const responsibleRow = props.row.original;

                return (
                    <div className="white-space table-actions">
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.toEdit}</Tooltip>}>
                            <span onClick={(e) => onClick_EditResponsible(e, responsibleRow)}>
                                <FaEdit size={18} />
                            </span>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.delete}</Tooltip>}>
                            <span onClick={(e) => onClick_DeleteResponsible(e, responsibleRows, responsibleRow)}>
                                <FaTrash size={18} />
                            </span>
                        </OverlayTrigger>
                    </div>
                );
            },
        }],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    ); // columnsResponsible

    const columnsConductor = useMemo(() => [
        {
            Header: display.label.name,
            accessor: 'people.fullname',
            Cell: (row: any) => (<div className="white-space">{row.value}</div>)
        },{
            Header: display.label.category,
            Cell: (row: any) => <DocumentsNauticalCategory translate={props.translate} values={row} />
        },{
            Header: display.label.enrollment,
            accessor: 'enrollment',
            Cell: (row: any) => <DocumentsNauticalEnrollment translate={props.translate} values={row} />
        },{
            Header: display.label.telephone,
            accessor: 'people.phones',
            Cell: (row: any) => <Phones translate={props.translate} values={row.value} />
        },{
            Header: display.legend.actions,
            accessor: "actions",
            Cell: (props: any) => {
                let conductorRows = [] as any;
                const originals = props.rows;
                for (var o = 0; o < originals.length; o++) {
                    conductorRows[conductorRows.length] = originals[o].original;
                }
                const conductorRow = props.row.original;

                return (
                    <div className="white-space table-actions">
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.toEdit}</Tooltip>}>
                            <span onClick={(e) => onClick_EditConductor(e, conductorRow)}>
                                <FaEdit size={18} />
                            </span>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.delete}</Tooltip>}>
                            <span onClick={(e) => onClick_DeleteConductor(e, conductorRows, conductorRow)}>
                                <FaTrash size={18} />
                            </span>
                        </OverlayTrigger>
                    </div>
                );
            },
        }],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    ); // columnsConductor
        
    const [ equipments, setEquipments ] = useState([] as any);

    async function fillRecord(vessel: any) {

        if (!isEmpty(vessel)) {
            setVesselId(vessel.id);

            // Dados Gerais
            setIndAlert(vessel.indAlert ? vessel.indAlert === 1 : false);
            setIndRestriction(vessel.indRestriction ? vessel.indRestriction === 1 : false);
            setIdentifyKey(vessel.identifyKey);
            setName(vessel.name);
            setYear(vessel.year);
            setTypeVessel(vessel.typeVessel);
            setHasTrailer(isEmpty(vessel.hasTrailer) ? '0' : vessel.hasTrailer);
            setTypeTrailer(isEmpty(vessel.typeTrailer) ? '0' : vessel.typeTrailer);
            setPlateTrailer(vessel.plateTrailer);
            setClassification(vessel.classification);

            const elementHarbor = getHarbor(vessel.harbor);
            setHarborState(elementHarbor.uf);
            setHarbor(elementHarbor.id);

            setEnrollment(vessel.enrollment);
            setManufacturer(vessel.manufacturer);
            setColor(vessel.color);
            setBrand(vessel.brand);
            setModel(vessel.model);
            setHullMaterial(vessel.hullMaterial);
            setQuantityPeople(vessel.quantityPeople);
            setPotency(vessel.potency);
            setVesselSize(sizeMask(vessel.vesselSize));
            setNumberTie(numberMask(vessel.numberTie));
            setValidityTie(dateToString(vessel.validityTie, 'dd/mm/yyyy'));
            setValidityTr(dateToString(vessel.validityTr, 'dd/mm/yyyy'));
            // Motor
            setEngines(vessel.engines);

            /*
            if (vessel.images && vessel.images.length > 0) {
                const localImages = [] as any;
                for (var idx_img = 0; idx_img < images.length; idx_img++) {
                    const image = images[idx_img];
                    for (var idx_lcl = 0; idx_lcl < vessel.images.length; idx_lcl++) {
                        if (image.norm === vessel.images[idx_lcl].norm) {
                            image.content = vessel.images[idx_lcl].content;
                            break;
                        }
                    }
                    localImages[localImages.length] = image;
                }
                setImages(localImages);
            }
            */

            // Dados do Seguro
            setInsuranceName(vessel.insuranceName);
            setInsuranceNumber(numberMask(vessel.insuranceNumber));
            setInsuranceCost(floatMask(vessel.insuranceCost));
            setInsuranceContact(vessel.insuranceContact);
            setInsuranceValidity(dateToString(vessel.insuranceValidity, 'dd/mm/yyyy'));
            // Valor da Embarcação
            setVesselCost(floatMask(vessel.vesselCost));
            setHullCost(floatMask(vessel.hullCost));
            setEngineCost(floatMask(vessel.engineCost));
            setGeneratorsCost(floatMask(vessel.generatorsCost, true));
            setSoundsystemCost(floatMask(vessel.soundsystemCost));
            setAccessoriesCost(floatMask(vessel.accessoriesCost));
            setOthersCost(floatMask(vessel.othersCost));
            setSailsAndMastsCost(floatMask(vessel.sailsAndMastsCost));
            // Proprietario e Autorizações
            setOwnerType(vessel.ownerType);
            setOwnerId(vessel.ownerId);
            
            if (vessel.ownerId) {
                try {
                    if (vessel.ownerType === PersonEnum.PESSOA_FISICA) {
                        const people = await cduService.peopleById(vessel.ownerId);
                        setOwnerRegistry(cpfMask(people.registrationPf));
                        setOwnerName(people.fullname);
                    } else if (vessel.ownerType === PersonEnum.PESSOA_JURIDICA) {
                        const company = await cduService.companyById(vessel.ownerId);
                        setOwnerRegistry(cnpjMask(company.registrationPj));
                        setOwnerName(company.fantasy);
                    }
                } catch (error: any) {
                    await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
                }
            }

            setResponsibles(vessel.responsibles);
            setConductors(vessel.conductors);
            if (vessel.equipments)
                for (var i = 0; i < vessel.equipments.length; i++) {
                    vessel.equipments[i].amount = floatMask(vessel.equipments[i].amount);
                }
            setEquipments(vessel.equipments);
            setImage(isEmpty(vessel.image) ? marcadguant : vessel.image);
            
            setCreated(dateToString(vessel.created, 'dd/mm/yyyy HH:MM:ss'));
            setUpdated(dateToString(vessel.updated, 'dd/mm/yyyy HH:MM:ss'));

            if (vessel.tracker) {
                const tracker = vessel.tracker;
                setTrackerId(tracker.id);
                setTrackerBrand(tracker.brand);
                setTrackerModel(tracker.model);
                setTrackerSerial(tracker.serial);
                setTrackerActive(dateToString(tracker.active, 'dd/mm/yyyy'));
                setTrackerResponsible(tracker.responsible);
            }
        }
    } // fillRecord

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(identifyKey))
            emptyRequired = true;
        //if (isEmpty(name))
        //    emptyRequired = true;
        //if (isEmpty(year))
        //    emptyRequired = true;
        if (isEmpty(typeVessel))
            emptyRequired = true;
        //if (isEmpty(harborState))
        //    emptyRequired = true;
        //if (isEmpty(harbor))
        //    emptyRequired = true;
        //if (isEmpty(enrollment))
        //    emptyRequired = true;

        if(isEmpty(responsibles)) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, display.message.invalid.it_is_mandatory_to_have_a_registered_responsible);
            result = false;
        }

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedForm(!result);
        return result;
    } // isValidForm

    async function fillVessel() {
        // { id : 0, maker : '', norm : 0, model : '', potency : '', year : '', serialNumber : '', fuel : 0, capacity : 0, image: '' }
        const dataEngine = [] as any;
        if (engines) {
            for (var idx_Eng = 0; idx_Eng < engines.length; idx_Eng++) {
                const engine = engines[idx_Eng];
                dataEngine[dataEngine.length] = {
                    id : engine.id,
                    vesselId,
                    maker : engine.maker,
                    norm : engine.norm,
                    model : engine.model,
                    potency : engine.potency,
                    year : engine.year,
                    serialNumber : removeMask(engine.serialNumber),
                    fuel : engine.fuel,
                    capacity : 0,
                    origin : engine.origin,
                    image : engine.image,
                    created : engine.created,
                    updated : engine.updated
                }
            }
        }
            
        // { id : 0, group : '', category : '', name : '', unity : '', quantity : '', amount : '' }
        const dataEquipment = [] as any;
        if (equipments) {
            for (var idx_Epmt = 0; idx_Epmt < equipments.length; idx_Epmt++) {
                const equipment = equipments[idx_Epmt];
                dataEquipment[dataEquipment.length] = {
                    id : equipment.id,
                    vesselId,
                    group : equipment.group,
                    category : equipment.category,
                    name : equipment.name,
                    unity : equipment.unity,
                    quantity : equipment.quantity,
                    amount : removeMask(equipment.amount),
                    created : equipment.created,
                    updated : equipment.updated
                }
            }
        }

        let dataVessel = {
            id : vesselId,
            partnerId : props.partner.id,
            partner : props.partner,
        // Dados Gerais
            indAlert,
            indRestriction,
            identifyKey,
            name,
            year,
            typeVessel,
            hasTrailer : removeMask(hasTrailer, '0'),
            typeTrailer : removeMask(typeTrailer, '0'),
            plateTrailer,
            classification,
            harbor,
            enrollment,
            manufacturer,
            color,
            brand,
            model,
            hullMaterial,
            quantityPeople,
            potency,
            vesselSize : removeMask(vesselSize, '0'),
            numberTie : removeMask(numberTie),
            validityTie : removeFormatDate(validityTie),
            validityTr : removeFormatDate(validityTr),
        // Motor
           engines: dataEngine,
           // images,
        // Dados do Seguro
            insuranceName,
            insuranceNumber : removeMask(insuranceNumber),
            insuranceCost : removeMask(insuranceCost, '0'),
            insuranceContact,
            insuranceValidity : removeFormatDate(insuranceValidity),
        // Valor da Embarcação
            vesselCost : removeMask(vesselCost, '0'),
            hullCost : removeMask(hullCost, '0'),
            engineCost : removeMask(engineCost, '0'),
            generatorsCost : removeMask(generatorsCost, '0'),
            soundsystemCost : removeMask(soundsystemCost, '0'),
            accessoriesCost : removeMask(accessoriesCost, '0'),
            othersCost : removeMask(othersCost, '0'),
            sailsAndMastsCost : removeMask(sailsAndMastsCost, '0'),
            equipments : dataEquipment,
        // Proprietario e Autorizações
            ownerType,
            ownerId, 
            responsibles,
            conductors,
        // Particular
            image,
            created : removeFormatDate(created), 
            updated : removeFormatDate(updated) 
        }

        return dataVessel;
    } // fillVessel

    async function fillTracker() {

        const dataTracker = {
            id : trackerId,
            vesselId,
            brand : trackerBrand,
            model : trackerModel,
            serial : trackerSerial,
            active : removeFormatDate(trackerActive),
            responsible : trackerResponsible
        }
        return dataTracker;
    } // fillTracker

    const normalizeArtifact = (artifact: any): ArtifactType => {
        if (artifact)
            return {
                id : artifact.id,
                norm : artifact.norm,
                source : artifact.source,
                identy : artifact.identy,
                document : artifact.document,
                filename : artifact.filename
            };
        else
            return DEFAULT_ARTIFACT;
    }

    async function searchParam() {
        let startSchedule = moment().add(1, 'day');
        setScheduleProhibited(startSchedule.format("DD/MM/YYYY HH:mm:ss"));

        try {
            if (!Number.isNaN(Number.parseInt(props.vesselId ||''))) {
                setEditForm(true);

                const vessel = await lclService.vesselById(Number(props.vesselId));
                setOriginRecord(vessel);
                fillRecord(vessel);

                /* Imagens */
                cduService.artifactByNorm('VESSEL', vessel.id, 'PROW').then((artifact: any) => {
                    if (!artifact)
                        artifact = { id : 0, norm : 'PROW', source : 'VESSEL', identy : vessel.id, document : '', filename : '' };
                    setProwImage(artifact);
                });

                cduService.artifactByNorm('VESSEL', vessel.id, 'STERN').then((artifact: any) => {
                    if (!artifact)
                        artifact = { id : 0, norm : 'STERN', source : 'VESSEL', identy : vessel.id, document : '', filename : '' };
                    setSternImage(artifact);
                });

                cduService.artifactByNorm('VESSEL', vessel.id, 'PORTBOARD').then((artifact: any) => {
                    if (!artifact)
                        artifact = { id : 0, norm : 'PORTBOARD', source : 'VESSEL', identy : vessel.id, document : '', filename : '' };
                    setPortboardImage(artifact);
                });

                cduService.artifactByNorm('VESSEL', vessel.id, 'STARBOARD').then((artifact: any) => {
                    if (!artifact)
                        artifact = { id : 0, norm : 'STARBOARD', source : 'VESSEL', identy : vessel.id, document : '', filename : '' };
                    setStarboardImage(artifact);
                });

                cduService.artifactByNorm('VESSEL', vessel.id, 'PANEL').then((artifact: any) => {
                    if (!artifact)
                        artifact = { id : 0, norm : 'PANEL', source : 'VESSEL', identy : vessel.id, document : '', filename : '' };
                    setPanelImage(artifact);
                });

                cduService.artifactByNorm('VESSEL', vessel.id, 'INTERIOR').then((artifact: any) => {
                    if (!artifact)
                        artifact = { id : 0, norm : 'INTERIOR', source : 'VESSEL', identy : vessel.id, document : '', filename : '' };
                    setInteriorImage(artifact);
                });

                cduService.artifactByNorm('VESSEL', vessel.id, 'CABIN').then((artifact: any) => {
                    if (!artifact)
                        artifact = { id : 0, norm : 'CABIN', source : 'VESSEL', identy : vessel.id, document : '', filename : '' };
                    setCabinImage(artifact);
                });
                
                /* Documents */
                cduService.artifactByNorm('VESSEL', vessel.id, 'FILE_VESSEL').then((artifact: any) => {
                    if (artifact) {
                        artifact.filename = `Atualizado em ${notNullToDate(artifact.updated, 'dd/mm/yyyy HH:MM:ss')}`
                        setVesselDocument(normalizeArtifact(artifact));
                    }
                });
                cduService.artifactByNorm('VESSEL', vessel.id, 'FILE_INSURANCE').then((artifact: any) => {
                    if (artifact) {
                        artifact.filename = `Atualizado em ${notNullToDate(artifact.updated, 'dd/mm/yyyy HH:MM:ss')}`
                        setInsurancePolicyDocument(normalizeArtifact(artifact));
                    }
                });
                cduService.artifactByNorm('VESSEL', vessel.id, 'FILE_DRIVER').then((artifact: any) => {
                    if (artifact) {
                        artifact.filename = `Atualizado em ${notNullToDate(artifact.updated, 'dd/mm/yyyy HH:MM:ss')}`
                        setDriversLicenseDocument(normalizeArtifact(artifact));
                    }
                });

                /* Others */
                srvService.scheduleByVessel(Number(props.vesselId)).then((schedules: any) => {
                    setSchedules(schedules);
                });
                lclService.bookcaseByVessel(vessel.id).then((bookcase: any) => {
                    setKeep(bookcase);
                });
                
            } else {
                setEditForm(false);
                setEquipments([
                    { id : 0, group : 'Segurança', category : '', name : 'Extintor', unity : '', quantity : '', amount : '' },
                    { id : 1, group : 'Segurança', category : '', name : 'Colete Salva Vidas', unity : '', quantity : '', amount : '' }
                ]);
            }
        } catch(error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
        }
    } // searchParam

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            searchParam();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []); // useEffect

    /* FUNCTION EXCLUSIVE OF FORM - START */

    function onClick_CancelVessel(event: any) {
        event.preventDefault();
        props.setRecord(event, 0)
    } // onClick_CancelVessel

    async function onClick_SaveVessel(event: any) {
        event.preventDefault();
        
        const isValid = await isValidForm();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(props.title, display.message.confirm_record);
                if (isConfirmed) {
                    setSending(true);

                    const vessel = await fillVessel();
                    if (isEditForm)
                       await lclService.saveVessel(vessel.id, vessel);
                    else
                       await lclService.createVessel(vessel);

                    const dataImages = [] as any;
                    dataImages.push(prowImage);
                    dataImages.push(sternImage);
                    dataImages.push(portboardImage);
                    dataImages.push(starboardImage);
                    dataImages.push(panelImage);
                    dataImages.push(interiorImage);
                    dataImages.push(cabinImage);

                    for ( let i = 0; i < dataImages.length; i++) {
                        const dataImage = dataImages[i];

                        if (dataImage) {
                            dataImage.source = 'VESSEL';
                            dataImage.identy = vesselId;
        
                            if ((dataImage.id > 0) && !isEmpty(dataImage.document)) {
                                await cduService.saveArtifact(dataImage.id, dataImage);
                            } else if ((dataImage.id > 0) && isEmpty(dataImage.document)) {
                                await cduService.artifactDeleteById(dataImage.id);
                            } else if ((dataImage.id === 0) && !isEmpty(dataImage.document)) {
                                await cduService.createArtifact(dataImage);
                            }   
                        }
                    }

                    const dataArtifacts = [] as any;
                    dataArtifacts.push(vesselDocument);
                    dataArtifacts.push(insurancePolicyDocument);
                    dataArtifacts.push(driversLicenseDocument);

                    for ( let i = 0; i < dataArtifacts.length; i++) {
                        const dataArtifact = dataArtifacts[i];
                    
                        if (dataArtifact) {
                            dataArtifact.source = 'VESSEL';
                            dataArtifact.identy = vesselId;

                            if (((dataArtifact.norm === 'FILE_VESSEL') && isChangedVesselDocument)
                                || ((dataArtifact.norm === 'FILE_INSURANCE') && isInsurancePolicyDocument)
                                || ((dataArtifact.norm === 'FILE_DRIVER') && isDriversLicenseDocument)
                            ) {
                                if ((dataArtifact.id > 0) && !isEmpty(dataArtifact.document)) {
                                    await cduService.saveArtifact(dataArtifact.id, dataArtifact);
                                } else if ((dataArtifact.id > 0) && isEmpty(dataArtifact.document)) {
                                    await cduService.artifactDeleteById(dataArtifact.id);
                                } else if ((dataArtifact.id === 0) && !isEmpty(dataArtifact.document)) {
                                    await cduService.createArtifact(dataArtifact);
                                }
                            }
                        }
                    }

                    const tracker = await fillTracker();
                    if (!isEmpty(trackerBrand) && !isEmpty(trackerModel) && !isEmpty(trackerSerial)) {
                        if (trackerId === 0)
                            await lclService.createTracker(tracker);
                        else
                            await lclService.saveTracker(trackerId, tracker);
                    } else {
                        if (trackerId > 0)
                            await lclService.deleteTracker(trackerId, 'No necessary content');
                    }

                    setSending(false);
                    await alertContext.show(AlertMessageEnum.SUCCESS, props.title, display.message.the_record_has_been_saved_successfully);
                    props.setRecord(event, 0);
                }
            } catch (error: any) {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
            }
        }
    } // onClick_SaveVessel
    
    function onClick_EditConductor(event: any, conductor: any) {
        event.preventDefault();

        setEditConductor(true);
        setConductorId(conductor.id);
        setConductor(conductor);
        setShowConductor(true);
    }

    function onClick_EditResponsible(event: any, responsible: any) {
        event.preventDefault();

        setEditResponsible(true);
        setResponsibleId(responsible.id);
        setResponsible(responsible);
        setShowResponsible(true);
    }

    async function onClick_DeleteResponsible(event: any, responsibles: any, responsible: any) {
        event.preventDefault();
        const isConfirmed = await deleteContext.show(props.title, display.message.delete_record, false);
        if (isConfirmed && isConfirmed.result) {
            try {
                const returned = await lclService.vesselResponsibleDeleteById(responsible.id, isConfirmed.message);
                if (!returned)
                    await alertContext.show(AlertMessageEnum.FAIL, props.title, 'Nao foi possivel remover o responsável.');
                else
                    searchParam();
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
            }
        }
    }

    async function onClick_DeleteConductor(event: any, conductors: any, conductor: any) {
        event.preventDefault();

        const isConfirmed = await deleteContext.show(props.title, display.message.delete_record, false);
        if (isConfirmed && isConfirmed.result) {
            try {
                const returned = await lclService.vesselConductorDeleteById(conductor.id, isConfirmed.message);
                if (!returned)
                    await alertContext.show(AlertMessageEnum.FAIL, props.title, 'Nao foi possivel remover o condutor.');
                else
                    searchParam();
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, props.title, 'Este condutor possui histórico de navegação.');
            }
        }
    }

    async function searchOwner(event: any) {
        event.preventDefault(event);

        if (!isEmpty(ownerType)) {
            let registry = removeMask(ownerRegistry);
            if (isValidCpfCnpj(registry)) {
                try {
                    if (ownerType === PersonEnum.PESSOA_FISICA) {
                        const people = await cduService.peopleByRegistration(registry);
                        if (people) {
                            setOwnerId(people.id);
                            setOwnerRegistry(cpfMask(people.registrationPf));
                            setOwnerName(people.fullname);
                        } else
                            await alertContext.show(AlertMessageEnum.WARN, props.title, 'O CPF não foi encontrado !!!');
                    } else if (ownerType === PersonEnum.PESSOA_JURIDICA) {
                        const company = await cduService.companyByRegistration(registry);
                        if (company) {
                            setOwnerId(company.id);
                            setOwnerRegistry(cnpjMask(company.registrationPj));
                            setOwnerName(company.fantasy);
                        } else
                            await alertContext.show(AlertMessageEnum.WARN, props.title, 'O CNPJ não foi encontrado !!!');
                    } else
                        await alertContext.show(AlertMessageEnum.FAIL, props.title, 'Informação não encontrada.');
                } catch (error: any) {
                    await alertContext.show(AlertMessageEnum.FAIL, props.title, display.message.invalid.document_type);
                }
            } else {
                await alertContext.show(AlertMessageEnum.FAIL, props.title, display.message.the_document_is_not_valid);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, display.message.owner_type_not_specified);
        }
    } // searchOwner

    function addResponsible(event: any) {
        event.preventDefault();

        setEditResponsible(false);
        setResponsibleId('new');
        setResponsible({} as any);
        setShowResponsible(true);
    }

    function addConductor(event: any) {
        event.preventDefault();

        setEditConductor(false);
        setConductorId('new');
        setConductor({} as any);
        setShowConductor(true);
    }

    function addEquipment(event: any) {
        event.preventDefault();

        if (!equipments) {
            setEquipments({ id : 0, group : '', category : '', name : '', unity : '', quantity : '', amount : '' });
        } else
            setEquipments([...equipments, { id : 0, group : '', category : '', name : '', unity : '', quantity : '', amount : '' }]);
    }

    function addEngine(event: any) {
        event.preventDefault();
        
        if (!engines) {
            setEngines([{ id : 0, maker : '', norm : 0, model : '', potency : '', year : '', serialNumber : '', fuel : 0, capacity : 0, origin : '', image : '' }]);
        } else
            setEngines([...engines, { id : 0, maker : '', norm : 0, model : '', potency : '', year : '', serialNumber : '', fuel : 0, capacity : 0, origin : '', image : '' }]);
    }

    function updateEngineItem(position: number, field: string, item: string) {
        const updatedInfoItems = engines.map((standardItem: any, index: number) => {
            if (index === position) {
                return { ...standardItem, [field]: item };
            }

            return standardItem;
        });
        setEngines(updatedInfoItems);
    }

    function removeEngineItem(event: any, index: number) {
        event.preventDefault();

        const itensCopy = Array.from(engines);
        itensCopy.splice(index, 1);
        setEngines(itensCopy);
    }

    function sumVesselCost(event: any) {
        const vesselCost = toFloat(hullCost) + 
            toFloat(engineCost) + 
            toFloat(generatorsCost) + 
            toFloat(soundsystemCost) + 
            toFloat(accessoriesCost) + 
            toFloat(othersCost) + 
            toFloat(sailsAndMastsCost);

        setHullCost(floatMask(hullCost, true));
        setEngineCost(floatMask(engineCost, true));
        setGeneratorsCost(floatMask(generatorsCost, true));
        setSoundsystemCost(floatMask(soundsystemCost, true));
        setAccessoriesCost(floatMask(accessoriesCost, true));
        setOthersCost(floatMask(othersCost, true));
        setSailsAndMastsCost(floatMask(sailsAndMastsCost, true));
        
        setVesselCost(floatToString(vesselCost));
    }

    function sumEquipmentValues() {
    }

    function updateEquipmentItem(position: number, field: string, item: string) {
        const updatedInfoItems = equipments.map((standardItem: any, index: number) => {
            if (index === position) {
                return { ...standardItem, [field]: item };
            }
            return standardItem;
        });
        setEquipments(updatedInfoItems);
    }

    function updateEquipmentValue(event: any) {
        sumEquipmentValues();
    }

    function removeEquipmentItem(event: any, index: number) {
        event.preventDefault();
        if(index !== 0 && index !== 1){
            const itensCopy = Array.from(equipments);
            itensCopy.splice(index, 1);
        
            setEquipments(itensCopy);
            sumEquipmentValues();
        }
    }

    function openTracking(event: any) {
        event.preventDefault();
        const jailTracker = window.open("https://sistema.getrak.com/nts", "_blank", "width=1100,height=800,left=200,top=200");
        if (jailTracker)
            jailTracker.addEventListener('visibilitychange', function(event: any) {
                event.preventDefault();
                event.returnValue = 'Que SHOW '+ Math.round(2.5);
                // handleClickCloseTracker(event);
            });
    }

    function changeImage(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                setImage(e.target.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            setImage('');
        }
    }

    function changeVesselDocument(event: any) {
        setChangedVesselDocument(true);
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let artfact: ArtifactType = {
                    id : vesselDocument.id,
                    norm : 'FILE_VESSEL',
                    source : 'VESSEL',
                    identy : String(vesselId),
                    document : e.target.result,
                    filename : event.target.files[0].name
                }
                setVesselDocument(artfact);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            let artifact: ArtifactType = { id : vesselDocument.id, norm : 'FILE_VESSEL', source : 'VESSEL', identy : String(vesselId), document : '', filename : '' };
            setVesselDocument(artifact);
        }
    }

    function changeInsurancePolicyDocument(event: any) {
        setChangedInsurancePolicyDocument(true);
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let artfact: ArtifactType = {
                    id: insurancePolicyDocument.id,
                    source: 'VESSEL',
                    identy: String(vesselId),
                    norm: 'FILE_INSURANCE',
                    document: toString(e.target.result),
                    filename: toString(event.target.files[0].name)
                };
                setInsurancePolicyDocument(artfact);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            let artifact: ArtifactType = { id : insurancePolicyDocument.id, norm : 'FILE_INSURANCE', source : 'VESSEL', identy : String(vesselId), document : '', filename : '' };
            setInsurancePolicyDocument(artifact);
        }
    }

    function changeDriversLicenseDocument(event: any) {
        setChangedDriversLicenseDocument(true);
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let artfact: ArtifactType = {
                    id : driversLicenseDocument.id,
                    norm : 'FILE_DRIVER',
                    source : 'VESSEL',
                    identy : String(vesselId),
                    document : e.target.result,
                    filename : event.target.files[0].name
                }
                setDriversLicenseDocument(artfact);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            let artifact: ArtifactType = { id : driversLicenseDocument.id, norm : 'FILE_DRIVER', source : 'VESSEL', identy : String(vesselId), document : '', filename : '' };
            setDriversLicenseDocument(artifact);
        }
    }

    function changeImageEngineItem(event: any, index: number) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                updateEngineItem(index, 'image', e.target.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            updateEngineItem(index, 'image', '');
        }
    }

    function changeImageVesselItem(event: any, order: string, index: number) {
        event.preventDefault();

        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                if (index === 0) {
                    let artfact: ArtifactType = { id: prowImage.id, source: 'VESSEL', identy: String(vesselId), norm: 'PROW', document: toString(e.target.result), filename: toString(event.target.files[0].name) };
                    setProwImage(artfact);
                } else if (index === 1) {
                    let artfact: ArtifactType = { id: sternImage.id, source: 'VESSEL', identy: String(vesselId), norm: 'STERN', document: toString(e.target.result), filename: toString(event.target.files[0].name) };
                    setSternImage(artfact);
                } else if (index === 2) {
                    let artfact: ArtifactType = { id: portboardImage.id, source: 'VESSEL', identy: String(vesselId), norm: 'PORTBOARD', document: toString(e.target.result), filename: toString(event.target.files[0].name) };
                    setPortboardImage(artfact);
                } else if (index === 3) {
                    let artfact: ArtifactType = { id: starboardImage.id, source: 'VESSEL', identy: String(vesselId), norm: 'STARBOARD', document: toString(e.target.result), filename: toString(event.target.files[0].name) };
                    setStarboardImage(artfact);
                } else if (index === 4) {
                    let artfact: ArtifactType = { id: panelImage.id, source: 'VESSEL', identy: String(vesselId), norm: 'PANEL', document: toString(e.target.result), filename: toString(event.target.files[0].name) };
                    setPanelImage(artfact);
                } else if (index === 5) {
                    let artfact: ArtifactType = { id: interiorImage.id, source: 'VESSEL', identy: String(vesselId), norm: 'INTERIOR', document: toString(e.target.result), filename: toString(event.target.files[0].name) };
                    setInteriorImage(artfact);
                } else {
                    let artfact: ArtifactType = { id: cabinImage.id, source: 'VESSEL', identy: String(vesselId), norm: 'CABIN', document: toString(e.target.result), filename: toString(event.target.files[0].name) };
                    setCabinImage(artfact);
                }
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            if (index === 0) {
                let artfact: ArtifactType = { id: prowImage.id, source: 'VESSEL', identy: String(vesselId), norm: 'PROW', document: '', filename: '' };
                setProwImage(artfact);
            } else if (index === 1) {
                let artfact: ArtifactType = { id: prowImage.id, source: 'VESSEL', identy: String(vesselId), norm: 'STERN', document: '', filename: '' };
                setSternImage(artfact);
            } else if (index === 2) {
                let artfact: ArtifactType = { id: prowImage.id, source: 'VESSEL', identy: String(vesselId), norm: 'PORTBOARD', document: '', filename: '' };
                setPortboardImage(artfact);
            } else if (index === 3) {
                let artfact: ArtifactType = { id: prowImage.id, source: 'VESSEL', identy: String(vesselId), norm: 'STARBOARD', document: '', filename: '' };
                setStarboardImage(artfact);
            } else if (index === 4) {
                let artfact: ArtifactType = { id: prowImage.id, source: 'VESSEL', identy: String(vesselId), norm: 'PANEL', document: '', filename: '' };
                setPanelImage(artfact);
            } else if (index === 5) {
                let artfact: ArtifactType = { id: prowImage.id, source: 'VESSEL', identy: String(vesselId), norm: 'INTERIOR', document: '', filename: '' };
                setInteriorImage(artfact);
            } else {
                let artfact: ArtifactType = { id: prowImage.id, source: 'VESSEL', identy: String(vesselId), norm: 'CABIN', document: '', filename: '' };
                setCabinImage(artfact);
            }
        }
    }

    async function clickSchedule(event: any) {
        event.preventDefault();

        if (isEmpty(scheduleTitle) || isEmpty(scheduleComments)) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, display.message.invalid.required);
        } else {
            const dataSchedule = {
                id : 0,
                partnerId : props.partner.id,
                prohibited : removeFormatDate(scheduleProhibited),
                departure : removeFormatDate(scheduleProhibited),
                title : display.label[toLowerCase(getScheduleTitleType(scheduleTitle).name)],
                color,
                sourceLink : 'Vessel',
                numberLink : vesselId, 
                comments: scheduleComments,
                created : removeFormatDate(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss')),
                updated : removeFormatDate(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'))
            }

            try {
                await srvService.createSchedule(dataSchedule);
                await alertContext.show(AlertMessageEnum.SUCCESS, props.title, display.message.the_record_has_been_saved_successfully);
            } catch (error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
            }
        }
    }

    async function validIdentifyKey(event: any) {
        event.preventDefault();

        try {
            //const exist = await lclService.existIdentifyKey(identifyKey);
            const attributes = [] as any;
            const where = { 
                partnerId : props.partner.id,
                identifyKey
            } as any;
            const order = [] as any;

            const vessels = await lclService.vesselFilter(attributes, where, order);
            if (vessels && (vessels.length > 0) && (vessels[0].id !== vesselId)) {
                await alertContext.show(AlertMessageEnum.FAIL, props.title, `A Chave ID '${identifyKey}' informada já cadastrada, informe outra !`);
                setIdentifyKey('');
            }
        } catch (error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
        }
    }

    /* FUNCION EXCLUVISE OF FORM - END */

    function viewCadastreForm() {
        return (
            <Container fluid className="page-body vessel">
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card>
                            <Card.Header>
                                {(originRecord.created || originRecord.updated) && (
                                    <div className="form-identy-registry float-right">
                                        <span className="white-space"><b>Criação: </b> {dateToString(originRecord.created, 'dd/mm/yyyy HH:MM:ss')}</span>
                                        <span className="white-space"><b>Atualização: </b> {dateToString(originRecord.updated, 'dd/mm/yyyy HH:MM:ss')}</span>
                                    </div>
                                )}
                                <Card.Title>{display.legend.record}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form className="form" id="form-cadastre-vessel" validated={invalidatedForm}>
                                    <VesselRecord
                                        translate={props.translate}
                                        title={props.title}
                                        isEdit={isEditForm}
                                        columnsResponsible={columnsResponsible}
                                        columnsConductor={columnsConductor}

                                        id={vesselId}
                                        // Dados Gerais
                                        indAlert={indAlert}
                                        indRestriction={indRestriction}
                                        identifyKey={identifyKey}
                                        name={name}
                                        year={year}
                                        typeVessel={typeVessel}
                                        hasTrailer={hasTrailer}
                                        typeTrailer={typeTrailer}
                                        plateTrailer={plateTrailer}
                                        classification={classification}
                                        harborState={harborState}
                                        harbor={harbor}
                                        enrollment={enrollment}
                                        manufacturer={manufacturer}
                                        color={color}
                                        brand={brand}
                                        model={model}
                                        hullMaterial={hullMaterial}
                                        quantityPeople={quantityPeople}
                                        potency={potency}
                                        vesselSize={typeVessel === '33' ? 10 : vesselSize}
                                        numberTie={numberTie}
                                        validityTie={validityTie}
                                        validityTr={validityTr}
                                        // Motor
                                        engines={engines}
                                        // Dados do Seguro
                                        insuranceName={insuranceName}
                                        insuranceNumber={insuranceNumber}
                                        insuranceCost={insuranceCost}
                                        insuranceContact={insuranceContact}
                                        insuranceValidity={insuranceValidity}
                                        // Valor da Embarcação
                                        vesselCost={vesselCost}
                                        hullCost={hullCost}
                                        engineCost={engineCost}
                                        generatorsCost={generatorsCost}
                                        soundsystemCost={soundsystemCost}
                                        accessoriesCost={accessoriesCost}
                                        othersCost={othersCost}
                                        sailsAndMastsCost={sailsAndMastsCost}
                                        // Rastreador
                                        trackerBrand={trackerBrand}
                                        trackerModel={trackerModel}
                                        trackerSerial={trackerSerial}
                                        trackerActive={trackerActive}
                                        trackerResponsible={trackerResponsible}
                                        // Proprietario e Autorizações
                                        ownerType={ownerType}
                                        ownerId={ownerId}
                                        ownerName={ownerName}
                                        ownerRegistry={ownerRegistry}
                                        //Documentos
                                        vesselDocument={vesselDocument}
                                        insurancePolicyDocument={insurancePolicyDocument}
                                        driversLicenseDocument={driversLicenseDocument}

                                        scheduleProhibited={scheduleProhibited}
                                        scheduleTitle={scheduleTitle}
                                        scheduleComments={scheduleComments}
                                        schedules={schedules}

                                        responsibles={responsibles}
                                        conductors={conductors}
                                        equipments={equipments}
                                        keep={keep}
                                        // Dados Gerais
                                        setIndAlert={(value: any) => { setIndAlert(value); }}
                                        setIndRestriction={(value: any) => { setIndRestriction(value); }}
                                        setIdentifyKey={(value: any) => { setIdentifyKey(value); }}
                                        setName={(value: any) => { setName(value); }}
                                        setYear={(value: any) => { setYear(value); }}
                                        setTypeVessel={(value: any) => { setTypeVessel(value); }}
                                        setHasTrailer={(value: any) => { setHasTrailer(value); }}
                                        setTypeTrailer={(value: any) => { setTypeTrailer(value); }}
                                        setPlateTrailer={(value: any) => { setPlateTrailer(value); }}
                                        setClassification={(value: any) => { setClassification(value); }}
                                        setHarborState={(value: any) => { setHarborState(value); }}
                                        setHarbor={(value: any) => { setHarbor(value); }}
                                        setEnrollment={(value: any) => { setEnrollment(value); }}
                                        setManufacturer={(value: any) => { setManufacturer(value); }}
                                        setColor={(value: any) => { setColor(value); }}
                                        setBrand={(value: any) => { setBrand(value); }}
                                        setModel={(value: any) => { setModel(value); }}
                                        setHullMaterial={(value: any) => { setHullMaterial(value); }}
                                        setQuantityPeople={(value: any) => { setQuantityPeople(value); }}
                                        setPotency={(value: any) => { setPotency(value); }}
                                        setVesselSize={(value: any) => { setVesselSize(value); }}
                                        setNumberTie={(value: any) => { setNumberTie(value); }}
                                        setValidityTie={(value: any) => { setValidityTie(value); }}
                                        setValidityTr={(value: any) => { setValidityTr(value); }}
                                        // Motor
                                        setEngines={(value: any) => { setEngines(value); }}
                                        // Dados do Seguro
                                        setInsuranceName={(value: any) => { setInsuranceName(value); }}
                                        setInsuranceNumber={(value: any) => { setInsuranceNumber(value); }}
                                        setInsuranceCost={(value: any) => { setInsuranceCost(value); }}
                                        setInsuranceContact={(value: any) => { setInsuranceContact(value); }}
                                        setInsuranceValidity={(value: any) => { setInsuranceValidity(value); }}
                                        // Valor da Embarcação
                                        setVesselCost={(value: any) => { setVesselCost(value); }}
                                        setHullCost={(value: any) => { setHullCost(value); }}
                                        setEngineCost={(value: any) => { setEngineCost(value); }}
                                        setGeneratorsCost={(value: any) => { setGeneratorsCost(value); }}
                                        setSoundsystemCost={(value: any) => { setSoundsystemCost(value); }}
                                        setAccessoriesCost={(value: any) => { setAccessoriesCost(value); }}
                                        setOthersCost={(value: any) => { setOthersCost(value); }}
                                        setSailsAndMastsCost={(value: any) => { setSailsAndMastsCost(value); }}
                                        // Rastreador
                                        setTrackerBrand={(value: any) => { setTrackerBrand(value); }}
                                        setTrackerModel={(value: any) => { setTrackerModel(value); }}
                                        setTrackerSerial={(value: any) => { setTrackerSerial(value); }}
                                        setTrackerActive={(value: any) => { setTrackerActive(value); }}
                                        setTrackerResponsible={(value: any) => { setTrackerResponsible(value); }}
                                        // Proprietario e Autorizações
                                        setOwnerType={(value: any) => { setOwnerType(value); }}
                                        setOwnerId={(value: any) => { setOwnerId(value); }}
                                        setOwnerName={(value: any) => { setOwnerName(value); }}
                                        setOwnerRegistry={(value: any) => { setOwnerRegistry(value); }}

                                        changeVesselDocument={(event: any) => { changeVesselDocument(event); }}
                                        changeInsurancePolicyDocument={(event: any) => { changeInsurancePolicyDocument(event); }}
                                        changeDriversLicenseDocument={(event: any) => { changeDriversLicenseDocument(event); }}

                                        addEngine={(event: any) => { addEngine(event); }}
                                        updateEngineItem={(position: number, field: string, item: string) => { updateEngineItem(position, field, item); }}
                                        removeEngineItem={(event: any, index: number) => { removeEngineItem(event, index); }}
                                        changeImageEngineItem={(event: any, index: number) => { changeImageEngineItem(event, index); }}

                                        searchOwner={(event: any) => { searchOwner(event); }}
                                        addResponsible={(event: any) => { addResponsible(event); }}
                                        addConductor={(event: any) => { addConductor(event); }}
                                        addEquipment={(event: any) => { addEquipment(event); }}
                                        sumVesselCost={(event: any) => { sumVesselCost(event); }}
                                        updateEquipmentItem={(position: number, field: string, item: string) => { updateEquipmentItem(position, field, item); }}
                                        updateEquipmentValue={(event: any) => { updateEquipmentValue(event); }}
                                        removeEquipmentItem={(event: any, index: number) => { removeEquipmentItem(event, index); }}
                                        openTracking={(event: any) => { openTracking(event); }}

                                        setScheduleProhibited={(event: any) => { setScheduleProhibited(event); }}
                                        setScheduleTitle={(event: any) => { setScheduleTitle(event); }}
                                        setScheduleComments={(event: any) => { setScheduleComments(event); }}

                                        image={image}
                                        changeImage={(value: any) => { changeImage(value); }}

                                        clickSchedule={(event: any) => { clickSchedule(event); }}

                                        prowImage={prowImage}
                                        sternImage={sternImage}
                                        portboardImage={portboardImage}
                                        starboardImage={starboardImage}
                                        panelImage={panelImage}
                                        interiorImage={interiorImage}
                                        cabinImage={cabinImage}
                                        changeImageVesselItem={(event: any, norm: string, index: number) => { changeImageVesselItem(event, norm, index); }}
                                        validIdentifyKey={(event: any) => { validIdentifyKey(event); }}
                                    />
                                </Form>
                            </Card.Body>
                            <Card.Footer className="text-right">
                                <Button className="me-2" onClick={ (e) => onClick_CancelVessel(e) } variant="contained" color="secondary">{display.buttom.cancel}</Button>
                                <Button onClick={ (e) => onClick_SaveVessel(e) } disabled={sending} variant="contained" color="primary">
                                    { sending ? <Spinner className="me-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSave className="me-2" size={22} /> } {' '}
                                    {display.buttom.save}
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <>
            { viewCadastreForm() }
            <VesselConductorCadastreModal 
                translate={props.translate} 
                isEdit={isEditConductor}

                show={showConductor}
                vessel={originRecord}
                conductorId={conductorId}
                record={conductor}
                onSubmitModal={async (event: any, record: any) => {
                    event.preventDefault();

                    setConductor(record);
                    if (isEditConductor) {
                        const updatedConductors = Array.from(conductors) as any;
                        for ( var idx_Edit = 0; idx_Edit < updatedConductors.length; idx_Edit++) {
                            if (updatedConductors[idx_Edit].id === record.id) {
                                updatedConductors[idx_Edit] = record;
                            }
                        }
                        setConductors(updatedConductors);
                    } else {
                        let existConductor = false;

                        for ( var idx_New = 0; idx_New < conductors.length; idx_New++) {
                            const conductor = conductors[idx_New];
                            if (((record.people.id === 0) && 
                                    (conductor.people.registrationPf === record.people.registrationPf)) || 
                                    (conductor.people.id === record.people.id)
                                ) {
                                existConductor = true;
                                await alertContext.show(AlertMessageEnum.FAIL, props.title, display.message.the_informed_record_is_already_linked);
                                break;
                            }
                        }
                        if (!existConductor)
                            setConductors([...conductors, record]);
                    }
                    setShowConductor(false);
                    setEditConductor(false);
                }}
                onCancelModal={(event: any) => {
                    event.preventDefault();

                    setEditConductor(false);
                    setShowConductor(false);
                }}
            />
            <VesselResponsibleCadastreModal 
                translate={props.translate} 
                isEdit={isEditResponsible}

                show={showResponsible}
                vessel={originRecord}
                responsibleId={responsibleId}
                record={responsible}
                onSubmitModal={async (event: any, record: any) => {
                    event.preventDefault();

                    setResponsible(record);
                    if (isEditResponsible) {
                        const updatedResponsibles = Array.from(responsibles) as any;
                        for ( var idx_Edit = 0; idx_Edit < updatedResponsibles.length; idx_Edit++) {
                            if (updatedResponsibles[idx_Edit].id === record.id) {
                                updatedResponsibles[idx_Edit] = record;
                            }
                        }
                        setResponsibles(updatedResponsibles);
                    } else {
                        let existResponsible = false;

                        for ( var idx_New = 0; idx_New < responsibles.length; idx_New++) {
                            const responsible = responsibles[idx_New];
                            if ((responsible.ownerType === record.ownerType) && (responsible.ownerId === record.ownerId)) {
                                existResponsible = true;
                                await alertContext.show(AlertMessageEnum.FAIL, props.title, display.message.the_informed_record_is_already_linked);
                                break;
                            }
                        }
                        if (!existResponsible)
                            setResponsibles([...responsibles, record]);
                    }
                    setShowResponsible(false);
                    setEditResponsible(false);

                    try {
                        const attributes = [] as any;;
                        const where = {
                            vesselId : record.vesselId,
                            peopleId : record.ownerId
                        } as any;
                        const order = [] as any;
                        const existConductors = await lclService.vesselConductorFilter(attributes, where, order);
                        if (existConductors.length === 0) {
                            const isConfirmed = await confirmContext.show(props.title, 'Proprietário é um condutor ?');
                            if (isConfirmed) {
                                const conductor = {
                                    id : 0,
                                    vesselId : record.vesselId,
                                    peopleId : record.ownerId,
                                    people : record.owner
                                }
                                const returned = await lclService.createVesselConductor(conductor);
                                conductor.id = returned.id;
                                setConductors([...conductors, conductor]);
                            }
                        }
                    } catch (error: any) {
                        await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
                    }
                }}
                onCancelModal={(event: any) => {
                    event.preventDefault();

                    setEditResponsible(false);
                    setShowResponsible(false);
                }}
            />
        </>
    );
    
}

export default VesselCadastreForm;
