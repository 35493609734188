import React, { useEffect, useState } from 'react'
import { Card, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import SearchIcon from '@mui/icons-material/Search'
import { FormControl, FormHelperText, Input, InputAdornment, InputLabel, LinearProgress } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import OperationHeader from '../headers/operation.header'
import { DataGrid, GridColDef, GridSelectionModel, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid'
import theme from '../../theme'
import styles from './contract-customer.financial.module.scss'
import lclService from '../../services/lcl.service'
import { dateToString, floatToString, leftPad, now, toDate, toFloat, toInt, toLowerCase, toStringFloat } from '../../utilities/auxiliary-functions'
import { cnpjMask, floatMask, phoneMask, zipcodeMask } from '../../utilities/masks'
import { isEmpty } from '../../utilities/validators'
import { FaBarcode, FaMoneyBillAlt } from 'react-icons/fa'
import { MdLaunch } from 'react-icons/md'
import { BsReceipt } from 'react-icons/bs'
import { BILLET_MODE_EDIT, BILLET_MODE_NONE, BILLET_MODE_VIEW } from '../../utilities/constants'
import PaymentCadastreModal from '../modals/payment-cadastre.modal'
import LauncherCadastreModal from '../modals/launcher.cadastre.modal'
import ContractReceiptReport, { TContractReceiptReport } from '../reports/contract-receipt.report'
import DetachedReceiptReport, { TDetachedReceiptReport } from '../reports/detached-receipt.report'
import cduService from '../../services/cdu.service'

interface Props {
    translate: any
    partner: any
    onClick_ShowDefinedPartner: any
}

function ContractCustomerFinancial(props: Props) {
    
    const display = props.translate.data;

    const title = display.title.contract_customer;
    const description = display.description.contract_customer;

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);

    const [ selectionModel, setSelectionModel ] = useState<GridSelectionModel>([]);
    const [ pageSize, setPageSize ] = React.useState(10);
    const [ records, setRecords ] = useState([] as any);

    const [ textSearch, setTextSearch ] = useState('');

    const [ sumDelay, setSumDelay ] = useState(0);
    const [ sumPaid, setSumPaid ] = useState(0);
    const [ sumParcel, setSumParcel ] = useState(0);
    const [ sumWaiting, setSumWaiting ] = useState(0);

    const [ columns, setColumns ] = useState([] as any);
    const monthSimpleNames = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    
    const [ launcherId, setLauncherId ] = useState('');
    const [ launcher, setLauncher ] = useState({} as any);
    const [ isEditLauncher, setEditLauncher ] = useState(0);
    const [ showLauncher, setShowLauncher ] = useState(false);
    const [ showPayment, setShowPayment ] = useState(false);

    const searchfilter = async () => {
        setLoading(true);
        const attributes = [] as any;
        const where = {} as any;
        const order = [] as any;

        /* Sempre incluir o partner na pesquisa */
        where.partnerId = props.partner.id;

        if (!isEmpty(textSearch)) {
            columns.forEach((column: any) => {
                where[column.field] = { like : '%' + textSearch +'%' }
            });
        }
        const contracts = await lclService.contractsCustomer(attributes, where, order);

        let delay = 0;
        let paid = 0;
        let parcel = 0;
        let waiting = 0;

        const sourceData: Array<any> = [];
        //const owners = Array(...new Set(contracts.map((row: any) => row.ownerId)));
        const owners: Array<any> = [];
        contracts.forEach((contract: any) => {
            if (owners.indexOf(contract.ownerId) === -1)
                owners.push(contract.ownerId);
        });
        const monthRefs = Array(...new Set(contracts.map((row: any) => row.monthRef)));
        monthRefs.sort();

        owners.forEach((owner: any) => {
            let ownerData = contracts.filter((contract: any) => contract.ownerId === owner);
            let sourceRow = { 
                id: ownerData[0].id, 
                contractKey: ownerData[0].contractKey, 
                ownerType: ownerData[0].ownerType, 
                ownerId: ownerData[0].ownerId, 
                ownerName: ownerData[0].ownerName, 
                ownerRegistration: ownerData[0].ownerRegistration
            } as any;

            monthRefs.forEach((monthRef: any) => {
                let data = ownerData.find((owner: any) => owner.monthRef === monthRef);
                if (data) {
                    parcel += toFloat(data.amountParcel);
                    if (data.payment) {
                        paid += toFloat(data.amount);
                    } else {
                        if (toDate(data.expiry, 'dd/mm/yyyy') > now())
                            delay += toFloat(data.amountParcel);
                        else
                            waiting += toFloat(data.amountParcel);
                    }
                }
                sourceRow[monthRef] = data ? data : {};
            });
            sourceData.push(sourceRow);
        })

        setSumDelay(delay);
        setSumPaid(paid);
        setSumParcel(parcel);
        setSumWaiting(waiting);

        const headerCol: GridColDef[] = [
            { field: 'contractKey', headerName: 'Contrato ID', maxWidth: 110, align: 'center', headerAlign: 'center' },
            {
                field: 'ownerName',
                headerName: 'Cliente',
                minWidth: 150,
                flex: 1,
                align: 'center',
                headerAlign: 'center'
            },{
                field: 'ownerRegistration',
                headerName: 'CPF',
                minWidth: 100,
                flex: 1,
                align: 'center',
                headerAlign: 'center'
            }
        ];
        monthRefs.forEach((monthRef: any) => {
            headerCol.push({
                field: monthRef,
                headerName: monthSimpleNames[toInt(monthRef.substring(4, 6)) - 1],
                flex: 1,
                align: 'right',
                headerAlign: 'center',
                renderCell: getRenderLauncher
            })
        });
        setColumns(headerCol);


        setRecords(sourceData);
        setLoading(false);
    }
    
    function onClick_PaymentLauncher(event: any, launcher: any) {
        event.preventDefault();

        setLauncherId(launcher.id);
        setLauncher(launcher);
        setShowPayment(true);
    }

    function onClick_BilletLauncher(event: any, launcher: any, modo: number) {
        event.preventDefault();
        
        setEditLauncher(modo);
        setLauncherId(launcher.id);
        setLauncher(launcher);
        setShowLauncher(true);
    } // onClick_EditRecord

    async function onClick_ReceiptLauncher(event: any, launcherRow: any) {
        event.preventDefault();

        let launcher = await lclService.launcherById(launcherRow.id);
        const address = await cduService.addressById(props.partner.company.addressId);
        if (launcher.sourceDocument === "CTT") {
            const contract = await lclService.contractById(Number(launcher.numberDocument));

            let receipt: TContractReceiptReport = {
                company: {
                    image: props.partner.company.image,
                    socialReason: props.partner.company.socialReason,
                    fantasy: props.partner.company.fantasy,
                    registrationPj: cnpjMask(props.partner.company.registrationPj),
                    telephone: phoneMask(props.partner.company.telephone),
                    email: props.partner.company.email,
                    address: `${address.place} - ${address.district} - ${address.city} - ${address.state} - ${zipcodeMask(address.zipcode)}`
                },
                paying: launcher.ownerName,
                amount: floatToString(launcher.amount),
                numParcel: launcher.numParcel,
                qtyParcels: launcher.qtyParcels,
                payday: dateToString(new Date(), 'dd/mm/yyyy'),
                numberDocument: launcher.sourceDocument + leftPad(launcher.numberDocument, 5, '0'),
                form: display.label[toLowerCase(launcher.paymentForm)],
                vessels: contract?.vessels.map((contractVessel: any) => {
                    return {
                        enrollment: contractVessel.vessel.enrollment,
                        name: contractVessel.vessel.name
                    }
                })
            };
            ContractReceiptReport(receipt);
        } else {
            let receipt: TDetachedReceiptReport = {
                company: {
                    image: props.partner.company.image,
                    socialReason: props.partner.company.socialReason,
                    fantasy: props.partner.company.fantasy,
                    registrationPj: cnpjMask(props.partner.company.registrationPj),
                    telephone: phoneMask(props.partner.company.telephone),
                    email: props.partner.company.email,
                    address: `${address.place} - ${address.district} - ${address.city} - ${address.state} - ${zipcodeMask(address.zipcode)}`
                },
                paying: launcher.ownerName,
                amount: floatToString(launcher.amount),
                payday: dateToString(new Date(), 'dd/mm/yyyy'),
                numberDocument: launcher.sourceDocument + leftPad(launcher.numberDocument, 5, '0'),
                descriptive : launcher?.descriptive
            };
            DetachedReceiptReport(receipt);
        }
    }

    
  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    )
  }

    function getRenderLauncher(param: any) {
        const monthly = param.row[param.field];

        return (
            <div className={`box-monthly monthly-gc${ toInt(monthly.numParcel) === 0 ? '-none' : 
                monthly.payment ? '-pay' : 
                toDate(monthly.expiry, 'dd/mm/yyyy') > now() ? '-late' : 
                monthly.status ? '-'+ monthly.status : '' }`}
            >
                <span className={`box-monthly-data`}>
                    <div>{monthly.numParcel}</div>
                    <div className={`monthly-gc-value`}>{floatMask(monthly.amountParcel)}</div>
                </span>
                <span className={`box-monthly-link`}>
                    { !isEmpty(monthly) ?
                        <>
                        { isEmpty(monthly.paymentForm) ? 
                            <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.make_payment}</Tooltip>}>
                                <span onClick={(e) => onClick_PaymentLauncher(e, monthly)}>
                                    <FaMoneyBillAlt size={16} />
                                </span>
                            </OverlayTrigger>
                        : 
                            <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.view_receipt}</Tooltip>}>
                                <span onClick={(e) => onClick_ReceiptLauncher(e, monthly)}>
                                    <BsReceipt size={16} />
                                </span>
                            </OverlayTrigger>
                        }
                        { isEmpty(monthly.paymentForm) ? !isEmpty(monthly.paymentCode) ? 
                            <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.track_status}</Tooltip>}>
                                <span onClick={(e) => onClick_BilletLauncher(e, monthly, BILLET_MODE_VIEW)}>
                                    <FaBarcode size={16} />
                                </span>
                            </OverlayTrigger>
                        : 
                            <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.generate_ticket}</Tooltip>}>
                                <span onClick={(e) => onClick_BilletLauncher(e, monthly, BILLET_MODE_EDIT)}>
                                    <MdLaunch size={16} />
                                </span>
                            </OverlayTrigger>
                        : null }
                        </>
                    : null }
                </span>
            </div>
        );
    }

    function onClick_Search(event: any) {
        event.preventDefault();
        searchfilter();
    }

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            searchfilter()
        }
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage
        }
    }, []) // useEffect

    function viewPage() {
        return (
            <Container fluid className={`page-body contracts ${styles.contracts}`} style={{ height: '100%' }}>
                <Row style={{ height: '100%' }}>
                    <Col md={12} className="page-sweet">
                        <Card style={{ height: '100%' }}>
                            <Card.Header>
                                <div className="card-actions float-right d-flex justify-content-end">
                                    <div className="card-actions-time" />
                                </div>
                                <Card.Title>Clientes</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <FormControl fullWidth sx={{width: 350 }} variant="standard">
                                        <InputLabel htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                                        <Input id="standard-adornment-amount"
                                            value={textSearch}
                                            onChange={(e) => setTextSearch(e.target.value)}
                                            endAdornment = {
                                                <InputAdornment position="end">
                                                    <SearchIcon className='isClickable' onClick={(e) => onClick_Search(e)} />
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText id="component-helper-text">
                                            Número do contrato, nome do cliente ou nome da embarcação
                                        </FormHelperText>
                                    </FormControl>
                                    <section className='d-flex'>
                                        <div className='me-5'>
                                            <div className='d-flex flex-column mb-2'>
                                                <h6>Total previsto</h6>
                                                <h5>R$ {toStringFloat(sumParcel)}</h5>
                                            </div>
                                            <div className='d-flex flex-column'>
                                                <h6>Em atraso</h6>
                                                <h5>R$ {toStringFloat(sumDelay)}</h5>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='d-flex flex-column mb-2'>
                                                <h6>Pago</h6>
                                                <h5>R$ {toStringFloat(sumPaid)}</h5>
                                            </div>
                                            <div className='d-flex flex-column'>
                                                <h6>À vencer</h6>
                                                <h5>R$ {toStringFloat(sumWaiting)}</h5>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <ThemeProvider theme={theme}>
                                    <div style={{ height: '85%', width: '100%', marginTop: 0 }}>
                                        <DataGrid
                                            rows={records}
                                            density="comfortable"
                                            rowCount={records.length}
                                            getRowId={row => row.id}
                                            loading={isLoading}
                                            columns={columns}
                                            pageSize={pageSize}
                                            onPageSizeChange={newPage => setPageSize(newPage)}
                                            rowsPerPageOptions={[10 , 25, 50, 75, 100]}
                                            disableSelectionOnClick
                                            onSelectionModelChange={(newSelectionModel: any) => {
                                                setSelectionModel(newSelectionModel)
                                            }}
                                            components={{
                                                Toolbar: CustomToolbar,
                                                LoadingOverlay: LinearProgress
                                            }}
                                            selectionModel={selectionModel}
                                        />
                                    </div>
                                </ThemeProvider>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }

    const [ itemsToast, setItemsToast ] = useState([] as any);

    return (
        <div className="page" style={{ height: '100%' }}>
            <OperationHeader
                title={title}
                description={description}
                onClick_ShowDefinedPartner={props.onClick_ShowDefinedPartner}
            />
            {viewPage()}
            <LauncherCadastreModal 
                translate={props.translate} 
                itemsToast={itemsToast}
                setItemsToast={(items: any) => {
                    setItemsToast(items);
                }}
                partner={props.partner}

                isEditMode={isEditLauncher}

                show={showLauncher}
                launcherId={launcherId}
                record={launcher}
                onSubmitModal={async (event: any, record: any) => {
                    event.preventDefault();
/*
                    setLauncher(record);
                    if (isEditLauncher) {
                        const updatedLaunchers = Array.from(records) as any;
                        for ( var idx_Edit = 0; idx_Edit < updatedLaunchers.length; idx_Edit++) {
                            if (updatedLaunchers[idx_Edit].id === record.id) {
                                updatedLaunchers[idx_Edit] = record;
                            }
                        }
                        setRecords(updatedLaunchers);
                    } else {
                        let existLauncher = false;

                        for ( var idx_New = 0; idx_New < records.length; idx_New++) {
                            const launcher = records[idx_New];
                            if (launcher.id === record.id) {
                                existLauncher = true;
                                await alertContext.show(AlertMessageEnum.FAIL, title, display.message.the_informed_record_is_already_linked);
                                break;
                            }
                        }
                        if (!existLauncher)
                            setRecords([...records, record]);
                    }
*/
                    setShowLauncher(false);
                    setEditLauncher(BILLET_MODE_NONE);
                    searchfilter();
                }}
                onCancelModal={(event: any) => {
                    // event.preventDefault();

                    setEditLauncher(BILLET_MODE_NONE);
                    setShowLauncher(false);
                }}
            />
            <PaymentCadastreModal 
                translate={props.translate} 
                partner={props.partner}

                show={showPayment}
                launcherId={launcherId}
                record={launcher}
                onSubmitModal={(event: any, record: any) => {
                    event.preventDefault();

                    setShowPayment(false);
                    searchfilter();
                }}
                onCancelModal={(event: any) => {
                    // event.preventDefault();

                    setShowPayment(false);
                }}
            />
        </div>
    )
}

export default ContractCustomerFinancial
