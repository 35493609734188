import { FaBroadcastTower, FaComments, FaFax, FaHeadset, FaHouseUser, FaMobile, FaPager, FaPhone } from "react-icons/fa";
import { MdBusinessCenter, MdLooks3, MdLooks4, MdLooksOne, MdLooksTwo, MdRadio } from "react-icons/md";
import { toInt } from "./auxiliary-functions";

export enum AlertMessageEnum {
    FAIL = 0,
    WARN = 1,
    SUCCESS = 2,
    QUESTION = 3
}

export const StateType = [
    { id : 'AC', description : 'Acre' },
    { id : 'AL', description : 'Alagoas' },
    { id : 'AP', description : 'Amapá' },
    { id : 'AM', description : 'Amazonas' },
    { id : 'BA', description : 'Bahia' },
    { id : 'CE', description : 'Ceará' },
    { id : 'ES', description : 'Espírito Santo' },
    { id : 'GO', description : 'Goiás' },
    { id : 'MA', description : 'Maranhão' },
    { id : 'MT', description : 'Mato Grosso' },
    { id : 'MS', description : 'Mato Grosso do Sul' },
    { id : 'MG', description : 'Minas Gerais' },
    { id : 'PA', description : 'Pará' },
    { id : 'PB', description : 'Paraíba' },
    { id : 'PR', description : 'Paraná' },
    { id : 'PE', description : 'Pernambuco' },
    { id : 'PI', description : 'Piauí' },
    { id : 'RJ', description : 'Rio de Janeiro' },
    { id : 'RN', description : 'Rio Grande do Norte' },
    { id : 'RS', description : 'Rio Grande do Sul' },
    { id : 'RO', description : 'Rondônia' },
    { id : 'RR', description : 'Roraima' },
    { id : 'SC', description : 'Santa Catarina' },
    { id : 'SP', description : 'São Paulo' },
    { id : 'SE', description : 'Sergipe' },
    { id : 'TO', description : 'Tocantins' }
]; // StateType

export function getState(key: string): any {
    let result = { id : '', name : '', description : '' };
    StateType.forEach((element: any) => {
        if ((element.id === key) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getState

export const GenderType = [
    { id : "M", name : 'MALE', description : "Male" },
    { id : "F", name : 'FEMININE', description : "Feminine" },
    { id : "N", name : 'BETTER_NOT_INFORM', description : "better_not_inform" },
    { id : "O", name : 'ANOTHER_TO_WICH_I_IDENTIFY', description : "another_to_wich_i_identify" }
]; // GenderType

export const NationalityType = [
    { id : 1, name : 'BRASILEIRA', description : "Brasileiro(a)" },
    { id : 2, name : 'ESTRANGEIRA', description : "Estrangeiro(a)" }
]; // NationalityType

export function getGender(key: string): any {
    let result = { id : '', name : '', description : '' };
    GenderType.forEach((element: any) => {
        if ((element.id === key) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getGender

export const PersonType = [
    { id : "PF", name : "Pessoa Fisica" },
    { id : "PJ", name : "Pessoa Juridica" }
]; // PersonType

export function getPerson(key: string): any {
    let result = { id : '', name : '', description : '' };
    PersonType.forEach((element: any) => {
        if ((element.id === key) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getPerson

export const UserProfileType = [
    { id : 0, name : 'MASTER', description : 'With absolute powers (environment settings, rules)' },
    { id : 1, name : 'ADMINISTRATOR', description : 'General administration and specific authorizations' },
    { id : 2, name : 'REGISTRATION', description : 'Responsible for registration maintenance of the application' },
    { id : 10, name : 'SUPPORT', description : 'Direct service to marinas' },
    { id : 20, name : 'COMMERCIAL', description : 'Support to marinas, ad approvals, ...' },
    { id : 30, name : 'DISTRIBUTOR', description : 'Takes care of the Partner`s interests' },
    { id : 50, name : 'ASSOCIATE', description : 'With absolute powers of the company' },
    { id : 51, name : 'ASSOCIATE_OPERATOR_FINANCIAL', description : 'Responsible for the financial area' },
    { id : 56, name : 'ASSOCIATE_OPERATOR_SERVICE', description : 'Service involving restaurant, washing' },
    { id : 61, name : 'ASSOCIATE_OPERATOR_TRACK', description : 'Responsible for the "availability and logistics" track' },
    { id : 66, name : 'ASSOCIATE_SUPERVISOR', description : 'Approvals and cancellations of services' },
    { id : 70, name : 'ASSOCIATE_MANAGER', description : 'Operational views' },
    { id : 80, name : 'PROPRIETOR', description : 'Visions and Service Authorizations of Vessels' },
    { id : 90, name : 'MONITORING', description : 'Application overviews' },
    { id : 99, name : 'ANY', description : 'Restricted access, specific features' }
]; // UserProfileType

export function getUserProfile(key: string): any {
    let result = { id : 0, name : '', description : '' };
    UserProfileType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
}  // getUserProfile

export const IssuerCpfType = [
    { id : 1 , name : '', description : 'Distrito Federal, Goiás, Mato Grosso do Sul e Tocantins' },
    { id : 2 , name : '', description : 'Pará, Amazonas, Acre, Amapá, Rondônia e Roraima' },
    { id : 3 , name : '', description : 'Ceará, Maranhão e Piauí' },
    { id : 4 , name : '', description : 'Pernambuco, Rio Grande do Norte, Paraíba e Alagoas' },
    { id : 5 , name : '', description : 'Bahia e Sergipe' },
    { id : 6 , name : '', description : 'Minas Gerais' },
    { id : 7 , name : '', description : 'Rio de Janeiro e Espírito Santo' },
    { id : 8 , name : '', description : 'São Paulo' },
    { id : 9 , name : '', description : 'Paraná e Santa Catarina' },
    { id : 0 , name : '', description : 'Rio Grande do Sul' }
]; // IssuerCpfType

export function getIssuerCpf(key: string) {
    let result = { id : 0, name : '', description : '' };
    IssuerCpfType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        } 
    });
    return result;
} // getIssuerCpf

export const VesselType = [
    { id : 33 , name : 'JETSKI' , description : 'Jetski' },
    { id : 34 , name : 'LANCHA' , description : 'Lancha' },
    { id : 35 , name : 'BARCO' , description : 'Barco' },
    { id : 36 , name : 'JETBOAT' , description : 'JetBoat' },
    { id : 37 , name : 'FLEXBOAT' , description : 'FlexBoat' },
    { id : 38 , name : 'VOADEIRA' , description : 'Voadeira' },
    { id : 39 , name : 'VELEIRO' , description : 'Veleiro' },
]; // VesselType

export function getVessel(key: string): any {
    let result = { id : -1, name : '', description : '' };

    VesselType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getVessel

export const TrailerType = [
    { id : 1 , name : 'ALUMINUM' , description : 'Aluminum' },
    { id : 2 , name : 'WOOD' , description : 'Wood' },
    { id : 3 , name : 'METAL' , description : 'Metal' }
]; // TrailerType

export function getTrailer(key: string): any {
    let result = { id : -1, name : '', description : '' };

    TrailerType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getTrailer

export const HarborType = [
    { id : 1 , dn : '1' , om : 'Agência da Capitania dos Portos em Paraty' , initials : 'AGPRTI' , uf : 'RJ', city : 'Paraty' },
    { id : 2 , dn : '1' , om : 'Agência da Capitania dos Portos em São João da Barra' , initials : 'AGSJBA' , uf : 'RJ', city : 'São João da Barra' },
    { id : 3 , dn : '1' , om : 'Capitania dos Portos de Macaé' , initials : 'CPMCAE' , uf : 'RJ', city : 'Macaé' },
    { id : 4 , dn : '1' , om : 'Capitania dos Portos do Espírito Santo' , initials : 'CPVITO' , uf : 'ES', city : 'Espírito Santo' },
    { id : 5 , dn : '1' , om : 'Capitania dos Portos do Rio de Janeiro' , initials : 'CPRIOJ' , uf : 'RJ', city : 'Rio de Janeiro' },
    { id : 6 , dn : '1' , om : 'Capitania Fluvial de Minas Gerais' , initials : 'CFMG' , uf : 'MG', city : 'Minas Gerais' },
    { id : 7 , dn : '1' , om : 'Delegacia da Capitania dos Portos em Angra dos Reis' , initials : 'DLANGR' , uf : 'RJ', city : 'Angra dos Reis' },
    { id : 8 , dn : '1' , om : 'Delegacia da Capitania dos Portos em Cabo Frio' , initials : 'DLCABO' , uf : 'RJ', city : 'Cabo Frio' },
    { id : 9 , dn : '1' , om : 'Delegacia da Capitania dos Portos em Itacuruçá' , initials : 'DLRUCA' , uf : 'RJ', city : 'Itacuruçá' },
    { id : 10 , dn : '1' , om : 'Delegacia Fluvial de Furnas' , initials : 'DLFURN' , uf : 'MG', city : 'Furnas' },
    { id : 11 , dn : '1' , om : 'Delegacia Fluvial de Pirapora' , initials : 'DLPIRA' , uf : 'MG', city : 'Pirapora' },
    { id : 12 , dn : '2' , om : 'Agência Fluvial de Bom Jesus da Lapa' , initials : 'AGBJLP' , uf : 'BA', city : 'Bom Jesus da Lapa' },
    { id : 13 , dn : '2' , om : 'Capitania dos Portos da Bahia' , initials : 'CPSALV' , uf : 'BA', city : 'Bahia' },
    { id : 14 , dn : '2' , om : 'Capitania dos Portos de Sergipe' , initials : 'CPCAJU' , uf : 'SE', city : 'Sergipe' },
    { id : 15 , dn : '2' , om : 'Capitania Fluvial de Juazeiro' , initials : 'CFJUAZ' , uf : 'BA', city : 'Juazeiro' },
    { id : 16 , dn : '2' , om : 'Delegacia da Capitania dos Portos em Ilhéus' , initials : 'DLHEUS' , uf : 'BA', city : 'Ilhéus' },
    { id : 17 , dn : '2' , om : 'Delegacia da Capitania dos Portos em Porto Seguro' , initials : 'DLSEGU' , uf : 'BA', city : 'Porto Seguro' },
    { id : 18 , dn : '3' , om : 'Agência da Capitania dos Portos em Aracati' , initials : 'AGCATI' , uf : 'CE', city : 'Aracati' },
    { id : 19 , dn : '3' , om : 'Agência da Capitania dos Portos em Areia Branca' , initials : 'AGBRAN' , uf : 'RN', city : 'Areia Branca' },
    { id : 20 , dn : '3' , om : 'Agência da Capitania dos Portos em Camocim' , initials : 'AGOCIM' , uf : 'CE', city : 'Camocim' },
    { id : 21 , dn : '3' , om : 'Agência Fluvial de Penedo' , initials : 'AGNEDO' , uf : 'AL', city : 'Penedo' },
    { id : 22 , dn : '3' , om : 'Capitania dos Portos da Paraíba' , initials : 'CPSSOA' , uf : 'PB', city : 'Paraíba' },
    { id : 23 , dn : '3' , om : 'Capitania dos Portos de Alagoas' , initials : 'CPCEIO' , uf : 'AL', city : 'Alagoas' },
    { id : 24 , dn : '3' , om : 'Capitania dos Portos de Pernambuco' , initials : 'CPCIFE' , uf : 'PE', city : 'Pernambuco' },
    { id : 25 , dn : '3' , om : 'Capitania dos Portos do Ceará' , initials : 'CPFTZA' , uf : 'CE', city : 'Ceará' },
    { id : 26 , dn : '3' , om : 'Capitania dos Portos do Rio Grande do Norte' , initials : 'CPNATL' , uf : 'RN', city : 'Rio Grande do Norte' },
    { id : 27 , dn : '4' , om : 'Agencia da Capitania dos Portos no Oiapoque' , initials : 'AGOQUE' , uf : 'AP', city : 'Oiapoque' },
    { id : 28 , dn : '4' , om : 'Agência Fluvial de Imperatriz' , initials : 'AGIMPE' , uf : 'MA', city : 'Imperatriz' },
    { id : 29 , dn : '4' , om : 'Capitania dos Portos da Amazônia Oriental' , initials : 'CPBLEM' , uf : 'PA', city : 'Amazônia Oriental' },
    { id : 30 , dn : '4' , om : 'Capitania dos Portos do Amapá' , initials : 'CPSTNA' , uf : 'AP', city : 'Amapá' },
    { id : 31 , dn : '4' , om : 'Capitania dos Portos do Maranhão' , initials : 'CPLUIZ' , uf : 'MA', city : 'Maranhão' },
    { id : 32 , dn : '4' , om : 'Capitania dos Portos do Piauí' , initials : 'CPPARN' , uf : 'PI', city : 'Piauí' },
    { id : 33 , dn : '4' , om : 'Capitania Fluvial de Santarém' , initials : 'CFAREM' , uf : 'PA', city : 'Santarém' },
    { id : 34 , dn : '5' , om : 'Agência da Capitania dos Portos em Tramandaí' , initials : 'AGNDAI' , uf : 'RS', city : 'Portos em Tramandaí' },
    { id : 35 , dn : '5' , om : 'Capitania dos Portos de Santa Catarina' , initials : 'CPFPOL' , uf : 'SC', city : 'Santa Catarina' },
    { id : 36 , dn : '5' , om : 'Capitania dos Portos do Rio Grande do Sul' , initials : 'CPGRAN' , uf : 'RS', city : 'Rio Grande do Sul' },
    { id : 37 , dn : '5' , om : 'Capitania Fluvial de Porto Alegre' , initials : 'CFALEG' , uf : 'RS', city : 'Porto Alegre' },
    { id : 38 , dn : '5' , om : 'Delegacia da Capitania dos Portos em Itajaí' , initials : 'DCPJAI' , uf : 'SC', city : 'Portos em Itajaí' },
    { id : 39 , dn : '5' , om : 'Delegacia da Capitania dos Portos em Laguna' , initials : 'DELUNA' , uf : 'SC', city : 'Portos em Laguna' },
    { id : 40 , dn : '5' , om : 'Delegacia da Capitania dos Portos em São Francisco do Sul' , initials : 'DLSFSL' , uf : 'SC', city : 'Portos em São Francisco do Sul' },
    { id : 41 , dn : '5' , om : 'Delegacia Fluvial de Uruguaiana' , initials : 'DLURUG' , uf : 'RS', city : 'Uruguaiana' },
    { id : 42 , dn : '6' , om : 'Agência Fluvial de Cáceres' , initials : 'AGCACE' , uf : 'MT', city : 'Cáceres' },
    { id : 43 , dn : '6' , om : 'Agência Fluvial de Porto Murtinho' , initials : 'AGMURT' , uf : 'MS', city : 'Porto Murtinho' },
    { id : 44 , dn : '6' , om : 'Agência Fluvial de São Felix do Araguaia' , initials : 'AGELIX' , uf : 'MT', city : 'São Felix do Araguaia' },
    { id : 45 , dn : '6' , om : 'Capitania Fluvial de Mato Grosso' , initials : 'CFMT' , uf : 'MT', city : 'Mato Grosso' },
    { id : 46 , dn : '6' , om : 'Capitania Fluvial do Pantanal' , initials : 'CPUMBA' , uf : 'MS', city : 'Pantanal' },
    { id : 47 , dn : '7' , om : 'Capitania Fluvial de Brasília' , initials : 'CPFBRA' , uf : 'DF', city : 'Brasília' },
    { id : 48 , dn : '7' , om : 'Capitania Fluvial de Goiás' , initials : 'CFGO' , uf : 'GO', city : 'Goiás' },
    { id : 49 , dn : '7' , om : 'Capitania Fluvial do Araguaia-Tocantins' , initials : 'CFATOC' , uf : 'TO', city : 'Araguaia-Tocantins' },
    { id : 50 , dn : '8' , om : 'Capitania dos Portos de São Paulo' , initials : 'CPSANT' , uf : 'SP', city : 'São Paulo' },
    { id : 51 , dn : '8' , om : 'Capitania dos Portos do Paraná' , initials : 'CPAGUA' , uf : 'PR', city : 'Paraná' },
    { id : 52 , dn : '8' , om : 'Capitania Fluvial do Rio Paraná' , initials : 'CPFOZU' , uf : 'PR', city : 'Rio Paraná' },
    { id : 53 , dn : '8' , om : 'Capitania Fluvial do Tietê-Paraná' , initials : 'CPFTPR' , uf : 'SP', city : 'Tietê-Paraná' },
    { id : 54 , dn : '8' , om : 'Delegacia da Capitania dos Portos em São Sebastião' , initials : 'DLTIAO' , uf : 'SP', city : 'Portos em São Sebastião' },
    { id : 55 , dn : '8' , om : 'Delegacia Fluvial de Guaíra' , initials : 'DLGUAI' , uf : 'PR', city : 'Guaíra' },
    { id : 56 , dn : '8' , om : 'Delegacia Fluvial de Presidente Epitácio' , initials : 'DLPREP' , uf : 'SP', city : 'Presidente Epitácio' },
    { id : 57 , dn : '9' , om : 'Agência Fluvial de Boca do Acre' , initials : 'AGACRE' , uf : 'AC', city : 'Boca do Acre' },
    { id : 58 , dn : '9' , om : 'Agência Fluvial de Caracaraí' , initials : 'AGCRAI' , uf : 'RR', city : 'Caracaraí' },
    { id : 59 , dn : '9' , om : 'Agência Fluvial de Cruzeiro do Sul' , initials : 'AGFCRS' , uf : 'AC', city : 'Cruzeiro do Sul' },
    { id : 60 , dn : '9' , om : 'Agência Fluvial de Eirunepé' , initials : 'AGNEPE' , uf : 'AM', city : 'Eirunepé' },
    { id : 61 , dn : '9' , om : 'Agência Fluvial de Guajará-Mirim' , initials : 'AgGMirim' , uf : 'RO', city : 'Guajará-Mirim' },
    { id : 62 , dn : '9' , om : 'Agência Fluvial de Humaitá' , initials : 'AGHMTA' , uf : 'AM', city : 'Humaitá' },
    { id : 63 , dn : '9' , om : 'Agência Fluvial de Itacoatiara' , initials : 'AGITAC' , uf : 'AM', city : 'Itacoatiara' },
    { id : 64 , dn : '9' , om : 'Agência Fluvial de Parintins' , initials : 'AGTINS' , uf : 'AM', city : 'Parintins' },
    { id : 65 , dn : '9' , om : 'Agência Fluvial de Tefé' , initials : 'AGTEFE' , uf : 'AM', city : 'Tefé' },
    { id : 66 , dn : '9' , om : 'Capitania Fluvial da Amazônia Ocidental' , initials : 'CPNAUS' , uf : 'AM', city : 'Amazônia Ocidental' },
    { id : 67 , dn : '9' , om : 'Capitania Fluvial de Porto Velho' , initials : 'CFPVEL' , uf : 'RO', city : 'Porto Velho' },
    { id : 68 , dn : '9' , om : 'Capitania Fluvial de Tabatinga' , initials : 'CFT' , uf : 'AM', city : 'Tabatinga' }
]; // HarborType

export function getHarbor(key: string): any {
    var result = { id : 0 , dn : '' , om : '' , initials : '' , uf : '' };
    HarborType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getHarbor

export const UserSituationType = [
    { id : 0, name : 'REGISTERED', description : 'Registered user awaiting activation' },
    { id : 1, name : 'MODIFIED', description : 'Modified token awaiting input of new password' },
    { id : 5, name : 'ACTIVED', description : 'Actived' },
    { id : 6, name : 'DISCLAIMER', description : 'Actived user, contact the administration' },
    { id : 9, name : 'INACTIVE', description : 'Inactive user, no access to the system' }
]; // UserSituationType

export function getUserSituation(key: string): any {
    let result = { id : -1, name : '', description : '' };
    UserSituationType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
}  // getUserSituation

export const PartnerSituationType = [
    { id : 0, name : 'REGISTERED', description : 'Registered partner awaiting activation' },
    { id : 5, name : 'ACTIVED', description : 'Active partner' },
    { id : 6, name : 'DISCLAIMER', description : 'Actived partner, contact the administration' },
    { id : 9, name : 'INACTIVE', description : 'Inactive partner, no access to the system' }
]; // PartnerSituationType

export function getPartnerSituation(key: string): any {
    let result = { id : -1, name : '', description : '' };
    PartnerSituationType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
}  // getPartnerSituation

export const MemberSituationType = [
    { id : 0, name : 'REGISTERED', description : 'Registered member' },
    { id : 5, name : 'ACTIVED', description : 'Active member' },
    { id : 6, name : 'DISCLAIMER', description : 'Actived member, contact the administration' },
    { id : 9, name : 'INACTIVE', description : 'Inactive member, no access to the system' }
]; // MemberSituationType

export function getMemberSituation(key: string): any {
    let result = { id : -1, name : '', description : '' };
    MemberSituationType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getMemberSituation

export const MechanicSituationType = [
    { id : 0, name : 'REGISTERED', description : 'Registered mechanic' },
    { id : 5, name : 'ACTIVED', description : 'Active mechanic' },
    { id : 6, name : 'DISCLAIMER', description : 'Actived mechanic, contact the administration' },
    { id : 9, name : 'INACTIVE', description : 'Inactive mechanic, no access to the system' }
]; // MechanicSituationType

export function getMechanicSituation(key: string): any {
    let result = { id : -1, name : '', description : '' };
    MechanicSituationType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getMechanicSituation

export const PurveyorSituationType = [
    { id : 0, name : 'REGISTERED', description : 'Registered purveyor' },
    { id : 5, name : 'ACTIVED', description : 'Active purveyor' },
    { id : 6, name : 'DISCLAIMER', description : 'Actived purveyor, contact the administration' },
    { id : 9, name : 'INACTIVE', description : 'Inactive purveyor, no access to the system' }
]; // PurveyorSituationType

export function getPurveyorSituation(key: string): any {
    let result = { id : -1, name : '', description : '' };
    PurveyorSituationType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getPurveyorSituation

export const ContractProgressType = [
    { id : 1, name : 'ELABORATION', description : 'Elaboração', icon : <MdLooksOne size={23} /> },
    { id : 2, name : 'FINANCIAL', description : 'Financeiro', icon : <MdLooksTwo size={23} /> },
    { id : 3, name : 'SIGNATURE', description : 'Assinatura', icon : <MdLooks3 size={23} /> },
    { id : 4, name : 'FINISHED', description : 'Finalizado', icon : <MdLooks4 size={23} /> }
]; // ContractProgressType

export function getContractProgress(key: string): any {
    let result = { id : 0, name : '', description : '', icon: {} as any  };
    ContractProgressType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getContractProgress

export const ContractSituationType = [
    { id : 0, name : 'NOT_DEFINED', description : 'Não definido' },
    { id : 1, name : 'INACTIVE', description : 'Inativo' },
    { id : 2, name : 'ACTIVED', description : 'Ativo' },
    { id : 3, name : 'CANCELED', description : 'Cancelado' }
]; // ContractSituationType

export function getContractSituation(key: string): any {
    let result = { id : 0, name : '', description : '' };
    ContractSituationType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getContractSituation

export const ScheduleSituationType = [
    { id : 1, name : 'ACTIVED', description : 'Ativo', color: 'transparent' }
]; // ScheduleSituationType

export function getScheduleSituation(key: string): any {
    let result = { id : 0, name : '', description : '' };
    ScheduleSituationType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getScheduleSituation

export const BudgetSituationType = [
    { id : 1, name : 'ACTIVED', description : 'Ativo', color: '' },
    { id : 5, name : 'CANCELED', description : 'Cancelado', color: '#dc3545' },
    { id : 7, name : 'MAINTENANCE', description : 'Manutenção', color: '#ffc107' },
    { id : 9, name : 'DELIVERED', description : 'Entregue', color: '#198754' }
]; // BudgetSituationType

export function getBudgetSituation(key: string): any {
    let result = { id : 0, name : '', description : '' };
    BudgetSituationType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getBudgetSituation

export const CategoriesNavalType = [
    { id : 0, name : 'ARRAIS_AMATEUR_CATEGORIES', initial : 'ARRAIS_AMATEUR_INITIALS', limits : 'LIMITS_ARRAIS_AMATEUR', description : 'Arrais Amador' },
    { id : 1, name : 'CAPTAIN_AMATEUR_CATEGORIES', initial : 'CAPTAIN_AMATEUR_INITIALS', limits : 'LIMITS_CAPTAIN_AMATEUR', description : 'Capitão Amador' },
    { id : 2, name : 'AMATEUR_MASTER_CATEGORIES', initial : 'AMATEUR_MASTER_INITIALS', limits : 'LIMITS_AMATEUR_MASTER', description : 'Mestre Amador' },
    { id : 3, name : 'MOTORBOAT_CATEGORIES', initial : 'MOTORBOAT_INITIALS', limits : 'LIMITS_MOTORBOAT', description : 'Motonauta' },
    { id : 4, name : 'SAILBOAT_CATEGORIES', initial : 'SAILBOAT_INITIALS', limits : 'LIMITS_SAILBOAT', description : 'Veleiro' }
]; // CategoriesNavalType

export function getCategoriesNaval(key: string): any {
    let result = { id : 0, name : '', description : '' };
    CategoriesNavalType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getCategoriesNaval

export const InitialsNavalType = [
    { id : 0, name : 'ARRAIS_AMATEUR_INITIALS', description : 'Arrais Amador' },
    { id : 1, name : 'CAPTAIN_AMATEUR_INITIALS', description : 'Capitão Amador' },
    { id : 2, name : 'AMATEUR_MASTER_INITIALS', description : 'Mestre Amador' },
    { id : 3, name : 'MOTORBOAT_INITIALS', description : 'Motonauta' },
    { id : 4, name : 'SAILBOAT_INITIALS', description : 'Veleiro' }
]; // InitialsNavalType

export function getInitialsNaval(key: string): any {
    let result = { id : 0, name : '', description : '' };
    InitialsNavalType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getInitialsNaval

export const LimitsNavalDescriptionType = [
    { id : 0, name : 'LIMITS_ARRAIS_AMATEUR', description : 'Driving between national and foreign ports, limit of coastline, except watercraft.' },
    { id : 1, name : 'LIMITS_CAPTAIN_AMATEUR', description : 'Driving within the limits of inland navigation, except watercraft.' },
    { id : 2, name : 'LIMITS_AMATEUR_MASTER', description : 'Driving between national and foreign ports, within the limits of coastal navigation, except watercraft.' },
    { id : 3, name : 'LIMITS_MOTORBOAT', description : 'Watercraft driving within the limits of inland navigation.' },
    { id : 4, name : 'LIMITS_SAILBOAT', description : 'Condução de embarcações a vela sem propulsão a motor, nos limites da navegação interior.' }
]; // LimitsNavalDescriptionType

export function getLimitsNavalDescription(key: string): any {
    let result = { id : 0, name : '', description : '' };
    LimitsNavalDescriptionType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getLimitsNavalDescription

export const PhonesType = [
    { id : 'A', icon : <FaHeadset size={18} />, name : 'assistant' },
    { id : 'CM', icon : <MdBusinessCenter size={18} />, name : 'commercial' },
    { id : 'CF', icon : <FaFax size={18} />, name : 'commercial_fax' },
    { id : 'C', icon : <FaPhone size={18} />, name : 'car' },
    { id : 'CP', icon : <FaPhone size={18} />, name : 'company' },
    { id : 'R', icon : <FaHouseUser size={18} />, name : 'residential' },
    { id : 'RF', icon : <FaFax size={18} />, name : 'residential_fax' },
    { id : 'C', icon : <FaMobile size={18} />, name : 'cell' },
    { id : 'P', icon : <FaPager size={18} />, name : 'pager' },
    { id : 'R', icon : <MdRadio size={18} />, name : 'radio' },
    { id : 'T', icon : <FaBroadcastTower size={18} />, name : 'satellite' },
    { id : 'O', icon : <FaComments size={18} />, name : 'other' }
]; // PhonesType

export function getPhones(key: string): any {
    let result = { id : '', name : '', description : '' };
    PhonesType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getPhones

export const PhoneType = [
    { id : 1, name : 'FIXED', description : 'Landline telephone' },
    { id : 2, name : 'CELL', description : 'Cell phone' },
]; 

export function getPhone(key: string): any {
    let result = { id : 0, name : '', description : '' };
    PhoneType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getPhone

export const StatusMovementVesselType = [
    { id : 1, name : 'GARAGE', description : 'Vessel at rest' },
    { id : 2, name : 'CHECK', description : 'vessel check' },
    { id : 3, name : 'START_ACTIVITY', description : 'Start activity' },
    { id : 4, name : 'WATER', description : 'Vessel in the water' },
    { id : 5, name : 'NAVIGATING', description : 'Vessel sailing' },
    { id : 6, name : 'DISEMBARKATION', description : 'Vessel awaiting disembarkation' },
    { id : 7, name : 'OVERDUE', description : 'delayed shipment' },
    { id : 8, name : 'GATHERING', description : 'Collecting vessel' },
    { id : 9, name : 'END_ACTIVITY', description : 'End activity' }
]; // StatusMovementVesselType

export function getStatusMovementVessel(key: string): any {
    let result = { id : 0, name : '', description : '' };
    StatusMovementVesselType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getStatusMovementVessel

export const CategoriesDriversLicenseType = [
    { id : 0, name : 'A', description : 'A' },
    { id : 1, name : 'B', description : 'B' },
    { id : 2, name : 'C', description : 'C' },
    { id : 3, name : 'D', description : 'D' },
    { id : 4, name : 'E', description : 'E' },

]; // CategoriesDriversLicenseType

export function getCategoriesDriversLicense(key: string): any {
    let result = { id : 0, name : '', description : '' };
    CategoriesDriversLicenseType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getCategoriesDriversLicense

export const EmployeeType = [
    { id : 0, name : 'CLT', description : 'Clt' },
    { id : 1, name : 'TEMPORARY', description : 'Temporary' },
    { id : 2, name : 'INTERN', description : 'Intern' },
    { id : 3, name : 'APPRENTICE', description : 'Apprentice' },
]; // EmployeeType

export function getEmployee(key: string): any {
    let result = { id : 0, name : '', description : '' };
    EmployeeType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getEmployee

export const DescriptionLauncherType = [
    { id : 0, name : 'SINGLE_MONTHLY_FEE', description : 'Single monthly fee' },
    { id : 1, name : 'SINGLE_SERVICE', description : 'Single Service' },
    { id : 2, name : 'OTHERS', description : 'others' },
]; // DescriptionLauncherType

export function getDescriptionLauncher(key: string): any {
    let result = { id : 0, name : '', description : '' };
    DescriptionLauncherType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getDescriptionLauncher

export const ExpirationDayType = [
    { id : 0, name : '1°', description : 'first day' },
    { id : 1, name : '5', description : 'day five' },
    { id : 2, name : '10', description : 'day ten' },
    { id : 3, name : '15', description : 'fifteenth day' },
    { id : 4, name : '20', description : 'day twenty' },
    { id : 5, name : '30', description : 'day thirty' },
]; // ExpirationDayType

export function getExpirationDay(key: string): any {
    let result = { id : 0, name : '', description : '' };
    ExpirationDayType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getExpirationDay

export const IssuerIdentityType = [
    { id : 0, name : 'SSP', description : 'SSP' },
    { id : 1, name : 'PC', description : 'PC' },
    { id : 2, name : 'PM', description : 'PM' },
    { id : 3, name : 'Outro', description : 'Outro' },
]; // IssuerIdentityType

export function getIssuerIdentity(key: string): any {
    let result = { id : 0, name : '', description : '' };
    IssuerIdentityType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getIssuerIdentity

export const PaymentType = [
    { id : 0, name : 'CASH', description : 'CASH' },
    { id : 1, name : 'CARTON', description : 'CARTON' },
    { id : 2, name : 'PIX', description : 'PIX' },
    { id : 3, name : 'BILLET', description : 'BILLET' },
]; // PaymentType

export function getPayment(key: string): any {
    let result = { id : 0, name : '', description : '' };
    PaymentType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getPayment

export const PaymentConditionsType = [
    { id : 0, name : 'IN_CASH', description : 'Paid in installments' },
    { id : 1, name : 'PARCEL_{0}X', description : 'Paid in {0} installment' },
    { id : 2, name : 'PARCEL_{0}X', description : 'Paid in {0} installment' },
    { id : 3, name : 'PARCEL_{0}X', description : 'Paid in {0} installment' },
    { id : 4, name : 'PARCEL_{0}X', description : 'Paid in {0} installment' },
    { id : 5, name : 'PARCEL_{0}X', description : 'Paid in {0} installment' },
    { id : 6, name : 'PARCEL_{0}X', description : 'Paid in {0} installment' },
    { id : 7, name : 'PARCEL_{0}X', description : 'Paid in {0} installment' },
    { id : 8, name : 'PARCEL_{0}X', description : 'Paid in {0} installment' },
    { id : 9, name : 'PARCEL_{0}X', description : 'Paid in {0} installment' },
    { id : 10, name : 'PARCEL_{0}X', description : 'Paid in {0} installment' }
]; // PaymentConditionsType

export function getPaymentConditions(key: string): any {
    let result = { id : 0, name : '', description : '' };
    PaymentConditionsType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getPaymentConditions

export const CategoryType = [
    { id : 1, name : 'VESSEL', description : 'Category for vessel' },
    { id : 2, name : 'PRODUCT', description : 'Category for product' },
    { id : 3, name : 'SERVICE', description : 'Category for service' },
    { id : 3, name : 'LABOR', description : 'Category for labor' },
]; // CategoryType

export function getCategory(key: string): any {
    let result = { id : 0, name : '', description : '' };
    CategoryType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getCategory

export const PermissionType = [
	{ id : 1, name : 'DASHBOARD', description : '' },
    { id : 11, name : 'DASHBOARD_FINANCIAL', description : '' },
	{ id : 12, name : 'DASHBOARD_MARITIME', description : '' },
	{ id : 13, name : 'DASHBOARD_MONITOR', description : '' },
	{ id : 14, name : 'DASHBOARD_MOVEMENT', description : '' },

	{ id : 2, name : 'CADASTRE', description : '' },

	{ id : 21, name : 'CADASTRE_CATEGORY', description : '' },
	{ id : 211, name : 'CADASTRE_CATEGORY_ADD', description : '' },
	{ id : 212, name : 'CADASTRE_CATEGORY_EDIT', description : '' },
	{ id : 213, name : 'CADASTRE_CATEGORY_REMOVE', description : '' },
	{ id : 214, name : 'CADASTRE_CATEGORY_VIEW', description : '' },

	{ id : 22, name : 'CADASTRE_PRODUCT', description : '' },
	{ id : 221, name : 'CADASTRE_PRODUCT_ADD', description : '' },
	{ id : 222, name : 'CADASTRE_PRODUCT_EDIT', description : '' },
	{ id : 223, name : 'CADASTRE_PRODUCT_REMOVE', description : '' },
	{ id : 224, name : 'CADASTRE_PRODUCT_VIEW', description : '' },

	{ id : 23, name : 'CADASTRE_PARTNER', description : '' },
	{ id : 231, name : 'CADASTRE_PARTNER_ADD', description : '' },
	{ id : 232, name : 'CADASTRE_PARTNER_EDIT', description : '' },
	{ id : 2321, name : 'CADASTRE_PARTNER_EDIT_SOCIETY', description : '' },
	{ id : 2322, name : 'CADASTRE_PARTNER_EDIT_EMPLOYEE', description : '' },
	{ id : 233, name : 'CADASTRE_PARTNER_REMOVE', description : '' },
	{ id : 234, name : 'CADASTRE_PARTNER_VIEW', description : '' },
    
	{ id : 24, name : 'CADASTRE_MEMBER', description : '' },
	{ id : 241, name : 'CADASTRE_MEMBER_ADD', description : '' },
	{ id : 242, name : 'CADASTRE_MEMBER_EDIT', description : '' },
	{ id : 243, name : 'CADASTRE_MEMBER_REMOVE', description : '' },
	{ id : 244, name : 'CADASTRE_MEMBER_VIEW', description : '' },

	{ id : 25, name : 'CADASTRE_VESSEL', description : '' },
	{ id : 251, name : 'CADASTRE_VESSEL_ADD', description : '' },
	{ id : 252, name : 'CADASTRE_VESSEL_EDIT', description : '' },
	{ id : 2521, name : 'CADASTRE_VESSEL_EDIT_AVATAR', description : '' },
	{ id : 2522, name : 'CADASTRE_VESSEL_EDIT_RESTRICTION', description : '' },
	{ id : 2523, name : 'CADASTRE_VESSEL_EDIT_GENERAL', description : '' },
	{ id : 2524, name : 'CADASTRE_VESSEL_EDIT_ENGINE', description : '' },
	{ id : 2525, name : 'CADASTRE_VESSEL_EDIT_INSURANCE', description : '' },
    { id : 2526, name : 'CADASTRE_VESSEL_EDIT_COST', description : '' },
	{ id : 2527, name : 'CADASTRE_VESSEL_EDIT_TRACKER', description : '' },
	{ id : 2528, name : 'CADASTRE_VESSEL_EDIT_OWNER', description : '' },
	{ id : 2529, name : 'CADASTRE_VESSEL_EDIT_DRIVER', description : '' },
	{ id : 2530, name : 'CADASTRE_VESSEL_EDIT_EQUIPMENT', description : '' },
	{ id : 2531, name : 'CADASTRE_VESSEL_EDIT_DOCUMENTATION', description : '' },
	{ id : 25311, name : 'CADASTRE_VESSEL_EDIT_DOCUMENTATION_VIEW', description : '' },
	{ id : 25312, name : 'CADASTRE_VESSEL_EDIT_DOCUMENTATION_UPDATE', description : '' },
	{ id : 2532, name : 'CADASTRE_VESSEL_EDIT_PHOTO', description : '' },
	{ id : 254, name : 'CADASTRE_VESSEL_REMOVE', description : '' },
	{ id : 255, name : 'CADASTRE_VESSEL_VIEW', description : '' },

	{ id : 26, name : 'CADASTRE_PURVEYOR', description : '' },
	{ id : 261, name : 'CADASTRE_PURVEYOR_ADD', description : '' },
	{ id : 262, name : 'CADASTRE_PURVEYOR_EDIT', description : '' },
	{ id : 263, name : 'CADASTRE_PURVEYOR_REMOVE', description : '' },
	{ id : 264, name : 'CADASTRE_PURVEYOR_VIEW', description : '' },

	{ id : 27, name : 'CADASTRE_MECHANIC', description : '' },
	{ id : 271, name : 'CADASTRE_MECHANIC_ADD', description : '' },
	{ id : 272, name : 'CADASTRE_MECHANIC_EDIT', description : '' },
	{ id : 273, name : 'CADASTRE_MECHANIC_REMOVE', description : '' },
	{ id : 274, name : 'CADASTRE_MECHANIC_VIEW', description : '' },

    { id : 3, name : 'OPERATION', description : '' },

	{ id : 31, name : 'OPERATION_MOVEMENT', description : '' },
	{ id : 311, name : 'OPERATION_MOVEMENT_ADD', description : '' },
	{ id : 312, name : 'OPERATION_MOVEMENT_EDIT', description : '' },
	{ id : 3121, name : 'OPERATION_MOVEMENT_EDIT_GENERAL', description : '' },
	{ id : 3122, name : 'OPERATION_MOVEMENT_EDIT_CREW', description : '' },
	{ id : 3123, name : 'OPERATION_MOVEMENT_EDIT_PASSENGER', description : '' },
	{ id : 3124, name : 'OPERATION_MOVEMENT_EDIT_CONFERENCE', description : '' },
	{ id : 313, name : 'OPERATION_MOVEMENT_REMOVE', description : '' },
	{ id : 314, name : 'OPERATION_MOVEMENT_VIEW', description : '' },

	{ id : 32, name : 'OPERATION_HISTORY', description : '' },
	{ id : 33, name : 'OPERATION_KEEP_BOAT', description : '' },
	{ id : 34, name : 'OPERATION_ASSESSMENT', description : '' },
    
    { id : 4, name : 'OFFICE', description : '' },

	{ id : 41, name : 'OFFICE_SCHEDULE', description : '' },
	{ id : 411, name : 'OFFICE_SCHEDULE_ADD', description : '' },
	{ id : 412, name : 'OFFICE_SCHEDULE_EDIT', description : '' },
	{ id : 413, name : 'OFFICE_SCHEDULE_REMOVE', description : '' },
	{ id : 414, name : 'OFFICE_SCHEDULE_VIEW', description : '' },

    { id : 42, name : 'OFFICE_BUDGET', description : '' },
	{ id : 421, name : 'OFFICE_BUDGET_ADD', description : '' },
	{ id : 422, name : 'OFFICE_BUDGET_EDIT', description : '' },
	{ id : 423, name : 'OFFICE_BUDGET_REMOVE', description : '' },
	{ id : 424, name : 'OFFICE_BUDGET_VIEW', description : '' },

	{ id : 5, name : 'FINANCIAL', description : '' },

    { id : 51, name : 'FINANCIAL_CONTRACT', description : '' },
	{ id : 511, name : 'FINANCIAL_CONTRACT_CREATE', description : '' },
	{ id : 512, name : 'FINANCIAL_CONTRACT_EDIT', description : '' },
	{ id : 513, name : 'FINANCIAL_CONTRACT_VIEW', description : '' },

    { id : 52, name : 'FINANCIAL_CONTRACT_MANAGEMENT', description : '' },
    { id : 53, name : 'FINANCIAL_CONTRACT_CUSTOMER', description : '' },

    { id : 54, name : 'FINANCIAL_LAUNCHER', description : '' },
	{ id : 541, name : 'FINANCIAL_LAUNCHER_ADD', description : '' },
    { id : 542, name : 'FINANCIAL_LAUNCHER_MAKE_PAYMENT', description : '' },
    { id : 543, name : 'FINANCIAL_LAUNCHER_TRACK_STATUS', description : '' },
    { id : 544, name : 'FINANCIAL_LAUNCHER_GENERATE_TICKET', description : '' },
    { id : 545, name : 'FINANCIAL_LAUNCHER_VIEW_RECEIPT', description : '' },
	{ id : 546, name : 'FINANCIAL_LAUNCHER_BILLET', description : '' },
	{ id : 5461, name : 'FINANCIAL_LAUNCHER_BILLET_UPDATE', description : '' },
	{ id : 5462, name : 'FINANCIAL_LAUNCHER_BILLET_GENERATE', description : '' },
	{ id : 5463, name : 'FINANCIAL_LAUNCHER_BILLET_VIEW', description : '' },
	{ id : 547, name : 'FINANCIAL_LAUNCHER_PAYMENT', description : '' },

    { id : 6, name : 'PROFILE', description : '' },
	{ id : 7, name : 'CONFIGURATION', description : '' }
]; // PermissionType

export function getPermission(key: any): any {
    let result = { id : 0, name : '', description : '' };
    PermissionType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getPermission

export const UnitType = [
    // Área
    { id : 0, name : 'M2', description : 'Metro quadrado' },
    // { id : 1, name : 'CM2', description : 'Centímetro quadrado' },
    // Comprimento
    { id : 2, name : 'M', description : 'Metro' },
    { id : 3, name : 'CM', description : 'Centímetro' },
    // Embalagem
    { id : 4, name : 'UN', description : 'Unidade' },
    { id : 5, name : 'CT', description : 'Cartela' },
    { id : 6, name : 'CX', description : 'Caixa' },
    { id : 7, name : 'DZ', description : 'Duzia' },
    // { id : 8, name : 'GS', description : 'Grosa' },
    { id : 9, name : 'PA', description : 'Par' },
    { id : 10, name : 'PÇ', description : 'Peça' },
    { id : 11, name : 'PR', description : 'Par' },
    { id : 12, name : 'PT', description : 'Pacote' },
    { id : 13, name : 'RL', description : 'Rolo' },
    // Peso
    { id : 14, name : 'KG', description : 'Kilograma' },
    { id : 15, name : 'G', description : 'Grama' },
    // { id : 16, name : 'SC60', description : 'Saca 60Kg' },
    // Volume
    { id : 17, name : 'L', description : 'Litro' },
    { id : 18, name : 'M3', description : 'Metro cúbico' },
    { id : 19, name : 'ML', description : 'Mililitro' },
    // Erro
    { id : 20, name : 'X', description : 'Não definida' }
]; // UnitType

export function getUnit(key: any): any {
    let result = { id : 0, name : '', description : '' };
    UnitType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getUnit

export const ScheduleTitleType = [
    { id : 1, name : 'MAINTENANCE', description : 'Maintenance' },
    { id : 2, name : 'REVISION', description : 'Revision' },
    { id : 3, name : 'CLEANING', description : 'Cleaning' },
]; // CategoryType

export function getScheduleTitleType(key: string): any {
    let result = { id : 0, name : '', description : '' };
    ScheduleTitleType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getCategory

export const OriginProductorType = [
    { id : 1, name : 'NATIONAL', description : 'National' },
    { id : 2, name : 'IMPORTED', description : 'Imported' }
]; // OriginProductorType

export const WarrantyProductType = [
    { id : 1, name : '7 Dias', description : '7 Dias' },
    { id : 2, name : '14 Dias', description : '14 Dias' },
    { id : 3, name : '30 Dias', description : '30 Dias' },
    { id : 4, name : '60 Dias', description : '60 Dias' },
    { id : 5, name : '90 Dias', description : '90 Dias' },
    { id : 6, name : '1 Ano', description : '1 Ano' },
    { id : 7, name : '3 Anos', description : '3 Anos' },
    { id : 8, name : '5 Anos', description : '5 Anos' },
    { id : 9, name : '10 Anos', description : '10 Anos' }
]; // WarrantyProductType

export const TypeProductType = [
    { id : 1, name : 'OFICINA', description : 'Oficina' },
    { id : 2, name : 'REVENDEDOR', description : 'Revendedor' },
    { id : 2, name : 'FABRICANTE', description : 'Fabricante' }
]; // TypeProductType

export const ExchangeProductType = [
    { id : 1, name : '7 Dias', description : '7 Dias' },
    { id : 2, name : '14 Dias', description : '14 Dias' },
    { id : 3, name : '30 Dias', description : '30 Dias' },
    { id : 4, name : '60 Dias', description : '60 Dias' },
    { id : 5, name : '90 Dias', description : '90 Dias' },
    { id : 6, name : '1 Ano', description : '1 Ano' }
]; // ExchangeProductType

export function getOriginProductor(key: string): any {
    let result = { id : 0, name : '', description : '' };
    OriginProductorType.forEach((element: any) => {
        if ((element.id === Number(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getOriginProductor

export const ColorDefaultType = [
    { id : '#FFFFFF', name : 'WHITE' },
    { id : '#C0C0C0', name : 'GRAY' },
    { id : '#006A9E', name : 'BLUE' },
    { id : '#00FFFF', name : 'AQUA' },
    { id : '#FFFF00', name : 'YELLOW' },
    { id : '#FF8C00', name : 'ORANGE' },
    { id : '#32CD32', name : 'GREEN' },
    { id : '#7FFFD4', name : 'AQUAMARINE' },
    { id : '#A020F0', name : 'PURPLE' },
    { id : '#FF69B4', name : 'PINK' },
    { id : '#8B4513', name : 'BROWN' },
    { id : '#FF0000', name : 'RED' },
    { id : '#000000', name : 'BLACK' }
]; // CategoryType

export function getColorDefault(key: string): any {
    let result = { id : '', name : '' };
    ColorDefaultType.forEach((element: any) => {
        if ((element.id === key) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getCategory

export const HullTypes = [
    { id : 1, name : 'Alumínio', description : 'Alumínio' },
    { id : 2, name : 'Fibra', description : 'Fibra' },
    { id : 3, name : 'Outro', description : 'Outro' },
]; // HullTypes

export const ClassificationTypes = [
    { id : 1, name : 'EC2 - Médio Porte', description : 'EC2 - Médio Porte' },
]; // HullTypes

export const MakerTypes = [
    { id : 1, name : 'Evinrude', description : 'Evinrude' },
    { id : 2, name : 'Hidea', description : 'Hidea' },
    { id : 3, name : 'Honda', description : 'Honda' },
    { id : 4, name : 'Jhonson', description : 'Jhonson' },
    { id : 5, name : 'Kawazaki', description : 'Kawazaki' },
    { id : 6, name : 'Mariner', description : 'Mariner' },
    { id : 7, name : 'Mercury', description : 'Mercury' },
    { id : 8, name : 'Sea-Doo', description : 'Sea-Doo' },
    { id : 9, name : 'Suzuki', description : 'Suzuki' },
    { id : 10, name : 'Tohatsu', description : 'Tohatsu' },
    { id : 11, name : 'Volvo', description : 'Volvo' },
    { id : 12, name : 'Yamaha', description : 'Yamaha' },
    { id : 13, name : 'Outro', description : 'Outro' },
]; // MakerTypes

export function getMaker(key: string): any {
    let result = { id : '', name : '', description : '' };
    MakerTypes.forEach((element: any) => {
        if ((element.id === toInt(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getMaker

export const FuelTypes = [
    { id : 1, name : 'Gasolina', description : 'Gasolina' },
    { id : 2, name : 'Diesel', description : 'Diesel' },
    { id : 3, name : 'Elétrico', description : 'Elétrico' },
]; // FuelTypes

export function getFuel(key: string): any {
    let result = { id : '', name : '', description : '' };
    FuelTypes.forEach((element: any) => {
        if ((element.id === toInt(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getFuel

export const SocietyType = [
    { id : 1, name : 'OWNER', description : 'Proprietario' },
    { id : 2, name : 'ADMINISTRADOR', description : 'Administrador' },
    { id : 3, name : 'SHAREHOLDER', description : 'Cotista' },
]; // SocietyType

export function getSociety(key: string): any {
    let result = { id : '', name : '', description : '' };
    SocietyType.forEach((element: any) => {
        if ((element.id === toInt(key)) || (element.name === key)) { 
            result = element;
        }
    });
    return result;
} // getSociety

export const EngineTypes = [
    { id : 1, name : 'Popa', description : 'Popa' },
    { id : 2, name : 'Centro Rabeta', description : 'Centro Rabeta' },
    { id : 3, name : 'Centro Eixo ', description : 'Centro Eixo ' },
    { id : 4, name : 'Elétrico', description : 'Elétrico' },
    { id : 5, name : 'Hidrojato', description : 'Hidrojato' },
]; // EngineTypes

export const OriginType = [
    { id : 1, name : 'Nacional', description : 'Nacional' },
    { id : 2, name : 'Importado', description : 'Importado' }
]; // OriginType

export const ManufacturerTypes = [
    { id : 1, name : 'Azimut Yachts', description : 'Azimut Yachts' },
    { id : 2, name : 'Cabrasmar', description : 'Cabrasmar' },
    { id : 3, name : 'Cimitarra', description : 'Cimitarra' },
    { id : 4, name : 'Ferretti', description : 'Ferretti' },
    { id : 5, name : 'Fibrafort', description : 'Fibrafort' },
    { id : 6, name : 'Fluvimar', description : 'Fluvimar' },
    { id : 7, name : 'FS Yachts', description : 'FS Yachts' },
    { id : 8, name : 'Intermarine', description : 'Intermarine' },
    { id : 9, name : 'Kawazaki', description : 'Kawazaki' },
    { id : 10, name : 'Levefort', description : 'Levefort' },
    { id : 11, name : 'Quest', description : 'Quest' },
    { id : 12, name : 'Schaefer Yachts', description : 'Schaefer Yachts' },
    { id : 13, name : 'Sea-Doo', description : 'Sea-Doo' },
    { id : 14, name : 'Sessa Marine', description : 'Sessa' },
    { id : 15, name : 'Yamaha', description : 'Yamaha' },
    { id : 16, name : 'Outro', description : 'Outro' },
]; // ManufacturerTypes


export const getManufacturerName = (manufacturterCode: number) => {
    const name =  ManufacturerTypes.find(manufacturer => manufacturer.id === Number(manufacturterCode))?.description
    return name === 'Outro' ? '' : name
}

export const VesselStatusTypes = [
    { id : 1, name : 'GARAGE', description : 'Garagem' },
    { id : 2, name : 'Abastecimento', description : 'Abastecimento' },
    { id : 3, name : 'Finalizado', description : 'Finalizado' },
    { id : 4, name : 'Higiene & Limpeza', description : 'Ducha & Limpeza' },
    { id : 5, name : 'NAVIGATING', description : 'Navegação' },
    { id : 6, name : 'Reparo Externa', description : 'Reparo Externo' },
    { id : 7, name : 'Entrando', description : 'Entrando' },
    { id : 8, name : 'Saindo', description : 'Saindo' },
    { id : 9, name : 'Oficina', description : 'Oficina' }
]; // VesselStatusTypes

export const getVesselStatus = (statusCode: number) => {
    const name =  VesselStatusTypes.find(manufacturer => manufacturer.id === Number(statusCode))?.description
    return name
}

export const SectorTypes = [
    { id : 1, name : 'administrative', description : 'Administrativo' },
    { id : 2, name : 'financial', description : 'Financeiro' },
    { id : 3, name : 'workshop', description : 'Oficina' },
    { id : 4, name : 'operation', description : 'Operação' },
    { id : 5, name : 'pub_and_bistro', description : 'Bar & Bistrô' },
    { id : 6, name : 'ordinance', description : 'Portaria' }
]; // SectorTypes

export const getSector = (name: string) => {
    return SectorTypes.find(sector => sector.name === name)?.description;
}

export const JetCapacity = [
    { id : 1, name : '1', description : '1' },
    { id : 2, name : '2', description : '2' },
    { id : 3, name : '3', description : '3' },
    { id : 4, name : '4', description : '4' },
]; // JetCapacity

