import React, { useEffect, useMemo, useState } from 'react'
import { Card, Col, Container, Row, OverlayTrigger, Tooltip, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { FaEye } from 'react-icons/fa'
import { RiEdit2Fill, RiNewspaperFill } from 'react-icons/ri'
import FinancialHeader from '../headers/financial.header'
import authService from '../../services/auth.service'
import lclService from '../../services/lcl.service'
import TableContainer from '../../components/table-container.component'
import { dateToString, leftPad, toInt } from '../../utilities/auxiliary-functions'
import { AlertMessageEnum, getContractProgress, getManufacturerName } from '../../utilities/types'
import { ContractProgressEnum, PermissionEnum, VESSEL_JETSKI } from '../../utilities/constants'
import ContractCadastreModal from '../modals/contract.cadastre.modal'
import { VesselOwner } from '../../components/sub-render.component'
import { useAlertMessageContext } from '../../contexts/alert-message.context'
import styles from './contratcts.financial.module.scss'
import { BsReceipt } from 'react-icons/bs'
import cduService from '../../services/cdu.service'
import { openDocument } from '../../utilities/view-document'
import Jet from '../../assets/Jetski.svg';
import Lancha from '../../assets/Lancha.svg';

interface Props {
    translate: any,
    partner: any,

    onClick_ShowDefinedPartner: any
}

export const FILTER_VESSEL_WITHOUT_ACTIVE_CONTRACT = 0;
export const FILTER_CONTRACTS_ACTIVE = 1;
export const FILTER_VESSELS_WITHOUT_CONTRACTS = 2;
export const FILTER_CONTRACTS_IN_PROGRESS = 3;
export const FILTER_CONTRACTS_INACTIVE = 4;

function ContratctsFinancial(props: Props) {
  
    const alertContext = useAlertMessageContext();

    const navigator = useNavigate();
    const display = props.translate.data;

    const title = display.title.contratct;
    const description = '';

    let reloadPage = false;
    const [isLoading, setLoading] = useState(false);

    const [typeFilter, setTypeFilter] = useState(2);

    const [qtyVesselsWithoutContracts, setQtyVesselsWithoutContracts] = useState(0);
    const [qtyContractsInProgress, setQtyContractsInProgress] = useState(0);
    const [qtyContractsActive, setQtyContractsActive] = useState(0);
    const [qtyContractsInactive, setQtyContractsInactive] = useState(0);

    const [vesselsWithoutContracts, setVesselsWithoutContracts] = useState([] as any);
    const [contractsInProgress, setContractsInProgress] = useState([] as any);
    const [contractsActive, setContractsActive] = useState([] as any);
    const [contractsInactive, setContractsInactive] = useState([] as any);

    const [records, setRecords] = useState([] as any);

    const columnsContract = useMemo(() => [{
            Header: display.label.contract_key,
            Cell: (props: any) => {
                const contract = props.row.original;
                return (<div className="white-space">{ dateToString(contract.confection, 'yyyymm') + leftPad(contract.id, 4, '0') }</div>);
            }
        },{
            Header: display.label.identify_key,
            Cell: (props: any) => {
                const contractVessels = props.row.original.vessels
                return (
                    <>
                        {contractVessels?.map((contractVessel: any, idx: number) => (
                            <div key={idx} className="white-space">{contractVessel.vessel?.identifyKey}</div>
                        ))}
                    </>
                )
            }
        },{
            Header: display.label.vessel,
            Cell: (props: any) => {
                const contractVessels = props.row.original.vessels

                return (
                    <>
                        {contractVessels?.map((contractVessel: any, idx: number) => (
                            <div key={idx} className="white-space">
                                {contractVessel.vessel?.name}
                            </div>
                        ))}
                    </>
                )
            }
        },{
            Header: display.label.type,
            Cell: (props: any) => {
                const contractVessels = props.row.original.vessels;

                return (
                    <>
                        {contractVessels?.map((contractVessel: any, idx: number) => (
                            toInt(contractVessel?.vessel.typeVessel) === VESSEL_JETSKI ? (
                                <div key={idx}  className="d-flex justify-content-center">
                                    <Image src={Jet} className="jet" style={{
                                        height: 32}}/>
                                    <small className="ms-1">10'</small>
                                </div>
                            ):(
                                <div key={idx} className="d-flex justify-content-center">
                                    <Image src={Lancha} className="jet" style={{
                                        height: 32}}/>
                                    <small className="ms-1">{`${contractVessel?.vessel.vesselSize || 0}'`}</small>
                                </div>
                            )
                        ))}
                    </>
                )
            }
        },{
            Header: display.label.color,
            Cell: (props: any) => {
                const contractVessels = props.row.original.vessels

                return (
                    <>
                        {contractVessels?.map((contractVessel: any, idx: number) => (
                            <div key={idx} className="white-space">
                                <div className="table-column-color d-flex justify-content-center">
                                    <span className="table-column-block30x30" style={{backgroundColor: `${contractVessel.vessel?.color}`}}></span>
                                </div>
                            </div>
                        ))}
                    </>
                )
            }
        },{
            Header: display.label.enrollment,
            Cell: (props: any) => {
                const contractVessels = props.row.original.vessels

                return (
                    <>
                        {contractVessels?.map((contractVessel: any, idx: number) => (
                            <div key={idx} className="white-space">{contractVessel.vessel?.enrollment}</div>
                        ))}
                    </>
                )
            }
        },{
            Header: display.label.manufacturer,
            //accessor: 'manufacturer',
            // Cell: (row: any) => <div className="white-space">{getManufacturerName(row.value)}</div>
            Cell: (props: any) => {
                const contractVessels = props.row.original.vessels

                return (
                    <>
                        {contractVessels?.map((contractVessel: any, idx: number) => (
                            <div key={idx} className="white-space">{contractVessel.vessel?.manufacturer}</div>
                        ))}
                    </>
                )
            }
        },{
            Header: display.label.brand,
            //accessor: 'brand',
            //Cell: (row: any) => <div className="white-space">{row.value}</div>
            Cell: (props: any) => {
                const contractVessels = props.row.original.vessels

                return (
                    <>
                        {contractVessels?.map((contractVessel: any, idx: number) => (
                            <div key={idx} className="white-space">{contractVessel.vessel?.brand}</div>
                        ))}
                    </>
                )
            }
        },{
            Header: display.label.model,
            //accessor: 'model',
            //Cell: (row: any) => <div className="white-space">{row.value}</div>
            Cell: (props: any) => {
                const contractVessels = props.row.original.vessels

                return (
                    <>
                        {contractVessels?.map((contractVessel: any, idx: number) => (
                            <div key={idx} className="white-space">{contractVessel.vessel?.model}</div>
                        ))}
                    </>
                )
            }
        },{
            Header: display.label.owner,
            Cell: (row: any) => (
                <VesselOwner translate={props.translate} values={row} />
            )
        },{
            Header: display.label.confection,
            accessor: 'confection',
            Cell: (row: any) => (
                <div className="white-space">{dateToString(row.value, 'dd/mm/yyyy')}</div>
            )
        },{
            Header: display.label.progress,
            accessor: 'progress',
            Cell: (row: any) => (
                <div className="white-space">{getContractProgress(row.value).description}</div>
            )
        },{
            Header: display.legend.actions,
            accessor: 'actions',
            Cell: (props: any) => {
                const contractRow = props.row.original;
                return (
                    <div className="white-space table-actions">
                        {toInt(contractRow.progress) !== ContractProgressEnum.FINISHED ? (
                            authService.hasPermission(PermissionEnum.FINANCIAL_CONTRACT_EDIT) ? (
                                <OverlayTrigger overlay={
                                    <Tooltip id="tooltip">{display.tooltips.to_edit} {display.tooltips.contract}</Tooltip>
                                }>
                                    <span onClick={e => onClick_EditContract(e, contractRow)}>
                                        <RiEdit2Fill size={18} />
                                    </span>
                                </OverlayTrigger>
                            ) : null
                        ) : authService.hasPermission(PermissionEnum.FINANCIAL_CONTRACT_VIEW) ? (
                            <>
                                <OverlayTrigger overlay={
                                    <Tooltip id="tooltip">{display.tooltips.to_view} {display.tooltips.contract}</Tooltip>
                                }>
                                    <span onClick={e => onClick_EditContract(e, contractRow)}>
                                        <FaEye size={18} />
                                    </span>
                                </OverlayTrigger>
                                {(contractRow.artifact) ?
                                    <OverlayTrigger overlay={
                                        <Tooltip id="tooltip">{display.tooltips.to_view} {display.tooltips.contract}</Tooltip>
                                    }>
                                        <span onClick={e => onClick_ReportContract(e, contractRow.artifact)}>
                                            <BsReceipt size={18} />
                                        </span>
                                    </OverlayTrigger>
                                : null }
                            </>
                        ) : null }
                    </div>
                )
            }
        }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
    ) // columnsContract

    const columnsVessel = useMemo(() => [{
            Header: display.label.identify_key,
            accessor: 'identifyKey'
        },{
            Header: display.label.vessel,
            accessor: 'name'
        },{
            Header: display.label.type,
            Cell: (props: any) => {
                const vessel = props.row.original;

                if (toInt(vessel.typeVessel) === VESSEL_JETSKI) {
                    return (<div className="d-flex justify-content-center">
                        <Image src={Jet} className="jet" style={{
                            height: 32}}/>
                        <small className="ms-1">10'</small>
                    </div>);
                } else {
                    return (<div className="d-flex justify-content-center">
                        <Image src={Lancha} className="jet" style={{
                            height: 32}}/>
                            <small className="ms-1">{`${vessel.vesselSize || 0}'`}</small>
                    </div>);
                }
            }
        },{
            Header: display.label.color,
            accessor: "color",
            Cell: (row: any) => (<div className="white-space">
                 <div className="table-column-color d-flex justify-content-center">
                  <span className="table-column-block30x30" style={{backgroundColor: `${row.value}`}}></span>
              </div>
            </div>)
        },{
            Header: display.label.enrollment,
            accessor: 'enrollment',
            Cell: (row: any) => <div className="white-space">{row.value}</div>
        },{
            Header: display.label.manufacturer,
            accessor: 'manufacturer',
            Cell: (row: any) => <div className="white-space">{getManufacturerName(row.value)}</div>
        },{
            Header: display.label.brand,
            accessor: 'brand',
            Cell: (row: any) => <div className="white-space">{row.value}</div>
        },{
            Header: display.label.model,
            accessor: 'model',
            Cell: (row: any) => <div className="white-space">{row.value}</div>
        },{
            Header: display.legend.actions,
            accessor: 'actions',
            Cell: (props: any) => {
                const vesselRow = props.row.original

                return (
                    <div className="white-space table-actions">
                        {authService.hasPermission(PermissionEnum.FINANCIAL_CONTRACT_CREATE) ? (
                            <OverlayTrigger overlay={
                                <Tooltip id="tooltip">{display.tooltips.to_create} {display.tooltips.contract}</Tooltip>
                            }>
                                <span onClick={e => onClick_AddContractForVessel(e, vesselRow)}>
                                    <RiNewspaperFill size={18} />
                                </span>
                            </OverlayTrigger>
                        ) : null}
                    </div>
                )
            }
        }],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    ); // columnsVessel

    const [contractId, setContractId] = useState('');
    const [contract, setContract] = useState({} as any);
    const [isEditContract, setEditContract] = useState(false);
    const [showContract, setShowContract] = useState(false);

    async function searchfilter() {
        if (props.partner) {
            const attributes = [] as any
            const where = {} as any
            const order = [] as any

            /* Sempre incluir o partner na pesquisa */
            where.partnerId = props.partner.id
            if (authService.isProprietor()) {
                const user = authService.currentUser();
                const people = await cduService.peopleByUser(user.id);
                where['ownerId'] = people.id;
            }

            let contracts_default = null
            try {
                const contracts = await lclService.listContracts(attributes, where, order);
                for ( let c = 0; c < contracts.contractsActive.length; c++) {
                    const contract = contracts.contractsActive[c];

                    const artifact = await cduService.artifactByNorm('CONTRACT', `${contract.id}`, 'FILE_SIGNATURE');
                    contracts.contractsActive[c].artifact = artifact;
                }

                contracts_default = contracts.vesselsWithoutContracts;
                setVesselsWithoutContracts(contracts.vesselsWithoutContracts);
                setQtyVesselsWithoutContracts(contracts.vesselsWithoutContracts.length);
                setContractsInProgress(contracts.contractsInProgress);
                setQtyContractsInProgress(contracts.contractsInProgress.length);
                setContractsActive(contracts.contractsActive);
                setQtyContractsActive(contracts.contractsActive.length);
                setContractsInactive(contracts.contractsInactive);
                setQtyContractsInactive(contracts.contractsInactive.length);
            } catch (error: any) {
                setVesselsWithoutContracts([] as any);
            }
            viewFilter(FILTER_VESSELS_WITHOUT_CONTRACTS, contracts_default);
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi definido uma Marina para exibir os contratos !');
            navigator(`/portal`);
        }
    } // searchfilter

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            searchfilter()
            setLoading(true)
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage
        }
    }, []) // useEffect

    /* START */

    function viewFilter(type: number, contracts?: any) {
        setTypeFilter(type)
        if (type === FILTER_VESSEL_WITHOUT_ACTIVE_CONTRACT) {
            setRecords([] as any);
        } else if (type === FILTER_CONTRACTS_ACTIVE) {
            setRecords(contractsActive);
        } else if (type === FILTER_CONTRACTS_INACTIVE) {
            setRecords(contractsInactive);
        } else if (type === FILTER_CONTRACTS_IN_PROGRESS) {
            setRecords(contractsInProgress);
        } else {
            if (vesselsWithoutContracts.length > 0) {
                setRecords(vesselsWithoutContracts);
            } else if (contracts) 
                setRecords(contracts);
            else 
                setRecords(vesselsWithoutContracts);
        }
    }

    function onClick_Filter(event: any, type: number) {
        event.preventDefault();
        viewFilter(type);
    }

    function onClick_AddContractForVessel(event: any, vessel: any) {
        event.preventDefault();

        const vessels = [] as any;
        vessels[0] = vessel;
        setEditContract(false);
        setContractId('new');
        setContract({
            id: 0,
            created: dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'),
            updated: dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'),
            vessels
        } as any);
        setShowContract(true);
    } // onClick_AddRecord

    function onClick_EditContract(event: any, contract: any) {
        event.preventDefault();

        setEditContract(true);
        setContractId(contract.id);
        setContract(contract);
        setShowContract(true);
    } // onClick_EditRecord

    function onClick_ReportContract(event: any, artifact: any) {
        event.preventDefault();
        openDocument(title, artifact.document);
    } // onClick_ReportContract

    /* END */

    function viewPage() {

        return (
            <Container fluid className="page-body contratcts">
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card style={{ minHeight: 500 }}>
                            <Card.Header>
                                <Card.Title>{display.subtitle.contratcts}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <div className="postits">
                                            <div className={`postit isClickable ${typeFilter === 2 ?  styles.active : ''}`} onClick={e => onClick_Filter(e, FILTER_VESSELS_WITHOUT_CONTRACTS)}>
                                                <div className="postit-body">
                                                    {qtyVesselsWithoutContracts}{' '}
                                                </div>
                                                <div className="postit-header">{display.legend.vessels_without_contracts}</div>
                                                <div className="postit-footer" />
                                            </div>
                                            <div className={`postit isClickable ${typeFilter === 3 ?  styles.active : ''}`} onClick={e => onClick_Filter(e, FILTER_CONTRACTS_IN_PROGRESS)}>
                                                <div className="postit-body">
                                                    {qtyContractsInProgress}
                                                </div>
                                                <div className="postit-header">{display.legend.contracts_in_progress}</div>
                                                <div className="postit-footer" />
                                            </div>
                                            <div className={`postit isClickable ${typeFilter === 1 ? styles.active : ''}`} onClick={e => onClick_Filter(e, FILTER_CONTRACTS_ACTIVE)}>
                                                <div className="postit-body">
                                                    {qtyContractsActive}
                                                </div>
                                                <div className="postit-header">{display.legend.contracts_active}</div>
                                                <div className="postit-footer" />
                                            </div>
                                            <div className={`postit isClickable ${typeFilter === 4 ? styles.active : ''}`} onClick={e => onClick_Filter(e, FILTER_CONTRACTS_INACTIVE)}>
                                                <div className="postit-body">
                                                    {qtyContractsInactive}
                                                </div>
                                                <div className="postit-header">Inativos</div>
                                                <div className="postit-footer" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="d-flex justify-content-center mt-3 mb-0">
                                    <p className='mb-0'>(Clique nos status acima para filtrar os contratos)</p>
                                </div>
                                <Row><Col><hr /></Col></Row>
                                <Row>
                                    <Col>
                                        <TableContainer columns={typeFilter === FILTER_VESSELS_WITHOUT_CONTRACTS ? columnsVessel : columnsContract} data={records} viewFilter={false} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    } // viewCadastreList

    return (
        <div className="page financial">
            <FinancialHeader
                title={title}
                description={description}
                onClick_ShowDefinedPartner={props.onClick_ShowDefinedPartner}
            />
            {viewPage()}
            <ContractCadastreModal
                translate={props.translate}
                partner={props.partner}
                typeFilter={FILTER_VESSEL_WITHOUT_ACTIVE_CONTRACT}
                isEdit={isEditContract}
                show={showContract}
                contractId={contractId}
                record={contract}
                setShowContract={(value: any) => {
                    setShowContract(value)
                }}
                onSubmitModal={(event: any, record: any) => {
                    event.preventDefault()

                    setShowContract(false)
                    setEditContract(false)
                    searchfilter()
                }}
                onCancelModal={(event: any) => {
                    // event.preventDefault();

                    setEditContract(false)
                    setShowContract(false)
                }}
            />
        </div>
    );
}

export default ContratctsFinancial
