import { Col, Form, FormControl, FormGroup, InputGroup, Row } from "react-bootstrap";
import { toString, toLowerCase } from '../../../../utilities/auxiliary-functions';
import { isEmpty } from "../../../../utilities/validators";
import AddressRecord from './address.record';
import PersonalDataRecord from './personal-data.record';
import PersonalDocumentRecord from './personal-document.record';
import WorkDataRecord from "./work-data.record";
import { EmployeeType } from "../../../../utilities/types";
import { cpfMask } from "../../../../utilities/masks";
import { FaSearch } from "react-icons/fa";

interface Props {
    translate: any,
    title: any,
    isEdit: boolean,

    employeeType?: any,
    created?: any,
    updated?: any,
    tratament?: any,
    fullname?: any,
    gender?: any,
    birth?: any,
    norm?: any,
    operator?: any,
    connection?: any,
    sms?: any,
    zap?: any,
    major?: any,
    nationality?: any,
    naturalness?: any,
    registrationPf?: any,
    registrationIdentity?: any,
    issuerIdentity?: any,
    stateIdentity?: any,
    expeditionIdentity?: any,
    driversLicense?: any,
    categoryDriversLicense?: any,
    validityDriversLicense?: any,
    sector?: any,
    occupation?: any,
    credential?: any,
    remuneration?: any,
    admission?: any,
    dismissal?: any,
    situation?: any,
    workdayFirst?: any,
    workdayStop?: any,
    workdayStart?: any,
    workdayLast?: any,
    zipcode?: any,
    state?: any,
    city?: any,
    district?: any,
    place?: any,
    habitation?: any,
    complement?: any,
    setEmployeeType?: any,
    setCreated?: any,
    setUpdated?: any,
    setTratament?: any,
    setFullname?: any,
    setGender?: any,
    setBirth?: any,
    setNorm?: any,
    setOperator?: any,
    setConnection?: any,
    setSms?: any,
    setZap?: any,
    setMajor?: any,
    setNationality?: any,
    setNaturalness?: any,
    setRegistrationPf?: any,
    setRegistrationIdentity?: any,
    setIssuerIdentity?: any,
    setStateIdentity?: any,
    setExpeditionIdentity?: any,
    setDriversLicense?: any,
    setCategoryDriversLicense?: any,
    setValidityDriversLicense?: any
    setSector?: any,
    setOccupation?: any,
    setCredential?: any,
    setRemuneration?: any,
    setAdmission?: any,
    setDismissal?: any,
    setSituation?: any,
    setWorkdayFirst?: any,
    setWorkdayStop?: any,
    setWorkdayStart?: any,
    setWorkdayLast?: any
    setZipcode?: any,
    setState?: any,
    setCity?: any,
    setDistrict?: any,
    setPlace?: any,
    setHabitation?: any,
    setComplement?: any,
    onClick_SearchPeople?: any,

    proofOfResidenceDocument: any,
    changeProofOfResidenceDocument: any,
    clickViewDocument: any
}

function EmployeeRecord(props: Props) {

    const display = props.translate.data;

    return (
        <>
            <Row>
                <Col sm="12">
                    <Row>
                        { !props.isEdit &&
                        <Col sm="2">
                            <FormGroup>
                                <Form.Label htmlFor="form-registration_pf">{display.label.registration_pf}</Form.Label>
                                <InputGroup className="mb-2">
                                    <FormControl type="text" id="form-registration_pf" name="registration_pf"
                                        placeholder={'Digite o CPF'} required readOnly={props.isEdit}
                                        value={toString(props.registrationPf)} onChange={e=>
                                        props.setRegistrationPf(cpfMask(e.target.value))}
                                        />
                                        <InputGroup.Text>
                                            <a href="/#" onClick={props.onClick_SearchPeople}>
                                                <FaSearch />
                                            </a>
                                        </InputGroup.Text>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        }
                        <Col sm="2">
                            <FormGroup>
                                <Form.Label htmlFor="form-employee-type">{display.label.employee_type}</Form.Label>
                                <Form.Select id="form-employee-type" name="employee-type" required
                                    value={toString(props.employeeType)}
                                    onChange={(e: any) => props.setEmployeeType(e.target.value)}
                                >
                                    { isEmpty(props.employeeType) ? (
                                        <option value="">...</option>
                                    ) : null }
                                     { EmployeeType.map((iterator: any, idx: number) => (<option key={idx} value={iterator.sigla}>{display.label[toLowerCase(iterator.name)]}</option>)) }
                                </Form.Select>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row><Col><hr /></Col></Row>
                    <fieldset>
                        <legend>{display.legend.general_data}</legend>
                        <PersonalDataRecord
                            translate={props.translate}
                            title={props.title}
                            isEdit={props.isEdit}

                            tratament={props.tratament}
                            fullname={props.fullname}
                            gender={props.gender}
                            birth={props.birth}
                            norm={props.norm}
                            operator={props.operator}
                            connection={props.connection}
                            sms={props.sms}
                            zap={props.zap}
                            major={props.major}
                            nationality={props.nationality}
                            naturalness={props.naturalness}
                            setTratament={(value: any) => { props.setTratament(value); }}  
                            setFullname={(value: any) => { props.setFullname(value); }}  
                            setGender={(value: any) => { props.setGender(value); }}  
                            setBirth={(value: any) => { props.setBirth(value); }}
                            setNorm={(value: any) => { props.setNorm(value); }}
                            setOperator={(value: any) => { props.setOperator(value); }}
                            setConnection={(value: any) => { props.setConnection(value); }}
                            setSms={(value: any) => { props.setSms(value); }}
                            setZap={(value: any) => { props.setZap(value); }}
                            setMajor={(value: any) => { props.setMajor(value); }}
                            setNationality={(value: any) => { props.setNationality(value); }} 
                            setNaturalness={(value: any) => { props.setNaturalness(value); }} 
                            />
                    </fieldset>
                    <fieldset>
                        <legend>{display.legend.work_data}</legend>
                        <WorkDataRecord
                            translate={props.translate}
                            title={props.title}
                            isEdit={props.isEdit}

                            sector={props.sector}
                            occupation={props.occupation}
                            credential={props.credential}
                            remuneration={props.remuneration}
                            admission={props.admission}
                            dismissal={props.dismissal}
                            workdayFirst={props.workdayFirst}
                            workdayStop={props.workdayStop}
                            workdayStart={props.workdayStart}
                            workdayLast={props.workdayLast}
                            setSector={(value: any) => { props.setSector(value); }}  
                            setOccupation={(value: any) => { props.setOccupation(value); }}  
                            setCredential={(value: any) => { props.setCredential(value); }}  
                            setRemuneration={(value: any) => { props.setRemuneration(value); }}  
                            setAdmission={(value: any) => { props.setAdmission(value); }}  
                            setDismissal={(value: any) => { props.setDismissal(value); }}  
                            setWorkdayFirst={(value: any) => { props.setWorkdayFirst(value); }}  
                            setWorkdayStop={(value: any) => { props.setWorkdayStop(value); }}  
                            setWorkdayStart={(value: any) => { props.setWorkdayStart(value); }}  
                            setWorkdayLast={(value: any) => { props.setWorkdayLast(value); }}  
                        />
                    </fieldset>
                    <fieldset>
                        <legend>{display.legend.documentation}</legend>
                        <PersonalDocumentRecord
                            translate={props.translate}
                            title={props.title}
                            isEdit={props.isEdit}

                            registrationPf={props.registrationPf}
                            registrationIdentity={props.registrationIdentity}
                            issuerIdentity={props.issuerIdentity}
                            stateIdentity={props.stateIdentity}
                            expeditionIdentity={props.expeditionIdentity}
                            driversLicense={props.driversLicense}
                            categoryDriversLicense={props.categoryDriversLicense}
                            validityDriversLicense={props.validityDriversLicense}
                            setRegistrationPf={(value: any) => { props.setRegistrationPf(value); }}  
                            setRegistrationIdentity={(value: any) => { props.setRegistrationIdentity(value); }}  
                            setIssuerIdentity={(value: any) => { props.setIssuerIdentity(value); }}  
                            setStateIdentity={(value: any) => { props.setStateIdentity(value); }}  
                            setExpeditionIdentity={(value: any) => { props.setExpeditionIdentity(value); }}  
                            setDriversLicense={(value: any) => { props.setDriversLicense(value); }}  
                            setCategoryDriversLicense={(value: any) => { props.setCategoryDriversLicense(value); }} 
                            setValidityDriversLicense={(value: any) => { props.setValidityDriversLicense(value); }} 
                        />
                    </fieldset>
                    <fieldset>
                        <legend>{display.legend.address}</legend>
                        <AddressRecord 
                            translate={props.translate}
                            title={props.title}
                            isEdit={props.isEdit}

                            zipcode={props.zipcode}
                            state={props.state}
                            city={props.city}
                            district={props.district}
                            place={props.place}
                            habitation={props.habitation}
                            complement={props.complement}
                            setZipcode={(value: any) => { props.setZipcode(value); }} 
                            setState={(value: any) => { props.setState(value); }} 
                            setCity={(value: any) => { props.setCity(value); }} 
                            setDistrict={(value: any) => { props.setDistrict(value); }} 
                            setPlace={(value: any) => { props.setPlace(value); }} 
                            setHabitation={(value: any) => { props.setHabitation(value); }} 
                            setComplement={(value: any) => { props.setComplement(value); }}

                            proofOfResidenceDocument={props.proofOfResidenceDocument}
                            changeProofOfResidenceDocument={props.changeProofOfResidenceDocument}
                            clickViewDocument={props.clickViewDocument}
                        />
                    </fieldset>
                </Col>
            </Row>
        </>
    );

}

export default EmployeeRecord;
