import { useEffect, useMemo, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { Phones } from "../../components/sub-render.component";
import TableContainer from "../../components/table-container.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import cduService from "../../services/cdu.service";
import { cpfMask } from "../../utilities/masks";
import { isEmpty } from "../../utilities/validators";
import { Button } from '@mui/material'
import { FaTimes } from "react-icons/fa";
import { AlertMessageEnum } from "../../utilities/types";
import authService from "../../services/auth.service";

interface Props {
    translate: any,
    peopleNotInList?: any,
    show: any,
    onClick_Close: any,
    onClick_Confirm: any,
}

function PeopleFilter(props: Props) {
    
    const alertContext = useAlertMessageContext();

    const display = props.translate.data;
    const title : string = display.title.people_filter;
    const partner = authService.currentPartner();

    const [record, setRecord] = useState({} as any);
    const [records, setRecords ] = useState([]);

    const columnsRecord = useMemo(() => [{
        Header: display.label.fullname,
        accessor: "fullname",
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    },{
        Header: display.label.registration_pf,
        accessor: "registrationPf",
        Cell: (row: any) => (<div className="white-space">{cpfMask(row.value)}</div>)
    },{
        Header: display.label.telephone,
        accessor: 'phones',
        Cell: (row: any) => <Phones translate={props.translate} values={row.value} />
    },{
        Header: display.label.adresses,
        columns: [
            {
                Header: display.label.norm,
                accessor: "adresses[0].norm",
                Cell: (row: any) => (<div className="white-space">{display.label[row.value]}</div>)
            },{
                Header: display.label.city,
                accessor: "adresses[0].address.city",
                Cell: (row: any) => (<div className="white-space">{row.value}</div>)
            },{
                Header: display.label.state,
                accessor: "adresses[0].address.state",
                Cell: (row: any) => (<div className="white-space">{row.value}</div>)
            }
        ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }], []
    ); // columnsRecord

    async function initialize() {
        try {
            if (props.show) {
                const attributes = [] as any;
                const where = {} as any;
                const order = [] as any;

                if (props.peopleNotInList?.length > 0)
                    where['id'] = { notIn : props.peopleNotInList }

                /* Sempre incluir o partner na pesquisa */
                where['partnerId'] = partner.id;
                const peoples = await cduService.peopleInPartner(attributes, where, order);

                setRecords(peoples);
                setRecord({} as any);
            }
        } catch(error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
        }
    }

    useEffect(() => {
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]); // useEffect

    return (
        <Modal
            size="lg"
            show={props.show}
            centered
        >
            <Modal.Header>
                <Modal.Title><FaSearch />{title}</Modal.Title>
                <div className="card-actions float-right">
                    <FaTimes className="isClickable" size={22} onClick={props.onClick_Close} />
                </div>
            </Modal.Header>
            <Modal.Body style={{ minHeight : 400 }}>
                <Row>
                    <Col>
                        <TableContainer columns={ columnsRecord } data={ records } viewFilter={true} viewPagination={true} setSelectedRow={ 
                            (event: any, dataRow: any) => { 
                                setRecord(dataRow); 
                            }}
                        />
                    </Col>
                </Row>                    
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col sm="8">
                            <div className="filter-selectred"><strong>{display.label.selected_record}:</strong> <span>{ record.fullname }</span></div>
                        </Col>
                        <Col sm="4">
                            <Button variant="contained" color="secondary" className="ms-2" onClick={ (e) => props.onClick_Close(e) } >{display.buttom.cancel}</Button>{' '}
                            <Button variant="contained" color="primary" onClick={ (e) => props.onClick_Confirm(e, record) }  disabled={isEmpty(record)}>{display.buttom.confirm}</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    )
}

export default PeopleFilter;
