import logoNauti from '../../assets/icon_logo.png';

import { useEffect, useState, useMemo } from "react";
import { Button, Col, Container, Form, FormControl, Image, InputGroup, Modal, Row, Spinner } from "react-bootstrap";
import { FaLink, FaTimes } from "react-icons/fa";
import { useConfirmMessageContext } from '../../contexts/confirm-message.context';
import TableContainer from '../../components/table-container.component';
import { MdReadMore } from 'react-icons/md';

import * as xlsx from "xlsx";
import { useAlertMessageContext } from '../../contexts/alert-message.context';
import { AlertMessageEnum } from '../../utilities/types';

interface Props {
    translate: any,
    partner: any,
    show: boolean,
    onSubmitModal: any,
    onCancelModal: any
}

function VesselImporterModal( props : Props ) {

    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();

    const display = props.translate.data;
    const title : string = display.title.importer_vessel;

    const [ invalidatedImporterForm, setInvalidatedImporterForm ] = useState(false);

    const [ sending, setSending ] = useState(false);

    // Importer
    const [ simplified, setSimplified ] = useState(true);

    const [ importerDocument, setImporterDocument ] = useState('');
    const [ fileImporterDocument, setFileImporterDocument ] = useState({} as any);

    const records: any = []
    const columnsImportation = useMemo(() => [{
        Header: display.label.name,
        accessor: "name",
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    }, {
        Header: display.label.type_vessel,
        accessor: "typeVessel",
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    }, {
        Header: display.label.classification,
        accessor: "classification",
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    }, {
        Header: display.label.enrollment,
        accessor: "enrollment",
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }], []); // columnsImportation

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedImporterForm(!result);
        return result;
    }

    async function onClick_Confirm(event: any) {
        event.preventDefault();


        const isValid = await isValidForm();
        if (isValid) {

            try {
                const isConfirmed = await confirmContext.show(title, display.message.confirm_record);
                if (isConfirmed) {
                    setSending(true);

                    let returned = null;
                  

                    setSending(false);
                    await alertContext.show(AlertMessageEnum.SUCCESS, title, display.message.the_record_has_been_saved_successfully);
                    props.onSubmitModal(event, returned);
                }
            } catch (error: any) {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }

    async function onClick_Cancel(event : any) {
        event.preventDefault();

        setInvalidatedImporterForm(false);
        props.onCancelModal(event);
    }

    async function initialize() {

    }

    useEffect(() => {
        if (props.show)
            initialize();
    }, [props.show]);

    /* FUNCTION EXCLUSIVE OF FORM - START */

    function onChange_TypeImporter(event: any, typeImporter: string) {
        setSimplified(typeImporter === 'S');
    }

    function onChange_ImporterDocument(event: any) {
        event.preventDefault();
        setImporterDocument(event.target.files[0].name);
        setFileImporterDocument(event.target.files[0]);
    }

    function onClick_ReadDocument(event: any) {
        event.preventDefault();
        console.log('onClick_ReadDocument');

        console.log(fileImporterDocument);
        let reader = new FileReader();
        reader.onload = (e: any) => {
            //console.log(e.target.result);
            var data = e.target.result;
            // console.log(data);
            const workbook = xlsx.read(data, {type: 'binary'});
            console.log(workbook);
            const wsname = workbook.SheetNames[0];
            console.log(wsname);
			const ws = workbook.Sheets[wsname];
            console.log(ws);
			const datas = xlsx.utils.sheet_to_json(ws, {header: 1});
            console.log(datas);
        };
        reader.readAsBinaryString(fileImporterDocument);

    }

    /* FUNCION EXCLUVISE OF FORM - END */

    function viewImporterForm() {
        return (
            <>
                <Modal show={props.show}
                    size="lg"
                    aria-labelledby="modal-importer-form"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="modal-importer-form"><Image src={logoNauti} style={{width: "23px", marginTop: "-5px", marginRight: "5px"}} />{title}</Modal.Title>
                        <div className="d-flex align-items-center">
                            <FaTimes className="isClickable" onClick={ onClick_Cancel } size={26}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{height: "350px"}}>
                        <Form className="form" id="form-importer" validated={invalidatedImporterForm}>
                            <Container>
                                <Row>
                                    <Col sm={2} className="margin-auto">
                                        <div key={`inline-radio`} className="mb-2">
                                            <Form.Check
                                                label="Simplificada"
                                                name="group1"
                                                type="radio"
                                                id={`inline-radio-1`}
                                                value={'S'}
                                                checked={simplified}
                                                onChange={(e) => onChange_TypeImporter(e, 'S') }
                                            />
                                            <Form.Check
                                                label="Completa"
                                                name="group1"
                                                type="radio"
                                                id={`inline-radio-2`}
                                                value={'C'}
                                                checked={!simplified}
                                                onChange={(e) => onChange_TypeImporter(e, 'C') }
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <Form.Label htmlFor='form-file-importer-document'>Arquivo de Importação</Form.Label>
                                        <InputGroup className="mb-2">
                                            <FormControl className="input-file-text" id="form-file-importer-document" name="importerDocument"
                                                value={importerDocument} readOnly
                                            /> {/* accept=".xlsx, .xls" */}
                                            <Form.Control type='file' id='input-file-importer-document' accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={ (e) => { onChange_ImporterDocument(e) }} />
                                            <Form.Label className="input-file-button" htmlFor='input-file-importer-document'><FaLink size={18} /></Form.Label>
                                            <Form.Label className="input-file-button" onClick={(e) => onClick_ReadDocument(e)}><MdReadMore size={23} /></Form.Label>
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <TableContainer columns={columnsImportation} data={records} />
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={ onClick_Cancel } variant="secondary">{display.buttom.cancel}</Button>
                        <Button onClick={ onClick_Confirm } disabled={sending}>
                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                            { display.buttom.confirm }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
            { viewImporterForm() }
        </>
    )
}

export default VesselImporterModal
