import axios from "axios";
import env from "react-dotenv";

class LocationService {

    API_IP = env.API_LOCATION_IP;
    
    async getIp() {
        
        return axios
            .get(this.API_IP, {})
            .then((response: any) => {
                let data = response.data; 
                return data.ip;
            })
            .catch((error: any) => {
                const responseMessage = (
                    error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();
                throw new Error(responseMessage);
            });
    }

    async getCityByIp() {

        return axios
            .get(env.API_LOCATION_IP, {})
            .then((response: any) => {
                return response.data.region; 
            })
            .catch((error: any) => {
                const responseMessage = (
                    error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();
                throw new Error(responseMessage);
            });
    }
    
    async now() {
        
        return axios
            .get(this.API_IP, {})
            .then((response: any) => {
                return response.data; 
            })
            .catch((error: any) => {
                const responseMessage = (
                    error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();
                throw new Error(responseMessage);
            });
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new LocationService();