import { useEffect, useMemo, useState } from "react";
import { Card, Col, Container, Image, Row } from "react-bootstrap";
import { BsClockHistory } from "react-icons/bs";
import { FaTint, FaUsers, FaWarehouse, FaTools } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Jet from '../../assets/Jetski.svg';
import Lancha from '../../assets/Lancha.svg';
import Navegando from '../../assets/Navegando.svg';
import Abastecimento from '../../assets/Abastecimento.svg';
import Manutenca0_externa from '../../assets/Manutenca0_externa.svg';
import Movimento_entrada from '../../assets/Movimento_entrada.svg';
import Movimento_saida from '../../assets/Movimento_saida.svg';
import Higiene from '../../assets/Limpeza.svg';
import selo from '../../assets/selo.png';
import TableContainer from "../../components/table-container.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import authService from '../../services/auth.service';
import cduService from "../../services/cdu.service";
import climateService from "../../services/climate.service";
import lclService from "../../services/lcl.service";
import { arrivalForecast, browsingDelay, dateToString, notNullToDate, toDate, toFloat, toInt } from "../../utilities/auxiliary-functions";
import { VESSEL_LANCHA } from "../../utilities/constants";
import { removeFormatDate } from "../../utilities/masks";
import { AlertMessageEnum, getVessel } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";
import DashboardHeader from "../headers/dashboard.header";
import styles from './maritime.dashboard.module.scss';
import {FormGroup, Tooltip } from "@mui/material";
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

interface Props {
	translate: any,
	partner: any,

	onClick_ShowDefinedPartner?: any
}

function MaritimeDashboard(props: Props) {
    
    const alertContext = useAlertMessageContext();
    const navigator = useNavigate();

	const display = props.translate.data;
	const [climateDescription, setClimateDescription] = useState("");

	const title = "Movimentação Diária";
	const description = "";

	let reloadPage = false;
	const [isLoading, setLoading] = useState(false);

	const [dateStart, setDateStart] = useState(
		dateToString(new Date(), "dd/mm/yyyy")
	);
	const [currentDate, setCurrentDate] = useState(new Date());
	const [hourNow, setHourNow] = useState(0);

	const [climateDay, setClimateDay] = useState([] as any);
	const [climateHour, setClimeHour] = useState([] as any);
	const [climateLocation, setClimateLocation] = useState([] as any);

	const [movements, setMovements] = useState([] as any);

	const [peoples, setPeoples] = useState(0);
	const [browsing, setBrowsing] = useState(0);
	const [delay, setDelay] = useState(0);
	const [portraitOrientation, setPortraitOrientation] = useState(false);
	const [vesselsCount, setVesselsCount] = useState<any>();

	const [averageNavigationTime, setAverageNavigationTime] = useState("00:00");

	const [time, setTime] = useState(new Date());

	const fiveMinutes = '300000';
	
	function handleClickRefresh(date: any) {
		if (typeof date === "string") {
			date = new Date(date);
		}

		let baseDate = notNullToDate(date, "dd/mm/yyyy");
		setCurrentDate(new Date());
		setDateStart(dateToString(date, "dd/mm/yyyy"));
		setHourNow(currentDate.getHours());
		handleMovement(baseDate).then(async (response: any) => {
      if (response) {
        handleGraficHourlyOccupation(baseDate, response.list);
        handleClimate();
      }
		});
	}

  	async function handleClimate() {
        const user = authService.currentUser();
        let userAddress = await cduService.addressByUser(user?.id);
        let city = `${userAddress?.address?.city}`
        city = city.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        if (city) {
            try {
                let responseClimate = await climateService.history(city, notNullToDate(currentDate, "yyyy-mm-dd"));

                const location = responseClimate;
                const climate = responseClimate.forecast.forecastday[0];
                const ClimateForecast = climate.hour;

                let hourCount;
                if (currentDate.getHours() <= 18) {
                    hourCount = currentDate.getHours() + 1;
                } else {
                    hourCount = 19;
                }

                const climehour = ClimateForecast.slice(hourCount, (hourCount + 5));

                let resultaClimateFilter = display.climate.filter((el: any) => el.code === climate.hour[currentDate.getHours()].condition.code);
                let climateDescription;
                if (currentDate.getHours() <= 18) {
                    climateDescription = resultaClimateFilter[0].day;
                } else {
                    climateDescription = resultaClimateFilter[0].night;
                }

                setClimateDescription(climateDescription);

                setClimeHour(climehour);
                setClimateDay(climate);
                setClimateLocation(location);
            } catch (error: any) {}
        }
    }

    const getVesselsCount = (movements: Array<any>) => {
        const jets = movements?.filter(movement => movement.vessel?.typeVessel === '34')?.length || 0
        const lanchas = movements?.filter(movement => movement.vessel?.typeVessel === '33')?.length || 0

        return {
            jets, lanchas
        }
    }

    const getResponsible = (column: any) => {
        if (column?.data?.length) {
            const responsible =  column.data.find((movement: any) => movement.vessel?.identifyKey === column.row.original.vessel?.identifyKey)
            if ((responsible) && (responsible.crews?.length > 0)) {
                return responsible.crews[0]?.conductor?.people?.fullname?.split(' ')[0]
            }
        }
        return '-'
    }

    const getDepartureHour = (column: any) => {
        if (column?.data?.length) {
            const departure =  column.data.find((movement: any) => movement.vessel?.identifyKey === column.row.original.vessel?.identifyKey)
            if (departure)
                return notNullToDate(departure.departure, "HH:MM")
        }
        return '-'
    }

    const columnsMovement = useMemo(() => [{
            Header: 'HORA | ID',
            accessor: "vessel.identifyKey",
            Cell: (row: any) => (
                <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                    <div className="white-space mt-3" style={{fontSize: '2.0rem'}}>{getDepartureHour(row)}</div>
                    <div className="white-space mt-1" style={{fontWeight: '100'}}>{row.value}</div>
                </div>
            )
        },{
            Header: 'TIPO | COR',
            accessor: "vessel",
            Cell: (row: any) => (
                getVessel(row.value?.typeVessel).id === VESSEL_LANCHA ? (
                    <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                        <div className="mt-3 d-flex justify-content-center mb-2">
                            <Image src={Lancha} className="jet" style={{height: 32}}/>
                            <small className="ms-2">{`${row.value.vesselSize}'`}</small>
                        </div>
                    <span className={styles.vesselColor} style={{backgroundColor: `${row.value?.color}`}}></span>
                    </div>
                ):(
                    <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                        <div className="d-flex justify-content-center mb-2">
                            <Image src={Jet} className="jet mt-3" style={{height: 32}}/>
                            <small className="ms-1 mt-3">{`10'`}</small>
                        </div>
                        <span className={styles.vesselColor} style={{backgroundColor: `${row.value?.color}`}}></span>
                    </div>
                )
            )
        },{
            Header: 'NOME EMB.',
            accessor: "vessel.name",
            Cell: (row: any) => <div className="mt-4" style={{fontSize: '2.0rem'}}>{row.value || '-'}</div>
        },{
            Header: display.label.brand,
            accessor: "vessel.brand",
            Cell: (row: any) => <div className="mt-4" style={{fontSize: '2.0rem'}}>{row.value}</div>
        },{
            Header: 'Mod. | Prop.',
            accessor: "vessel.model",
            Cell: (row: any) => (
                <div className="d-flex flex-column justify-content-center mb-2">
                    <div className="mt-3" style={{fontSize: '2.0rem'}}>{row.value || '-'}</div>
                    <div className="mt-1">{getResponsible(row)}</div>
                </div>
            )
        },{
            Header: 'PREV. RET',
            accessor: "arrival",
            Cell: (props: any) => (
                <div className="mt-4" style={{fontSize: '2.0rem'}}>{calculateEstimated(props.row.original)}</div>
            )
        },{
            Header: 'STATUS',
            accessor: "status",
            Cell: (row: any) => (
                <div className="mt-3 text-center d-flex justify-content-center">{getIcons(row.value)}</div>
            )
        }],
        // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

    const getIcons = (status: string) => {
        switch (status) {
            case '1':
                return (
                    <div className="d-flex flex-column align-items-center">
                        <FaWarehouse size={23} />
                        <small>Garagem</small>
                    </div>
                )
            case '2':
                return (
                    <div className="d-flex flex-column align-items-center">
                        <Image src={Abastecimento} className="jet" style={{ height: 23 , width: 23}}/>
                        <small>Abastecimento</small>
                    </div>
                )
            case '3':
                return (
                    <p style={{marginBottom: 0, marginTop: 3, backgroundColor: 'green', padding: 2, paddingLeft: 6, paddingRight: 6, borderRadius: '8px'}}>Finalizado</p>
                )
            case '4':
                return (
                    <div className="d-flex flex-column align-items-center">
                        <Image src={Higiene} className="jet" style={{height: 28 , width: 28}}/>
                        <small>Limpeza</small>
                    </div>
                )
            case '5':
                return (
                    <div className="d-flex flex-column align-items-center">
                        <div className="rotating">
                            <Image src={Navegando} className="jet" style={{ height: 23 , width: 23}}/>
                        </div>
                        <small>Navegando</small>
                    </div>
                )
            case '6':
                return (
                    <div className="d-flex flex-column align-items-center">
                        <Image src={Manutenca0_externa} className="jet" style={{ height: 30 , width: 30}}/>
                        <small>Reparo Externo</small>
                    </div>
                )
            case '7':
                return (
                    <div className="d-flex flex-column align-items-center">
                        <Image src={Movimento_entrada} className="jet" style={{ height: 32 , width: 32}}/>
                        <small>Entrando</small>
                    </div>
                )
            case '8':
                return (
                    <div className="d-flex flex-column align-items-center">
                        <Image src={Movimento_saida} className="jet" style={{ height: 32 , width: 32}}/>
                        <small>Saindo</small>
                    </div>
                )
            case '9':
                return (
                    <div className="d-flex flex-column align-items-center">
                        <FaTools className="jet mt-1" size={23} />
                        <small>Oficina</small>
                    </div>
                )
            default:
                break;
        }
    }

    function calculateEstimated(movement: any) {
        let arrival = arrivalForecast(
            notNullToDate(movement.dateMovement, "dd/mm/yyyy"),
            notNullToDate(movement.departure, "HH:MM"),
            movement.estimated
        );
        if (!isEmpty(movement.arrival)) 
            arrival = movement.arrival;
        else
            arrival = dateToString(toDate(arrival, "dd/mm/yyyy HH:MM:ss"), "HH:MM");
        return arrival;
    }

    async function handleMovement(baseDate: string) {
        if (props.partner) {
            const attributes = [] as any;
            const where = {
                dateMovement: {
                    start: removeFormatDate(baseDate + " 00:00:00"),
                    end: removeFormatDate(baseDate + " 23:59:59"),
                },
            } as any;
            const order = [["departure", "ASC"]] as any;
            where["partnerId"] = props.partner.id;

            const movements = await lclService.movementVesselFilter(attributes, where, order);

            let movementList = [{}] as any;
            let localPeoples = 0;
            let localBrowsing = 0;
            let localDelay = 0;
            let localAverageNavigationTime = "00:00";
            let localVesselInTheWater = 0;

            if (movements !== "undefined") {
                movementList = movements;

                localPeoples = 0;
                localBrowsing = 0;
                localDelay = 0;
                localAverageNavigationTime = "00:00";
                localVesselInTheWater = 0;

                let countTimeRamp = 0;
                let sumTimeRamp = 0;
                let countNavigationTime = 0;
                let sumNavigationTime = 0;

                movementList.forEach((movement: any) => {
                    localPeoples += movement.quantityPeople;
                    if (movement.status === "5") {
                        localBrowsing += 1;

                        const isDelay = browsingDelay(
                            movement.status,
                            notNullToDate(movement.dateMovement, "dd/mm/yyyy"),
                            notNullToDate(movement.departure, "HH:MM"),
                            movement.estimated
                        );
                        if (isDelay ) localDelay += 1;
                    } else if (movement.status === "2" || movement.status === "3") {
                        countTimeRamp += 1;

                        const forecast = new Date(movement.updated).getTime() - new Date(movement.departure).getTime();
                        sumTimeRamp += forecast;
                    } else if (movement.status === "1") {
                    } else if ((movement.status === "8")|| (movement.status === "9")) {
                        countNavigationTime += 1;
                        let arrivaleCalculate = new Date(movement.departure).setHours(new Date(movement.departure).getHours() + toFloat(movement.estimated))
                        const forecast = new Date(arrivaleCalculate).getTime() - new Date(movement.departure).getTime();
                        sumNavigationTime += forecast;
                    }
                    if (movement.status === "5" || movement.status === "8") {
                        localVesselInTheWater += 1;
                    //} else if(movement.status === "7"){
                        //  localDelay += 1;
                    }
                });

                let averageTimeRamp = sumTimeRamp / countTimeRamp;
                if (isNaN(averageTimeRamp)) averageTimeRamp = 0;

                let avarageNavigationTime = sumNavigationTime / countNavigationTime;
                if (isNaN(avarageNavigationTime)) avarageNavigationTime = 0;
                localAverageNavigationTime = notNullToDate(new Date(new Date().setTime(avarageNavigationTime)), "HH:MM");
        
                setPeoples(localPeoples);
                setBrowsing(localBrowsing);
                setDelay(localDelay);

                setAverageNavigationTime(localAverageNavigationTime);

                setMovements(movementList);
                setVesselsCount((getVesselsCount(movementList)))
            } else {
                setPeoples(0);
                setBrowsing(0);
                setDelay(0);

                setAverageNavigationTime("00:00");

                setMovements([]);
            }

            return { list: movementList, count: localVesselInTheWater };
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi definido uma Marina para exibir as movimentações !');
            navigator(`/portal`);
        }
    }

    function handleGraficHourlyOccupation(baseDate: string, movementList: any) {
        const startHour = toDate(removeFormatDate(baseDate + " 00:00:00"), "yyyy-mm-ddTHH:MM:ss.sssZ");
        const finishHour = toDate(removeFormatDate(baseDate + " 23:59:59"), "yyyy-mm-ddTHH:MM:ss.sssZ");

        let dataGrafigo = [];
        let dateAux = startHour;

        while (dateAux < finishHour) {
            let hoursAux = dateToString(dateAux, "HH");
            let countAux = 0;
            for (var i = 0; i < movementList?.length; i++) {
                const hours = dateToString(toDate(movementList[i].departure, "yyyy-mm-ddTHH:MM:ss.sssZ"), "HH");
                if (hoursAux === hours) {
                    countAux += isNaN(movementList[i].quantityPeople) ? 0 : movementList[i].quantityPeople;
                }
            }

            const item = {
                hour: hoursAux,
                count: countAux,
            };
            dataGrafigo[dataGrafigo?.length] = item;
            dateAux = new Date(dateAux.setHours(dateAux.getHours() + 1));
        }
    }

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            handleClickRefresh(toDate(dateStart, "dd/mm/yyyy"))
            setLoading(true);
        }

        let refreshInterval = localStorage.getItem('timeRefresh');
        if (refreshInterval === null) {
            refreshInterval = fiveMinutes
        }

        const intervalFunction = setInterval(() => {
            handleClickRefresh(toDate(dateStart, "dd/mm/yyyy"))
        }, Number(refreshInterval));

        return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
            clearInterval(intervalFunction);
        };
    }, []);
    //useEffect

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const handlePortraitOrientation = () => {
        handleClickRefresh(currentDate)
        setPortraitOrientation(!portraitOrientation)
    }

    function viewDashboard() {
        return (
            <Container fluid className="page-body movements">
                <Tooltip title={portraitOrientation ? 'Desativar visualização vertical' :'Ativar visualização vertical'}>
                    <FormGroup className={styles.switch} onClick={handlePortraitOrientation}>
                        <ScreenRotationIcon className="isClickable" sx={{fontSize: 26}} color={portraitOrientation ? 'primary' : 'inherit'} />
                    </FormGroup>

                </Tooltip>
                <h1 className="mb-2 text-center">MOVIMENTAÇÃO</h1>

                <Row sm="12" className={styles.mainInfo}>
                    <Col sm={portraitOrientation ? '5' : "6"}>
                        <div className={`d-flex justify-content-between align-items-center p-3 ${styles.clockTitle}`}>
                            {props.partner?.company?.image ? (
                                <img src={props.partner?.company?.image} alt="Logo Marina" className={styles.partnerLogo} />
                            ):(
                                <div className={styles.partnerLogoPlaceholder}>
                                    <p>Logo Marina</p>
                                </div>
                            )}
                            <h1 className="me-3">{time.toLocaleTimeString()}</h1>
                        </div>
                    </Col>
                    <Col  sm={portraitOrientation ? '7' : "6"}>
                    {typeof climateDay !== "undefined" && climateDay.hour && (
                        <Container>
                            <Row>
                                <Col sm="12" className="climate-partials">
                                    <div className="climate-current">
                                        <div className="climate-current-main">
                                            <div className="current-main me-5">
                                                
                                                <img className="climate-current-icon" src={climateDay.hour[hourNow].condition.icon} alt={climateDay.hour[hourNow].condition.text} />
                                                <div>
                                                    <div className="climate-current-temperature">
                                                        {toInt(climateDay.hour[hourNow].temp_c)}
                                                        <span>ºC</span>
                                                    </div>
                                                    <div className="climate-current-label">
                                                        {climateDescription}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="climate-location">
                                                <span>
                                                    {" "}
                                                    {climateLocation.location.name},{" "}
                                                    {climateLocation.location.region}{" "}
                                                </span>
                                            </div>
                                            <div className="climate-current-information">
                                            {portraitOrientation ? (
                                                <span className="climate-current-temperature d-flex flex-column align-items-center me-3">

                                                Chuva{" "}
                                                <span>
                                                {climateDay.hour[hourNow].chance_of_rain}%

                                                </span>
                                            </span>
                                            ):(
                                                <span className="climate-current-temperature me-3">
                                                Chuva{" "}
                                                {climateDay.hour[hourNow].chance_of_rain}%
                                            </span>

                                            )}
                                            {portraitOrientation ? (
                                            <span className="climate-current-temperature d-flex flex-column align-items-center me-3">

                                                    Umidade 
                                                    
                                                    <span>

                                                    {climateDay.hour[hourNow].humidity}%
                                                    </span>
                                                </span>
                                            ):(
                                            <span className="climate-current-temperature me-3">
                                                Umidade {climateDay.hour[hourNow].humidity}%
                                            </span>

                                            )}

                                                {portraitOrientation ? (
                                                    <span className="climate-current-temperature d-flex flex-column align-items-center">
                                                            Vento
                                                        <span>
                                                        {climateDay.hour[hourNow].wind_kph} km/h
                                                        </span>
                                                    </span> 
                                                ):(

                                                    <span className="climate-current-temperature">
                                                        Vento {climateDay.hour[hourNow].wind_kph} km/h
                                                        
                                                    </span>
                                                )}
                                                </div>

                                        </div>
                                        <div className="climate-hour">
                                            <div className="card-hour">
                                                <span className="hour">
                                                    {climateHour[0].time.substring(11)}
                                                </span>
                                                <img className="climate-current-icon" src={climateHour[0].condition.icon} alt={climateHour[0].condition.text} />
                                                <div className="climate-current-temperature">
                                                    {toInt(climateHour[0].temp_c)} ºC
                                                </div>
                                                <div className="climate-current-temperature">
                                                    <FaTint size={10} />
                                                    {climateHour[0].chance_of_rain}%
                                                </div>
                                            </div>
                                            <div className="card-hour">
                                                <span className="hour">
                                                    {climateHour[1].time.substring(11)}
                                                </span>
                                                <img className="climate-current-icon" src={climateHour[1].condition.icon} alt={climateHour[1].condition.text} />
                                                <div className="climate-current-temperature">
                                                    {toInt(climateHour[1].temp_c)} ºC
                                                </div>
                                                <div className="climate-current-temperature">
                                                    <FaTint size={10} />
                                                    {climateHour[1].chance_of_rain}%
                                                </div>
                                            </div>
                                            <div className="card-hour">
                                                <span className="hour">
                                                    {climateHour[2].time.substring(11)}
                                                </span>
                                                <img className="climate-current-icon" src={climateHour[2].condition.icon} alt={climateHour[2].condition.text} />
                                                <div className="climate-current-temperature">
                                                    {toInt(climateHour[2].temp_c)} ºC
                                                </div>
                                                <div className="climate-current-temperature">
                                                    <FaTint size={10} />
                                                    {climateHour[2].chance_of_rain}%
                                                </div>
                                            </div>
                                            <div className="card-hour">
                                                <span className="hour">
                                                    {climateHour[3].time.substring(11)}
                                                </span>
                                                <img className="climate-current-icon" src={climateHour[3].condition.icon} alt={climateHour[3].condition.text} />
                                                <div className="climate-current-temperature">
                                                    {toInt(climateHour[3].temp_c)} ºC
                                                </div>
                                                <div className="climate-current-temperature">
                                                    <FaTint size={10} />
                                                    {climateHour[3].chance_of_rain}%
                                                </div>
                                            </div>
                                            <div className="card-hour">
                                                <span className="hour">
                                                    {climateHour[4].time.substring(11)}
                                                </span>
                                                <img className="climate-current-icon" src={climateHour[4].condition.icon} alt={climateHour[4].condition.text} />
                                                <div className="climate-current-temperature">
                                                    {toInt(climateHour[4].temp_c)} ºC
                                                </div>
                                                <div className="climate-current-temperature">
                                                    <FaTint size={10} />
                                                    {climateHour[4].chance_of_rain}%
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    )}
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="dashboard-sweet mt-3">
                        <Row>
                            <Col sm="12">
                                <div className="movement-resume mb-0 d-flex justify-content-between">
                                    <div className="movement-resume-item">
                                        <div className="d-flex flex-column align-items-center">
                                            <div className="movement-resume-label">EMBARCAÇÕES</div>
                                            <div className="d-flex justify-content-between w-100">
                                                <div className="d-flex me-5 align-items-center">
                                                    <Image src={Lancha} className="jet me-3" style={{ height: 48 , width: 48}} />
                                                    <div className="movement-resume-data">{vesselsCount?.jets}</div>
                                                </div>
                                                <div className="d-flex align-items-center">
                                                    <div className="d-flex justify-content-center">
                                                        <Image src={Jet} className="jet me-3" style={{ height: 48 , width: 48}} />
                                                    </div>
                                                    <div className="movement-resume-data">{vesselsCount?.lanchas}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="movement-resume-item">
                                        <div className="movement-resume-img">
                                            <div className="movement-resume-label">VIDAS</div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <FaUsers size={40} className="me-3" />
                                                <div className="movement-resume-data">{peoples}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="movement-resume-item">
                                        <div className="movement-resume-img">
                                            <div className="movement-resume-label">NAVEGANDO</div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="rotating">
                                                    <Image src={Navegando} className="jet me-3" style={{ height: 38 , width: 38}} />
                                                </div>
                                                <div className="movement-resume-data">{browsing}</div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="movement-resume-item">
                                        <div className="movement-resume-img">
                                            <div className="movement-resume-label">ATRASO</div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <BsClockHistory size={38} className="me-3" />
                                                <div className="movement-resume-data">{delay} </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="movement-resume-item">
                                        <div className="d-flex flex-column align-items-center">
                                            <div className="movement-resume-label" style={{marginTop: '-10px'}}>TEMP. MED. NAVEG.</div>
                                            <div className="movement-resume-label mt-4" style={{fontSize: '2rem'}}>{averageNavigationTime}</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="12" className="page-sweet mt-0">
                                <Card style={{ minHeight: 'fit-content' }}>
                                    <Card.Body style={{paddingLeft: 0, paddingRight: 0, paddingBottom: 0, marginBottom: 0}}>
                                        <TableContainer autoPagination={true} hidePagination={true} linesSize={portraitOrientation ? 15 : 5} columns={columnsMovement} data={movements} viewFilter={false} className={styles.table} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Image src={selo} className={`${styles.logoPartner}`} style={{filter: 'invert(80%) sepia(100%) saturate(2%) hue-rotate(234deg) brightness(200%) contrast(101%)'}} />
            </Container>
        );
    }

    return (
        <div className="dashboard">
            <DashboardHeader title='' description={description} onClick_ShowDefinedPartner={props.onClick_ShowDefinedPartner} />
            {viewDashboard()}
        </div>
    );
}

export default MaritimeDashboard;
