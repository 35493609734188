import { useEffect, useState } from "react";
import { Col, Dropdown, Form, FormControl, FormGroup, InputGroup, Row } from "react-bootstrap";
import cduService from "../../../../services/cdu.service";
import { toString, toLowerCase, toInt } from "../../../../utilities/auxiliary-functions";
import { CategoryType } from "../../../../utilities/types";

interface Props {
    translate: any,
    title: any,
    isEdit: boolean,

    team: any,
    group: any,
    subgroup: any,
    name: any,

    setTeam: any,
    setGroup: any,
    setSubgroup: any,
    setName: any,

    changeTeam?: any
}

function CategoryRecord(props: Props) {

    const display = props.translate.data;

    const [ memoryGroup, setMemoryGroup ] = useState([] as any);
    const [ memorySubgroup, setMemorySubgroup ] = useState([] as any);

    async function initialize() {
        changeTeam(props.team);
    }

    useEffect(() => {
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    function onChange_Team(event: any) {
        const team = event.target.value;
        props.setTeam(team);
        changeTeam(team);
    }

    async function changeTeam(team: string) {
        const attributes = [] as any;
        const where = {} as any;
        const order = [] as any;

        where['team'] = team;
        
        const categories = await cduService.categoryFilter(attributes, where, order);
        const memory = [] as any;
        categories.forEach((category: any, index: number) => {
            if (memory.indexOf(category.group) === -1)
                memory[memory.length] = category.group;
        });
        setMemoryGroup(memory);
    }

    async function onSelect_Group(eventKey: any) {
        props.setGroup(eventKey);

        const attributes = [] as any;
        const where = {} as any;
        const order = [] as any;

        where['team'] = props.team;
        where['group'] = eventKey;
        
        const categories = await cduService.categoryFilter(attributes, where, order);
        const memory = [] as any;
        categories.forEach((category: any, index: number) => {
            if (memory.indexOf(category.subgroup) === -1)
                memory[memory.length] = category.subgroup;
        });
        setMemorySubgroup(memory);
    }
    
    function onBlur_Group(event: any) {
        if (memoryGroup.indexOf(event.target.value) === -1)
            memoryGroup[memoryGroup.length] = event.target.value;
    }

    function onSelect_Subgroup(eventKey: any) {
        props.setSubgroup(eventKey);
    }

    function viewCategoryCadaster() {
        return (
            <>
            <Row>
                <Col sm="12">
                    <fieldset>
                        <legend>{display.label.general_data}</legend>
                        <Row>
                            <Col sm="2">
                                <Row>
                                    <Col sm="12">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-team">{display.label.modulo}</Form.Label>
                                            { CategoryType.map((iterator: any, idx: number) => (
                                                <Form.Check type="radio"  key={idx}
                                                    label={display.label[toLowerCase(iterator.name)]}
                                                    checked={iterator.id === toInt(props.team)}
                                                    name="team"
                                                    value={iterator.id}
                                                    onChange={(e) => onChange_Team(e)}
                                                />
                                            )) }
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm="10">
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-group">{display.label.category}</Form.Label>
                                            <Dropdown as={InputGroup} align={{ lg: 'end' }}  onSelect={(e) => onSelect_Group(e)}>
                                                <Form.Control 
                                                    value={toString(props.group)}
                                                    onChange={e => props.setGroup(e.target.value)}
                                                    onBlur={(e) => onBlur_Group(e)}
                                                    required
                                                />
                                                <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
                                                <Dropdown.Menu>
                                                    <Dropdown.Header>Selecione uma das opções abaixo</Dropdown.Header>
                                                    { memoryGroup.map(((name: any, idx: number) => (
                                                        <Dropdown.Item key={idx} eventKey={name}>{name}</Dropdown.Item>
                                                    ))) }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-subgroup">{display.label.subgroup}</Form.Label>
                                            <Dropdown as={InputGroup} align={{ lg: 'end' }} onSelect={(e) => onSelect_Subgroup(e)} >
                                                <Form.Control
                                                    value={toString(props.subgroup)}
                                                    onChange={e => props.setSubgroup(e.target.value)}
                                                />
                                                <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" />
                                                <Dropdown.Menu>
                                                    <Dropdown.Header>Selecione uma das opções abaixo</Dropdown.Header>
                                                    { memorySubgroup.map(((name: any, idx: number) => (
                                                        <Dropdown.Item key={idx} eventKey={name}>{name}</Dropdown.Item>
                                                    ))) }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-name">{display.label.description}</Form.Label>
                                            <FormControl type="text" id="form-name" name="name" placeholder={display.example.name}
                                                value={toString(props.name)}
                                                onChange={e => props.setName(e.target.value)}
                                                required
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </fieldset>
                </Col>
            </Row>
        </>
        );
    }

    return (
        <>
            {viewCategoryCadaster()}
        </>
    );

}

export default CategoryRecord;