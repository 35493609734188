import { Col, Form, FormControl, FormGroup, Row } from "react-bootstrap";
import { toString } from '../../../../utilities/auxiliary-functions';
import { dataMask, floatMask, horaMask } from "../../../../utilities/masks";
import { isEmpty } from "../../../../utilities/validators";
import { SectorTypes } from "../../../../utilities/types";

interface Props {
    translate: any,
    title: any,
    isEdit: boolean,

    sector?: any,
    occupation?: any,
    credential?: any,
    remuneration?: any,
    admission?: any,
    dismissal?: any,
    workdayFirst?: any,
    workdayStop?: any,
    workdayStart?: any,
    workdayLast?: any,
    setSector?: any,
    setOccupation?: any,
    setCredential?: any,
    setRemuneration?: any,
    setAdmission?: any,
    setDismissal?: any,
    setWorkdayFirst?: any,
    setWorkdayStop?: any,
    setWorkdayStart?: any,
    setWorkdayLast?: any
}

function WorkDataRecord(props: Props) {

    const display = props.translate.data;

    return (
        <>
            <Row>
                <Col sm="4">
                    <FormGroup>
                        <Form.Label htmlFor="form-sector">{display.label.sector}</Form.Label>
                        <Form.Select id="form-sector" name="sector"
                            value={toString(props.sector)}
                            onChange={(e: any) => props.setSector(e.target.value)}
                            required
                        >
                            { isEmpty(props.sector) ? (
                                <option value="">...</option>
                            ) : null }
                            { SectorTypes.map((iterator: any, idx: number) => (<option key={idx} value={iterator.name}>{iterator.description}</option>)) }
                        </Form.Select>
                    </FormGroup>
                </Col>
                <Col sm={(props.sector === 'workshop' || props.sector === 'operation') ? "5" : "8"}>
                    <FormGroup>
                        <Form.Label htmlFor="form-occupation">{display.label.occupation}</Form.Label>
                        <FormControl type="text" id="form-occupation" name="occupation" placeholder={display.example.occupation}
                            value={toString(props.occupation)}
                            onChange={e => props.setOccupation(e.target.value)}
                            required
                        />
                    </FormGroup>
                </Col>
                {(props.sector === 'workshop' || props.sector === 'operation') && (
                <Col sm="3">
                    <FormGroup>
                        <Form.Label htmlFor="form-credential">{display.label.credential}</Form.Label>
                        <FormControl type="text" id="form-credential" name="credential" placeholder={display.example.credential}
                            value={toString(props.credential)}
                            onChange={e => props.setCredential(e.target.value)}
                        />
                    </FormGroup>
                </Col>
                )}
            </Row>
            <Row>
                <Col sm="4">
                    <FormGroup>
                        <Form.Label htmlFor="form-remuneration">{display.label.fixed_remuneration}</Form.Label>
                        <FormControl type="text" id="form-remuneration" name="remuneration" placeholder={display.example.remuneration}
                            maxLength={10}
                            className="text-right"
                            value={props.remuneration}
                            onChange={e => props.setRemuneration(floatMask(e.target.value))}
                        />
                    </FormGroup>
                </Col>
                <Col sm="4">
                    <FormGroup>
                        <Form.Label htmlFor="form-admission">{display.label.admission}</Form.Label>
                        <FormControl type="text" id="form-admission" name="admission" maxLength={10} placeholder={display.example.admission}
                            value={toString(props.admission)}
                            onChange={e => props.setAdmission(dataMask(e.target.value))}
                        />
                    </FormGroup>
                </Col>
                <Col sm="4">
                    <FormGroup>
                        <Form.Label htmlFor="form-dismissal">{display.label.dismissal}</Form.Label>
                        <FormControl type="text" id="form-dismissal" name="dismissal" maxLength={10} placeholder={display.example.dismissal}
                            value={toString(props.dismissal)}
                            onChange={e => props.setDismissal(dataMask(e.target.value))}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    <Form.Label htmlFor="form-workday-first">{display.label.expected_work_day}</Form.Label>
                </Col>
            </Row>
            <Row>
                <Col sm="3">
                    <FormControl type="text" id="form-workday-first" name="workday-first" maxLength={5} placeholder={display.example.workday_first}
                        value={toString(props.workdayFirst)}
                        onChange={e => props.setWorkdayFirst(horaMask(e.target.value))}
                    />
                </Col>
                <Col sm="3">
                    <FormControl type="text" id="form-workday-stop" name="workday-stop" maxLength={5} placeholder={display.example.workday_stop}
                        value={toString(props.workdayStop)}
                        onChange={e => props.setWorkdayStop(horaMask(e.target.value))}
                    />
                </Col>
                <Col sm="3">
                    <FormControl type="text" id="form-workday-start" name="workday-start" maxLength={5} placeholder={display.example.workday_start}
                        value={toString(props.workdayStart)}
                        onChange={e => props.setWorkdayStart(horaMask(e.target.value))}
                    />
                </Col>
                <Col sm="3">
                    <FormControl type="text" id="form-workday-last" name="workday-last" maxLength={5} placeholder={display.example.workday_last}
                        value={toString(props.workdayLast)}
                        onChange={e => props.setWorkdayLast(horaMask(e.target.value))}
                    />
                </Col>
            </Row>
        </>
    );

}

export default WorkDataRecord;
