import { Link } from "react-router-dom";

interface Props {
}

function MaintenancePage(props: Props) {

    return (
        <>
            <div>Maintenance</div>
            <Link to="/">retornar a página inicial</Link>
        </>
    )
}

export default MaintenancePage;
