import { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import NavMenu from "../components/nav-menu.component";
import SideMenu from "../components/side-menu.component";
import { TranslateContext } from "../contexts/translate.context";
import authService from "../services/auth.service";
import MemberCadastre from "./cadastres/member.cadastre";
import PartnerCadastre from "./cadastres/partner.cadastre";
import ShipCadastre from "./cadastres/vessel.cadastre";
import DashboardPage from "./dashboards/dashboard";
import MaritimeDashboard from "./dashboards/maritime.dashboard";
import MonitoringDashboard from "./dashboards/monitoring.dashboard";
import MovementDashboard from "./dashboards/movement.dashboard";
import FinancialDashboard from "./dashboards/financial.dashboard";
import ErrorPage from "./error.page";
import ConciliationsFinancial from "./financial/conciliations.financial";
import ContractLauchersFinancial from "./financial/launchers.financial";
import ContratctsFinancial from "./financial/contratcts.financial";
import MovementsOperation from "./operations/movements.operation";
import ProfilePage from "./others/profile.page";
import SettingsPage from "./others/settings.page";
import KeepBoatsOperation from "./operations/keep-boats.operation";
import CategoryCadastre from "./cadastres/category.cadastre";
import PurveyorCadastre from "./cadastres/purveyor.cadastre";
import MechanicCadastre from "./cadastres/mechanic.cadastre";
import ProductCadastre from "./cadastres/product.cadastre";
import BudgetOffice from "./offices/budget.office";
import ScheduleOffice from "./offices/schedule.office";
import MenuAccessPage from "./others/menu-access.page";
import UserAccessPage from "./others/user-access.page";
import { Badge, Col, Form, ListGroup, Modal, Row } from "react-bootstrap";
import { cnpjMask } from "../utilities/masks";
import { useAlertMessageContext } from "../contexts/alert-message.context";
import lclService from "../services/lcl.service";
import { AlertMessageEnum } from "../utilities/types";
import ContractManagementFinancial from "./financial/ContractManagementFinancial";
import MovementsHistoryOperation from "./operations/movement-history.operation";
import { FaTimes } from "react-icons/fa";
import { Button } from '@mui/material';
import AssessmentsOperation from "./operations/assessments.operation";
import ContractCustomerFinancial from "./financial/contract-customer.financial";
import EmployeeCadastre from "./cadastres/employee.cadastre";


interface Props {

}

function PortalPage(props: Props) {
    
    const alertContext = useAlertMessageContext();
    const navigator = useNavigate();

    const { translate } = useContext(TranslateContext) as any;
    const partner = authService.currentPartner();

    const isDashboard = (window.location.pathname.indexOf('dashboard') > -1);
    const isSetting = (window.location.pathname.indexOf('settings') > -1);
    const [ inactive, setInactive ] = useState(isDashboard || isSetting);


    const [ selectPartner, setSelectPartner ] = useState({} as any);
    const [ showDefinedPartner, setShowDefinedPartner ] = useState(false);
    const [ userPartners, setUserPartners ] = useState([] as any);

    /*
    window.addEventListener("beforeunload", function(event) {
        event.preventDefault();
        event.returnValue = "Mensagem de aviso"; 
        return "Mensagem de aviso";
    });
    */

    function onUnload(event: any) {
        event.preventDefault();
        return "Mensagem de aviso";
    }

    function onClickBrand_Click(event: any) {
        // event.preventDefault();
        setInactive(!inactive);
    }

    function onClick_Logout(event: any) {
        event.preventDefault();

        authService.logout();
        window.location.href = '/';
    }

    function onMouseMove(event: any) {
        authService.validToken(true);
    }

    function onClose_DefinedPartner(event: any) {
        setShowDefinedPartner(false);

        authService.changePartner(selectPartner);
        navigator('/portal', { replace: true });
    }
    
    function isUserPartner(partnerId: number) {
        var ckecked = selectPartner ? partnerId === selectPartner.id : false;
        return ckecked;
    }

    function onChange_UserPartner(event: any, partnerId: number) {
        for (var up = 0; up < userPartners.length; up++) {
            const userPartner = userPartners[up];
            if (userPartner.partnerId === partnerId) {
                setSelectPartner(userPartner.partner);
                break;
            }
        }
    }

    function onClick_ShowDefinedPartner(event: any, action: boolean) {
        event.preventDefault();
        if (userPartners.length > 1)
            setShowDefinedPartner(true);
    }

    async function searchfilter() {
        try {
            const user = authService.currentUser();
            const localUserPartners = await lclService.partnersUser(user.id);
            const localPartners = [] as any;
            for (var up = 0; up < localUserPartners.length; up++) {
                const userPartner = localUserPartners[up];

                const localPartner = await lclService.partnerById(userPartner.partnerId);
                localPartners[localPartners.length] = {
                    partnerId: userPartner.partnerId,
                    principal: userPartner.principal,
                    partner: localPartner,
                    userId: userPartner.userId
                }
            }
            setUserPartners(localPartners);
        } catch (error: any) {
            alertContext.show(AlertMessageEnum.FAIL, 'Portal', error);
        }
    }

    useEffect(() => {
        setSelectPartner(partner);
        searchfilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDefinedPartner]);

    useEffect(() => {
        window.addEventListener("beforeunload", onUnload);
        return () => {
            window.removeEventListener("beforeunload", onUnload);
            setInterval(() => {
                authService.validToken(false);
            }, 3600);
        }
    });

    const handleClose = () => setShowDefinedPartner(false);

    return (
        <>
            <div className="portal" onMouseMove={ e => onMouseMove(e) }>
                <SideMenu
                    translate={translate} 
                    partner={partner}
                    /* 
                    smooth={smooth}
                    name={userData.name} 
                    profile={userData.profile} 
                    email={userData.email} 
                    telephone={userData.telephone}
                    partner={userData.partner}
                    onChangeProfile={(value: any) => {
                        setViewProfile(value);
                    }}
                    */
                    onCollapse={inactive}
                    onCloseSideMenu={(value: any) => {
                        setInactive(value);
                    }}
                    onSignout={onClick_Logout}
                />
                <div className="main">
                    <NavMenu translate={translate} onBrandClick={ (e: any) => onClickBrand_Click(e) } onClickSair={ (e: any) => onClick_Logout(e) } onCloseSideMenu={(value: any) => {
                        setInactive(value);
                    }} />
                    <Routes>
                        <Route index element={<DashboardPage translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />

                        <Route path='/profile' element={<ProfilePage translate={translate} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        <Route path='/settings' element={<SettingsPage translate={translate} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        <Route path='/menu_access' element={<MenuAccessPage translate={translate} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        <Route path='/user_access' element={<UserAccessPage translate={translate} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />

                        <Route path='/dashboard/maritime' element={<MaritimeDashboard translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        <Route path='/dashboard/financial' element={<FinancialDashboard translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        <Route path='/dashboard/movement' element={<MovementDashboard translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        <Route path='/dashboard/monitoring' element={<MonitoringDashboard translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        
                        <Route path='/cadastre/category' element={<CategoryCadastre translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />}>
                            <Route path='/cadastre/category/:categoryId' element={<CategoryCadastre translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        </Route>
                        <Route path='/cadastre/member' element={<MemberCadastre translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />}>
                            <Route path='/cadastre/member/:memberId' element={<MemberCadastre translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        </Route>
                        <Route path='/cadastre/partner' element={<PartnerCadastre translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />}>
                            <Route path='/cadastre/partner/:partnerId' element={<PartnerCadastre translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        </Route>
                        <Route path='/cadastre/vessel' element={<ShipCadastre translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />}>
                            <Route path='/cadastre/vessel/:vesselId' element={<ShipCadastre translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        </Route>
                        <Route path='/cadastre/purveyor' element={<PurveyorCadastre translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />}>
                            <Route path='/cadastre/purveyor/:purveyorId' element={<PurveyorCadastre translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        </Route>
                        <Route path='/cadastre/employee' element={<EmployeeCadastre translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />}>
                            <Route path='/cadastre/employee/:employeeId' element={<EmployeeCadastre translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        </Route>
                        <Route path='/cadastre/mechanic' element={<MechanicCadastre translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />}>
                            <Route path='/cadastre/mechanic/:mechanicId' element={<MechanicCadastre translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        </Route>
                        <Route path='/cadastre/product' element={<ProductCadastre translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />}>
                            <Route path='/cadastre/product/:productId' element={<ProductCadastre translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        </Route>

                        <Route path='/operation/movement' element={<MovementsOperation translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />}>
                            <Route path='/operation/movement/:movementId' element={<MovementsOperation translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        </Route>
                        <Route path='/operation/movement-history' element={<MovementsHistoryOperation translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />}>
                            <Route path='/operation/movement-history/:movementHistoryId' element={<MovementsHistoryOperation translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        </Route>
                        <Route path='/operation/keep_boat' element={<KeepBoatsOperation translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />}>
                            <Route path='/operation/keep_boat/:bookcaseId' element={<KeepBoatsOperation translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        </Route>
                        <Route path='/operation/assessment' element={<AssessmentsOperation translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />}>
                            <Route path='/operation/assessment/:assessmentId' element={<AssessmentsOperation translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        </Route>
                        
                        <Route path='/office/schedule' element={<ScheduleOffice translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />}>
                            <Route path='/office/schedule/:scheduleId' element={<ScheduleOffice translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        </Route>
                        <Route path='/office/budget' element={<BudgetOffice translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />}>
                            <Route path='/office/budget/:budgetId' element={<BudgetOffice translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        </Route>

                        <Route path='/financial/contract' element={<ContratctsFinancial translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />}>
                            <Route path='/financial/contract/:contractId' element={<ContratctsFinancial translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        </Route>
                        <Route path='/financial/launcher' element={<ContractLauchersFinancial translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />}>
                            <Route path='/financial/launcher/:launcherId' element={<ContractLauchersFinancial translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        </Route>
                        <Route path='/financial/contract-management' element={<ContractManagementFinancial translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />}>
                            <Route path='/financial/contract-management/:contractId' element={<ContractManagementFinancial translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        </Route>
                        <Route path='/financial/contract-customer' element={<ContractCustomerFinancial translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />}>
                            <Route path='/financial/contract-customer/:contractId' element={<ContractCustomerFinancial translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        </Route>
                        <Route path='/financial/conciliation' element={<ConciliationsFinancial translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />}>
                            <Route path='/financial/conciliation/:conciliationId' element={<ConciliationsFinancial translate={translate} partner={partner} onClick_ShowDefinedPartner={onClick_ShowDefinedPartner} />} />
                        </Route>

                        <Route path="*" element={<ErrorPage type={'Não mapeado em Portal'} />} />
                    </Routes>
                </div>
                <Modal
                    show={showDefinedPartner}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title>Definir Parceiro</Modal.Title>
                        <div className="card-actions float-right">
                            <FaTimes className="isClickable" size={22} onClick={() => setShowDefinedPartner(false)} />
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col>
                                <ListGroup className="partner">
                                    { userPartners.map((userPartner: any, idx: number) => (
                                        <ListGroup.Item key={`listgroup-${idx}`} className="d-flex justify-content-between align-items-start"
                                            onClick={e => onChange_UserPartner(e, userPartner.partner.id)}
                                        >
                                            <Form.Check key={`check-${idx}`}
                                                type='radio'
                                                id={`${userPartner.partnerId}-radio`}
                                                checked={isUserPartner(userPartner.partner.id)}
                                                onChange={e => onChange_UserPartner(e, userPartner.partner.id)}
                                            />
                                            <div key={`label-${idx}`} className="w-90">
                                                {userPartner.partner.company && cnpjMask(userPartner.partner.company.registrationPj)}<br />{userPartner.partner.company && userPartner.partner.company.socialReason}
                                            </div>
                                            <Badge key={`badge-${idx}`} className="badge-principal">{userPartner.principal ? 'Principal' : ''}</Badge>
                                        </ListGroup.Item>
                                    )) }
                                </ListGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <span></span>
                        <Button variant="contained" onClick={(e: any) => onClose_DefinedPartner(e)}>Definir</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default PortalPage;
