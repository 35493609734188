import { useEffect, useState } from "react";
import { Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import { useAlertMessageContext } from "../../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../../contexts/confirm-message.context";
import cduService from "../../../services/cdu.service";
import srvService from "../../../services/srv.service";
import { notNullToDate } from "../../../utilities/auxiliary-functions";
import { cpfMask, numberMask, phoneMask, removeFormatDate, removeMask, zipcodeMask } from "../../../utilities/masks";
import { isCpf, isEmpty } from "../../../utilities/validators";
import { Button } from '@mui/material'
import { AlertMessageEnum } from "../../../utilities/types";
import { openDocument } from "../../../utilities/view-document";
import EmployeeRecord from "./records/employee.record";
import { USER_SITUATION_REGISTERED, UserSituationEnum } from "../../../utilities/constants";

interface Props {
    translate: any,
    partner: any,

    title: string,

    employeeId: string,
    setRecord: any
}

function EmployeeForm(props: Props) {

    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();

    const display = props.translate.data;

    const [ invalidatedForm, setInvalidatedForm ] = useState(false);
    const [ isEditForm, setEditForm ] = useState(true);

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ originRecord, setOriginRecord ] = useState({} as any);
    const [ sending, setSending ] = useState(false);

    /* TRATAMENTO PARTICULAR DO OBJETO QUE ESTA SENDO MANTIDO */
    // Employee
    const [ employeeId, setEmployeeId ] = useState(0);
    const [ companyId, setCompanyId ] = useState(0);
    const [ employeeType, setEmployeeType ] = useState('');
    const [ created, setCreated ] = useState('');
    const [ updated, setUpdated ] = useState('');

    // PersonalData
    const [ peopleId, setPeopleId ] = useState(0); 
    const [ tratament, setTratament ] = useState(''); 
    const [ fullname, setFullname ] = useState(''); 
    const [ gender, setGender ] = useState('');
    const [ birth, setBirth ] = useState('');

    // Phone
    const [ phoneId, setPhoneId ] = useState(0);
    const [ norm, setNorm ] = useState('');
    const [ operator, setOperator ] = useState('');
    const [ connection, setConnection ] = useState('');
    const [ sms, setSms ] = useState(0);
    const [ zap, setZap ] = useState(0);
    const [ major, setMajor ] = useState(0);

    const [ nationality, setNationality ] = useState('');
    const [ naturalness, setNaturalness ] = useState('');

    // PersonalDocument
    const [ registrationPf, setRegistrationPf ] = useState('');
    const [ registrationIdentity, setRegistrationIdentity ] = useState('');
    const [ issuerIdentity, setIssuerIdentity ] = useState('');
    const [ stateIdentity, setStateIdentity ] = useState('');
    const [ expeditionIdentity, setExpeditionIdentity ] = useState('');
    const [ driversLicense, setDriversLicense ] = useState('');
    const [ categoryDriversLicense, setCategoryDriversLicense ] = useState('');
    const [ validityDriversLicense, setValidityDriversLicense ] = useState('');

    // Address
    const [ addressId, setAddressId ] = useState(0);
    const [ zipcode, setZipcode ] = useState('');
    const [ state, setState ] = useState('');
    const [ city, setCity ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ place, setPlace ] = useState('');
    const [ habitation, setHabitation ] = useState('');
    const [ complement, setComplement ] = useState('');

    // WorkData
    const [ sector, setSector ] = useState('');
    const [ occupation, setOccupation ] = useState('');
    const [ credential, setCredential ] = useState('');
    const [ admission, setAdmission ] = useState('');
    const [ dismissal, setDismissal ] = useState('');
    const [ situation, setSituation ] = useState('');
    const [ remuneration, setRemuneration ] = useState('');
    const [ workdayFirst, setWorkdayFirst ] = useState('');
    const [ workdayStop, setWorkdayStop ] = useState('');
    const [ workdayStart, setWorkdayStart ] = useState('');
    const [ workdayLast, setWorkdayLast ] = useState('');

    const title : string = display.title.employee_cadastre;

    type ArtifactType = { id : number, norm : string, source : string, identy : string, document : string, filename : string };
    const DEFAULT_ARTIFACT: ArtifactType = { id : 0, norm : '', source : '', identy : '', document : '', filename : '' };
    
    const [ proofOfResidenceDocument, setProofOfResidenceDocument ] = useState(DEFAULT_ARTIFACT);

    const [ image, setImage ] = useState(''); 
    const [ email, setEmail ] = useState(''); 
    const [ userId, setUserId ] = useState(0); 

    function fillRecord(employee: any) {

        if (!isEmpty(employee)) {
            setEmployeeId(employee.id);
            setCompanyId(employee.companyId);
            setEmployeeType(employee.employeeType);
            setCreated(notNullToDate(employee.created, 'dd/mm/yyyy HH:MM:ss'));  
            setUpdated(notNullToDate(employee.updated, 'dd/mm/yyyy HH:MM:ss'));  

            setSector(employee.sector);
            setOccupation(employee.occupation);
            setCredential(employee.credential);
            setRemuneration(employee.remuneration);
            setAdmission(notNullToDate(employee.admission, 'dd/mm/yyyy'));
            setDismissal(notNullToDate(employee.dismissal, 'dd/mm/yyyy'));
            setSituation(employee.situation);
            setWorkdayFirst(employee.workdayFirst);
            setWorkdayStop(employee.workdayStop);
            setWorkdayStart(employee.workdayStart);
            setWorkdayLast(employee.workdayLast);

            setPeopleId(employee.peopleId)
            fillRecordPeople(employee.people);
        }
    } // fillRecord

    function fillRecordPeople(people: any) {

        if (!isEmpty(people)) {
            setPeopleId(people.id);
            setTratament(people.tratament);
            setFullname(people.fullname);
            setGender(people.gender);
            setBirth(notNullToDate(people.birth, 'dd/mm/yyyy'));
            setEmail(people.email);
            setImage(people.image);
            setUserId(people.userId);

            if (!isEmpty(people.phones)) {
                const phone = people.phones[0];

                setPhoneId(phone.id);
                setNorm(phone.norm);
                setOperator(phone.operator);
                setConnection(phoneMask(phone.connection));
                setSms(phone.sms);
                setZap(phone.zap);
                setMajor(phone.major);
            }
            setNationality(people.nationality);
            setNaturalness(people.naturalness);

            setRegistrationPf(cpfMask(people.registrationPf));
            setRegistrationIdentity(numberMask(people.registrationIdentity));
            setIssuerIdentity(people.issuerIdentity);
            setStateIdentity(people.stateIdentity);
            setExpeditionIdentity(notNullToDate(people.expeditionIdentity, 'dd/mm/yyyy'));
            setDriversLicense(numberMask(people.driversLicense));
            setCategoryDriversLicense(people.categoryDriversLicense);
            setValidityDriversLicense(notNullToDate(people.validityDriversLicense, 'dd/mm/yyyy'));
            
            if (!isEmpty(people.adresses)) {
                const addressPeople = people.adresses[0];

                setAddressId(addressPeople.addressId);
                setZipcode(zipcodeMask(addressPeople.address.zipcode));
                setState(addressPeople.address.state);
                setCity(addressPeople.address.city);
                setDistrict(addressPeople.address.district);
                setPlace(addressPeople.address.place);

                setHabitation(addressPeople.habitation);
                setComplement(addressPeople.complement);
            }
        }
    }

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(fullname))
            emptyRequired = true;

        if (isEmpty(sector))
            emptyRequired = true;
        if (isEmpty(occupation))
            emptyRequired = true;

        if (isEmpty(registrationPf))
            emptyRequired = true;
        if ((!isEmpty(registrationPf)) && (!isCpf(registrationPf))) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.registration_pf);
            result = false;
        }
    
        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedForm(!result);
        return result;
    } // isValidForm

    async function onClick_CancelEmployee(event : any) {
        event.preventDefault();
        props.setRecord(event, 0)
    } // onClick_Cancel

    function clearEmployee() {

        setEmployeeId(0);
        setCompanyId(0);
        setEmployeeType('');
        setCreated('');
        setUpdated('');

        setPeopleId(0);
        setTratament('');
        setFullname('');
        setGender('');
        setBirth('');

        setPhoneId(0);
        setNorm('');
        setOperator('');
        setConnection('');
        setSms(0);
        setZap(0);
        setMajor(0);
            
        setNationality('');
        setNaturalness('');

        setRegistrationPf('');
        setRegistrationIdentity('');
        setIssuerIdentity('');
        setStateIdentity('');
        setExpeditionIdentity('');
        setDriversLicense('');
        setCategoryDriversLicense('');
        setValidityDriversLicense('');
        
        setSector('');
        setOccupation('');
        setCredential('');
        setRemuneration('');
        setAdmission('');
        setDismissal('');
        setSituation(String(UserSituationEnum.REGISTERED));
        setWorkdayFirst('');
        setWorkdayStop('');
        setWorkdayStart('');
        setWorkdayLast('');

        setAddressId(0);
        setZipcode('');
        setState('');
        setCity('');
        setDistrict('');
        setPlace('');
        setHabitation('');
        setComplement('');
    }

    function fillEmployee() {

        const adresses = [];
        adresses[0] = {
            peopleId : peopleId,
            addressId : addressId,
            address : {
                id : addressId,
                zipcode : removeMask(zipcode),
                state : state,
                city : city,
                district : district,
                place : place
            },
            correspondence : 1,
            norm : 'residential',
            habitation,
            complement
        };

        const phones = [];
        phones[0] = {
            id : phoneId,
            peopleId,
            norm, 
            operator,
            connection : removeMask(connection),
            sms,
            zap,
            major
        };

        const dataEmployee = {
            id : employeeId,
            companyId : props.partner.companyId,
            employeeType: removeMask(employeeType, '0'), 
            created: removeFormatDate(created),
            updated: removeFormatDate(updated),
            sector,
            occupation,
            credential,
            remuneration: removeMask(remuneration, '0'),
            admission: removeFormatDate(admission),
            dismissal: removeFormatDate(dismissal),
            situation: removeMask(situation, '0'),
            workdayFirst,
            workdayStop,
            workdayStart,
            workdayLast,
            peopleId,
            people : {
                id: peopleId,
                tratament,
                fullname, 
                gender, 
                birth : removeFormatDate(birth), 
                email,
                phones, 
                nationality, 
                naturalness, 
                registrationPf : removeMask(registrationPf),
                registrationIdentity : removeMask(registrationIdentity),
                issuerIdentity,
                stateIdentity,
                expeditionIdentity : removeFormatDate(expeditionIdentity), 
                driversLicense : removeMask(driversLicense),
                categoryDriversLicense,
                validityDriversLicense : removeFormatDate(validityDriversLicense), 
                adresses,
                image,
                userId
            }
        };

        return dataEmployee;
    }

    async function onClick_SaveEmployee(event: any) {
        event.preventDefault();
        
        const isValid = await isValidForm();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(props.title, display.message.confirm_record);
                if (isConfirmed) {
                    setSending(true);

                    const employee = fillEmployee();
                    if (isEditForm)
                       await cduService.saveEmployee(employee.id, employee);
                    else
                        await cduService.createEmployee(employee);

                    if (proofOfResidenceDocument) {
                        proofOfResidenceDocument.source = 'PEOPLE';
                        proofOfResidenceDocument.identy = String(employee.people.id);
    
                        if ((proofOfResidenceDocument.id > 0) && !isEmpty(proofOfResidenceDocument.document)) {
                            await cduService.saveArtifact(proofOfResidenceDocument.id, proofOfResidenceDocument);
                        } else if ((proofOfResidenceDocument.id > 0) && isEmpty(proofOfResidenceDocument.document)) {
                            await cduService.artifactDeleteById(proofOfResidenceDocument.id);
                        } else if ((proofOfResidenceDocument.id === 0) && !isEmpty(proofOfResidenceDocument.document)) {
                            await cduService.createArtifact(proofOfResidenceDocument);
                        }   
                    }

                    setSending(false);
                    await alertContext.show(AlertMessageEnum.SUCCESS, props.title, display.message.the_record_has_been_saved_successfully);
                    props.setRecord(event, 0)
                }
            } catch (error: any) {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
            }
        }
    } // onClick_SaveEmployee

    async function onClick_SearchPeople(event: any) {
        event.preventDefault();

        if (!isEmpty(registrationPf)) {
            const registration = removeMask(registrationPf);
            if (isCpf(registration)) {
                const people = await cduService.peopleByRegistration(registration);
                fillRecordPeople(people);
                if (!isEmpty(people)) {
                    const attributes = [] as any;
                    const where = { peopleId : people.id };
                    const order = [] as any;
                    const mechanics = await srvService.mechanicFilter(attributes, where, order);
                    if (mechanics.length > 0) {
                        await alertContext.show(AlertMessageEnum.SUCCESS, props.title, 'CPF já cadastrado.');
                        props.setRecord(event, 0);
                    }
                } else 
                    await alertContext.show(AlertMessageEnum.SUCCESS, props.title, 'CPF não localizado. Efetue o cadastro completo.');
            } else {
                await alertContext.show(AlertMessageEnum.FAIL, props.title, 'CPF Inválido !');
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, 'Insira um CPF válido !');
        }
    }

    async function searchParam() {
        try {
            clearEmployee();
            if (!Number.isNaN(Number.parseInt(props.employeeId ||''))) {
                setEditForm(true);

                const employee = await cduService.employeeById(Number(props.employeeId));
                setOriginRecord(employee);
                fillRecord(employee);

                cduService.artifactByNorm('PEOPLE', employee.people.id, 'FILE_RESIDENCE').then((artifact: any) => {
                    if (artifact) {
                        artifact.filename = `Atualizado em ${notNullToDate(artifact.updated, 'dd/mm/yyyy HH:MM:ss')}`
                        setProofOfResidenceDocument(artifact);
                    }
                });
            } else {
                setEditForm(false);
            }
        } catch(error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
        }
    } // searchParam

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            searchParam();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []); // useEffect

    function changeProofOfResidenceDocument(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let artfact: ArtifactType = {
                    id : proofOfResidenceDocument.id,
                    norm : 'FILE_RESIDENCE',
                    source : 'PEOPLE',
                    identy : String(peopleId),
                    document : e.target.result,
                    filename : event.target.files[0].name
                }
                setProofOfResidenceDocument(artfact);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            let artifact: ArtifactType = { id : proofOfResidenceDocument.id, norm : 'FILE_RESIDENCE', source : 'PEOPLE', identy : String(peopleId), document : '', filename : '' };
            setProofOfResidenceDocument(artifact);
        }
    }

    function clickViewDocument(event: any, title: string, url: any) {
        event.preventDefault();
        openDocument(title, url);
    }

    function changeImage(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                setImage(e.target.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            setImage('');
        }
    }

    function viewCadastreForm() {

        return (
            <Container fluid className="page-body employee">
                <Row>
                    <Col sm={12} className="page-sweet">
                        <Card>
                            <Card.Header>
                                {/* {(originRecord.created || originRecord.updated) && (
                                    <div className="form-identy-registry float-right">
                                        <span className="white-space"><b>Criação: </b> {dateToString(originRecord.created, 'dd/mm/yyyy HH:MM:ss')}</span>
                                        <span className="white-space"><b>Atualização: </b> {dateToString(originRecord.updated, 'dd/mm/yyyy HH:MM:ss')}</span>
                                    </div>
                                )} */}
                                <Card.Title>{display.legend.record}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                            <Form className="form" id="form-cadastre-employee" validated={invalidatedForm}>
                                <EmployeeRecord
                                    translate={props.translate}

                                    title={props.title}
                                    isEdit={isEditForm}

                                    employeeType={employeeType}
                                    created={created}
                                    updated={updated}
                                    tratament={tratament}
                                    fullname={fullname}
                                    gender={gender}
                                    birth={birth}
                                    norm={norm}
                                    operator={operator}
                                    connection={connection}
                                    sms={sms}
                                    zap={zap}
                                    major={major}
                                    nationality={nationality}
                                    naturalness={naturalness}
                                    registrationPf={registrationPf}
                                    registrationIdentity={registrationIdentity}
                                    issuerIdentity={issuerIdentity}
                                    stateIdentity={stateIdentity}
                                    expeditionIdentity={expeditionIdentity}
                                    driversLicense={driversLicense}
                                    categoryDriversLicense={categoryDriversLicense}
                                    validityDriversLicense={validityDriversLicense}
                                    sector={sector}
                                    occupation={occupation}
                                    credential={credential}
                                    remuneration={remuneration}
                                    admission={admission}
                                    dismissal={dismissal}
                                    situation={situation}
                                    workdayFirst={workdayFirst}
                                    workdayStop={workdayStop}
                                    workdayStart={workdayStart}
                                    workdayLast={workdayLast}
                                    zipcode={zipcode}
                                    state={state}
                                    city={city}
                                    district={district}
                                    place={place}
                                    habitation={habitation}
                                    complement={complement}
                                    setEmployeeType={(value: any) => { setEmployeeType(value); }}
                                    setCreated={(value: any) => { setCreated(value); }}
                                    setUpdated={(value: any) => { setUpdated(value); }}
                                    setTratament={(value: any) => { setTratament(value); }}
                                    setFullname={(value: any) => { setFullname(value); }}
                                    setGender={(value: any) => { setGender(value); }}
                                    setBirth={(value: any) => { setBirth(value); }}
                                    setNorm={(value: any) => { setNorm(value); }}
                                    setOperator={(value: any) => { setOperator(value); }}
                                    setConnection={(value: any) => { setConnection(value); }}
                                    setSms={(value: any) => { setSms(value); }}
                                    setZap={(value: any) => { setZap(value); }}
                                    setMajor={(value: any) => { setMajor(value); }}
                                    setNationality={(value: any) => { setNationality(value); }}
                                    setNaturalness={(value: any) => { setNaturalness(value); }}
                                    setRegistrationPf={(value: any) => { setRegistrationPf(value); }}  
                                    setRegistrationIdentity={(value: any) => { setRegistrationIdentity(value); }}  
                                    setIssuerIdentity={(value: any) => { setIssuerIdentity(value); }}  
                                    setStateIdentity={(value: any) => { setStateIdentity(value); }}  
                                    setExpeditionIdentity={(value: any) => { setExpeditionIdentity(value); }}  
                                    setDriversLicense={(value: any) => { setDriversLicense(value); }}  
                                    setCategoryDriversLicense={(value: any) => { setCategoryDriversLicense(value); }} 
                                    setValidityDriversLicense={(value: any) => { setValidityDriversLicense(value); }} 
                                    setSector={(value: any) => { setSector(value); }}
                                    setOccupation={(value: any) => { setOccupation(value); }}
                                    setCredential={(value: any) => { setCredential(value); }}
                                    setRemuneration={(value: any) => { setRemuneration(value); }}
                                    setAdmission={(value: any) => { setAdmission(value); }}
                                    setDismissal={(value: any) => { setDismissal(value); }}
                                    setSituation={(value: any) => { setSituation(value); }}
                                    setWorkdayFirst={(value: any) => { setWorkdayFirst(value); }}
                                    setWorkdayStop={(value: any) => { setWorkdayStop(value); }}
                                    setWorkdayStart={(value: any) => { setWorkdayStart(value); }}
                                    setWorkdayLast={(value: any) => { setWorkdayLast(value); }}
                                    setZipcode={(value: any) => { setZipcode(value); }}
                                    setState={(value: any) => { setState(value); }}
                                    setCity={(value: any) => { setCity(value); }}
                                    setDistrict={(value: any) => { setDistrict(value); }}
                                    setPlace={(value: any) => { setPlace(value); }}
                                    setHabitation={(value: any) => { setHabitation(value); }}
                                    setComplement={(value: any) => { setComplement(value); }}

                                    onClick_SearchPeople={(value: any) => { onClick_SearchPeople(value); }}

                                    proofOfResidenceDocument={proofOfResidenceDocument}
                                    changeProofOfResidenceDocument={changeProofOfResidenceDocument}
                                    clickViewDocument={clickViewDocument}
                                />
                                </Form>
                            </Card.Body>
                            <Card.Footer className="text-right">
                            <Button className="me-2" onClick={ (e) => onClick_CancelEmployee(e) } variant="contained" color="secondary">{display.buttom.cancel}</Button>
                                <Button onClick={ (e) => onClick_SaveEmployee(e) } disabled={sending} variant="contained" color="primary">
                                    { sending ? <Spinner className="me-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSave className="me-2" size={22} /> } {' '}
                                    {display.buttom.save}
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewCadastreForm

    return (
        <>
            { viewCadastreForm() }
        </>
    );

}

export default EmployeeForm;
