import { ThemeProvider } from '@mui/material/styles'
import SearchIcon from '@mui/icons-material/Search'
import { FormControl, FormHelperText, Input, InputAdornment, InputLabel, LinearProgress } from '@mui/material'
import { DataGrid, GridColDef, GridSelectionModel, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import theme from '../../theme'
import OperationHeader from '../headers/operation.header'

interface Props {
    translate: any
    partner: any
    onClick_ShowDefinedPartner: any
}

function AssessmentsOperation(props: Props) {
    
    const display = props.translate.data;

    const title = display.title.assessment;
    const description = display.description.assessment;

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);

    const [ selectionModel, setSelectionModel ] = useState<GridSelectionModel>([]);
    const [ pageSize, setPageSize ] = React.useState(5);
    const [ records, setRecords ] = useState([] as any);

    const [ textSearch, setTextSearch ] = useState('');

    const [ abortions, setAbortions ] = useState(0);
    const [ notifications, setNotifications ] = useState(0);
    const [ fines, setFines ] = useState(0);
    const [ seizures, setSeizures ] = useState(0);
    const [ redemptions, setRedemptions ] = useState(0);

    const columns: GridColDef[] = [
        { field: 'dateTime', headerName: 'Data/Hora', align: 'center', headerAlign: 'center' },
        { field: 'norm', headerName: 'Tipo', align: 'center', headerAlign: 'center' },
        { field: 'registre', headerName: 'Registro', align: 'center', headerAlign: 'center' },
        { field: 'validity_e', headerName: 'Validade', align: 'center', headerAlign: 'center' },
        { field: 'responsible', headerName: 'Responsável/Capitão', align: 'center', headerAlign: 'center' },
        { field: 'qualification', headerName: 'Habilitação', align: 'center', headerAlign: 'center' },
        { field: 'category', headerName: 'Categoria', align: 'center', headerAlign: 'center' },
        { field: 'validity_r', headerName: 'Validade', align: 'center', headerAlign: 'center' },
        { field: 'notification', headerName: 'Notificação', align: 'center', headerAlign: 'center' },
        { field: 'apprehended', headerName: 'Apreendido', align: 'center', headerAlign: 'center' },
        { field: 'reason', headerName: 'Motivo', align: 'center', headerAlign: 'center' },
        { field: 'image', headerName: 'Imagem', align: 'center', headerAlign: 'center' },
        { field: 'authorityKey', headerName: 'Autoridade ID', align: 'center', headerAlign: 'center' },
        { field: 'patent', headerName: 'Patente', align: 'center', headerAlign: 'center' },
        { field: 'document', headerName: 'Documento', align: 'center', headerAlign: 'center' }
    ];

    const searchfilter = async () => {
        setLoading(true);
        const where = {} as any;

        /* Sempre incluir o partner na pesquisa */
        where.partnerId = props.partner.id;

        const assessments = [{
            "id" : 1,
            "dateTime" : "22/04/2023 09:32:52",
            "norm" : "Lancha",
            "registre" : "89264829282",
            "validity_e" : "22/04/2023",
            "responsible" : "Pedro Dornelles Souza Machado",
            "qualification" : "4587698521",
            "category" : "CTA ARA",
            "validity_r" : "22/04/2023",
            "notification" : "0",
            "apprehended" : "0",
            "reason" : "",
            "image" : "",
            "authorityKey" : "",
            "patent" : "",
            "document" : ""
        },{
            "id" : 2,
            "dateTime" : "22/04/2023 11:21:41",
            "norm" : "Lancha",
            "registre" : "89264829282",
            "validity_e" : "22/04/2023",
            "responsible" : "Pedro Dornelles Souza Machado",
            "qualification" : "4587698521",
            "category" : "CTA ARA",
            "validity_r" : "22/04/2023",
            "notification" : "1",
            "apprehended" : "1",
            "reason" : "ART.16 I,IV",
            "image" : "",
            "authorityKey" : "356354 - Silva",
            "patent" : "SGT II",
            "document" : "OCOR-0001"
        }];

        let abortions = 0;
        let notifications = 0;
        let fines = 0;
        let seizures = 0;
        let redemptions = 0;
    
        setAbortions(abortions);
        setNotifications(notifications);
        setFines(fines);
        setSeizures(seizures);
        setRedemptions(redemptions);

        setRecords(assessments);
        setLoading(false);
    }

    function onClick_Search(event: any) {
        event.preventDefault();
        searchfilter();
    }

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            searchfilter()
        }
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage
        }
    }, []) // useEffect

    function viewPage() {
        return (
            <Container fluid className={`page-body movements`}>
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card style={{ height: 570}}>
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <FormControl fullWidth sx={{width: 350 }} variant="standard">
                                        <InputLabel htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                                        <Input id="standard-adornment-amount"
                                            value={textSearch}
                                            onChange={(e) => setTextSearch(e.target.value)}
                                            endAdornment = {
                                                <InputAdornment position="end">
                                                    <SearchIcon onClick={(e) => onClick_Search(e)} />
                                                </InputAdornment>
                                            }
                                        />
                                        <FormHelperText id="component-helper-text">
                                            Número do contrato, nome do cliente ou nome da embarcação
                                        </FormHelperText>
                                    </FormControl>
                                    <section className='d-flex'>
                                        <div className='d-flex flex-column align-items-center me-5'>
                                            <h6>Abortagens</h6>
                                            <h5>{abortions}</h5>
                                        </div>
                                        <div className='d-flex flex-column align-items-center me-5'>
                                            <h6>Notificações</h6>
                                            <h5>{notifications}</h5>
                                        </div>
                                        <div className='d-flex flex-column align-items-center me-5'>
                                            <h6>Multas</h6>
                                            <h5>{fines}</h5>
                                        </div>
                                        <div className='d-flex flex-column align-items-center me-5'>
                                            <h6>Apreensões</h6>
                                            <h5>{seizures}</h5>
                                        </div>
                                        <div className='d-flex flex-column align-items-center me-5'>
                                            <h6>Resgates</h6>
                                            <h5>{redemptions}</h5>
                                        </div>
                                    </section>
                                </div>
                                <ThemeProvider theme={theme}>
                                    <div style={{ height: 402, width: '100%', marginTop: 0 }}>
                                        <DataGrid
                                            rows={records}
                                            rowCount={records.length}
                                            getRowId={row => row.id}
                                            loading={isLoading}
                                            columns={columns}
                                            pageSize={pageSize}
                                            onPageSizeChange={newPage => setPageSize(newPage)}
                                            rowsPerPageOptions={[5 ,10, 25, 50, 75, 100]}
                                            disableSelectionOnClick
                                            onCellClick={(e) => console.log(e)}
                                            onSelectionModelChange={(newSelectionModel: any) => {
                                                setSelectionModel(newSelectionModel)
                                            }}
                                            components={{
                                                Toolbar: GridToolbar,
                                                LoadingOverlay: LinearProgress
                                            }}
                                            selectionModel={selectionModel}
                                        />
                                    </div>
                                </ThemeProvider>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <div className="page">
            <OperationHeader
                title={title}
                description={description}
                onClick_ShowDefinedPartner={props.onClick_ShowDefinedPartner}
            />
            {viewPage()}
        </div>
    )
}

export default AssessmentsOperation
