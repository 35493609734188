import React, { useEffect, useState } from 'react';
import { CameraAltOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button as ButtonMaterial, Tooltip as TooltipMaterial } from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';
import { Button, Col, Container, Dropdown, Form, FormControl, FormGroup, InputGroup, OverlayTrigger, Row, Tab, Table, Tabs, Tooltip } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { BsCashCoin } from "react-icons/bs";
import { FaMinusSquare, FaTrashAlt, FaWifi } from "react-icons/fa";
import QRCode from "react-qr-code";
import ImageLightBox from "../../../../components/image-lightbox/ImageLightbox";
import { PickerInput } from "../../../../components/sub-render.component";
import TableContainer from "../../../../components/table-container.component";
import { useAlertMessageContext } from "../../../../contexts/alert-message.context";
import addressService from "../../../../services/address.service";
import { dateToString, toDate, toInt, toLowerCase, toString, toUpperCase } from '../../../../utilities/auxiliary-functions';
import { dataMask, floatMask, sizeMask } from "../../../../utilities/masks";
import { AlertMessageEnum, ClassificationTypes, ColorDefaultType, EngineTypes, FuelTypes, HarborType, HullTypes, JetCapacity, MakerTypes, ManufacturerTypes, OriginType, ScheduleTitleType, TrailerType, VesselType, getManufacturerName } from "../../../../utilities/types";
import { isEmpty } from "../../../../utilities/validators";
import styles from './vessel.record.module.scss';
import { openDocument } from '../../../../utilities/view-document';
import UploadFileIcon from '@mui/icons-material/UploadFile';

interface Props {
    translate: any,
    title: any,
    isEdit: boolean,
    columnsResponsible: any,
    columnsConductor: any,

    // Dados Gerais
    id?: any,
    indAlert?: any,
    indRestriction?: any,
    identifyKey?: any,
    name?: any,
    year?: any,
    typeVessel?: any,
    hasTrailer?: any,
    typeTrailer?: any,
    plateTrailer?: any,
    classification?: any,
    harborState?: any,
    harbor?: any,
    enrollment?: any,
    manufacturer?: any,
    color?: any,
    brand?: any,
    model?: any,
    hullMaterial?: any,
    quantityPeople?: any,
    potency?:any,
    vesselSize?: any,
    numberTie?: any,
    validityTie?: any,
    validityTr?: any,
    // Motor
    engines?: any,
    // Dados do Seguro
    insuranceName?: any,
    insuranceNumber?: any,
    insuranceCost?: any,
    insuranceContact?: any,
    insuranceValidity?: any,
    // Valor da Embarcação
    vesselCost?: any,
    hullCost?: any,
    engineCost?: any,
    generatorsCost?: any,
    soundsystemCost?: any,
    accessoriesCost?: any,
    othersCost?: any,
    sailsAndMastsCost?: any,
    // Rastreador
    trackerBrand?: any,
    trackerModel?: any,
    trackerSerial?: any,
    trackerActive?: any,
    trackerResponsible?: any,
    // Proprietario e Autorizações
    ownerType?: any,
    ownerId?: any,
    ownerName?: any,
    ownerRegistry?: any,
    // Documentos
    vesselDocument?: any,
    insurancePolicyDocument?: any,
    driversLicenseDocument?: any,

    responsibles?: any,
    conductors?: any,
    equipments?: any,
    schedules?: any,

    keep: any,

    // Dados Gerais
    setIndAlert?: any,
    setIndRestriction?: any,
    setIdentifyKey?: any,
    setName?: any,
    setYear?: any,
    setTypeVessel?: any,
    setHasTrailer?: any,
    setTypeTrailer?: any,
    setPlateTrailer?: any,
    setClassification?: any,
    setHarborState?: any,
    setHarbor?: any,
    setHull?: any,
    setEnrollment?: any,
    setManufacturer?: any,
    setColor?: any,
    setBrand?: any,
    setModel?: any,
    setHullMaterial?: any,
    setQuantityPeople?: any,
    setPotency?: any,
    setVesselSize?: any,
    setNumberTie?: any,
    setValidityTie?: any,
    setValidityTr?: any,
    // Motor
    setEngines?: any,
    // Dados do Seguro
    setInsuranceName?: any,
    setInsuranceNumber?: any,
    setInsuranceCost?: any,
    setInsuranceContact?: any,
    setInsuranceValidity?: any,
    // Valor da Embarcação
    setVesselCost?: any,
    setHullCost?: any,
    setEngineCost?: any,
    setGeneratorsCost?: any,
    setSoundsystemCost?: any,
    setAccessoriesCost?: any,
    setOthersCost?: any,
    setSailsAndMastsCost?: any,
    // Rastreador
    setTrackerBrand?: any,
    setTrackerModel?: any,
    setTrackerSerial?: any,
    setTrackerActive?: any,
    setTrackerResponsible?: any,
    // Proprietario e Autorizações
    setOwnerType?: any,
    setOwnerId?: any,
    setOwnerName?: any,
    setOwnerRegistry?: any
    // Documentos
    changeVesselDocument?: any,
    changeInsurancePolicyDocument?: any,
    changeDriversLicenseDocument?: any,

    addEngine?: any,
    updateEngineItem?: any,
    removeEngineItem?: any,
    changeImageEngineItem?: any,
    searchOwner?: any,
    addResponsible?: any,
    addConductor?: any,
    addEquipment?: any,
    sumVesselCost?: any,
    updateEquipmentItem?: any,
    updateEquipmentValue?: any,
    removeEquipmentItem?: any,
    openTracking?: any,

    scheduleProhibited?: any,
    scheduleTitle?: any,
    scheduleComments?: any,

    setScheduleProhibited?: any,
    setScheduleTitle?: any,
    setScheduleComments?: any,

    image?: any,
    changeImage?: any,

    images?: any,
    changeImageVesselItem?: any,

    prowImage?: any,
    sternImage?: any,
    portboardImage?: any,
    starboardImage?: any,
    panelImage?: any,
    interiorImage?: any,
    cabinImage?: any,

    clickSchedule?: any,
    validIdentifyKey?: any
}

function VesselRecord(props: Props) {

    const alertContext = useAlertMessageContext();

    const display = props.translate.data;

    const [ listStates, setListStates ] = useState([] as any);
    const [ listHarbor, setListHarbor ] = useState([] as any);

    const windowQrcode = window.location.origin +"/card/vessel/"+ props.enrollment;
   
    async function fillStates() {
        try {
            const states = await addressService.listStates();
            setListStates(states);
        } catch (error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
        }
    }

    function treatHarbor(state: string): void {
        const listHarborLocal = new Array({} as any);
        HarborType.forEach((iterator: any) => {
            if (iterator.uf === state) {
                listHarborLocal.push(iterator);
            }
        });
        setListHarbor(listHarborLocal);
    }

    function onClick_QrCode(event: any) {
        event.preventDefault();

        
        var url = windowQrcode;
        /*
        var w = 500;
        var h = 850;
        var left = 200;
        var tops = 100;
                                
        return window.open(url, '', 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+ w +', height='+ h +', top='+ tops +', left='+ left);
        */
        
        var win = window.open('', '',
            "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=yes,width=500,height=830,top=100,left=200"
        );
        if (win) {
            win.document.write(`<html>`);
            win.document.write(`<head>`);
            win.document.write(`<title>Cartão da Embarcação</title>`);
            win.document.write(`<meta http-equiv="Content-Type" content="text/html; charset=iso-8859-1" />`);
            win.document.write(`<style style rel="stylesheet" type="text/css">body { font-size: 1em; color: #fff; background: #303A4A; width: vw; height: vh; margin: 0; padding: 0; }</style>`);
            win.document.write(`</head>`);
            win.document.write(`<body>`);
            win.document.write(`<object type="application/pdf" width="100%" height="100%" data="${url}">The “object” tag is not supported by your browser.</object>`);
            win.document.write(`</body>`);
            win.document.write(`</html>`);
        }
    }

    function onChange_State(state: string) {
        props.setHarborState(state);
        treatHarbor(state);
    }


    function onClick_AddResponsible(event: any) {
        event.preventDefault();
        props.addResponsible(event);
    }
    
    function onClick_AddConductor(event: any) {
        event.preventDefault();
        props.addConductor(event);
    }
    
    function onClick_AddEngine(event: any) {
        event.preventDefault();
        if(props.engines.length < 4){
            props.addEngine(event);
        }
    }
    
    function onClick_AddEquipment(event: any) {
        event.preventDefault();
        props.addEquipment(event);
        if(props.equipments < 10){
            
        }
    }

    function onBlur_SumVesselCost(event: any) {
        event.preventDefault();
        props.sumVesselCost(event);
    }
    
    function onChange_UpdateEngineItem(event: any, position: number, field: string, item: any) {
        event.preventDefault();
        props.updateEngineItem(position, field, item);
    }

    function onClick_RemoveEngineItem(event: any, index: number) {
        props.removeEngineItem(event, index);
    }

    function onChange_UpdateEquipmentItem(event: any, position: number, field: string, item: string) {
        event.preventDefault();
        props.updateEquipmentItem(position, field, item);
    }

    function onBlur_UpdateEquipmentValue(event: any) {
        event.preventDefault();
        props.updateEquipmentValue(event);
    }

    function onClick_RemoveEquipmentItem(event: any, index: number) {
        props.removeEquipmentItem(event, index);
    }

    function onChange_VesselDocument(event: any) {
        event.preventDefault();
        props.changeVesselDocument(event);
    }

    function onChange_InsurancePolicyDocument(event: any) {
        event.preventDefault();
        props.changeInsurancePolicyDocument(event);
    }

    function onChange_DriversLicenseDocument(event: any) {
        event.preventDefault();
        props.changeDriversLicenseDocument(event);
    }

    function onChange_Image(event: any) {
        event.preventDefault();
        props.changeImage(event);
    }

    function onChange_ImageEngineItem(event: any, index: number) {
        event.preventDefault();
        props.changeImageEngineItem(event, index);
    }
    
    function onChange_ImageVesselItem(event: any, order: string, index: number) {
        event.preventDefault();
        props.changeImageVesselItem(event, order, index);
    }

    function onClick_Tracker(event: any) {
        event.preventDefault();
        props.openTracking(event);
    }

    function onClick_ViewDocument(event: any, title: string, url: any) {
        event.preventDefault();
        openDocument(title, url);
    } 

    function onChange_Prohibited(event: any, date: any) {
        props.setScheduleProhibited(dateToString(date, 'dd/mm/yyyy HH:MM:SS'));
    }

    function onClick_Schedule(event: any) {
        event.preventDefault();
        props.clickSchedule(event);
    }

    function onClick_Color(event: any, value: string) {
        props.setColor(value);
    }

    function onBlue_ValidIdentifyKey(event: any) {
        props.validIdentifyKey(event);
    }

    function onChange_Manufacturer(event: any) {
        event.preventDefault();

        let manufacturer = event.target.value;
        props.setManufacturer(manufacturer);
        let brand = manufacturer === '16' ? props.brand : getManufacturerName(manufacturer);
        props.setBrand(brand);
    }

    async function initialize() {
        await fillStates();
    }

    useEffect(() => {
        if (props.harborState)
            treatHarbor(props.harborState);
    }, [props.harborState]);

    useEffect(() => {
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function viewKeep() {
        var bookcaseName = '';
        var shelfName = '';
        var drawerName = '';
        var colorName = '';

        if (props.keep) {
            bookcaseName = props.keep.name;
            if (props.keep.shelves && (props.keep.shelves.length > 0)) {
                const shelf = props.keep.shelves[0];
                shelfName = shelf.name;
                if (shelf.drawers && (shelf.drawers.length > 0)) {
                    const drawer = shelf.drawers[0];
                    drawerName = drawer.name;
                    colorName = drawer.color;
                }
            }
        }

        return (
            <div className="card-drawer" style={{ backgroundColor: colorName }}>
                <div className="card-drawer-title">{bookcaseName}</div>
                <div className="card-drawer-group">{shelfName}</div>
                <div className="card-drawer-number">{drawerName}</div>
            </div>
        );
    }

    function viewVesselCadaster() {
        return (
            <Row>
                <Col sm="12">
                    <Row>
                        <Col sm="3">
                            <div className="d-flex flex-column align-items-center">
                                <ImageLightBox containerStyle={styles.imageWrapper} src={props.image} alt="Embarcação"
                                    theftIndicator={props.indAlert} financialIndicator={props.indRestriction} />
                                <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                    <label htmlFor='input-file-image'>
                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                            <CloudUploadIcon />
                                        </TooltipMaterial>
                                    </label>
                                    <label className="text-right" onClick={(e)=> onChange_Image(e)}>
                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                            <HighlightOffIcon />
                                        </TooltipMaterial>
                                    </label>
                                    <Form.Control type='file' id='input-file-image' accept="image/*" onChange={(e)=> onChange_Image(e)} />
                                </FormGroup>
                            </div>
                        </Col>
                        <Col sm="6">
                            <Container className="container-min">
                                <Row>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-identify-key">{display.label.identify_key}</Form.Label>
                                            <FormControl id="form-identify-key" name="identifyKey" maxLength={10}
                                                placeholder={display.example.identify_key} required
                                                value={toString(props.identifyKey)} onChange={e=>
                                                props.setIdentifyKey(toUpperCase(e.target.value))}
                                                onBlur={(e) => onBlue_ValidIdentifyKey(e)}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="9">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-name-vessel">{display.label.name_vessel}</Form.Label>
                                            <FormControl id="form-name-vessel" name="nameVessel"
                                                placeholder={display.example.name_vessel} value={toString(props.name)}
                                                onChange={e=> props.setName(e.target.value)}
                                                />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="3">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-year">{display.label.year}</Form.Label>
                                            <FormControl id="form-year" name="year" placeholder={display.example.year}
                                                value={toString(props.year)} onChange={e=> props.setYear(e.target.value)}
                                                />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="8">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-type-vessel">{display.label.type_vessel}</Form.Label>
                                            <Form.Select id="form-type-vessel" name="typeVessel" required
                                                value={toString(props.typeVessel)} onChange={(e: any)=>
                                                props.setTypeVessel(e.target.value)}
                                            >
                                                { isEmpty(props.typeVessel) ? (
                                                    <option value="">...</option>
                                                ) : null }
                                                { VesselType.map((iterator: any, idx: any) => (
                                                    <option key={idx} value={iterator.id}>{iterator.description}</option>)
                                                ).sort((a: any, b: any) => { 
                                                    return a.props.children > b.props.children ? 1 : -1; 
                                                }) }
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="1">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-color">{display.label.color}</Form.Label>
                                            <Dropdown className="dropdown-color">
                                                <Dropdown.Toggle className={`${styles.dropdownToogle} dropdown-color-toggle`} variant="none">
                                                    <div className={styles.selectedColor} style={{backgroundColor: props.color}}></div>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className={`${styles.dropdownMenu} dropdown-color-menu`}>
                                                    { ColorDefaultType.map((iterator: any, idx: any) => (
                                                    <Dropdown.Item key={idx} className={`${styles.dropdownItem}
                                                        dropdown-color-item`} onClick={e=> onClick_Color(e, iterator.id)}>
                                                        <span className="dropdown-color-item-block" style={{backgroundColor: `${iterator.id}`}}></span>
                                                    </Dropdown.Item>
                                                    )) }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="2">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-has-trailer">{display.label.trailer}</Form.Label>
                                            <Form.Select id="form-has-trailer" name="hasTrailer"
                                                value={toString(props.hasTrailer)} onChange={(e: any)=>
                                                props.setHasTrailer(e.target.value)}
                                            >
                                                <option value={0}>Não</option>
                                                <option value={1}>Sim</option>
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-type-trailer">{display.label.type_trailer}</Form.Label>
                                            <Form.Select id="form-type-trailer" name="typeTrailer"
                                                value={toString(props.typeTrailer)} onChange={(e: any)=>
                                                props.setTypeTrailer(e.target.value)}
                                            >
                                                { isEmpty(props.typeTrailer) ? (
                                                <option value="">...</option>
                                                ) : null }
                                                { TrailerType.map((iterator: any, idx: any) => (<option key={idx}
                                                    value={iterator.id}>{display.label[toLowerCase(iterator.name)]}</option>)).sort((a : any, b :
                                                any) => { return a.props.children > b.props.children ? 1 : -1; }) }
                                            </Form.Select>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="4">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-plate-trailer">{display.label.plate}</Form.Label>
                                            <FormControl id="form-plate-trailer" name="plateTrailer"
                                                placeholder={display.example.plate_trailer} value={toString(props.plateTrailer)}
                                                onChange={e=> props.setPlateTrailer(toUpperCase(e.target.value))}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col sm="3">
                            <Row>
                                <Col sm="6">
                                    <div className="box-code">
                                        <QRCode bgColor={'#303A4A'} fgColor={'#FFFFFF'} value={windowQrcode} size={80} onClick={ (e)=> onClick_QrCode(e) }/>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    {viewKeep()}
                                </Col>
                            </Row>
                            <Row>
                                <Col><hr /></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Check type="switch" label="Alerta de Roubo" id="form-ind-alert" name="indAlert"
                                        checked={props.indAlert} className={styles.switch} onChange={(e)=>
                                        props.setIndAlert(!props.indAlert)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Check type="switch" label="Restrição Financeira" id="form-ind-restriction"
                                        name="indRestriction" checked={props.indRestriction} className={styles.switch}
                                        onChange={(e)=> props.setIndRestriction(!props.indRestriction)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col sm="12">
                            <Container fluid className="container-min">
                                <Tabs defaultActiveKey="ship-registration" id="tabs-ship-registration" className="mb-3">
                                    <Tab eventKey="ship-registration" title={display.subtitle.vessel_information}>
                                        <Container fluid className="container-min">
                                            <fieldset>
                                                <legend>{display.legend.general_data}</legend>
                                                <Row>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-enrollment">{display.label.enrollment}</Form.Label>
                                                            <FormControl id="form-enrollment" name="enrollment"
                                                                placeholder={display.example.enrollment}
                                                                value={toString(props.enrollment)} onChange={e=>
                                                                props.setEnrollment(toUpperCase(e.target.value))}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-harbor-state">Estado</Form.Label>
                                                            <Form.Select id="form-harbor-state" name="harborstate" value={toString(props.harborState)} onChange={(e: any)=> onChange_State(e.target.value)} >
                                                                { isEmpty(props.harborState) ? (
                                                                    <option value="">...</option>
                                                                ) : null }
                                                                { listStates.map((iterator: any, idx: number) => (
                                                                    <option key={idx} value={iterator.sigla}>{iterator.nome}</option>
                                                                )).sort((a: any, b: any) => { 
                                                                    return a.props.children > b.props.children ? 1 : -1;
                                                                })}
                                                            </Form.Select>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="6">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-harbor">DPC</Form.Label>
                                                            <Form.Select id="form-harbor" name="harbor" value={toString(props.harbor)} onChange={(e: any)=> props.setHarbor(e.target.value)} >
                                                                { isEmpty(props.harbor) ? (
                                                                    <option value="">...</option>
                                                                ) : null }
                                                                { listHarbor.map((iterator: any, idx: number) => (
                                                                    <option key={idx} value={iterator.id}>{iterator.om} - {iterator.initials}</option>
                                                                )).sort((a : any, b : any) => {
                                                                    return a.props.children > b.props.children ? 1 : -1; 
                                                                })}
                                                            </Form.Select>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="3">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-manufacturer">{display.label.maker} </Form.Label>
                                                            <Form.Select id="form-manufacturer" name="manufacturer" required value={toString(props.manufacturer)} 
                                                                onChange={e => onChange_Manufacturer(e)}
                                                            >
                                                                <option value="">...</option>
                                                                { ManufacturerTypes.map((iterator: any, idx: number) => (
                                                                    <option key={idx} value={iterator.id}> {iterator.description}</option>)) 
                                                                }
                                                            </Form.Select>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-brand">{display.label.brand}</Form.Label>
                                                            <FormControl id="form-brand" name="brand"
                                                                placeholder={props.manufacturer === '16' ? 'Digite a marca' : ''}
                                                                onChange={e => props.setBrand(e.target.value)}
                                                                value={toString(props.brand)} 
                                                                disabled={props.manufacturer !== '16'}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-model">{display.label.model}</Form.Label>
                                                            <FormControl id="form-model" name="model"
                                                                placeholder={display.example.model}
                                                                value={toString(props.model)} onChange={e => props.setModel(e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="3">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-hull">Classificação</Form.Label>
                                                            <Form.Select id="form-hull" name="hull" required
                                                                value={toString(props.classification)} onChange={e => props.setClassification(e.target.value)}
                                                            >
                                                                <option value="">...</option>
                                                                { ClassificationTypes.map((iterator: any, idx: number) => (
                                                                <option key={idx} value={iterator.id}>{iterator.description}
                                                                </option>)).sort((a : any, b : any) => { return a.props.children
                                                                > b.props.children ? 1 : -1; }) }
                                                            </Form.Select>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-hull">{display.label.hull}</Form.Label>
                                                            <Form.Select id="form-hull" name="hull" required onChange={(e: any)=> props.setHull(e.target.value)}>
                                                                <option value="">...</option>
                                                                { HullTypes.map((iterator: any, idx: number) => (<option
                                                                    key={idx} value={iterator.id}>{iterator.description}</option>
                                                                )).sort((a : any, b : any) => { 
                                                                    return a.props.children > b.props.children ? 1 : -1; 
                                                                })}
                                                            </Form.Select>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-quantity-people">{display.label.capacity}</Form.Label>
                                                            {props.typeVessel === '33' ? (
                                                                <Form.Select id="form-hull" name="hull" required onChange={(e: any)=> props.setHull(e.target.value)}>
                                                                    <option value="">...</option>
                                                                    { JetCapacity.map((iterator: any, idx: number) => (<option
                                                                        key={idx} value={iterator.id}>{iterator.description}</option>
                                                                    )).sort((a : any, b : any) => { 
                                                                        return a.props.children > b.props.children ? 1 : -1; 
                                                                    })}
                                                                </Form.Select>
                                                            ):(
                                                                <FormControl type="number" id="form-quantity-people"
                                                                    name="quantityPeople" placeholder={display.example.capacity}
                                                                    className="text-right" value={toString(props.quantityPeople)}
                                                                    onChange={e=> props.setQuantityPeople(toInt(e.target.value))}
                                                                />
                                                            )}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-vessel-size">{display.label.vessel_size} (Pés)</Form.Label>
                                                            <FormControl id="form-insurance-cost" name="vesselSize"
                                                                maxLength={4} 
                                                                placeholder={display.example.vessel_size}
                                                                className="text-right" 
                                                                value={toString(props.vesselSize)} 
                                                                onChange={e=> props.setVesselSize(sizeMask(e.target.value))}
                                                                onBlur={e=> props.setVesselSize(sizeMask(e.target.value))}
                                                                disabled={props.typeVessel === '33'}
                                                            />

                                                            {/*
                                                            <FormControl type="number" id="form-vessel-size" name="vesselSize"
                                                                placeholder={display.example.vessel_size} 
                                                                className="text-right"
                                                                maxLength={2}
                                                                value={toString(props.vesselSize)} 
                                                                onChange={e=> props.setVesselSize(toInt(e.target.value))}
                                                                disabled={props.typeVessel === '33'}
                                                            />
                                                            */}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-vessel-size">{display.label.potency} (HP)
                                                            </Form.Label>
                                                            <FormControl type="number" id="form-vessel-size" name="potency"
                                                                placeholder={display.example.potency} className="text-right"
                                                                maxLength={2} value={toString(props.potency)} onChange={e=>
                                                                props.setPotency(toInt(e.target.value))}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-number-tie">{display.label.number_tie}</Form.Label>
                                                            <FormControl id="form-number-tie" name="numberTie"
                                                                placeholder={display.example.number_tie}
                                                                value={toString(props.numberTie)} 
                                                                onChange={e=> props.setNumberTie(e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-validity-tie">{display.label.validity_tie}</Form.Label>
                                                            <FormControl id="form-validity-tie" name="validityTie"
                                                                maxLength={10} 
                                                                placeholder={display.example.validity_tie}
                                                                value={toString(props.validityTie)} 
                                                                onChange={e=> props.setValidityTie(dataMask(e.target.value))}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-validity-tr">{display.label.validity_tr}</Form.Label>
                                                            <FormControl id="form-validity-tr" name="validityTr" 
                                                                maxLength={10}
                                                                placeholder={display.example.validity_tr}
                                                                value={toString(props.validityTr)} 
                                                                onChange={e=> props.setValidityTr(dataMask(e.target.value))}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                            <fieldset>
                                                 <legend>{display.legend.engines}
                                                    <div className="card-actions float-right">
                                                        <ButtonMaterial variant="contained" color='primary'
                                                            sx={{minWidth: 106,  maxWidth: 106}} size='small'
                                                            onClick={onClick_AddEngine}>
                                                            <AddIcon />
                                                            Motor
                                                        </ButtonMaterial>
                                                    </div>
                                                </legend>
                                                {props.engines && props.engines.map((engine: any, index: number) => (
                                                <Row key={`engine-${index}`}>
                                                    <Col sm="9">
                                                        <Row>
                                                            <Col sm="3">
                                                                <FormGroup>
                                                                    <Form.Label htmlFor="form-validity-tr">{display.label.maker}</Form.Label>
                                                                    <Form.Select id="form-maker" name="maker"
                                                                        value={toString(engine.maker)} onChange={(e: any)=>
                                                                        onChange_UpdateEngineItem(e, index, 'maker',
                                                                        e.target.value)}
                                                                    >
                                                                        <option value="">...</option>
                                                                        { MakerTypes.map((iterator: any, idx: number) => (
                                                                            <option key={idx} value={iterator.id}> {iterator.description}</option>
                                                                        )).sort((a: any, b: any) => { 
                                                                            return a.props.children > b.props.children ? 1 : -1;
                                                                        }) }
                                                                    </Form.Select>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="3">
                                                                <FormGroup>
                                                                    <Form.Label htmlFor="form-validity-tr">Modelo</Form.Label>
                                                                    <FormControl id="form-model" name="model"
                                                                        placeholder={display.example.type_and_model}
                                                                        value={toString(engine.model)} onChange={(e)=>
                                                                        onChange_UpdateEngineItem(e, index, 'model',
                                                                        e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="3">
                                                                <FormGroup>
                                                                    <Form.Label htmlFor="form-validity-tr">{display.label.type_and_model}</Form.Label>
                                                                    <Form.Select id="form-norm" name="norm"
                                                                        value={toString(engine.norm)} onChange={(e: any)=>
                                                                        onChange_UpdateEngineItem(e, index, 'norm',
                                                                        e.target.value)}
                                                                    >
                                                                        <option value="">...</option>
                                                                        { EngineTypes.map((iterator: any, idx: number) => (
                                                                            <option key={idx} value={iterator.id}>{iterator.description}</option>
                                                                        )).sort((a: any, b: any) => { 
                                                                            return a.props.children > b.props.children ? 1 : -1;
                                                                        }) }
                                                                    </Form.Select>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="3">
                                                                <FormGroup>
                                                                    <Form.Label htmlFor="form-validity-tr">Ano</Form.Label>
                                                                    <FormControl id="form-year" name="year"
                                                                        value={toString(engine.year)} onChange={(e)=>
                                                                        onChange_UpdateEngineItem(e, index, 'year',
                                                                        e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm="3">
                                                                <FormGroup>
                                                                    <Form.Label htmlFor="form-validity-tr">Número de Série</Form.Label>
                                                                    <FormControl id="form-serial-number" name="serialNumber"
                                                                        placeholder={display.example.serial_number}
                                                                        value={toString(engine.serialNumber)} onChange={(e)=>
                                                                        onChange_UpdateEngineItem(e, index, 'serialNumber',
                                                                        e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="3">
                                                                <FormGroup>
                                                                    <Form.Label htmlFor="form-validity-tr">Potência (HP)</Form.Label>
                                                                    <FormControl id="form-potency" name="potency"
                                                                        value={toString(engine.potency)} onChange={(e)=>
                                                                        onChange_UpdateEngineItem(e, index, 'potency',
                                                                        e.target.value)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="3">
                                                                <FormGroup>
                                                                    <Form.Label htmlFor="form-validity-tr">Combustível</Form.Label>
                                                                    <Form.Select id="form-fuel" name="fuel"
                                                                        value={toString(engine.fuel)} onChange={(e: any)=>
                                                                        onChange_UpdateEngineItem(e, index, 'fuel',
                                                                        e.target.value)}
                                                                    >
                                                                        <option value="">...</option>
                                                                        { FuelTypes.map((iterator: any, idx: number) => (
                                                                            <option key={idx} value={iterator.id}>{iterator.description}</option>
                                                                        )).sort((a: any, b: any) => { 
                                                                            return a.props.children > b.props.children ? 1 : -1;
                                                                        })}
                                                                    </Form.Select>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="3">
                                                                <FormGroup>
                                                                    <Form.Label htmlFor="form-origin">Procedência</Form.Label>
                                                                    <Form.Select id="form-origin" name="fuel"
                                                                        value={toString(engine.origin)} onChange={(e: any)=>
                                                                        onChange_UpdateEngineItem(e, index, 'origin',
                                                                        e.target.value)}
                                                                    >
                                                                        <option value="">...</option>
                                                                        { OriginType.map((iterator: any, idx: number) => (
                                                                        <option key={idx} value={iterator.id}>{iterator.description}</option>
                                                                        )).sort((a: any, b: any) => { 
                                                                            return a.props.children > b.props.children ? 1 : -1;
                                                                        })}
                                                                    </Form.Select>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col sm="2">
                                                        <div className="d-flex justify-content-between">
                                                            {!isEmpty(engine.image) ? (
                                                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                                                <ImageLightBox containerStyle={`${styles.imageWrapper} ${styles.vesselImage}`} src={engine.image}
                                                                    alt={'Motor'}
                                                                    noBackground />
                                                                <FormGroup as={Row} className={`mt-3 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-engine-${index}`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageEngineItem(e, index)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-engine-${index}`} accept="image/*" onChange={(e)=> onChange_ImageEngineItem(e, index)} />
                                                                </FormGroup>
                                                            </div>
                                                            ) : (
                                                            <div className={`${styles.imageWrapper} ${styles.vesselImagePlaceholder}`}>
                                                                <CameraAltOutlined color='secondary' className={`isClickable mb-3 ${styles.svg}`} sx={{fontSize: '73px'}} />
                                                                <FormGroup as={Row} className={` d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-engine-${index}`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right">
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-engine-${index}`} accept="image/*" onChange={(e)=> onChange_ImageEngineItem(e, index)} />
                                                                </FormGroup>
                                                            </div>
                                                            )}
                                                        </div>
                                                    </Col>
                                                    <Col sm="1" style={{maxWidth:30}}>
                                                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.delete}</Tooltip>}>
                                                            <a href="/#" onClick={(e)=> onClick_RemoveEngineItem(e, index)}>
                                                                <FaMinusSquare className='mt-4' size={18} />
                                                            </a>
                                                        </OverlayTrigger>
                                                    </Col>
                                                </Row>
                                                ))}
                                            </fieldset>
                                            <fieldset>
                                                <legend>Imagens</legend>
                                                <div className="d-flex justify-content-center mt-3">
                                                    <div key={0} className='d-flex flex-column align-items-center justify-content-center'>
                                                        <small style={{marginBottom: '-20px'}}>{display.label[toLowerCase(props.prowImage.norm)]}</small>
                                                        {!isEmpty(props.prowImage.document) ? (
                                                            <>
                                                                <ImageLightBox containerStyle={`${styles.imageWrapper} ${styles.vesselImage}`} src={props.prowImage.document} alt={display.label[toLowerCase(props.prowImage.norm)]} noBackground />
                                                                <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-prow-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.prowImage.norm, 0)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-prow-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.prowImage.norm, 0)} />
                                                                </FormGroup>
                                                            </>
                                                        ) : (
                                                            <div className={`${styles.imageWrapper} ${styles.vesselImagePlaceholder}`}>
                                                                <CameraAltOutlined color='secondary' className={`isClickable mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />
                                                                <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-prow-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.prowImage.norm, 0)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-prow-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.prowImage.norm, 0)} />
                                                                </FormGroup>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div key={1} className='d-flex flex-column align-items-center justify-content-center'>
                                                        <small style={{marginBottom: '-20px'}}>{display.label[toLowerCase(props.sternImage.norm)]}</small>
                                                        {!isEmpty(props.sternImage.document) ? (
                                                            <>
                                                                <ImageLightBox containerStyle={`${styles.imageWrapper} ${styles.vesselImage}`} src={props.sternImage.document} alt={display.label[toLowerCase(props.sternImage.norm)]} noBackground />
                                                                <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-stern-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.sternImage.norm, 1)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-stern-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.sternImage.norm, 1)} />
                                                                </FormGroup>
                                                            </>
                                                        ) : (
                                                            <div className={`${styles.imageWrapper} ${styles.vesselImagePlaceholder}`}>
                                                                <CameraAltOutlined color='secondary' className={`isClickable mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />
                                                                <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-stern-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.sternImage.norm, 1)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-stern-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.sternImage.norm, 1)} />
                                                                </FormGroup>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div key={2} className='d-flex flex-column align-items-center justify-content-center'>
                                                        <small style={{marginBottom: '-20px'}}>{display.label[toLowerCase(props.portboardImage.norm)]}</small>
                                                        {!isEmpty(props.portboardImage.document) ? (
                                                            <>
                                                                <ImageLightBox containerStyle={`${styles.imageWrapper} ${styles.vesselImage}`} src={props.portboardImage.document} alt={display.label[toLowerCase(props.portboardImage.norm)]} noBackground />
                                                                <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-portboard-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.portboardImage.norm, 2)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-portboard-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.portboardImage.norm, 2)} />
                                                                </FormGroup>
                                                            </>
                                                        ) : (
                                                            <div className={`${styles.imageWrapper} ${styles.vesselImagePlaceholder}`}>
                                                                <CameraAltOutlined color='secondary' className={`isClickable mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />
                                                                <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-portboard-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.portboardImage.norm, 2)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-portboard-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.portboardImage.norm, 2)} />
                                                                </FormGroup>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div key={3} className='d-flex flex-column align-items-center justify-content-center'>
                                                        <small style={{marginBottom: '-20px'}}>{display.label[toLowerCase(props.starboardImage.norm)]}</small>
                                                        {!isEmpty(props.starboardImage.document) ? (
                                                            <>
                                                                <ImageLightBox containerStyle={`${styles.imageWrapper} ${styles.vesselImage}`} src={props.starboardImage.document} alt={display.label[toLowerCase(props.starboardImage.norm)]} noBackground />
                                                                <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-starboard-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.starboardImage.norm, 3)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-starboard-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.starboardImage.norm, 3)} />
                                                                </FormGroup>
                                                            </>
                                                        ) : (
                                                            <div className={`${styles.imageWrapper} ${styles.vesselImagePlaceholder}`}>
                                                                <CameraAltOutlined color='secondary' className={`isClickable mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />
                                                                <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-starboard-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.starboardImage.norm, 3)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-starboard-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.starboardImage.norm, 3)} />
                                                                </FormGroup>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div key={4} className='d-flex flex-column align-items-center justify-content-center'>
                                                        <small style={{marginBottom: '-20px'}}>{display.label[toLowerCase(props.panelImage.norm)]}</small>
                                                        {!isEmpty(props.panelImage.document) ? (
                                                            <>
                                                                <ImageLightBox containerStyle={`${styles.imageWrapper} ${styles.vesselImage}`} src={props.panelImage.document} alt={display.label[toLowerCase(props.panelImage.norm)]} noBackground />
                                                                <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-panel-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.panelImage.norm, 4)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-panel-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.panelImage.norm, 4)} />
                                                                </FormGroup>
                                                            </>
                                                        ) : (
                                                            <div className={`${styles.imageWrapper} ${styles.vesselImagePlaceholder}`}>
                                                                <CameraAltOutlined color='secondary' className={`isClickable mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />
                                                                <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-panel-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.panelImage.norm, 4)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-panel-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.panelImage.norm, 4)} />
                                                                </FormGroup>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div key={5} className='d-flex flex-column align-items-center justify-content-center'>
                                                        <small style={{marginBottom: '-20px'}}>{display.label[toLowerCase(props.interiorImage.norm)]}</small>
                                                        {!isEmpty(props.interiorImage.document) ? (
                                                            <>
                                                                <ImageLightBox containerStyle={`${styles.imageWrapper} ${styles.vesselImage}`} src={props.interiorImage.document} alt={display.label[toLowerCase(props.interiorImage.norm)]} noBackground />
                                                                <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-interior-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.interiorImage.norm, 5)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-interior-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.interiorImage.norm, 5)} />
                                                                </FormGroup>
                                                            </>
                                                        ) : (
                                                            <div className={`${styles.imageWrapper} ${styles.vesselImagePlaceholder}`}>
                                                                <CameraAltOutlined color='secondary' className={`isClickable mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />
                                                                <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                    <label htmlFor={`input-file-image-interior-image`}>
                                                                        <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                            <CloudUploadIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.interiorImage.norm, 5)}>
                                                                        <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                            <HighlightOffIcon />
                                                                        </TooltipMaterial>
                                                                    </label>
                                                                    <Form.Control type='file' id={`input-file-image-interior-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.interiorImage.norm, 5)} />
                                                                </FormGroup>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div key={6} className='d-flex flex-column align-items-center justify-content-center'>
                                                        <small style={{marginBottom: '-20px'}}>{display.label[toLowerCase(props.cabinImage.norm)]}</small>
                                                        {!isEmpty(props.cabinImage.document) ? (
                                                        <>
                                                            <ImageLightBox containerStyle={`${styles.imageWrapper} ${styles.vesselImage}`} src={props.cabinImage.document} alt={display.label[toLowerCase(props.cabinImage.norm)]} noBackground />
                                                            <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                <label htmlFor={`input-file-image-cabin-image`}>
                                                                    <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                        <CloudUploadIcon />
                                                                    </TooltipMaterial>
                                                                </label>
                                                                <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.cabinImage.norm, 6)}>
                                                                    <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                        <HighlightOffIcon />
                                                                    </TooltipMaterial>
                                                                </label>
                                                                <Form.Control type='file' id={`input-file-image-cabin-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.cabinImage.norm, 6)} />
                                                            </FormGroup>
                                                        </>
                                                        ) : (
                                                        <div className={`${styles.imageWrapper} ${styles.vesselImagePlaceholder}`}>
                                                            <CameraAltOutlined color='secondary' className={`isClickable mb-1 ${styles.svg}`} sx={{fontSize: '73px'}} />
                                                            <FormGroup as={Row} className={`mt-2 d-flex justify-content-start ${styles.imageControls}`}>
                                                                <label htmlFor={`input-file-image-cabin-image`}>
                                                                    <TooltipMaterial className="isClickable" title="Fazer upload de nova foto">
                                                                        <CloudUploadIcon />
                                                                    </TooltipMaterial>
                                                                </label>
                                                                <label className="text-right" onClick={(e)=> onChange_ImageVesselItem(e, props.cabinImage.norm, 6)}>
                                                                    <TooltipMaterial className="isClickable ms-2" title="Remover foto atual">
                                                                        <HighlightOffIcon />
                                                                    </TooltipMaterial>
                                                                </label>
                                                                <Form.Control type='file' id={`input-file-image-cabin-image`} accept="image/*" onChange={(e)=> onChange_ImageVesselItem(e, props.cabinImage.norm, 6)} />
                                                            </FormGroup>
                                                        </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <Row>
                                                <Col sm="12" className="ps-0 pe-0">
                                                    <fieldset>
                                                        <legend>{display.legend.associates}
                                                            <div className="card-actions float-right">
                                                                <ButtonMaterial sx={{minWidth: 106,  maxWidth: 106}}
                                                                    variant="contained" color='primary' size='small'
                                                                    onClick={onClick_AddResponsible}>
                                                                    <AddIcon />
                                                                    Proprietário
                                                                </ButtonMaterial>
                                                            </div>
                                                        </legend>
                                                        {props.responsibles.length > 0 && (
                                                            <TableContainer columns={props.columnsResponsible} data={isEmpty(props.responsibles) ? [] : props.responsibles} />
                                                        )}
                                                    </fieldset>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm="12" className="ps-0 pe-0">
                                                    <fieldset>
                                                        <legend>{display.legend.pre_authorized_persons}
                                                            <div className="card-actions float-right">
                                                                <ButtonMaterial variant="contained" color='primary' size='small'
                                                                    sx={{minWidth: 106,  maxWidth: 106}}
                                                                    onClick={onClick_AddConductor}>
                                                                    <AddIcon />
                                                                    Condutor
                                                                </ButtonMaterial>
                                                            </div>
                                                        </legend>
                                                        {props.conductors.length > 0 && (
                                                            <TableContainer columns={props.columnsConductor} data={isEmpty(props.conductors) ? [] : props.conductors} />
                                                        )}
                                                    </fieldset>
                                                </Col>
                                            </Row>
                                            <fieldset>
                                                <legend>{display.legend.documentation_annex}</legend>
                                                <Table size="sm">
                                                    <tbody>
                                                        <tr>
                                                            <td ></td>
                                                            <td style={{display:  'flex', justifyContent: 'flex-end'}}>
                                                                <span className='me-5 mt-2' style={{verticalAlign: 'initial'}}>

                                                                    {display.label.vessel_document}
                                                                </span>
                                                                <InputGroup style={{width: '420px'}}>
                                                                    <FormControl className="input-file-text"
                                                                        id="form-file-vessel-document" name="vesselDocument"
                                                                        value={toString(props.vesselDocument.filename)}
                                                                        readOnly
                                                                        style={{marginBottom: '2px'}}

                                                                    />
                                                                    <Form.Control type='file' id='input-file-vessel-document' onChange={ (e)=> {onChange_VesselDocument(e) }} />
                                                                    <OverlayTrigger overlay={<Tooltip id="tooltip">
                                                                        {display.tooltips.upload_documents}</Tooltip>}>
                                                                        <Form.Label className="input-file-button"
                                                                            htmlFor='input-file-vessel-document'>
                                                                            <UploadFileIcon />
                                                                        </Form.Label>
                                                                    </OverlayTrigger>
                                                                    {!isEmpty(props.vesselDocument.document) ? (
                                                                        <>
                                                                        <Form.Label
                                                                            className="open-file-button input-file-button"
                                                                            htmlFor='input-file-vessel-document'
                                                                            onClick={ (e)=> { onClick_ViewDocument(e, display.label.vessel_document, props.vesselDocument.document) }}
                                                                        >
                                                                            {display.buttom.visualize}
                                                                        </Form.Label>
                                                                        <OverlayTrigger overlay={<Tooltip id="tooltip">
                                                                            {display.tooltips.remove_documents}</Tooltip>}>
                                                                            <Form.Label className="input-file-button"
                                                                                onClick={(e)=> {onChange_VesselDocument(e)}}
                                                                            >
                                                                                <FaTrashAlt size={18} />
                                                                            </Form.Label>
                                                                        </OverlayTrigger>
                                                                        </>
                                                                    ) : null}
                                                                </InputGroup>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </fieldset>
                                        </Container>
                                    </Tab>
                                    <Tab eventKey="ship-protection" title={display.subtitle.protection}>
                                        <Container fluid className="container-min">
                                            <fieldset>
                                                <legend>{display.legend.insurance_data}</legend>
                                                <Row>
                                                    <Col sm="3" className="ms-0 me-0">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-insurance-cnpj">CNPJ</Form.Label>
                                                            <FormControl id="form-insurance-cnpj" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="3" className="ms-0 me-0">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-insurance-name">{display.label.insurance_name}</Form.Label>
                                                            <FormControl id="form-insurance-name" name="insuranceName"
                                                                placeholder={display.example.insurance_name}
                                                                value={toString(props.insuranceName)} onChange={e=>
                                                                props.setInsuranceName(e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-insurance-number">{display.label.insurance_number}</Form.Label>
                                                            <FormControl id="form-insurance-number" name="insuranceNumber"
                                                                placeholder={display.example.insurance_number}
                                                                value={toString(props.insuranceNumber)} 
                                                                onChange={e=> props.setInsuranceNumber(toUpperCase(e.target.value))}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-insurance-validity">{display.label.insurance_validity}</Form.Label>
                                                            <FormControl id="form-insurance-validity" name="insuranceValidity"
                                                                maxLength={10} 
                                                                placeholder={display.example.insurance_validity}
                                                                value={toString(props.insuranceValidity)} 
                                                                onChange={e=> props.setInsuranceValidity(dataMask(e.target.value))}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-insurance-cost">{display.label.insurance_cost}</Form.Label>
                                                            <FormControl id="form-insurance-cost" name="insuranceCost"
                                                                maxLength={10} 
                                                                placeholder={display.example.insurance_cost}
                                                                className="text-right" 
                                                                value={toString(props.insuranceCost)} 
                                                                onChange={e=> props.setInsuranceCost(floatMask(e.target.value))}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="4">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-insurance-contact">{display.label.insurance_contact}</Form.Label>
                                                            <FormControl id="form-insurance-contact" name="insuranceContact"
                                                                placeholder={display.example.insurance_contact}
                                                                value={toString(props.insuranceContact)} onChange={e=>
                                                                props.setInsuranceContact(e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-insurance-cost">{display.label.insurance_phone1}</Form.Label>
                                                            <FormControl id="form-insurance-cost" name="insuranceCost"
                                                                maxLength={10} placeholder={display.example.insurance_cost}
                                                                className="text-right" onChange={e=>
                                                                props.setInsuranceCost(floatMask(e.target.value))}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="2">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-insurance-contact">{display.label.insurance_phone2}</Form.Label>
                                                            <FormControl id="form-insurance-contact" name="insuranceContact"
                                                                placeholder={display.example.insurance_contact} onChange={e=>
                                                                props.setInsuranceContact(e.target.value)}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormGroup>
                                                            <Form.Label htmlFor="form-insurance-validity">{display.label.insurance_email}</Form.Label>
                                                            <FormControl id="form-insurance-validity" name="insuranceValidity"
                                                                maxLength={10} placeholder={display.example.insurance_validity}
                                                                onChange={e=>
                                                                props.setInsuranceValidity(dataMask(e.target.value))}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </fieldset>
                                            <Row>
                                                <Col sm="8" className="ps-0 pe-0">
                                                    <fieldset>
                                                        <legend>{display.legend.vessel_values}</legend>
                                                        <Row>
                                                            <Col sm="12">
                                                            <Table size="sm">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <Form.Label htmlFor="form-hull-cost">
                                                                                {display.label.hull}</Form.Label>
                                                                        </td>
                                                                        <td>
                                                                            <InputGroup>
                                                                                <InputGroup.Text>
                                                                                    <BsCashCoin />
                                                                                </InputGroup.Text>
                                                                                <FormControl id="form-hull-cost" name="hullCost"
                                                                                    maxLength={10}
                                                                                    placeholder={display.example.hull_cost}
                                                                                    className="text-right"
                                                                                    value={toString(props.hullCost)}
                                                                                    onChange={e=>
                                                                                    props.setHullCost(floatMask(e.target.value))}
                                                                                    onBlur={e => onBlur_SumVesselCost(e)}
                                                                                    />
                                                                            </InputGroup>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Form.Label htmlFor="form-engine-cost">
                                                                                {display.label.engines}</Form.Label>
                                                                        </td>
                                                                        <td>
                                                                            <InputGroup>
                                                                                <InputGroup.Text>
                                                                                    <BsCashCoin />
                                                                                </InputGroup.Text>
                                                                                <FormControl id="form-engine-cost"
                                                                                    name="engineCost" maxLength={10}
                                                                                    placeholder={display.example.engine_cost}
                                                                                    className="text-right"
                                                                                    value={toString(props.engineCost)}
                                                                                    onChange={e=>
                                                                                    props.setEngineCost(floatMask(e.target.value))}
                                                                                    onBlur={e => onBlur_SumVesselCost(e)}
                                                                                    />
                                                                            </InputGroup>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Form.Label htmlFor="form-generators-cost">
                                                                                {display.label.generators}</Form.Label>
                                                                        </td>
                                                                        <td>
                                                                            <InputGroup>
                                                                                <InputGroup.Text>
                                                                                    <BsCashCoin />
                                                                                </InputGroup.Text>
                                                                                <FormControl id="form-generators-cost"
                                                                                    name="generatorsCost" maxLength={10}
                                                                                    placeholder={display.example.generators_cost}
                                                                                    className="text-right"
                                                                                    value={toString(props.generatorsCost)}
                                                                                    onChange={e=>
                                                                                    props.setGeneratorsCost(floatMask(e.target.value))}
                                                                                    onBlur={e => onBlur_SumVesselCost(e)}
                                                                                    />
                                                                            </InputGroup>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Form.Label htmlFor="form-soundsystem-cost">
                                                                                {display.label.soundsystem}</Form.Label>
                                                                        </td>
                                                                        <td>
                                                                            <InputGroup>
                                                                                <InputGroup.Text>
                                                                                    <BsCashCoin />
                                                                                </InputGroup.Text>
                                                                                <FormControl id="form-soundsystem-cost"
                                                                                    name="soundsystemCost" maxLength={10}
                                                                                    placeholder={display.example.soundsystem_cost}
                                                                                    className="text-right"
                                                                                    value={toString(props.soundsystemCost)}
                                                                                    onChange={e=>
                                                                                    props.setSoundsystemCost(floatMask(e.target.value))}
                                                                                    onBlur={e => onBlur_SumVesselCost(e)}
                                                                                    />
                                                                            </InputGroup>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Form.Label htmlFor="form-sails-and-masts-cost">
                                                                                {display.label.sails_and_masts}</Form.Label>
                                                                        </td>
                                                                        <td>
                                                                            <InputGroup>
                                                                                <InputGroup.Text>
                                                                                    <BsCashCoin />
                                                                                </InputGroup.Text>
                                                                                <FormControl id="form-sails-and-masts-cost"
                                                                                    name="sailsAndMastsCost" maxLength={10}
                                                                                    placeholder={display.example.sails_and_masts_cost}
                                                                                    className="text-right"
                                                                                    value={toString(props.sailsAndMastsCost)}
                                                                                    onChange={e=>
                                                                                    props.setSailsAndMastsCost(floatMask(e.target.value))}
                                                                                    onBlur={e => onBlur_SumVesselCost(e)}
                                                                                    />
                                                                            </InputGroup>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Form.Label htmlFor="form-accessories-cost">
                                                                                {display.label.accessories}</Form.Label>
                                                                        </td>
                                                                        <td>
                                                                            <InputGroup>
                                                                                <InputGroup.Text>
                                                                                    <BsCashCoin />
                                                                                </InputGroup.Text>
                                                                                <FormControl id="form-accessories-cost"
                                                                                    name="accessoriesCost" maxLength={10}
                                                                                    placeholder={display.example.accessories_cost}
                                                                                    className="text-right"
                                                                                    value={toString(props.accessoriesCost)}
                                                                                    onChange={e=>
                                                                                    props.setAccessoriesCost(floatMask(e.target.value))}
                                                                                    onBlur={e => onBlur_SumVesselCost(e)}
                                                                                    />
                                                                            </InputGroup>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Form.Label htmlFor="form-others-cost">
                                                                                {display.label.other}</Form.Label>
                                                                        </td>
                                                                        <td>
                                                                            <InputGroup>
                                                                                <InputGroup.Text>
                                                                                    <BsCashCoin />
                                                                                </InputGroup.Text>
                                                                                <FormControl id="form-others-cost"
                                                                                    name="othersCost" maxLength={10}
                                                                                    placeholder={display.example.others_cost}
                                                                                    className="text-right"
                                                                                    value={toString(props.othersCost)}
                                                                                    onChange={e=>
                                                                                    props.setOthersCost(floatMask(e.target.value))}
                                                                                    onBlur={e => onBlur_SumVesselCost(e)}
                                                                                    />
                                                                            </InputGroup>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <Form.Label htmlFor="form-vessel-cost">
                                                                                {display.label.amount}</Form.Label>
                                                                        </td>
                                                                        <td>
                                                                            <InputGroup>
                                                                                <InputGroup.Text>
                                                                                    <BsCashCoin />
                                                                                </InputGroup.Text>
                                                                                <FormControl id="form-vessel-cost"
                                                                                    name="vesselCost" maxLength={10}
                                                                                    placeholder={display.example.vessel_cost}
                                                                                    readOnly className="text-right"
                                                                                    value={toString(props.vesselCost)}
                                                                                    onChange={e=>
                                                                                    props.setVesselCost(floatMask(e.target.value))}
                                                                                    />
                                                                            </InputGroup>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                            </Col>
                                                        </Row>
                                                    </fieldset>
                                                </Col>
                                                <Col sm="4" className="ps-3 pe-0">
                                                    <fieldset>
                                                        <legend>
                                                            {display.legend.tracker}
                                                            <div className="card-actions float-right">
                                                                <a href="/#" onClick={(e)=> onClick_Tracker(e)}>
                                                                    <FaWifi size={22} />
                                                                </a>
                                                            </div>
                                                        </legend>
                                                        <Row>
                                                            <Col sm="5">
                                                            <Form.Label htmlFor="form-tracker-brand">{display.label.brand}
                                                            </Form.Label>
                                                            </Col>
                                                            <Col sm="7">
                                                            <FormControl id="form-tracker-brand" name="trackerBrand"
                                                                placeholder={display.example.brand}
                                                                value={toString(props.trackerBrand)} onChange={e=>
                                                                props.setTrackerBrand(e.target.value)}
                                                                />
                                                                </Col>
                                                        </Row>
                                                        <Row className="form-row-divider">
                                                            <Col sm="5">
                                                            <Form.Label htmlFor="form-tracker-model">{display.label.model}
                                                            </Form.Label>
                                                            </Col>
                                                            <Col sm="7">
                                                            <FormControl id="form-tracker-model" name="trackerModel"
                                                                placeholder={display.example.model}
                                                                value={toString(props.trackerModel)} onChange={e=>
                                                                props.setTrackerModel(e.target.value)}
                                                                />
                                                                </Col>
                                                        </Row>
                                                        <Row className="form-row-divider">
                                                            <Col sm="5">
                                                            <Form.Label htmlFor="form-tracker-serial">
                                                                {display.label.serial_number}</Form.Label>
                                                            </Col>
                                                            <Col sm="7">
                                                            <FormControl id="form-tracker-serial" name="trackerSerial"
                                                                placeholder={display.example.serial_number}
                                                                value={toString(props.trackerSerial)} onChange={e=>
                                                                props.setTrackerSerial(e.target.value)}
                                                                />
                                                                </Col>
                                                        </Row>
                                                        <Row className="form-row-divider">
                                                            <Col sm="5">
                                                            <Form.Label htmlFor="form-tracker-active">{display.label.actived}
                                                            </Form.Label>
                                                            </Col>
                                                            <Col sm="7">
                                                            <FormControl id="form-tracker-active" name="trackerSerial"
                                                                placeholder={display.example.actived}
                                                                value={toString(props.trackerActive)} onChange={e=>
                                                                props.setTrackerActive(dataMask(e.target.value))}
                                                                />
                                                                </Col>
                                                        </Row>
                                                        <Row className="form-row-divider">
                                                            <Col sm="5">
                                                            <Form.Label htmlFor="form-tracker-responsible">
                                                                {display.label.responsible}</Form.Label>
                                                            </Col>
                                                            <Col sm="7">
                                                            <FormControl id="form-tracker-responsible" name="trackerResponsible"
                                                                placeholder={display.example.responsible}
                                                                value={toString(props.trackerResponsible)} onChange={e=>
                                                                props.setTrackerResponsible(e.target.value)}
                                                                />
                                                                </Col>
                                                        </Row>
                                                    </fieldset>
                                                </Col>
                                            </Row>
                                            <fieldset>
                                                <legend>{display.legend.documentation_annex}</legend>
                                                <Table size="sm">
                                                    <tbody>
                                                        <tr>
                                                        <td className='text-right' style={{verticalAlign: 'initial'}}>
                                                            </td>
                                                        <td style={{display:  'flex', justifyContent: 'flex-end'}}>
                                                            <span className='me-5 mt-2' style={{verticalAlign: 'initial'}}>

                                                                    {display.label.insurance_policy_document}
                                                            </span>
                                                                <InputGroup style={{width: '420px'}}>
                                                                    <FormControl className="input-file-text"
                                                                        id="form-file-insurance-policy-document" name="insurancePolicyDocument"
                                                                        value={toString(props.insurancePolicyDocument.filename)}
                                                                        readOnly
                                                                        style={{marginBottom: '2px'}}
                                                                    />
                                                                    <Form.Control type='file' id='input-file-insurance-policy-document' onChange={(e)=> { onChange_InsurancePolicyDocument(e) }} />
                                                                        <OverlayTrigger overlay={<Tooltip id="tooltip">
                                                                            {display.tooltips.upload_documents}</Tooltip>}>
                                                                            <Form.Label className="input-file-button"
                                                                                htmlFor='input-file-insurance-policy-document'>
                                                                                <UploadFileIcon />
                                                                            </Form.Label>
                                                                        </OverlayTrigger>
                                                                        {!isEmpty(props.insurancePolicyDocument.document) ? (
                                                                            <>
                                                                            <Form.Label
                                                                                className="open-file-button input-file-button"
                                                                                htmlFor='file-insurance-policy-document'
                                                                                onClick={ (e)=> { onClick_ViewDocument(e, display.label.insurance_policy_document, props.insurancePolicyDocument.document)}}
                                                                            >
                                                                                {display.buttom.visualize}
                                                                            </Form.Label>
                                                                            <OverlayTrigger overlay={<Tooltip id="tooltip">
                                                                                {display.tooltips.remove_documents}</Tooltip>}>
                                                                                <Form.Label className="input-file-button"
                                                                                    onClick={(e)=> {onChange_VesselDocument(e)}}
                                                                                >
                                                                                    <FaTrashAlt size={18} />
                                                                                </Form.Label>
                                                                            </OverlayTrigger>
                                                                            </>
                                                                        ) : null}
                                                                </InputGroup>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                        <td ></td>
                                                            <td style={{display:  'flex', justifyContent: 'flex-end'}}>
                                                                <span className='me-5 mt-2' style={{verticalAlign: 'initial'}}>

                                                                {display.label.tracker_policy_document}
                                                                </span>
                                                                <InputGroup style={{width: '420px'}}>
                                                                    <FormControl className="input-file-text"
                                                                        id="form-file-drivers-license-document" name="driversLicenseDocument"
                                                                        value={toString(props.driversLicenseDocument.filename)}
                                                                        readOnly
                                                                        style={{marginBottom: '2px'}}
                                                                    />
                                                                    <Form.Control type='file' id='input-file-drivers-license-document' onChange={(e)=> { onChange_DriversLicenseDocument(e) }} />
                                                                        <OverlayTrigger overlay={<Tooltip id="tooltip">
                                                                            {display.tooltips.upload_documents}</Tooltip>}>
                                                                            <Form.Label className="input-file-button"
                                                                                htmlFor='input-file-drivers-license-document'>
                                                                                <UploadFileIcon />
                                                                            </Form.Label>
                                                                        </OverlayTrigger>
                                                                        {!isEmpty(props.driversLicenseDocument.document) ? (
                                                                            <>
                                                                            <Form.Label
                                                                                className="open-file-button input-file-button"
                                                                                htmlFor='file-drivers-license-document'
                                                                                onClick={ (e)=> { onClick_ViewDocument(e, 
                                                                                    display.label.tracker_policy_document, 
                                                                                    props.driversLicenseDocument.document
                                                                                )
                                                                            }}>{display.buttom.visualize}</Form.Label>
                                                                            <OverlayTrigger overlay={<Tooltip id="tooltip">
                                                                                {display.tooltips.remove_documents}</Tooltip>}>
                                                                                <Form.Label className="input-file-button"
                                                                                    onClick={(e)=> {onChange_VesselDocument(e)}}
                                                                                >
                                                                                    <FaTrashAlt size={18} />
                                                                                </Form.Label>
                                                                            </OverlayTrigger>
                                                                            </>
                                                                        ) : null}
                                                                </InputGroup>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </fieldset>
                                        </Container>
                                    </Tab>
                                    <Tab eventKey="ship-equipment-and-accessories"
                                        title={display.subtitle.equipment_and_accessories}>
                                        <Container fluid className="container-min">
                                            <fieldset>
                                                <legend>{display.legend.detailing_of_vessel_items}
                                                    <div className="card-actions float-right">
                                                        <ButtonMaterial variant="contained" color='primary' size='small'
                                                            onClick={onClick_AddEquipment}>
                                                            <AddIcon />
                                                            Item
                                                        </ButtonMaterial>
                                                    </div>
                                                </legend>
                                                <Table size="sm">
                                                    <thead>
                                                        <tr>
                                                            <th>{display.label.group}</th>
                                                            <th>{display.label.category}</th>
                                                            <th>{display.label.name}</th>
                                                            <th>{display.label.unity}</th>
                                                            <th>{display.label.quantity}</th>
                                                            <th>{display.label.amount}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {props.equipments && props.equipments.map((item: any, index: number) => {
                                                        return (
                                                        <tr key={index}>
                                                            <td>
                                                                <FormControl type="hidden" id="form-equipment-id"
                                                                    name="equipmentId" placeholder={display.example.group}
                                                                    onChange={(e)=> onChange_UpdateEquipmentItem(e, index,
                                                                    'id', e.target.value)}
                                                                    value={toString(item.id)} />
                                                                    <FormControl id="form-group" name="group"
                                                                        placeholder={display.example.group} onChange={(e)=>
                                                                        onChange_UpdateEquipmentItem(e, index, 'group',
                                                                        e.target.value)}
                                                                        value={toString(item.group)} />
                                                            </td>
                                                            <td>
                                                                <FormControl id="form-category" name="category"
                                                                    placeholder={display.example.category} onChange={(e)=>
                                                                    onChange_UpdateEquipmentItem(e, index, 'category',
                                                                    e.target.value)}
                                                                    value={toString(item.category)} />
                                                            </td>
                                                            <td>
                                                                <FormControl id="form-name" name="name"
                                                                    placeholder={display.example.name} onChange={(e)=>
                                                                    onChange_UpdateEquipmentItem(e, index, 'name',
                                                                    e.target.value)}
                                                                    value={toString(item.name)} />
                                                            </td>
                                                            <td>
                                                                <FormControl id="form-unity" name="unity"
                                                                    placeholder={display.example.unity} onChange={(e)=>
                                                                    onChange_UpdateEquipmentItem(e, index, 'unity',
                                                                    e.target.value)}
                                                                    value={toString(item.unity)}
                                                                    />
                                                            </td>
                                                            <td>
                                                                <FormControl id="form-quantity" name="quantity"
                                                                    placeholder={display.example.quantity}
                                                                    onChange={(e)=>
                                                                    onChange_UpdateEquipmentItem(e, index, 'quantity',
                                                                    e.target.value)}
                                                                    value={toString(item.quantity)}
                                                                    />
                                                            </td>
                                                            <td>
                                                                <FormControl id="form-amount" name="amount"
                                                                    placeholder={display.example.amount}
                                                                    className="text-right"
                                                                    maxLength={7} onChange={(e)=>
                                                                    onChange_UpdateEquipmentItem(e, index, 'amount',
                                                                    floatMask(e.target.value))}
                                                                    onBlur={(e) => onBlur_UpdateEquipmentValue(e)}
                                                                    value={toString(item.amount)}
                                                                    />
                                                            </td>
                                                            <td>
                                                                { index !== 0 && index !== 1 && (
                                                                <OverlayTrigger overlay={<Tooltip id="tooltip">
                                                                    {display.tooltips.delete}</Tooltip>}>
                                                                    <a href="/#" onClick={(e)=>
                                                                        onClick_RemoveEquipmentItem(e, index)}>
                                                                        <FaMinusSquare size={18} />
                                                                    </a>
                                                                </OverlayTrigger>
                                                                )}
                                                            </td>
                                                        </tr>
                                                        );
                                                        })}
                                                    </tbody>
                                                </Table>
                                            </fieldset>
                                        </Container>
                                    </Tab>
                                    <Tab eventKey="ship-maintenance" title={display.subtitle.maintenance}>
                                        <Container fluid className="container-min">
                                            <Row>
                                                <Col sm="7">
                                                    <fieldset>
                                                        <legend>Agendamento</legend>
                                                        <Row>
                                                            <Col sm="4">
                                                            <FormGroup>
                                                                <Form.Label htmlFor="form-schedule-prohibited">
                                                                    {display.label.prohibited}</Form.Label>
                                                                <DatePicker locale={ptBR}
                                                                    selected={toDate(props.scheduleProhibited, 'dd/mm/yyyy HH:MM:ss'
                                                                    )} onChange={(date: any, e: any)=> onChange_Prohibited(e,
                                                                    date)}
                                                                    dateFormat="dd/MM/yyyy HH:mm"
                                                                    customInput={
                                                                    <PickerInput />}
                                                                    // minDate={new Date()}
                                                                    showTimeSelect
                                                                    timeFormat="HH:mm"
                                                                    timeIntervals={20}
                                                                    timeCaption="Hora"

                                                                    popperClassName="some-custom-class"
                                                                    popperPlacement="top-end"
                                                                    popperModifiers={[{
                                                                    name : "offset",
                                                                    options : {
                                                                    offset : [5, 10]
                                                                    }
                                                                    },{
                                                                    name : "preventOverflow",
                                                                    options : {
                                                                    rootBoundary : "viewport",
                                                                    tether : false,
                                                                    altAxis : true
                                                                    }
                                                                    }]}
                                                                    />
                                                            </FormGroup>
                                                            </Col>
                                                            <Col sm="8">
                                                            <FormGroup>
                                                                <Form.Label htmlFor="form-schedule-title">{display.label.title}
                                                                </Form.Label>
                                                                <Form.Select id="form-schedule-title" name="scheduleTitle"
                                                                    value={toString(props.scheduleTitle)} onChange={(e: any)=>
                                                                    props.setScheduleTitle(e.target.value)}
                                                                    >
                                                                    { isEmpty(props.scheduleTitle) ? (
                                                                    <option value="">...</option>
                                                                    ) : null }
                                                                    { ScheduleTitleType.map((iterator: any, idx: any) => (
                                                                    <option key={idx} value={iterator.id}>
                                                                        {display.label[toLowerCase(iterator.name)]}</option>))}
                                                                </Form.Select>
                                                            </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm="12">
                                                            <FormGroup>
                                                                <Form.Label htmlFor="form-schedule-comments">
                                                                    {display.label.comments}</Form.Label>
                                                                <Form.Control as="textarea" rows={3} type=""
                                                                    id="form-schedule-comments" name="scheduleComments"
                                                                    placeholder={display.example.comments}
                                                                    value={toString(props.scheduleComments)} onChange={e=>
                                                                    props.setScheduleComments(e.target.value)}
                                                                    />
                                                            </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col sm="12" className="other-footer text-right">
                                                            <Button onClick={(e)=>
                                                                onClick_Schedule(e)}>{display.buttom.schedule}</Button>
                                                            </Col>
                                                        </Row>
                                                    </fieldset>
                                                </Col>
                                                <Col sm="5">
                                                    <fieldset>
                                                        <legend>Histórico de Serviços</legend>
                                                        <div className={styles.vesselSchedules}>
                                                            {props.schedules && props.schedules.map((schedule: any, idx: any) =>
                                                            (
                                                            <div key={idx} className={styles.vesselSchedule}>
                                                                <div className={styles.vesselScheduleHeader}>
                                                                    <div>{schedule.title}</div>
                                                                    <div className={styles.vesselDate}>
                                                                        {dateToString(schedule.prohibited, 'dd/mm/yyyyHH:MM:ss')}
                                                                    </div>
                                                                </div>
                                                                <div>{schedule.comments}</div>
                                                            </div>
                                                            ))}
                                                        </div>
                                                    </fieldset>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Tab>
                                </Tabs>
                            </Container>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    return (
        <>
            {viewVesselCadaster()}
        </>
    );

}

export default VesselRecord;