import AddIcon from '@mui/icons-material/Add';
import { Button as ButtonMaterial } from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Container, OverlayTrigger, Row, Spinner, Tooltip, Image } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { FaEdit, FaSpinner } from "react-icons/fa";
import { IoNewspaperOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { PickerButtom } from "../../components/sub-render.component";
import TableContainer from "../../components/table-container.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import authService from "../../services/auth.service";
import srvService from "../../services/srv.service";
import { dateToString, floatToString, maxString, toDate, toLowerCase } from "../../utilities/auxiliary-functions";
import { PERSON_PESSOA_JURIDICA, PermissionEnum } from "../../utilities/constants";
import { cnpjMask, phoneMask, removeFormatDate, zipcodeMask } from "../../utilities/masks";
import { AlertMessageEnum, getBudgetSituation, getColorDefault, getPayment, getPaymentConditions, getUnit, getVessel } from "../../utilities/types";
import { isDate, isEmpty } from "../../utilities/validators";
import OfficeHeader from "../headers/office.header";
import BudgetCadastreModal from "../modals/budget.cadastre.modal";
import BudgetReport, { TBudgetReport } from "../reports/budget.report";
import lclService from '../../services/lcl.service';
import cduService from '../../services/cdu.service';
import { VESSEL_LANCHA } from "../../utilities/constants";
import Jet from '../../assets/Jetski.svg';
import Lancha from '../../assets/Lancha.svg';
import styles from './budget.office.module.scss'


interface Props {
    translate: any,
    partner: any,

    onClick_ShowDefinedPartner: any
}

function BudgetOffice(props: Props) {
    
    const alertContext = useAlertMessageContext();

    const navigator = useNavigate();
    const display = props.translate.data;

    const title = display.title.budget;
    const description = display.description.budget;
    
    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ sending, setSending ] = useState(false);

    const [ dateTimeStart, setDateTimeStart ] = useState(dateToString(new Date(), 'dd/mm/yyyy') + ' 00:00:00');
    const [ dateTimeEnd, setDateTimeEnd ] = useState(dateToString(new Date(), 'dd/mm/yyyy') + ' 23:59:59');
    
    const onChange_DateTimeStart = (date: any) => {
        const dateStop = toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss');
        if (date > dateStop) {
            setDateTimeStart(dateToString(dateStop, 'dd/mm/yyyy') + ' 00:00:00');
        } else {
            setDateTimeStart(dateToString(date, 'dd/mm/yyyy HH:MM:ss'));
        }
    }

    const onChange_DateTimeStop = (date: any) => {
        const dateStart = toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss');
        if (date < dateStart) {
            setDateTimeEnd(dateToString(dateStart, 'dd/mm/yyyy') + ' 23:59:59');
        } else {
            setDateTimeEnd(dateToString(date, 'dd/mm/yyyy HH:MM:ss'));
        }
    }

    const [records, setRecords ] = useState([] as any);
    const columnsRecord = useMemo(() => [{
        Header: display.label.os,
        accessor: 'code',
        Cell: (row: any) => (<div className="white-space mt-4">{row.value}</div>)
    },{
        Header: display.label.prohibited,
        accessor: 'prohibited',
        Cell: (row: any) => (<div className="white-space mt-4">{dateToString(row.value, 'dd/mm/yyyy')}</div>)
    },{
        Header: display.label.departure,
        accessor: 'departure',
        Cell: (row: any) => (<div className="white-space mt-4">{dateToString(row.value, 'dd/mm/yyyy')}</div>)
    },{
        Header: display.label.vessel,
        accessor: 'vessel.name',
        Cell: (row: any) => (<div className="white-space mt-4">{row.value}</div>)
    },{
        Header: display.label.identify_key,
        accessor: 'vessel.identifyKey',
        Cell: (row: any) => (<div className="white-space mt-4">{row.value}</div>)
    },{
        Header: display.label.enrollment,
        accessor: 'vessel.enrollment',
        Cell: (row: any) => (<div className="white-space mt-4">{row.value}</div>)
    },{
        Header: 'Tipo | Cor',
        accessor: "vessel",
        Cell: (row: any) => (
            getVessel(row.value?.typeVessel).id === VESSEL_LANCHA ? (
                <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                    <div className="mt-3 d-flex justify-content-center mb-2">
                        <Image src={Lancha} className="jet" style={{height: 32}}/>
                        <small className="ms-2">{`${row.value.vesselSize}'`}</small>
                    </div>
                <span className={styles.vesselColor} style={{backgroundColor: `${row.value?.color}`}}></span>
                </div>
            ):(
                <div className="d-flex flex-column align-items-center justify-content-center mb-2">
                    <div className="d-flex justify-content-center mb-2">
                        <Image src={Jet} className="jet mt-3" style={{height: 32}}/>
                        <small className="ms-1 mt-3">{`10'`}</small>
                    </div>
                    <span className={styles.vesselColor} style={{backgroundColor: `${row.value?.color}`}}></span>
                </div>
            )
        )
    },{
        Header: 'Mar. | Mod.',
        // accessor: "vessel",
        Cell: (row: any) => (
            <div className="d-flex flex-column justify-content-center mb-2">
                <div className="mt-3">{row.row.values?.vessel.brand || '-'}</div>
                <div className="mt-1">{row.row.values?.vessel.model || '-'}</div>
            </div>
        )
    },{
        Header: display.label.situation,
        accessor: 'situation',
        Cell: (row: any) => (<div className="white-space mt-4">
            <div className="table-column-color">
                <span className="table-column-blockAutox30" style={{backgroundColor: `${getBudgetSituation(row.value).color}`}}>
                    {display.label[toLowerCase(getBudgetSituation(row.value).name)]}
                </span>
            </div>
        </div>)
    },{
        Header: display.label.mechanic,
        accessor: 'mechanic.people.fullname',
        Cell: (row: any) => (<div className="white-space mt-4">{!isEmpty(row.value) ? maxString(row.value, row.value.indexOf(' ')) : ''}</div>)
    },{
        Header: display.legend.actions,
        accessor: "actions",
        Cell: (props: any) => {
            let budgetRows = [] as any;
            const originals = props.rows;
            for (var o = 0; o < originals.length; o++) {
                budgetRows[budgetRows.length] = originals[o].original;
            }
            const budgetRow = props.row.original;

            return (
                <div className="white-space table-actions mt-4">
                    {authService.hasPermission(PermissionEnum.OFFICE_BUDGET_EDIT) && 
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.toEdit}</Tooltip>}>
                            <span onClick={(e) => onClick_EditBudget(e, budgetRow)}>
                                <FaEdit size={18} />
                            </span>
                        </OverlayTrigger>
                    }
                    {authService.hasPermission(PermissionEnum.OFFICE_BUDGET_EDIT) && 
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.view_report}</Tooltip>}>
                            <span onClick={(e) => onClick_ReportBudget(e, budgetRow)}>
                                <IoNewspaperOutline size={22} />
                            </span>
                        </OverlayTrigger>
                    }
                </div>
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }], []); // columnsRecord
    
    const [ budgetId, setBudgetId ] = useState('');
    const [ budget, setBudget ] = useState({} as any);
    const [ isEditBudget, setEditBudget ] = useState(false);
    const [ showBudget, setShowBudget ] = useState(false);


    function onClick_RefreshBudget(event: any) {
        event.preventDefault();

        setSending(true);
        searchfilter(dateTimeStart, dateTimeEnd);
    } // onClick_RefreshBudget
    
    function onClick_AddRecord(event: any) {
        event.preventDefault();

        setEditBudget(false);
        setBudgetId('new');
        setBudget({} as any);
        setShowBudget(true);
    } // onClick_AddRecord

    function onClick_EditBudget(event: any, budget: any) {
        event.preventDefault();

        setEditBudget(true);
        setBudgetId(budget.id);
        setBudget(budget);
        setShowBudget(true);
    } // onClick_EditRecord

    function reportResponsableName(budget: any) {
        let name = '';

        const responsibles = budget.vessel.responsibles;
        if (responsibles && responsibles.length > 0) {
            name = responsibles[0].owner?.fullname;
        }
        if (isEmpty(name)) {
            const owner = budget.vessel.owner;
            if (owner) {
                name = (budget.vessel.ownerType === PERSON_PESSOA_JURIDICA) ? owner.socialReason : owner?.fullname;
            }
        }
        return name;
    }

    function reportResponsableEmail(budget: any) {
        let email = '';
        const responsibles = budget.vessel.responsibles;
        if (responsibles && responsibles.length > 0) {
            email = responsibles[0].owner?.email;
        }
        return email;
    }

    function reportResponsableTelephone(budget: any) {
        let telephone = '';

        const responsibles = budget.vessel.responsibles;
        if (responsibles && responsibles.length > 0) {
            const phones = responsibles[0].owner?.phones
            if (phones && phones.length > 0) {
                telephone = phones[0].connection;
            }
        }
        return telephone;
    }

    async function onClick_ReportBudget(event: any, record: any) {
        event.preventDefault();

        /*
        let report: IBudgetReport = {
            translate: props.translate,
            partner: props.partner,
            budgetId: record.id
        }
        */
        try {
            const budget = await srvService.budgetById(Number(record.id));
            if (budget.vesselId) {
                const vessel = await lclService.vesselById(budget.vesselId);
                budget.vessel = vessel;
            }
            if (budget.mechanicId) {
                const mechanic = await srvService.mechanicById(budget.mechanicId);
                budget.mechanic = mechanic;
            }

            const address = await cduService.addressById(props.partner.company.addressId);
            let report: TBudgetReport = {
                company: {
                    image: props.partner.company.image,
                    socialReason: props.partner.company.socialReason,
                    fantasy: props.partner.company.fantasy,
                    registrationPj: cnpjMask(props.partner.company.registrationPj),
                    telephone: phoneMask(props.partner.company.telephone),
                    email: props.partner.company.email,
                    address: `${address.place} - ${address.district} - ${address.city} - ${address.state} - ${zipcodeMask(address.zipcode)}`
                },
                budget: {
                    code: budget.code,
                    prohibited: dateToString(budget.prohibited, 'dd/mm/yyyy HH:MM'),
                    departure: dateToString(budget.departure, 'dd/mm/yyyy HH:MM')
                },
                responsable: {
                    name: reportResponsableName(budget),
                    email: reportResponsableEmail(budget),
                    telephone: reportResponsableTelephone(budget)
                },
                vessels: [{
                    identifyKey: budget.vessel.identifyKey,
                    enrollment: budget.vessel.enrollment,
                    name: budget.vessel.name,
                    color: display.label[toLowerCase(getColorDefault(budget.vessel.color).name)],
                    brand: budget.vessel.brand,
                    model: budget.vessel.model
                }],
                comments: budget.comments,
                productAndServices: budget.items.map((item: any) => {
                    return {
                        reference: item.stock.product.reference,
                        code: item.stock.product.code,
                        category: item.stock.product.category.name,
                        description: item.stock.product.description,
                        unit: getUnit(item.stock.product.unit).name,
                        quantity: item.quantity,
                        sale: floatToString(item.stock.sale),
                        amount: floatToString(item.amount)
                    }
                }),
                mechanic: budget?.mechanic?.people?.fullname,
                situation: display.label[toLowerCase(getBudgetSituation(budget.situation).name)],
                paymentForm: display.label[toLowerCase(getPayment(budget.paymentForm).name)],
                paymentConditions: display.label[toLowerCase(getPaymentConditions(budget.paymentConditions).name)],
                productCost: floatToString(budget.productCost),
                officeCost: floatToString(budget.officeCost),
                discountCost: floatToString(budget.discountCost),
                orderCost: floatToString(budget.orderCost)
            };
            BudgetReport(report);
        } catch(error: any) {
            console.error(error);
        }
    } // onClick_ReportBudget

    async function searchfilter(dateTimeStart: string, dateTimeEnd: string) {
        if (props.partner) {
            setDateTimeStart(dateTimeStart);
            setDateTimeEnd(dateTimeEnd);

            if (isDate(toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')) && isDate(toDate(dateTimeEnd, 'dd/mm/yyyy HH:MM:ss'))) {
                const attributes = [] as any;
                const where = { prohibited : { start : removeFormatDate(dateTimeStart), end : removeFormatDate(dateTimeEnd) } } as any;
                const order = [ [ 'prohibited', 'ASC' ] ] as any;

                try {
                    /* Sempre incluir o partner na pesquisa */
                    where['partnerId'] = props.partner.id;

                    const budgets = await srvService.budgetList(attributes, where, order);
                    setRecords(budgets);
                    setSending(false);
                } catch(error: any) {
                    await alertContext.show(AlertMessageEnum.FAIL, title, error);
                }
                setSending(false);
            } else {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.an_invalid_date_was_reported);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi definido uma Marina para exibir os orçamentos !');
            navigator(`/portal`);
        }
    } // searchfilter

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            let inicio = moment().day(0); // domingo desta semana
            let fim = moment().day(6); // sábado desta semana

            let startDate = inicio.format('DD/MM/YYYY 00:00:00');
            let endDate = fim.format('DD/MM/YYYY 23:59:59');

            searchfilter(startDate, endDate);
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []); // useEffect

    function viewPage() {

        return (
            <Container fluid className="page-body budgets">
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card style={{ minHeight : 500 }}>
                            <Card.Header>
                                <div className="card-actions d-flex justify-content-between">
                                    <div className="d-flex">

                                    <div className="card-actions-time">
                                        <DatePicker
                                            locale={ptBR}
                                            selected={toDate(dateTimeStart, 'dd/mm/yyyy')}
                                            onChange={(date: any) => onChange_DateTimeStart(date) }
                                            dateFormat="dd/MM/yyyy"
                                            customInput={<PickerButtom />}
                                            selectsStart
                                            startDate={toDate(dateTimeStart, 'dd/mm/yyyy')}
                                            endDate={toDate(dateTimeEnd, 'dd/mm/yyyy')}

                                            popperClassName="some-custom-class"
                                            popperPlacement="top-end"
                                            popperModifiers={[
                                                {
                                                    name : "offset",
                                                    options : {
                                                        offset : [5, 10]
                                                    }
                                                },{
                                                    name : "preventOverflow",
                                                    options : {
                                                        rootBoundary : "viewport",
                                                        tether : false,
                                                        altAxis : true
                                                    }
                                                }
                                            ]}
                                        /> 
                                        <DatePicker
                                            locale={ptBR}
                                            selected={toDate(dateTimeEnd, 'dd/mm/yyyy')}
                                            onChange={(date: any) => onChange_DateTimeStop(date) }
                                            dateFormat="dd/MM/yyyy"
                                            customInput={<PickerButtom />}
                                            selectsEnd
                                            startDate={toDate(dateTimeStart, 'dd/mm/yyyy')}
                                            endDate={toDate(dateTimeEnd, 'dd/mm/yyyy')}
                                            minDate={toDate(dateTimeStart, 'dd/mm/yyyy')}

                                            popperClassName="some-custom-class"
                                            popperPlacement="top-end"
                                            popperModifiers={[
                                                {
                                                    name : "offset",
                                                    options : {
                                                        offset : [5, 10]
                                                    }
                                                },{
                                                    name : "preventOverflow",
                                                    options : {
                                                        rootBoundary : "viewport",
                                                        tether : false,
                                                        altAxis : true
                                                    }
                                                }
                                            ]}
                                        />
                                     </div>
                                        <Button variant="secondary" className="ms-2" onClick={ (e) => onClick_RefreshBudget(e) } disabled={sending}>
                                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSpinner size={22} /> } {' '}
                                            {display.buttom.refresh}
                                        </Button>
                                     </div>
                                        {authService.hasPermission(PermissionEnum.OFFICE_BUDGET_ADD) && 
                                            <ButtonMaterial size="small" variant="contained" onClick={(e) => onClick_AddRecord(e) } ><AddIcon />Orçamento</ButtonMaterial>
                                        }
                                </div>
                                <Card.Title>{display.subtitle.budget}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <TableContainer columns={columnsRecord} data={records} viewFilter={false} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewPage

    return (
        <div className="page">
            <OfficeHeader title={title} description={description} onClick_ShowDefinedPartner={props.onClick_ShowDefinedPartner} />
            { viewPage() }
            <BudgetCadastreModal 
                translate={props.translate} 
                partner={props.partner}
                isEdit={isEditBudget}

                show={showBudget}
                budgetId={budgetId}
                record={budget}
                records={records}
                onSubmitModal={(event: any, record: any) => {
                    event.preventDefault();

                    setBudget(record);
                    if (isEditBudget) {
                        const updatedBudgets = Array.from(records) as any;
                        for ( var idx_Edit = 0; idx_Edit < updatedBudgets.length; idx_Edit++) {
                            if (updatedBudgets[idx_Edit].id === record.id) {
                                updatedBudgets[idx_Edit] = record;
                            }
                        }
                        setRecords(updatedBudgets);
                    } else {
                        setRecords([...records, record]);
                    }
                    setShowBudget(false);
                    setEditBudget(false);
                    searchfilter(dateTimeStart, dateTimeEnd);
                }}
                onCancelModal={(event: any) => {
                    setEditBudget(false);
                    setShowBudget(false);
                }}
            />
        </div>
    );

}

export default BudgetOffice;