import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { MdOutlineModelTraining } from "react-icons/md";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

enum PositionFieldsEnum {
    'LEFT',
    'RIGHT'
}
  
interface RenderTree {
    id: string;
    name: string;
    label: string;
    children?: readonly RenderTree[];
}

type TPositionFields = typeof PositionFieldsEnum[keyof typeof PositionFieldsEnum]

interface IEditorProps {
    editorHtml: string,
    setEditorHtml: Function,
    placeholder?: string,

    selectField?: any,
    fieldInLeft?: TPositionFields,

    clickTemplateDefault: Function
}

export class Editor extends Component<IEditorProps> {

    quillRef: any;
    reactQuillRef: any;

    constructor(props: IEditorProps) {
        super(props);

        this.quillRef = null; // Quill instance
        this.reactQuillRef = null; // ReactQuill component
    }
  
    componentDidMount() {
        this.attachQuillRefs();
    }
  
    componentDidUpdate() {
        this.attachQuillRefs();
    }
  
    attachQuillRefs = () => {
        if (typeof this.reactQuillRef.getEditor !== 'function') return;
        this.quillRef = this.reactQuillRef.getEditor();
        
        const toolbar = this.quillRef.getModule("toolbar");
            toolbar.addHandler("table", () => {
            this.insertTable();
        });
    };

    insertTable = () => {
        const tableModule = this.quillRef.getModule("better-table");
        tableModule.insertTable(3, 3);
    };

    insertText = (text: string) => {
        this.quillRef.focus();
        var range = this.quillRef.getSelection();

        let position = range ? range.index : 0;
        this.quillRef.insertText(position, `%${text}%`);
    };
  
    viewSelectField() {
        const { selectField } = this.props;

        const renderTree = (nodes: RenderTree) => {
            if (Array.isArray(nodes)) {
                return (
                    <div className="treeview-root">
                        {nodes.map((node: any) => renderTree(node))}
                    </div>
                );
            } else {
                return (
                    <div key={nodes.id} className={nodes.children ? "treeview-group" : "treeview-item"}>
                        {!nodes.children ? (
                            <span onClick={e => this.insertText(nodes.name)}>{nodes.label}</span>
                        ) : (
                            <span>{nodes.label}</span>
                        )}
                        {Array.isArray(nodes.children)
                            ? nodes.children.map((node: any) => renderTree(node))
                            : null}
                    </div>
                );
            }
        }

        return (
            <fieldset>
                <legend>Campos Dinâmicos</legend>
                <div className="treeview"
                    style={{ maxHeight: '535px', overflowY: 'auto' }}
                >
                    {renderTree(selectField)}
                </div>
            </fieldset>
        );
    }

    render() {
        const { selectField, fieldInLeft, 
            clickTemplateDefault, 
            editorHtml, setEditorHtml, placeholder
        } = this.props;

        let position = PositionFieldsEnum.RIGHT;
        if (fieldInLeft !== undefined)
            position = fieldInLeft;

        return (
            <Container fluid className="container-min">
                <Row>
                    {selectField && (position === PositionFieldsEnum.LEFT) ? (<Col sm="3">{ this.viewSelectField() }</Col>) : null }
                    <Col sm={selectField ? "9" : "12"}>
                        <fieldset>
                            <legend>Descrição Contratual
                                <div className="card-actions float-right">
                                    <MdOutlineModelTraining size={18} onClick={e => clickTemplateDefault(e)} />
                                </div>
                            </legend>
                            <ReactQuill theme="snow" 
                                ref={(el: any) => {
                                    this.reactQuillRef = el;
                                }}
                                value={editorHtml} 
                                onChange={e => setEditorHtml(e)} 
                                placeholder={placeholder}
                                style={{ margin: '20px 10px 35px 10px', height: '500px' }} />
                        </fieldset>
                    </Col>
                    {selectField && (position === PositionFieldsEnum.RIGHT) ? (<Col sm="3">{ this.viewSelectField() }</Col>) : null }
                </Row>
            </Container>
        );
    }

}