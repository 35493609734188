import { useEffect, useMemo, useState } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { FaSearch, } from "react-icons/fa";
import TableContainer from "../../components/table-container.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import srvService from "../../services/srv.service";
import { floatMask } from "../../utilities/masks";
import { AlertMessageEnum, getUnit } from "../../utilities/types";
import { isEmpty } from "../../utilities/validators";
import { Button } from '@mui/material'
import { FaTimes } from "react-icons/fa";

interface Props {
    translate: any,
    team?: any,
    category?: any,
    stockNotInList: any,
    show: any,
    onClick_Close: any,
    onClick_Confirm: any,
}

function StockFilter(props: Props) {

    const alertContext = useAlertMessageContext();

    const display = props.translate.data;
    const title : string = display.title.stock_filter;

    const [record, setRecord] = useState({} as any);
    const [records, setRecords ] = useState([] as any);

    const columnsRecord = useMemo(() => [{
        Header: display.label.code,
        accessor: "product.code"
    },{
        Header: display.label.category,
        Cell: (props: any) => {
            const category = props.row.original.product.category;

            return (
                <div className="white-space">
                    <div>{category?.group} {category.subgroup ? ` / ${category.subgroup}` : ''}</div>
                    <div>{category.name}</div>
                </div>
            );
        }
    },{
        Header: display.label.description,
        accessor: "product.description",
        Cell: (row: any) => (<div className="white-space">{row.value}</div>)
    },{
        Header: display.label.stock,
        accessor: "actual",
    },{
        Header: display.label.unit,
        accessor: "product.unit",
        Cell: (row: any) => (<div className="white-space">{getUnit(row.value).name}</div>)
    },{
        Header: display.label.value,
        accessor: "sale",
        Cell: (row: any) => (<div className="white-space">{floatMask(row.value)}</div>)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }], []); // columnsRecord

    async function initialize() {
        try {
            if (props.show) {
                const attributes = [] as any;
                const where = {} as any;
                const order = [] as any;

                if (!isEmpty(props.team))
                    where['product.category.team'] = props.team;
                if (!isEmpty(props.category)) {
                    if (typeof props.category === 'object')
                        where['product.categoryId'] = props.category.id;
                    else
                        where['product.categoryId'] = props.category;
                }

                let stocks = await srvService.stockFilter(attributes, where, order);
                setRecords(stocks);
                setRecord({} as any);
            }
        } catch(error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, title, error);
        }
    }

    useEffect(() => {
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]); // useEffect
    
    return (
        <>
            <Modal
                size="lg"
                show={props.show}
                centered
            >
                <Modal.Header>
                    <Modal.Title><FaSearch />{title}</Modal.Title>
                    <div className="card-actions float-right">
                        <FaTimes className="isClickable" size={22} onClick={props.onClick_Close} />
                    </div>
                </Modal.Header>
                <Modal.Body style={{ minHeight : 500 }}>
                    <Row>
                        <Col>
                            <TableContainer columns={ columnsRecord } data={ records } viewFilter={true} viewPagination={true} setSelectedRow={ 
                                (event: any, dataRow: any) => { 
                                    setRecord(dataRow); 
                                }}
                            />
                        </Col>
                    </Row>                    
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col sm="8">
                                <div className="filter-selectred"><strong>{display.label.selected_record}:</strong> <span>{!isEmpty(record) ? record.product.description : ''}</span></div>
                            </Col>
                            <Col sm="4" className="d-flex justify-content-end">
                                <Button variant="contained" color="secondary" className="me-2" onClick={ (e) => props.onClick_Close(e) } >{display.buttom.cancel}</Button>{' '}
                                <Button variant="contained" color="primary" onClick={ (e) => props.onClick_Confirm(e, record) }  disabled={isEmpty(record)}>{display.buttom.confirm}</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default StockFilter;
