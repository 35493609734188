import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useEffect, useMemo, useState } from "react";
import { Card, Col, Container, Form, FormControl, FormGroup, InputGroup, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaEdit, FaSave, FaSearch, FaTrash } from "react-icons/fa";
import { Phones } from "../../../components/sub-render.component";
import TableContainer from "../../../components/table-container.component";
import { useAlertMessageContext } from "../../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../../contexts/confirm-message.context";
import authService from "../../../services/auth.service";
import cduService from "../../../services/cdu.service";
import lclService from "../../../services/lcl.service";
import { dateToString, notNullToDate, toString, toUpperCase } from "../../../utilities/auxiliary-functions";
import { PermissionEnum } from "../../../utilities/constants";
import { cnpjMask, numberMask, phoneMask, removeFormatDate, removeMask, zipcodeMask } from "../../../utilities/masks";
import { AlertMessageEnum } from "../../../utilities/types";
import { isCnpj, isEmpty } from "../../../utilities/validators";
import EmployeeCadastreModal from "../../modals/employee.cadastre.modal";
import SocietyCadastreModal from "../../modals/society.cadastre.modal";
import AddressRecord from "./records/address.record";
import CorporateDataRecord from "./records/corporate-data.record";

import { Editor } from '../../../components/editor.component';
import moment from 'moment';
import { openDocument } from '../../../utilities/view-document';

interface Props {
    translate: any,
    partner: any,
    title: string,
    partnerId: string,
    setRecord: any
}

function PartnerCadastreForm(props: Props) {

    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();

    const display = props.translate.data;

    const [ invalidatedForm, setInvalidatedForm ] = useState(false);
    const [ isEditForm, setEditForm ] = useState(true);

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ originRecord, setOriginRecord ] = useState({} as any);
    const [ sending, setSending ] = useState(false);

    /* TRATAMENTO PARTICULAR DO OBJETO QUE ESTA SENDO MANTIDO */
    // Partner
    const [ partnerId, setPartnerId ] = useState(0);
    const [ situation, setSituation ] = useState(0);
    const [ versionedCAG, setVersionedCAG ] = useState(0);
    const [ versionedCPS, setVersionedCPS ] = useState(0);
    const [ versionedCRT, setVersionedCRT ] = useState(0);
    const [ created, setCreated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ updated, setUpdated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));

    // Company
    const [ companyId, setCompanyId ] = useState(0);
    const [ situationCompany, setSituationCompany ] = useState(0);
    const [ image, setImage ] = useState('');
    const [ socialReason, setSocialReason ] = useState('');
    const [ fantasy, setFantasy ] = useState('');
    const [ sector, setSector ] = useState('');
    const [ registrationPj, setRegistrationPj ] = useState('');
    const [ registrationState, setRegistrationState ] = useState('');
    const [ foundation, setFoundation ] = useState('');
    const [ site, setSite ] = useState('');
    const [ email, setEmail ] = useState('');
    const [ telephone, setTelephone ] = useState('');
    const [ whatsapp, setWhatsapp ] = useState('');
    const [ url, setUrl ] = useState('');
    const [ latitude, setLatitude ] = useState('');
    const [ longitude, setLongitude ] = useState('');

    // Address
    const [ addressId, setAddressId ] = useState(0);
    const [ zipcode, setZipcode ] = useState('');
    const [ state, setState ] = useState('');
    const [ city, setCity ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ place, setPlace ] = useState('');
    const [ habitation, setHabitation ] = useState('');
    const [ complement, setComplement ] = useState('');
    
    type ArtifactType = { id : number, norm : string, source : string, identy : string, document : string, filename : string };
    const DEFAULT_ARTIFACT: ArtifactType = { id : 0, norm : '', source : '', identy : '', document : '', filename : '' };
    
    const [ proofOfResidenceDocument, setProofOfResidenceDocument ] = useState(DEFAULT_ARTIFACT);
    const [ isChangedProofOfResidenceDocument, setChangedProofOfResidenceDocument ] = useState(false);

    const [ society, setSociety ] = useState({} as any);
    const [ isEditSociety, setEditSociety ] = useState(false);
    const [ showSociety, setShowSociety ] = useState(false);
    const [ societies, setSocieties ] = useState([] as any);

    const columnsSociety = useMemo(() => [
        {
            Header: display.label.name,
            accessor: 'people.fullname',
            Cell: (row: any) => (<div className="white-space">{row.value}</div>)
        },{
            Header: display.label.telephone,
            accessor: 'people.phones',
            Cell: (row: any) => <Phones translate={props.translate} values={row.value} />
        },{
            Header: display.legend.actions,
            accessor: "actions",
            Cell: (props: any) => {
                let societyRows = [] as any;
                const originals = props.rows;
                for (var o = 0; o < originals.length; o++) {
                    societyRows[societyRows.length] = originals[o].original;
                }
                const societyRow = props.row.original;

                return (
                    <div className="white-space table-actions">
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.toEdit}</Tooltip>}>
                            <span onClick={(e) => onClick_EditSociety(e, societyRow)}>
                                <FaEdit size={18} />
                            </span>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.delete}</Tooltip>}>
                            <span onClick={(e) => onClick_DeleteSociety(e, societyRows, societyRow)}>
                                <FaTrash size={18} />
                            </span>
                        </OverlayTrigger>
                    </div>
                );
            },
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], []); // columnsSociety
    
    const [ employee, setEmployee ] = useState({} as any);
    const [ isEditEmployee, setEditEmployee ] = useState(false);
    const [ showEmployee, setShowEmployee ] = useState(false);
    const [ employees, setEmployees ] = useState([] as any);

    const columnsEmployee = useMemo(() => [
        {
            Header: display.label.name,
            accessor: 'people.fullname',
            Cell: (row: any) => (<div className="white-space">{row.value}</div>)
        },{
            Header: display.label.sector,
            accessor: 'sector',
            Cell: (row: any) => (<div className="white-space">{row.value}</div>)
        },{
            Header: display.label.office,
            accessor: 'office',
            Cell: (row: any) => (<div className="white-space">{row.value}</div>)
        },{
            Header: display.legend.actions,
            accessor: "actions",
            Cell: (props: any) => {
                let employeeRows = [] as any;
                const originals = props.rows;
                for (var o = 0; o < originals.length; o++) {
                    employeeRows[employeeRows.length] = originals[o].original;
                }
                const employeeRow = props.row.original;

                return (
                    <div className="white-space table-actions">
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.toEdit}</Tooltip>}>
                            <span onClick={(e) => onClick_EditEmployee(e, employeeRow)}>
                                <FaEdit size={18} />
                            </span>
                        </OverlayTrigger>
                        <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.delete}</Tooltip>}>
                            <span onClick={(e) => onClick_DeleteEmployee(e, employeeRows, employeeRow)}>
                                <FaTrash size={18} />
                            </span>
                        </OverlayTrigger>
                    </div>
                );
            },
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ],[]); // columnsEmployee

    // Contratos
    const [ contractCagId, setContractCagId ] = useState(0);
    const [ contractCagSituation, setContractCagSituation ] = useState(5);
    const [ contractCagContent, setContractCagContent ] = useState('');
    const [ contractCagSubject, setContractCagSubject ] = useState('');
    const [ contractCagValidity, setContractCagValidity ] = useState('');
    const [ contractCagCreated, setContractCagCreated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ contractCagUpdated, setContractCagUpdated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));

    const [ contractCpsId, setContractCpsId ] = useState(0);
    const [ contractCpsSituation, setContractCpsSituation ] = useState(5);
    const [ contractCpsContent, setContractCpsContent ] = useState('');
    const [ contractCpsSubject, setContractCpsSubject ] = useState('');
    const [ contractCpsValidity, setContractCpsValidity ] = useState('');
    const [ contractCpsCreated, setContractCpsCreated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ contractCpsUpdated, setContractCpsUpdated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));

    const [ contractCrtId, setContractCrtId ] = useState(0);
    const [ contractCrtSituation, setContractCrtSituation ] = useState(5);
    const [ contractCrtContent, setContractCrtContent ] = useState('');
    const [ contractCrtSubject, setContractCrtSubject ] = useState('');
    const [ contractCrtValidity, setContractCrtValidity ] = useState('');
    const [ contractCrtCreated, setContractCrtCreated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ contractCrtUpdated, setContractCrtUpdated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));

    const fieldsContractCAG = [{
        id: '1',
        name: 'proprietario',
        label: 'Proprietário',
        children: [
            {
                id: '2',
                name: 'proprietario.nome_completo',
                label: 'Nome Completo'
            },{
                id: '3',
                name: 'proprietario.nacionalidade',
                label: 'Nacionalidade'
            },{
                id: '4',
                name: 'proprietario.documento',
                label: 'Documento',
                children: [
                    {
                        id: '5',
                        name: 'proprietario.identidade',
                        label: 'Identidade'
                    },{
                        id: '6',
                        name: 'proprietario.cpf',
                        label: 'CPF'
                    }
                ]
            },{
                id: '7',
                name: 'proprietario.endereco',
                label: 'Endereço',
                children: [{
                    id: '8',
                    name: 'endereco.logradouro',
                    label: 'Logradouro'
                },{
                    id: '9',
                    name: 'endereco.numero',
                    label: 'Número'
                },{
                    id: '11',
                    name: 'endereco.bairro',
                    label: 'Bairro'
                },{
                    id: '12',
                    name: 'endereco.cidade',
                    label: 'Cidade'
                },{
                    id: '13',
                    name: 'endereco.estado',
                    label: 'Estado'
                },{
                    id: '14',
                    name: 'endereco.cep',
                    label: 'CEP'
                },{
                    id: '15',
                    name: 'endereco.complemento',
                    label: 'Complemento'
                }]
            }
        ]
    },{
        id: '16',
        name: 'embarcacao',
        label: 'Embarcação',
        children: [
            {
                id: '17',
                name: 'embarcacao.chave_id',
                label: 'Chave ID'
            },{
                id: '18',
                name: 'embarcacao.nome',
                label: 'Nome'
            },{
                id: '19',
                name: 'embarcacao.tipo',
                label: 'Tipo'
            },{
                id: '20',
                name: 'embarcacao.cor',
                label: 'Cor'
            },{
                id: '21',
                name: 'embarcacao.registro',
                label: 'Registro'
            },{
                id: '22',
                name: 'embarcacao.fabricante',
                label: 'Fabricante'
            },{
                id: '23',
                name: 'embarcacao.marca',
                label: 'Marca'
            },{
                id: '24',
                name: 'embarcacao.modelo',
                label: 'Modelo'
            }
        ]
    },{
        id: '25',
        name: 'contrato',
        label: 'Contrato',
        children: [
            {
                id: '26',
                name: 'contrato.qtd_parcelas',
                label: 'Qtd Parcelas'
            },{
                id: '27',
                name: 'contrato.valor_parcela',
                label: 'Valor da Parcela'
            },{
                id: '28',
                name: 'contrato.valor',
                label: 'Valor Contrato'
            }
        ]
    }];

    function fillContract(type: string, contract: any) {
        if (type === "CAG") {
            setContractCagId(contract.id);
            setContractCagSituation(contract.situation);
            setContractCagContent(contract.content);
            setContractCagSubject(contract.subject);
            setContractCagValidity(contract.subject);
            setContractCagCreated(contract.created);
            setContractCagUpdated(contract.updated);
        } else if (type === "CPS") {
            setContractCpsId(contract.id);
            setContractCpsSituation(contract.situation);
            setContractCpsContent(contract.content);
            setContractCpsSubject(contract.subject);
            setContractCpsValidity(contract.subject);
            setContractCpsCreated(contract.created);
            setContractCpsUpdated(contract.updated);
        } else if (type === "CRT") {
            setContractCrtId(contract.id);
            setContractCrtSituation(contract.situation);
            setContractCrtContent(contract.content);
            setContractCrtSubject(contract.subject);
            setContractCrtValidity(contract.subject);
            setContractCrtCreated(contract.created);
            setContractCrtUpdated(contract.updated);
        }
    }

    function fillRecord(partner: any) {

        if (!isEmpty(partner)) {
            setPartnerId(partner.id);
            setSituation(partner.situation);
            setVersionedCAG(partner.versionedCAG);
            setVersionedCPS(partner.versionedCPS);
            setVersionedCRT(partner.versionedCRT);
            setCreated(notNullToDate(partner.created, 'dd/mm/yyyy HH:MM:ss'));  
            setUpdated(notNullToDate(partner.updated, 'dd/mm/yyyy HH:MM:ss'));  

            fillRecordCompany(partner.company);
        }
    } // fillRecord

    function fillRecordCompany(company: any) {

        if (!isEmpty(company)) {
            setCompanyId(company.id);
            setImage(company.image);
            setSituationCompany(company.situation);
            setSocialReason(company.socialReason);
            setFantasy(company.fantasy);
            setSector(company.sector);
            setRegistrationPj(cnpjMask(company.registrationPj));
            setRegistrationState(numberMask(company.registrationState));
            setFoundation(notNullToDate(company.foundation, 'dd/mm/yyyy'));
            setSite(company.site);
            setEmail(company.email);
            setTelephone(phoneMask(company.telephone));
            setWhatsapp(phoneMask(company.whatsapp));
            setUrl(company.url);
            setLatitude(company.latitude);
            setLongitude(company.longitude);

            setSocieties(company.societies);
            setEmployees(company.employees);

            // Address
            setAddressId(company.addressId);
            setZipcode(zipcodeMask(company.address.zipcode));
            setState(company.address.state);
            setCity(company.address.city);
            setDistrict(company.address.district);
            setPlace(company.address.place);
            setHabitation(company.habitation);
            setComplement(company.complement);
        }
    }

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(socialReason))
            emptyRequired = true;
        if (isEmpty(fantasy))
            emptyRequired = true;
        if (isEmpty(registrationPj))
            emptyRequired = true;
        if ((!isEmpty(registrationPj)) && (!isCnpj(registrationPj))) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, display.message.invalid.registration_pj);
            result = false;
        }

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedForm(!result);
        return result;
    } // isValidForm

    function onClick_CancelPartner(event: any) {
        event.preventDefault();
        props.setRecord(event, 0)
    } // onClick_CancelPartner

    function fillPartner() {

        for (var i = 0; i < societies.length; i++) {
            const society = societies[i];

            let addressSociety = [];
            if (!isEmpty(society.people.address)) {
                for ( var j = 0; j < society.people.address.length; j++) {
                    const addressPeople = society.people.address[j];
                    addressSociety[j] = {
                        peopleId : addressPeople.peopleId,
                        addressId : addressPeople.addressId,
                        address : {
                            id : addressPeople.address.id,
                            zipcode : addressPeople.address.zipcode,
                            state : addressPeople.address.state,
                            city : addressPeople.address.city,
                            district : addressPeople.address.district,
                            place : addressPeople.address.place
                        },
                        correspondence : addressPeople.correspondence,
                        norm : addressPeople.norm,
                        habitation : addressPeople.habitation,
                        complement : addressPeople.complement
                    }
                }
            }

            let phonesSociety = [];
            if (!isEmpty(society.people.phones)) {
                // eslint-disable-next-line @typescript-eslint/no-redeclare
                for ( var j = 0; j < society.people.phones.length; j++) {
                    const phone = society.people.phones[j];
                    phonesSociety[j] = {
                        id : phone.id, 
                        norm : phone.norm, 
                        operator : phone.operator, 
                        connection : removeMask(phone.connection), 
                        sms : phone.sms, 
                        zap : phone.zap, 
                        major : phone.major
                    }
                }
            }

            const dataSociety = {
                id : society.id, 
                companyId, 
                societyType : society.societyType, 
                created : removeFormatDate(society.created), 
                updated : removeFormatDate(society.updated),
                peopleId : society.peopleId,
                people : {
                    id : society.people.id,
                    tratament : '', // society.people.tratament,
                    fullname : society.people.fullname, 
                    gender : society.people.gender, 
                    birth : removeFormatDate(society.people.birth), 
                    phones : phonesSociety, 
                    nationality : society.people.nationality, 
                    naturalness : society.people.naturalness, 
                    registrationPf : removeMask(society.people.registrationPf),
                    registrationIdentity : removeMask(society.people.registrationIdentity),
                    issuerIdentity : society.people.issuerIdentity,
                    stateIdentity : society.people.stateIdentity,
                    expedition : removeFormatDate(society.people.expedition),
                    driversLicense : society.people.driversLicense,
                    categoryDriversLicense : society.people.categoryDriversLicense,
                    validityDriversLicense : removeFormatDate(society.people.validityDriversLicense),
                    adresses: addressSociety
                }
            }

            societies[i] = dataSociety;
        }

        // eslint-disable-next-line @typescript-eslint/no-redeclare
        for (var i = 0; i < employees.length; i++) {
            const employee = employees[i];

            let addressEmployee = [];
            if (!isEmpty(employee.people.address)) {
                // eslint-disable-next-line @typescript-eslint/no-redeclare
                for ( var j = 0; j < employee.people.address.length; j++) {
                    const addressPeople = employee.people.address[j];
                    addressEmployee[j] = {
                        peopleId : addressPeople.peopleId,
                        addressId : addressPeople.addressId,
                        address : {
                            id : addressPeople.address.id,
                            zipcode : addressPeople.address.zipcode,
                            state : addressPeople.address.state,
                            city : addressPeople.address.city,
                            district : addressPeople.address.district,
                            place : addressPeople.address.place
                        },
                        correspondence : addressPeople.correspondence,
                        norm : addressPeople.norm,
                        habitation : addressPeople.habitation,
                        complement : addressPeople.complement
                    }
                }
            }

            let phonesEmployee = [];
            if (!isEmpty(employee.people.phones)) {
                // eslint-disable-next-line @typescript-eslint/no-redeclare
                for ( var j = 0; j < employee.people.phones.length; j++) {
                    const phone = employee.people.phones[j];
                    phonesEmployee[j] = {
                        id : phone.id, 
                        norm : phone.norm, 
                        operator : phone.operator, 
                        connection : removeMask(phone.connection), 
                        sms : phone.sms, 
                        zap : phone.zap, 
                        major : phone.major
                    }
                }
            }

            const dataEmployee = {
                id : employee.id,
                companyId, 
                employeeType : employee.employeeType, 
                created : removeFormatDate(employee.created),
                updated : removeFormatDate(employee.updated),
                sector : employee.sector,
                office : employee.office,
                remuneration : removeMask(employee.remuneration),
                workdayFirst : employee.workdayFirst,
                workdayStop : employee.workdayStop,
                workdayStart : employee.workdayStart,
                workdayLast : employee.workdayLast,
                peopleId : employee.peopleId,
                people : {
                    id : employee.people.id,
                    tratament : employee.people.tratament,
                    fullname : employee.people.fullname, 
                    gender : employee.people.gender, 
                    birth : removeFormatDate(employee.people.birth), 
                    phones : phonesEmployee, 
                    nationality : employee.people.nationality, 
                    naturalness : employee.people.naturalness,
                    registrationPf : removeMask(employee.people.registrationPf),
                    registrationIdentity : removeMask(employee.people.registrationIdentity),
                    issuerIdentity : employee.people.issuerIdentity,
                    stateIdentity : employee.people.stateIdentity,
                    expedition : removeFormatDate(employee.people.expedition),
                    driversLicense : employee.people.driversLicense,
                    categoryDriversLicense : employee.people.categoryDriversLicense,
                    validityDriversLicense : removeFormatDate(employee.people.validityDriversLicense),
                    adresses: addressEmployee
                }
            };

            employees[i] = dataEmployee;
        }

        const dataPartner = {
            id : partnerId,
            situation, 
            versionedCAG, 
            versionedCPS, 
            versionedCRT, 
            created : removeFormatDate(created), 
            updated : removeFormatDate(updated),
            partnerId : props.partner.id,
            // partner : props.partner,
            companyId,
            company : {
                id : companyId,
                situation : situationCompany,
                image : image,
                socialReason, 
                fantasy, 
                sector, 
                registrationPj : removeMask(registrationPj), 
                registrationState, 
                foundation : removeFormatDate(foundation),
                site, 
                email, 
                telephone : removeMask(telephone), 
                whatsapp : removeMask(whatsapp), 
                url, 
                latitude, 
                longitude, 
                created : removeFormatDate(created), 
                updated : removeFormatDate(updated), 
                societies, 
                employees, 
                addressId,
                address : {
                    id : addressId,
                    zipcode : removeMask(zipcode), 
                    state, 
                    city, 
                    district, 
                    place 
                },
                habitation, 
                complement
            },
            ships : []
        };

        return dataPartner;
    } // fillPartner

    async function onClick_SavePartner(event: any) {
        event.preventDefault();
        
        const isValid = await isValidForm();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(props.title, display.message.confirm_record);
                if (isConfirmed) {
                    setSending(true);

                    let partner = fillPartner();
                    let result = {} as any;
                    if (partnerId > 0)
                        result = await lclService.savePartner(partner.id, partner);
                    else {
                        result = await lclService.createPartner(partner);
                    }
                    partner = await lclService.partnerById(result.id);
                    setPartnerId(partner.id);

                    if (isChangedProofOfResidenceDocument && proofOfResidenceDocument) {
                        proofOfResidenceDocument.source = 'COMPANY';
                        proofOfResidenceDocument.identy = String(partner.company.id);
    
                        if ((proofOfResidenceDocument.id > 0) && !isEmpty(proofOfResidenceDocument.document)) {
                            await cduService.saveArtifact(proofOfResidenceDocument.id, proofOfResidenceDocument);
                        } else if ((proofOfResidenceDocument.id > 0) && isEmpty(proofOfResidenceDocument.document)) {
                            await cduService.artifactDeleteById(proofOfResidenceDocument.id);
                        } else if ((proofOfResidenceDocument.id === 0) && !isEmpty(proofOfResidenceDocument.document)) {
                            await cduService.createArtifact(proofOfResidenceDocument);
                        }
                    }

                    if (!versionedCAG) {
                        let subject = moment().format("DD/MM/YYYY hh:mm:ss");
                        let validity = moment().add(2, 'years').format("DD/MM/YYYY hh:mm:ss");

                        const dataContractCAG = {
                            id : contractCagId, 
                            partnerId : partner.id, 
                            type : 'CAG',
                            situation : contractCagSituation,
                            content : contractCagContent,
                            subject : removeFormatDate(isEmpty(contractCagSubject) ? subject : contractCagSubject),
                            validity : removeFormatDate(isEmpty(contractCagValidity) ? validity : contractCagValidity),
                            created : removeFormatDate(contractCagCreated), 
                            updated : removeFormatDate(contractCagUpdated),
                        }

                        if (contractCagId > 0) {
                            await lclService.savePartnerContract(dataContractCAG.type, contractCagId, dataContractCAG);
                        } else {
                            await lclService.createPartnerContract(dataContractCAG.type, dataContractCAG);
                        }
                    }

                    if (!versionedCPS) {
                        let subject = moment().format("DD/MM/YYYY hh:mm:ss");
                        let validity = moment().add(2, 'years').format("DD/MM/YYYY hh:mm:ss");

                        const dataContractCPS = {
                            id : contractCpsId, 
                            partnerId : partner.id, 
                            type : 'CPS',
                            situation : contractCpsSituation,
                            content : contractCpsContent,
                            subject : removeFormatDate(isEmpty(contractCpsSubject) ? subject : contractCpsSubject),
                            validity : removeFormatDate(isEmpty(contractCpsValidity) ? validity : contractCpsValidity),
                            created : removeFormatDate(contractCpsCreated), 
                            updated : removeFormatDate(contractCpsUpdated),
                        }

                        if (contractCpsId > 0) {
                            await lclService.savePartnerContract(dataContractCPS.type, contractCpsId, dataContractCPS);
                        } else {
                            await lclService.createPartnerContract(dataContractCPS.type, dataContractCPS);
                        }
                    }
                    if (!versionedCRT) {
                        let subject = moment().format("DD/MM/YYYY hh:mm:ss");
                        let validity = moment().add(2, 'years').format("DD/MM/YYYY hh:mm:ss");

                        const dataContractCRT = {
                            id : contractCrtId, 
                            partnerId : partner.id, 
                            type : 'CRT',
                            situation : contractCrtSituation,
                            content : contractCrtContent,
                            subject : removeFormatDate(isEmpty(contractCrtSubject) ? subject : contractCrtSubject),
                            validity : removeFormatDate(isEmpty(contractCrtValidity) ? validity : contractCrtValidity),
                            created : removeFormatDate(contractCrtCreated), 
                            updated : removeFormatDate(contractCrtUpdated),
                        }

                        if (contractCrtId > 0) {
                            await lclService.savePartnerContract(dataContractCRT.type, contractCrtId, dataContractCRT);
                        } else {
                            await lclService.createPartnerContract(dataContractCRT.type, dataContractCRT);
                        }
                    }

                    setSending(false);
                    await alertContext.show(AlertMessageEnum.SUCCESS, props.title, display.message.the_record_has_been_saved_successfully);
                    if (!authService.isAssociate())
                        props.setRecord(event, 0);
                }
            } catch (error: any) {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
            }
        }
    } // onClick_SavePartner

    function onClick_AddSociety(event: any) {
        event.preventDefault();

        setEditSociety(false);
        setSociety({} as any);
        setShowSociety(true);
    } // onClick_AddSociety

    function onClick_EditSociety(event: any, society: any) {
        event.preventDefault();

        setEditSociety(true);
        setSociety(society);
        setShowSociety(true);
    } // onClick_EditSociety

    function onClick_DeleteSociety(event: any, societies: any, society: any) {
        event.preventDefault();
    } // onClick_DeleteSociety

    function onClick_AddEmployee(event: any) {
        event.preventDefault();

        setEditEmployee(false);
        setEmployee({} as any);
        setShowEmployee(true);
    } // onClick_AddEmployee

    function onClick_EditEmployee(event: any, employee: any) {
        event.preventDefault();

        setEditEmployee(true);
        setEmployee(employee);
        setShowEmployee(true);
    } // onClick_EditEmployee

    function onClick_DeleteEmployee(event: any, employees: any, employee: any) {
        event.preventDefault();
    } // onClick_DeleteEmployee

    async function onClick_SearchCompany(event: any) {
        event.preventDefault();

        if (!isEmpty(registrationPj)) {
            const registration = removeMask(registrationPj);
            if (isCnpj(registration)) {
                const company = await cduService.companyByRegistration(registration);
                fillRecordCompany(company);
                if (!isEmpty(company)) {

                    const attributes = [] as any;
                    const where = { companyId : company.id };
                    const order = [] as any;
                    const partners = await lclService.partnerFilter(attributes, where, order);
                    if (partners.length > 0) {
                        await alertContext.show(AlertMessageEnum.SUCCESS, props.title, 'CNPJ já cadastrado.');
                        props.setRecord(event, 0);
                    }
                } else 
                    await alertContext.show(AlertMessageEnum.SUCCESS, props.title, 'CNPJ não localizado. Efetue o cadastro completo.');

                } else {
                await alertContext.show(AlertMessageEnum.FAIL, props.title, 'CNPJ Inválido !');
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, 'Insira um CNPJ válido !');
        }
    }

    async function onClick_TemplateCAG(event: any) {
        event.preventDefault();
        
        let isConfirmed = true;
        if (!isEmpty(contractCagContent))
            isConfirmed = await confirmContext.show(props.title, 'O templete irá substituir as informações atuais, deseja continuar ?');
        
        if (isConfirmed) {
            const partner = fillPartner();

            let template = '';
            template += `<p class="ql-align-center"><strong>CONTRATO DE ALUGUEL DE VAGA DE GARAGEM</strong></p>`;
            template += `<p><br></p>`;
            template += `<p><br></p>`;
            template += `<p><strong>IDENTIFICAÇÃO DAS PARTES CONTRATANTES</strong></p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">LOCADOR: ${partner.company.socialReason}, CNPJ nº ${cnpjMask(partner.company.registrationPj)}, `;
            template += `situado na ${partner.company.address.place}, nº ${partner.company.habitation}. bairro ${partner.company.address.district}, `;
            template += `Cep ${zipcodeMask(partner.company.address.zipcode)}, Cidade de ${partner.company.address.city}, no Estado de ${partner.company.address.state}.</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">LOCATÁRI(O|A|OS|AS):</p>`;

            template += `<p>`;
            template += `%inicio_laco_proprietario%`;
            template += `<strong>%proprietario.nome_completo%</strong>, <strong>%proprietario.nacionalidade%</strong> `;
            template += `Identidade nº <strong>%proprietario.identidade% </strong>CPF: <strong>%proprietario.cpf%</strong>, `;
            template += `residente e domiciliado(a) na <strong>%endereco.logradouro%</strong> nº <strong>%endereco.numero%</strong> - <strong>%endereco.bairro%</strong> - `;
            template += `CEP <strong>%endereco.cep%</strong>, Cidade de <strong>%endereco.cidade%</strong> no Estado de <strong>%endereco.estado%</strong>.<br>`;
            template += `%fim_laco_proprietario%`;
            template += `</p>`;

            template += `<p><br></p>`;
            template += `<p class="ql-align-justify"><strong>DO MOVEL</strong></p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">As seguintes embarcações compõem este contrato:</p>`;

            template += `%inicio_laco_embarcacao%`;
            template += `<p class="ql-align-justify">Chave ID: <strong>%embarcacao.chave_id%</strong></p>`;
            template += `<p class="ql-align-justify">Nome: <strong>%embarcacao.nome%</strong></p>`;
            template += `<p class="ql-align-justify">Tipo: <strong>%embarcacao.tipo%</strong></p>`;
            template += `<p class="ql-align-justify">Cor: <strong>%embarcacao.cor%</strong></p>`;
            template += `<p class="ql-align-justify">Registro: <strong>%embarcacao.registro%</strong></p>`;
            template += `<p class="ql-align-justify">Fabricante: <strong>%embarcacao.fabricante%</strong></p>`;
            template += `<p class="ql-align-justify">Marca: <strong>%embarcacao.marca%</strong></p>`;
            template += `<p class="ql-align-justify">Modelo: <strong>%embarcacao.modelo%</strong></p>`;
            template += `%fim_laco_embarcacao%`;

            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">As partes acima identificadas têm, entre si, justo e acertado o presente Contrato de Locação de Vaga de Garagem, que se regerá pelas cláusulas seguintes e pelas condições de preço, forma e termo de pagamento descritas no presente.</p>`;
            template += `<p><br></p>`;
            template += `<p><strong>DO OBJETO DO CONTRATO</strong></p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">Cláusula 1ª. O presente contrato tem como OBJETO a locação de 01 (uma) vaga de garagem na propriedade do LOCADOR, `;
            template += `situado na ${partner.company.address.place}, nº ${partner.company.habitation}. bairro ${partner.company.address.district}, `;
            template += `Cep ${zipcodeMask(partner.company.address.zipcode)}, Cidade de ${partner.company.address.city}, no Estado de ${partner.company.address.state}.</p>`;
            template += `<p><br></p>`;
            template += `<p><strong>DA UTILIZAÇÃO DA GARAGEM</strong></p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">Cláusula 2ª. Somente o LOCATÁRIO ou seus familiares poderá se utilizar à vaga de garagem, objeto deste contrato, para o estacionamento de veículos particular, vedando-se a utilização da garagem para qualquer outro fim não previsto neste instrumento.</p>`;
            template += `<p><br></p>`;
            template += `<p><strong>DO ALUGUEL</strong></p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">Cláusula 3ª. O valor do aluguel a ser pago mensalmente será de R$ <strong>%contrato.valor_parcela%</strong>, a ser pago diretamente a favor do LOCADOR através no mesmo boleto de cobrança das demais taxas. O valor do aluguel será corregido pelo IGP-M da Fundação Getúlio Vargas acumulado no período, dentro do prazo mínimo legal de <strong>%contrato.qtd_parcelas%</strong> meses.</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">Cláusula 4ª. Caso o LOCATÁRIO torne-se inadimplente e não sendo este período superior a 15 (quinze) dias, será aplicada a ele uma multa de 2% (dois por cento) sobre o valo do aluguel devido, acrescido de mora de 0,33% ao dia.</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">Cláusula 5ª. No caso do LOCATÁRIO ficar inadimplente por mais de sessenta dias, perderá o direito do uso da garagem, ficando a mesma liberada para se alugada para um novo LOCATÁRIO.</p>`;
            template += `<p><br></p>`;
            template += `<p><strong>DO PRAZO</strong></p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">Cláusula 6ª. A presente locação é celebrada por prazo indeterminado, a contar da data de sua assinatura, podendo ser rescindida a qualquer tempo por ambas às partes, mediante Aviso Prévio por escrito, com antecedência mínima de 30 (trinta) dias, sem que neste caso caiba o pagamento de qualquer indenização ou multa, seja a que título for, devendo o LOCATÁRIO desocupar a vaga, estando esta em perfeitas condições de uso, como quando recebeu.</p>`;
            template += `<p><br></p>`;
            template += `<p><strong>CONDIÇÕES GERAIS</strong></p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">Cláusula 7ª. Necessita-se da autorização por escrito do LOCADOR para que possa haver a transferência, a sublocação, a cessão ou empréstimo do imóvel objeto deste contrato.</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">Cláusula 8ª. Os herdeiros, sucessores ou cessionários das partes se obrigam desde já ao inteiro teor deste contrato.</p>`;
            template += `<p><br></p>`;
            template += `<p><strong>DO FORO</strong></p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">Cláusula 9ª. Para dirimir quaisquer controvérsias oriundas do CONTRATO, as partes elegem o foro da comarca de ${partner.company.address.city} - ${partner.company.address.state}.</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">${partner.company.address.city}, ______de ___________de 2023.</p>`;
            template += `<p><br></p>`;
            template += `<p><br></p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">_____________________________________________</p>`;
            template += `<p class="ql-align-justify">${toUpperCase(partner.company.socialReason)}</p>`;
            template += `<p><br></p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">_____________________________________________</p>`;
            template += `<p class="ql-align-justify">LOCADOR</p>`;
            template += `<p><br></p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">TESTEMUNHAS:</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">1 ____________________________________________</p>`;
            template += `<p class="ql-align-justify">NOME:</p>`;
            template += `<p class="ql-align-justify">CPF:</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">2 ___________________________________________</p>`;
            template += `<p class="ql-align-justify">NOME:</p>`;
            template += `<p class="ql-align-justify">CPF:</p>`;
            template += `<p><br></p>`;

            setContractCagContent(template);
        }
    }

    async function onClick_TemplateCPS(event: any) {
        event.preventDefault();
        
        let isConfirmed = true;
        if (!isEmpty(contractCpsContent))
            isConfirmed = await confirmContext.show(props.title, 'O templete irá substituir as informações atuais, deseja continuar ?');
        
        if (isConfirmed) {
            let template = '';

            template += `<p class="ql-align-center"><strong>CONTRATO DE PRESTAÇÃO DE SERVIÇOS</strong></p>`;
            template += `<p><br></p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify"><strong>DAS PARTES</strong></p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">________ , pessoa jurídica de direito privado, inscrita no CNPJ nº ________ , com sede em ________ , doravante denominado&nbsp;<strong>CONTRATANTE&nbsp;</strong>e neste ato representada na forma de seus atos constitutivos, por seu representante legal ________ , ________ , ________ , ________ , portador do Documento de Identidade RG nº. ________ , inscrito no CPF sob o nº. ________ , residente e domiciliado em ________ , e;</p>`;
            template += `<p class="ql-align-justify"><strong>____________</strong>, (nacionalidade), (solteiro), (profissão), portador da identidade nº ____________ SSP/___ e do CPF nº _______________, residente e domiciliado nesta comarca, na Rua _______________________________, doravante denominado&nbsp;<strong>CONTRATADA</strong>.</p>`;
            template += `<p class="ql-align-justify">Decidem as partes, na melhor forma de direito, celebrar o presente CONTRATO DE PRESTAÇÃO DE SERVIÇOS, que reger-se-á mediante as cláusulas e condições adiante estipuladas.</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify"><strong>CLÁUSULA PRIMEIRA - DO OBJETO</strong></p>`;
            template += `<p class="ql-align-justify"><strong>1.1&nbsp;</strong>O presente contrato tem por objeto a prestação de serviços profissionais especializados em ________ por parte da CONTRATADA de acordo com os termos e condições detalhados neste contrato.</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify"><strong>CLÁUSULA SEGUNDA - OBRIGAÇÕES DA CONTRATANTE</strong></p>`;
            template += `<p class="ql-align-justify"><strong>2.1&nbsp;</strong>A CONTRATANTE deverá fornecer à CONTRATADA todas as informações necessárias à realização do serviço, devendo especificar os detalhes necessários à perfeita consecução do mesmo.</p>`;
            template += `<p class="ql-align-justify"><strong>2.2&nbsp;</strong>A CONTRATANTE é obrigada ainda a disponibilizar: ________ (EPI’S ETC...)</p>`;
            template += `<p class="ql-align-justify"><strong>2.3&nbsp;</strong>A CONTRATANTE deverá efetuar o pagamento na forma e condições estabelecidas na cláusula quinta.</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify"><strong>CLÁUSULA TERCEIRA - OBRIGAÇÕES DA CONTRATADA</strong></p>`;
            template += `<p class="ql-align-justify"><strong>3.1&nbsp;</strong>A CONTRATADA deverá prestar os serviços solicitados pela CONTRATANTE conforme descritivo, especificações e prazos previstos no ANEXO I.</p>`;
            template += `<p class="ql-align-justify"><strong>3.2&nbsp;</strong>A CONTRATADA se obriga a manter absoluto sigilo sobre as operações, dados, estratégias, materiais, informações e documentos da CONTRATANTE, mesmo após a conclusão dos serviços ou do término da relação contratual.</p>`;
            template += `<p class="ql-align-justify"><strong>3.3&nbsp;</strong>Os contratos, informações, dados, materiais e documentos inerentes à CONTRATANTE ou a seus clientes deverão ser utilizados, pela CONTRATADA, por seus funcionários ou contratados, estritamente para cumprimento dos serviços solicitados pela CONTRATANTE, sendo VEDADO a comercialização ou utilização para outros fins.</p>`;
            template += `<p class="ql-align-justify"><strong>3.4&nbsp;</strong>Será de responsabilidade da CONTRATADA todo o ônus trabalhista, tributário e previdenciário referente ao período que perdurar a prestação do serviço objeto deste instrumento, ficando a CONTRATANTE isenta de qualquer obrigação em relação a eles.</p>`;
            template += `<p class="ql-align-justify"><strong>3.5&nbsp;</strong>A CONTRATADA deverá fornecer os respectivos documentos fiscais ou RPA’S (recibo de pagamento de autônomo), referente ao (s) pagamento (s) do presente instrumento.</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify"><strong>CLÁUSULA QUARTA - DOS SERVIÇOS</strong></p>`;
            template += `<p class="ql-align-justify"><strong>4.1</strong>&nbsp;A CONTRATADA atuará nos serviços contratados de acordo com as especificações descritas no ANEXO I, que passa ser parte integrante do presente contrato.</p>`;
            template += `<p class="ql-align-justify"><strong>4.2</strong>&nbsp;Os serviços terão início em ________ dias corridos da assinatura do presente contrato.</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify"><strong>CLÁUSULA QUINTA - DA AVALIAÇÃO DE PERFORMANCE</strong></p>`;
            template += `<p class="ql-align-justify"><strong>5.1&nbsp;</strong>A execução dos serviços, objeto do presente contrato, passará por avaliações periódicas para fins de constatar o cumprimento dos indicadores de produtividade, abaixo indicados:</p>`;
            template += `<p class="ql-align-justify"><strong>5.1.1&nbsp;</strong>SEGURANÇA: Para fins de observância aos indicadores de segurança do presente contrato, mensalmente a CONTRATADA deve comprovar o uso regular de EPI’S ou quaisquer outros pedidos que tratem da segurança pessoal ou da regular prestação de serviço;</p>`;
            template += `<p class="ql-align-justify"><strong>5.1.2&nbsp;</strong>QUALIDADE: Para fins de observância aos indicadores de qualidade do presente contrato, mensalmente a CONTRATADA deve comprovar o cumprimento integral da prestação de serviço por meio de fotos ou qualquer outro meio estipulado pelo CONTRATANTE;</p>`;
            template += `<p class="ql-align-justify"><strong>5.1.3&nbsp;</strong>COMUNICAÇÃO: Para fins de observância aos indicadores de comunicação do presente contrato, mensalmente a CONTRATADA deve comprovar o uso eficiente dos materiais de comunicação (radio, celular etc..) ;</p>`;
            template += `<p class="ql-align-justify"><strong>5.1.4&nbsp;</strong>PONTUALIDADE: Para fins de observância aos indicadores de pontualidade do presente contrato, mensalmente a CONTRATADA deve comprovar o cumprimento integral do período mínimo fixado para desempenho da sua prestação de serviço;</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify"><strong>CLÁUSULA SEXTA - DA EXCLUSIVIDADE</strong></p>`;
            template += `<p class="ql-align-justify"><strong>6.1&nbsp;</strong>A CONTRATADA atuará&nbsp;<strong>COM EXCLUSIVIDADE</strong>&nbsp;em favor da CONTRATADA, NÃO podendo exercer sua atividade para outras empresas, ou efetuar negócios em nome e por conta própria, ainda que seja com terceiro não cliente da CONTRATANTE.</p>`;
            template += `<p class="ql-align-justify"><strong>6.2&nbsp;</strong>A CONTRATADA terá gerência integral na área de serviço que lhe é destinada, com TOTAL AUTONOMIA, devendo atender exclusivamente o cronograma firmado entre as partes.</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify"><strong>CLÁUSULA SÉTIMA - DO PREÇO E DAS CONDIÇÕES DE PAGAMENTO</strong></p>`;
            template += `<p class="ql-align-justify"><strong>7.1&nbsp;</strong>Os serviços OBJETO deste contrato serão remunerados pela quantia total de ________ , a serem divididos em ________ pagamentos, da seguinte forma:</p>`;
            template += `<p class="ql-align-justify">1ª parcela: R$ ________ (________ reais), a ser efetuado no dia ________ ;</p>`;
            template += `<p class="ql-align-justify">2ª parcela: R$ ________ (________ reais), a ser efetuado no dia ________ ;</p>`;
            template += `<p class="ql-align-justify">3ª parcela: R$ ________ (________ reais), a ser efetuado no dia ________ .</p>`;
            template += `<p class="ql-align-justify">4ª parcela: R$ ________ (________ reais), a ser efetuado no dia ________ .</p>`;
            template += `<p class="ql-align-justify">5ª parcela: R$ ________ (________ reais), a ser efetuado no dia ________ .</p>`;
            template += `<p class="ql-align-justify">6ª parcela: R$ ________ (________ reais), a ser efetuado no dia ________ .</p>`;
            template += `<p class="ql-align-justify">7ª parcela: R$ ________ (________ reais), a ser efetuado no dia ________ .</p>`;
            template += `<p class="ql-align-justify">8ª parcela: R$ ________ (________ reais), a ser efetuado no dia ________ .</p>`;
            template += `<p class="ql-align-justify">9ª parcela: R$ ________ (________ reais), a ser efetuado no dia ________ .</p>`;
            template += `<p class="ql-align-justify">10ª parcela: R$ ________ (________ reais), a ser efetuado no dia ________ .</p>`;
            template += `<p class="ql-align-justify">11ª parcela: R$ ________ (________ reais), a ser efetuado no dia ________ .</p>`;
            template += `<p class="ql-align-justify">12ª parcela: R$ ________ (________ reais), a ser efetuado no dia ________ .</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify"><strong>7.2&nbsp;</strong>No caso de atraso no pagamento superior a 10 dias, será devida multa moratória no valor de ________ sobre a parcela inadimplida, além da atualização do valor pelo ________ .</p>`;
            template += `<p class="ql-align-justify"><strong>7.3&nbsp;</strong>Considera-se o cumprimento integral do contrato o momento em que todos os serviços especificados no ANEXO I tenham sido concluídos, mediante aprovação e revisão final da CONTRATANTE ou outra forma de entrega especificada no ANEXO I.</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify"><strong>CLÁUSULA OITAVA - DO DESCUMPRIMENTO</strong></p>`;
            template += `<p class="ql-align-justify"><strong>8.1&nbsp;</strong>O descumprimento de qualquer uma das cláusulas por qualquer parte, implicará na rescisão imediata deste contrato, não isentando a CONTRATADA de suas responsabilidades referentes ao zelo com informações e dados da CONTRATANTE.</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify"><strong>CLÁUSULA NONA - DO PRAZO E VALIDADE</strong></p>`;
            template += `<p class="ql-align-justify"><strong>9.1&nbsp;</strong>A CONTRATADA deverá realizar os serviços dentro dos prazos determinados no cronograma previsto no ANEXO I, sendo sua responsabilidade comunicar a impossibilidade de cumprimento, bem como os motivos para tal e o novo prazo previsto, estando em sua competência a capacidade para tal avaliação.</p>`;
            template += `<p class="ql-align-justify"><strong>9.2&nbsp;</strong>Este instrumento é válido pelo prazo de 1 ano, prorrogável por igual período, até a finalização do serviço, ora contratado, ou encerramento do contrato, não ficando as partes isentas de seus compromissos éticos.</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify"><strong>CLÁUSULA DÉCIMA - DA RESCISÃO IMOTIVADA</strong></p>`;
            template += `<p class="ql-align-justify"><strong>10.1&nbsp;</strong>Poderá o presente instrumento ser rescindido por qualquer das partes, em qualquer momento, sem que haja qualquer tipo de motivo relevante, respeitando-se um período mínimo de ________ dias, devendo então somente ser finalizadas e pagas as etapas que já estiverem em andamento.</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify"><strong>CLÁUSULA DÉCIMA PRIMEIRA - DA OBSERVÂNCIA À&nbsp;LGPD</strong></p>`;
            template += `<p class="ql-align-justify"><strong>11.1&nbsp;</strong>O CONTRATANTE declara expresso CONSENTIMENTO que o CONTRATADO irá coletar, tratar e compartilhar os dados necessários ao cumprimento do contrato, nos termos do Art.&nbsp;7º, inc.&nbsp;V&nbsp;da&nbsp;LGPD, os dados necessários para cumprimento de obrigações legais, nos termos do Art.&nbsp;7º, inc.&nbsp;II&nbsp;da&nbsp;LGPD, bem como os dados, se necessários para proteção ao crédito, conforme autorizado pelo Art.&nbsp;7º, inc.&nbsp;V&nbsp;da&nbsp;LGPD.</p>`;
            template += `<p class="ql-align-justify"><strong>11.2&nbsp;</strong>Outros dados poderão ser coletados, conforme termo de consentimento específico.</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify"><strong>CLÁUSULA DÉCIMA SEGUNDA - DAS DISPOSIÇÕES GERAIS</strong></p>`;
            template += `<p class="ql-align-justify"><strong>12.1&nbsp;</strong>Fica pactuada a total inexistência de vínculo trabalhista entre as partes, excluindo as obrigações previdenciárias e os encargos sociais, não havendo entre CONTRATADA e CONTRATANTE qualquer tipo de relação de subordinação.</p>`;
            template += `<p class="ql-align-justify"><strong>12.2&nbsp;</strong>A contratação da CONTRATADA, cumpridas todas as formalidades legais, com ou sem exclusividade, de forma contínua ou não, afasta a qualidade de empregado prevista no art.&nbsp;3º&nbsp;da&nbsp;CLT, nos termos do art.&nbsp;442-B&nbsp;da&nbsp;CLT.</p>`;
            template += `<p class="ql-align-justify"><strong>12.3&nbsp;</strong>A tolerância, por qualquer das partes, com relação ao descumprimento de qualquer termo ou condição aqui ajustado, não será considerada como desistência em exigir o cumprimento de disposição nele contida, nem representará novação com relação à obrigação passada, presente ou futura, no tocante ao termo ou condição cujo descumprimento foi tolerado.</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify"><strong>CLÁUSULA DÉCIMA TERCEIRA - DO FORO</strong></p>`;
            template += `<p class="ql-align-justify"><strong>13.1&nbsp;</strong>Para dirimir quaisquer controvérsias oriundas do presente contrato, as partes elegem o foro da Comarca de ________ do Estado de ________ .</p>`;
            template += `<p class="ql-align-justify">Por estarem assim justos e de acordo, firmam o presente instrumento, em duas vias de igual teor, juntamente com 2 (duas) testemunhas.</p>`;
            template += `<p class="ql-align-justify">________ , ________ .</p>`;
            template += `<p class="ql-align-justify">_________________________</p>`;
            template += `<p class="ql-align-justify">CONTRATANTE</p>`;
            template += `<p class="ql-align-justify">_________________________</p>`;
            template += `<p class="ql-align-justify">CONTRATADO</p>`;
            template += `<p class="ql-align-justify">TESTEMUNHAS:</p>`;
            template += `<p class="ql-align-justify">__________________________</p>`;
            template += `<p class="ql-align-justify">CPF/MF:</p>`;
            template += `<p class="ql-align-justify">__________________________</p>`;
            template += `<p class="ql-align-justify">CPF/MF:</p>`;
            template += `<p class="ql-align-justify">ANEXOS:</p>`;
            template += `<p><br></p>`;
            template += `<p class="ql-align-justify">ANEXO I - Descritivo, cronograma e especificações dos serviços</p>`;
            template += `<p class="ql-align-justify">(Elaborar planilha detalhada com especificações de trabalhos a serem desenvolvidos de acordo com a atividade a ser exercida pelo CONTRATADO;)</p>`;
            template += `<p><br></p>`;

            setContractCpsContent(template);
        }
    }
    
    async function onClick_TemplateCRT(event: any) {
        event.preventDefault();
        
        let isConfirmed = true;
        if (!isEmpty(contractCrtContent))
            isConfirmed = await confirmContext.show(props.title, 'O templete irá substituir as informações atuais, deseja continuar ?');
        
        if (isConfirmed) {
            let template = '';

            template += `<p class="ql-align-center"><strong>CONTRATO DE RASTREAMENTO</strong></p>`;
            template += `<p><br></p>`;
            template += `<p><br></p>`;

            setContractCrtContent(template);
        }
    }

    async function searchParam() {
        try {
            if (!Number.isNaN(Number.parseInt(props.partnerId ||''))) {
                setEditForm(true);

                const partner = await lclService.partnerById(Number(props.partnerId));
                setOriginRecord(partner);
                fillRecord(partner);

                cduService.artifactByNorm('COMPANY', partner.company.id, 'FILE_RESIDENCE').then((artifact: any) => {
                    if (artifact) {
                        artifact.filename = `Atualizado em ${notNullToDate(artifact.updated, 'dd/mm/yyyy HH:MM:ss')}`
                        setProofOfResidenceDocument(artifact);
                    }
                });

                const attributes = [] as any;
                const where = {
                    partnerId: partner.id,
                    situation: 5
                } as any;
                const order = [] as any;

                const contracts = await lclService.partnerContractFilter(attributes, where, order);
                if (contracts)
                    contracts.forEach((contract: any) => {
                        fillContract(contract.type, contract); 
                    });
            } else {
                setEditForm(false);
            }
        } catch(error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
        }
    } // searchParam

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            searchParam();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []); // useEffect

    function changeImage(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                setImage(e.target.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            setImage('');
        }
    }

    function changeProofOfResidenceDocument(event: any) {
        setChangedProofOfResidenceDocument(true);
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let artfact: ArtifactType = {
                    id : proofOfResidenceDocument.id,
                    norm : 'FILE_RESIDENCE',
                    source : 'COMPANY',
                    identy : String(companyId),
                    document : e.target.result,
                    filename : event.target.files[0].name
                }
                setProofOfResidenceDocument(artfact);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            let artifact: ArtifactType = { id : proofOfResidenceDocument.id, norm : 'FILE_RESIDENCE', source : 'COMPANY', identy : String(companyId), document : '', filename : '' };
            setProofOfResidenceDocument(artifact);
        }
    }

    function clickViewDocument(event: any, title: string, url: any) {
        event.preventDefault();
        openDocument(title, url);
    } 

    function viewCadastreForm() {

        return (
            <Container fluid className="page-body partner">
                <Row>
                    <Col sm={12} className="page-sweet">
                        <Card>
                            <Card.Header>
                                {(originRecord.created || originRecord.updated) && (
                                    <div className="form-identy-registry float-right">
                                        <span className="white-space"><b>Criação: </b> {dateToString(originRecord.created, 'dd/mm/yyyy HH:MM:ss')}</span>
                                        <span className="white-space"><b>Atualização: </b> {dateToString(originRecord.updated, 'dd/mm/yyyy HH:MM:ss')}</span>
                                    </div>
                                )}
                                <Card.Title>{display.legend.record}</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Tabs defaultActiveKey="partner-registration" id="tabs-partner-registration" className="mb-3">
                                    <Tab eventKey="partner-registration" title="Dados Gerais">
                                        <Container fluid className="container-min">
                                            <Form className="form" id="form-cadastre" validated={invalidatedForm}>
                                                <Row>
                                                    <Col sm="12">
                                                        { !isEditForm && <>
                                                            <Row>
                                                                <Col sm="5">
                                                                    <FormGroup>
                                                                        <Form.Label htmlFor="form-registration_pj">{display.label.registration_pj}</Form.Label>
                                                                        <InputGroup className="mb-2">
                                                                            <FormControl type="text" id="form-registration_pj" name="registration_pj" placeholder={'Digite o CNPJ'} required readOnly={isEditForm}
                                                                                value={toString(registrationPj)}
                                                                                onChange={e => setRegistrationPj(cnpjMask(e.target.value))}
                                                                            />
                                                                            <InputGroup.Text>
                                                                                <a href="/#" onClick={onClick_SearchCompany}>
                                                                                    <FaSearch />
                                                                                </a>
                                                                            </InputGroup.Text>
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row> 
                                                            <Row><Col><hr /></Col></Row>
                                                        </>}
                                                        <fieldset>
                                                            <legend>{display.legend.general_data}</legend>
                                                            <CorporateDataRecord 
                                                                translate={props.translate}
                                                                title={props.title}
                                                                isEdit={isEditForm}

                                                                socialReason={socialReason}
                                                                fantasy={fantasy}
                                                                sector={sector}
                                                                registrationPj={registrationPj}
                                                                registrationState={registrationState}
                                                                foundation={foundation}
                                                                site={site}
                                                                email={email}
                                                                telephone={telephone}
                                                                whatsapp={whatsapp}
                                                                situation={situation}
                                                                image={image}
                                                                setSocialReason={(value: any) => { setSocialReason(value); }}  
                                                                setFantasy={(value: any) => { setFantasy(value); }}  
                                                                setSector={(value: any) => { setSector(value); }}  
                                                                setRegistrationPj={(value: any) => { setRegistrationPj(value); }}  
                                                                setRegistrationState={(value: any) => { setRegistrationState(value); }} 
                                                                setFoundation={(value: any) => { setFoundation(value); }} 
                                                                setSite={(value: any) => { setSite(value); }} 
                                                                setEmail={(value: any) => { setEmail(value); }} 
                                                                setTelephone={(value: any) => { setTelephone(value); }} 
                                                                setWhatsapp={(value: any) => { setWhatsapp(value); }} 
                                                                setSituation={(value: any) => { setSituation(value); }} 
                                                                changeImage={(event: any) => { changeImage(event); }} 
                                                            />
                                                        </fieldset>
                                                        <fieldset>
                                                            <legend>{display.legend.administrators}
                                                                <div className="card-actions float-right">
                                                                {authService.hasPermission(PermissionEnum.CADASTRE_PARTNER_EDIT_SOCIETY) && 
                                                                    <Button size="small" variant="contained" onClick={(e) => onClick_AddSociety(e)}>
                                                                        <AddIcon />
                                                                        Administrador
                                                                    </Button>
                                                                }
                                                                </div>
                                                            </legend>
                                                            <TableContainer columns={columnsSociety} data={societies} />
                                                        </fieldset>
                                                        <fieldset>
                                                            <legend>
                                                                {display.legend.employees} {' / '}
                                                                {display.legend.sector} {' / '}
                                                                {display.legend.position}
                                                                <div className="card-actions float-right">
                                                                {authService.hasPermission(PermissionEnum.CADASTRE_PARTNER_EDIT_EMPLOYEE) && 
                                                                    <Button size="small" sx={{minWidth: 120}} variant="contained" onClick={(e) => onClick_AddEmployee(e)}>
                                                                        <AddIcon />
                                                                        Funcionário
                                                                    </Button>
                                                                }
                                                                </div>
                                                            </legend>
                                                            <TableContainer columns={columnsEmployee} data={employees} />
                                                        </fieldset>
                                                        <fieldset>
                                                            <legend>{display.legend.address}</legend>
                                                            <AddressRecord 
                                                                translate={props.translate}
                                                                title={props.title}
                                                                isEdit={false}

                                                                zipcode={zipcode}
                                                                state={state}
                                                                city={city}
                                                                district={district}
                                                                place={place}
                                                                habitation={habitation}
                                                                complement={complement}
                                                                setZipcode={(value: any) => { setZipcode(value); }} 
                                                                setState={(value: any) => { setState(value); }} 
                                                                setCity={(value: any) => { setCity(value); }} 
                                                                setDistrict={(value: any) => { setDistrict(value); }} 
                                                                setPlace={(value: any) => { setPlace(value); }} 
                                                                setHabitation={(value: any) => { setHabitation(value); }} 
                                                                setComplement={(value: any) => { setComplement(value); }}

                                                                proofOfResidenceDocument={proofOfResidenceDocument}
                                                                changeProofOfResidenceDocument={changeProofOfResidenceDocument}
                                                                clickViewDocument={clickViewDocument}
                                                            />
                                                        </fieldset>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm="12">
                                                        <fieldset>
                                                            <legend>{display.legend.location_in_maps}</legend>
                                                            <Row>
                                                            <Col sm="4">
                                                                    <FormGroup>
                                                                        <Form.Label htmlFor="form-url">{display.label.url}</Form.Label>
                                                                        <FormControl id="form-url" name="url" placeholder={display.example.url}
                                                                            value={toString(url)}
                                                                            onChange={(e => setUrl(e.target.value))}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Form.Label htmlFor="form-latitude">{display.label.latitude}</Form.Label>
                                                                        <FormControl id="form-latitude" name="latitude" placeholder={display.example.latitude}
                                                                            value={toString(latitude)}
                                                                            onChange={(e => setLatitude(e.target.value))}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col sm="4">
                                                                    <FormGroup>
                                                                        <Form.Label htmlFor="form-longitude">{display.label.longitude}</Form.Label>
                                                                        <FormControl id="form-longitude" name="longitude" placeholder={display.example.longitude}
                                                                            value={toString(longitude)}
                                                                            onChange={(e => setLongitude(e.target.value))}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </fieldset>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Container>
                                    </Tab>
                                    
                                    {/* CAG - Aluguel Garagem | CPS - Perstação de Serviço | CRT - Rastreamento */}
                                    <Tab eventKey="partner-contracts" title="Contratos">
                                        <Container fluid className="container-min">
                                            <Tabs defaultActiveKey="partner-contract-cag" id="tabs-partner-contract-cag" className="mb-3">
                                                <Tab eventKey="partner-contract-cag" title="Contrato de Alugel de Garagem">
                                                    {/*
                                                    <Container fluid className="container-min">
                                                        <Row>
                                                            <Col sm="3">
                                                                <FormGroup>
                                                                    <Form.Switch id="form-versioned-cag" name="versionedCag" placeholder={display.example.versioned}
                                                                        label={"Versionar Contrato"}
                                                                        value={versionedCAG}
                                                                        onChange={(e => setVersionedCAG(Number(e.target.value)))}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="9">
                                                                <Row>
                                                                    <Col sm="12">

                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                    */}
                                                    <Editor editorHtml={contractCagContent} setEditorHtml={setContractCagContent} clickTemplateDefault={onClick_TemplateCAG} selectField={fieldsContractCAG} />
                                                </Tab>
                                                <Tab eventKey="partner-contract-cps" title="Contrato de Perstação de Serviço">
                                                    <Container fluid className="container-min">
                                                        <Editor editorHtml={contractCpsContent} setEditorHtml={setContractCpsContent} clickTemplateDefault={onClick_TemplateCPS} />
                                                    </Container>
                                                </Tab>
                                                <Tab eventKey="partner-contract-crt" title="Contrato de Rastreamento">
                                                    <Container fluid className="container-min">
                                                        <Editor editorHtml={contractCrtContent} setEditorHtml={setContractCrtContent} clickTemplateDefault={onClick_TemplateCRT} />
                                                    </Container>
                                                </Tab>
                                            </Tabs>
                                        </Container>
                                    </Tab>
                                </Tabs>
                            </Card.Body>
                            <Card.Footer className="text-right">
                                { !authService.isAssociate() && <Button className="me-2" onClick={ (e) => onClick_CancelPartner(e) } variant="contained" color="secondary">{display.buttom.cancel}</Button>}
                                <Button variant="contained" color="primary" onClick={ (e) => onClick_SavePartner(e) } disabled={sending}>
                                    { sending ? <Spinner className="me-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSave className="me-2" size={22} /> } {' '}
                                    {display.buttom.save}
                                </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewCadastreForm

    return (
        <>
            { viewCadastreForm() }
            <SocietyCadastreModal 
                translate={props.translate} 
                isEdit={isEditSociety}
                show={showSociety}
                partner={props.partner}
                record={society}
                onSubmitModal={(event: any, record: any) => {
                    setSociety(record);
                    if (isEditSociety) {
                        let updatedSocieties =  null;
                        updatedSocieties = societies.map((societyItem: any, idx: number) => {
                            if (societyItem.id === record.id) {
                                return { ...societyItem, record};
                            }
                            return societyItem;
                        });
                        setSocieties(updatedSocieties);
                    } else {
                        setSocieties([...societies, record]);
                    }
                    setShowSociety(false);
                    setEditSociety(false);
                }}
                onCancelModal={(event: any) => {
                    setEditSociety(false);
                    setShowSociety(false);
                }}
            />
            <EmployeeCadastreModal 
                translate={props.translate} 
                isEdit={isEditEmployee}
                show={showEmployee}
                partner={props.partner}
                record={employee}
                onSubmitModal={(event: any, record: any) => {
                    setEmployee(record);

                    if (isEditEmployee) {
                        let updatedEmployees =  null;
                        updatedEmployees = societies.map((employeeItem: any, idx: number) => {
                            if (employeeItem.id === record.id) {
                                return { ...employeeItem, record};
                            }
                            return employeeItem;
                        });
                        setEmployees(updatedEmployees);
                    } else {
                        setEmployees([...employees, record]);
                    }
                    setShowEmployee(false);
                    setEditEmployee(false);
                }}
                onCancelModal={(event: any) => {
                    setEditEmployee(false);
                    setShowEmployee(false);
                }}
            />
        </>
    );
    
}

export default PartnerCadastreForm;
