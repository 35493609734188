import { Col, Form, FormControl, FormGroup, InputGroup, Row } from "react-bootstrap";
import { toString } from "../../../../utilities/auxiliary-functions";
import AddressRecord from './address.record';
import PersonalDataRecord from './personal-data.record';
import PersonalDocumentRecord from './personal-document.record';
import { cpfMask } from "../../../../utilities/masks";
import { FaSearch } from "react-icons/fa";
import ImageLightBox from "../../../../components/image-lightbox/ImageLightbox";
import styles from './mechanic.record.module.scss'
import { Tooltip as TooltipMaterial } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { CameraAltOutlined } from "@mui/icons-material";

interface Props {
    translate: any,
    title: any,
    isEdit: boolean,

    created?: any,
    updated?: any,
    tratament?: any,
    fullname?: any,
    gender?: any,
    birth?: any,
    norm?: any,
    operator?: any,
    connection?: any,
    sms?: any,
    zap?: any,
    major?: any,
    nationality?: any,
    country?: any,
    naturalness?: any,
    registrationPf?: any,
    registrationIdentity?: any,
    issuerIdentity?: any,
    stateIdentity?: any,
    expeditionIdentity?: any,
    driversLicense?: any,
    categoryDriversLicense?: any,
    validityDriversLicense?: any,
    sector?: any,
    office?: any,
    remuneration?: any,
    workdayFirst?: any,
    workdayStop?: any,
    workdayStart?: any,
    workdayLast?: any,
    zipcode?: any,
    state?: any,
    city?: any,
    district?: any,
    place?: any,
    habitation?: any,
    complement?: any,
    email?: any,
    setCreated?: any,
    setUpdated?: any,
    setTratament?: any,
    setFullname?: any,
    setGender?: any,
    setBirth?: any,
    setNorm?: any,
    setOperator?: any,
    setConnection?: any,
    setSms?: any,
    setZap?: any,
    setMajor?: any,
    setNationality?: any,
    setCountry?: any,
    setNaturalness?: any,
    setRegistrationPf?: any,
    setRegistrationIdentity?: any,
    setIssuerIdentity?: any,
    setStateIdentity?: any,
    setExpeditionIdentity?: any,
    setDriversLicense?: any,
    setCategoryDriversLicense?: any,
    setValidityDriversLicense?: any
    setSector?: any,
    setOffice?: any,
    setRemuneration?: any,
    setWorkdayFirst?: any,
    setWorkdayStop?: any,
    setWorkdayStart?: any,
    setWorkdayLast?: any
    setZipcode?: any,
    setState?: any,
    setCity?: any,
    setDistrict?: any,
    setPlace?: any,
    setHabitation?: any,
    setComplement?: any,
    setEmail ?: any,

    onClick_SearchPeople: any,

    proofOfResidenceDocument: any,
    changeProofOfResidenceDocument: any,
    clickViewDocument: any,

    image?:any,
    changeImage?: any
}

function MechanicRecord(props: Props) {

    const display = props.translate.data;

    return (
        <>
            <Row>
                <Col sm="12" className="ms-0 me-0 ps-0 pe-0">
                { !props.isEdit && <>
                    <Row>
                        <Col sm="3">
                        <FormGroup>
                            <Form.Label htmlFor="form-registration_pf">{display.label.registration_pf}</Form.Label>
                            <InputGroup className="mb-2">
                                <FormControl type="text" id="form-registration_pf" name="registration_pf"
                                    placeholder={'Digite o CPF'} required readOnly={props.isEdit}
                                    value={toString(props.registrationPf)} onChange={e=>
                                    props.setRegistrationPf(cpfMask(e.target.value))}
                                    />
                                    <InputGroup.Text>
                                        <a href="/#" onClick={props.onClick_SearchPeople}>
                                            <FaSearch />
                                        </a>
                                    </InputGroup.Text>
                            </InputGroup>
                        </FormGroup>
                        </Col>
                    </Row>
                    <Row sm="12" className="ms-1 me-1">
                        <Col className="ps-1 pe-1">
                        <hr />
                        </Col>
                    </Row>
                </>}
                </Col>
            </Row>
            <Row>
                
                <fieldset>
                    <legend>{display.legend.general_data}</legend>
                    <PersonalDataRecord translate={props.translate} title={props.title} isEdit={props.isEdit}
                        tratament={props.tratament} fullname={props.fullname} gender={props.gender} country={props.country}
                        birth={props.birth} norm={props.norm} operator={props.operator}
                        connection={props.connection} sms={props.sms} zap={props.zap} major={props.major}
                        nationality={props.nationality} naturalness={props.naturalness} setTratament={(value: any)=>
                        { props.setTratament(value); }}
                        setFullname={(value: any) => { props.setFullname(value); }}
                        setGender={(value: any) => { props.setGender(value); }}
                        setBirth={(value: any) => { props.setBirth(value); }}
                        setNorm={(value: any) => { props.setNorm(value); }}
                        setOperator={(value: any) => { props.setOperator(value); }}
                        setConnection={(value: any) => { props.setConnection(value); }}
                        setSms={(value: any) => { props.setSms(value); }}
                        setZap={(value: any) => { props.setZap(value); }}
                        setMajor={(value: any) => { props.setMajor(value); }}
                        setNationality={(value: any) => { props.setNationality(value); }}
                        setCountry={(value: any) => { props.setCountry(value); }}
                        setNaturalness={(value: any) => { props.setNaturalness(value); }}
                        />
                </fieldset>

                <Col sm="3">
                    <fieldset style={{height: '97%', marginRight: '5px'}}>
                        <legend>Foto</legend>
                        {props.image ? (
                            <div
                                className='d-flex flex-column justify-content-center align-items-center'>
                                <ImageLightBox containerStyle={`${styles.imageWrapper}
                                    ${styles.vesselImage} mb-3`} src={props.image}
                                    alt={'Cliente'}/>
                                <FormGroup as={Row} className={`d-flex
                                    justify-content-center ${styles.imageControls}`}>
                                    <label htmlFor={`member-image`}>
                                        <TooltipMaterial className="isClickable"
                                            title="Fazer upload de nova foto">
                                            <CloudUploadIcon />
                                        </TooltipMaterial>
                                    </label>
                                    <label className="text-right">
                                        <TooltipMaterial className="isClickable ms-2"
                                            title="Remover foto atual">
                                            <HighlightOffIcon />
                                        </TooltipMaterial>
                                    </label>
                                    <Form.Control type='file'
                                        id={`member-image`}
                                        accept="image/*"
                                        onChange={(e)=> props.changeImage(e) } />
                                </FormGroup>
                            </div>
                        ) : (
                            <div className={`${styles.imageWrapper}
                                ${styles.vesselImagePlaceholder}`}>
                                <CameraAltOutlined color='secondary' className={`isClickable
                                    ${styles.svg}`} sx={{fontSize: '73px'}} />
                                <FormGroup as={Row} className={` d-flex
                                    justify-content-center ${styles.imageControls}`}>
                                    <label htmlFor={`member-image-placeholder`}>
                                        <TooltipMaterial className="isClickable"
                                            title="Fazer upload de nova foto">
                                            <CloudUploadIcon />
                                        </TooltipMaterial>
                                    </label>
                                    <label className="text-right">
                                        <TooltipMaterial className="isClickable ms-2"
                                            title="Remover foto atual">
                                            <HighlightOffIcon />
                                        </TooltipMaterial>
                                    </label>
                                    <Form.Control type='file'
                                        id={`member-image-placeholder`}
                                        accept="image/*" onChange={(e)=> props.changeImage(e) } />
                                </FormGroup>
                            </div>
                        )}
                    </fieldset>
                </Col>
                <Col sm="9" className="ps-0 pe-0">
                    
                    <fieldset>
                        <FormGroup style={{width: '33%'}}>
                            <Form.Label htmlFor="form-email">{display.label.email}</Form.Label>
                            <Form.Control id="form-email" name="email" required placeholder={display.example.email}
                                value={props.email}
                                onChange={(e: any) => props.setEmail(e.target.value)}
                            />
                        </FormGroup>
                    </fieldset>
                    <fieldset>
                        <legend>{display.legend.documentation}</legend>
                        <PersonalDocumentRecord translate={props.translate} title={props.title} isEdit={props.isEdit}
                            registrationPf={props.registrationPf} registrationIdentity={props.registrationIdentity}
                            issuerIdentity={props.issuerIdentity} stateIdentity={props.stateIdentity}
                            expeditionIdentity={props.expeditionIdentity} driversLicense={props.driversLicense}
                            categoryDriversLicense={props.categoryDriversLicense}
                            validityDriversLicense={props.validityDriversLicense} setRegistrationPf={(value: any)=> {
                            props.setRegistrationPf(value); }}
                            setRegistrationIdentity={(value: any) => { props.setRegistrationIdentity(value); }}
                            setIssuerIdentity={(value: any) => { props.setIssuerIdentity(value); }}
                            setStateIdentity={(value: any) => { props.setStateIdentity(value); }}
                            setExpeditionIdentity={(value: any) => { props.setExpeditionIdentity(value); }}
                            setDriversLicense={(value: any) => { props.setDriversLicense(value); }}
                            setCategoryDriversLicense={(value: any) => { props.setCategoryDriversLicense(value); }}
                            setValidityDriversLicense={(value: any) => { props.setValidityDriversLicense(value); }}
                            />
                    </fieldset>
                </Col>
            </Row>

            <Row>
                <Col sm="12">
                <fieldset>
                    <legend>{display.legend.address}</legend>
                    <AddressRecord translate={props.translate} title={props.title} isEdit={props.isEdit}
                        zipcode={props.zipcode} state={props.state} city={props.city} district={props.district}
                        place={props.place} habitation={props.habitation} complement={props.complement}
                        setZipcode={(value: any)=> { props.setZipcode(value); }}
                        setState={(value: any) => { props.setState(value); }}
                        setCity={(value: any) => { props.setCity(value); }}
                        setDistrict={(value: any) => { props.setDistrict(value); }}
                        setPlace={(value: any) => { props.setPlace(value); }}
                        setHabitation={(value: any) => { props.setHabitation(value); }}
                        setComplement={(value: any) => { props.setComplement(value); }}

                        proofOfResidenceDocument={props.proofOfResidenceDocument}
                        changeProofOfResidenceDocument={props.changeProofOfResidenceDocument}
                        clickViewDocument={props.clickViewDocument}
                    />
                </fieldset>
                </Col>
            </Row>
        </>
    );

}

export default MechanicRecord;
