export default function authHeader(useData: boolean = false): {} {

    let localUser = localStorage.getItem('user') || '{}';
    const user = JSON.parse(localUser);
    
    if(user && user.authorization) {
        return { 
            'X-Access-Token': user.authorization ,
            'Content-Type': useData ? 'multipart/form-data' : 'application/json'
        };
    } else {
        return {};
    }
}

