import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Button, Tooltip as TooltipMaterial } from '@mui/material';
import { useEffect, useState } from "react";
import { Card, Col, Container, Form, FormGroup, Row, Spinner } from "react-bootstrap";
import env from "react-dotenv";
import { BsCreditCard2FrontFill, BsHouseFill } from "react-icons/bs";
import { FaSave } from "react-icons/fa";
import { IoIosCloudy, IoIosLock } from "react-icons/io";
import { IoDocumentLockSharp, IoDocumentsSharp } from "react-icons/io5";
import Resizer from 'react-image-file-resizer';
import Avatar from '../../assets/avatar.png';
import ImageLightBox from "../../components/image-lightbox/ImageLightbox";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../contexts/confirm-message.context";
import authService from "../../services/auth.service";
import cduService from "../../services/cdu.service";
import { dateToString, notNullToDate, toString } from "../../utilities/auxiliary-functions";
import { cpfMask, numberMask, phoneMask, zipcodeMask } from "../../utilities/masks";
import { AlertMessageEnum } from "../../utilities/types";
import { isCpf, isEmail, isEmpty } from "../../utilities/validators";
import AccessDataRecord from "../cadastres/forms/records/access-data.record";
import AddressRecord from "../cadastres/forms/records/address.record";
import MaritimeLicenseRecord from "../cadastres/forms/records/maritime-license.record";
import PersonalDataRecord from "../cadastres/forms/records/personal-data.record";
import PersonalDocumentRecord from "../cadastres/forms/records/personal-document.record";
import CadastreHeader from "../headers/cadastre.header";
import styles from './profile.page.module.scss';
import { openDocument } from '../../utilities/view-document';


interface Props {
    translate: any,
    
    onClick_ShowDefinedPartner: any
}

function ProfilePage(props: Props) {
    
    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();
    
    const MIN_CHAR_PASS = env.MIN_CHAR_PASS;
    const MAX_CHAR_PASS = env.MAX_CHAR_PASS;

    const display = props.translate.data;
    const title = display.title.profile;
    const description = display.description.profile;

    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);

    const [ invalidatedFormPublicInfo, setInvalidatedFormPublicInfo ] = useState(false);
    const [ invalidatedFormPrivateInfo, setInvalidatedFormPrivateInfo ] = useState(false);
    const [ invalidatedFormAccessInfo, setInvalidatedFormAccessInfo ] = useState(false);

    const [ sendingPublicInfo, setSendingPublicInfo ] = useState(false);
    const [ sendingPrivateInfo, setSendingPrivateInfo ] = useState(false);
    const [ sendingAccessInfo, setSendingAccessInfo ] = useState(false);

    // PublicData
    const [ username, setUsername ] = useState(''); 
    const [ biography, setBiography ] = useState(''); 
    const [ avatar, setAvatar ] = useState(''); 
    const [ email, setEmail ] = useState(''); 

    // PersonalData
    const [ peopleId, setPeopleId ] = useState(0); 
    const [ tratament, setTratament ] = useState(''); 
    const [ fullname, setFullname ] = useState(''); 
    const [ gender, setGender ] = useState('');
    const [ birth, setBirth ] = useState('');
    // Phone
    const [ norm, setNorm ] = useState('');
    const [ operator, setOperator ] = useState('');
    const [ connection, setConnection ] = useState('');
    const [ sms, setSms ] = useState(0);
    const [ zap, setZap ] = useState(0);
    const [ major, setMajor ] = useState(0);

    const [ nationality, setNationality ] = useState('');
    const [ naturalness, setNaturalness ] = useState('');
    // PersonalDocument
    const [ registrationPf, setRegistrationPf ] = useState('');
    const [ registrationIdentity, setRegistrationIdentity ] = useState('');
    const [ issuerIdentity, setIssuerIdentity ] = useState('');
    const [ stateIdentity, setStateIdentity ] = useState('');
    const [ expeditionIdentity, setExpeditionIdentity ] = useState('');
    const [ driversLicense, setDriversLicense ] = useState('');
    const [ categoryDriversLicense, setCategoryDriversLicense ] = useState('');
    const [ validityDriversLicense, setValidityDriversLicense ] = useState('');
    // Maritime License
    const [ categoryNaval, setCategoryNaval ] = useState('');
    const [ enrollmentNaval, setEnrollmentNaval ] = useState('');
    const [ validityNaval, setValidityNaval ] = useState('');
    const [ limitsNaval, setLimitsNaval ] = useState('');
    const [ commentsNaval, setCommentsNaval ] = useState('');
    const [ locationNaval, setLocationNaval ] = useState('');
    const [ expeditionNaval, setExpeditionNaval ] = useState('');
    // Address
    const [ zipcode, setZipcode ] = useState('');
    const [ state, setState ] = useState('');
    const [ city, setCity ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ place, setPlace ] = useState('');
    const [ habitation, setHabitation ] = useState('');
    const [ complement, setComplement ] = useState('');

    type ArtifactType = { id : number, norm : string, source : string, identy : string, document : string, filename : string };
    const DEFAULT_ARTIFACT: ArtifactType = { id : 0, norm : '', source : '', identy : '', document : '', filename : '' };
    
    const [ driversLicenseDocument, setDriversLicenseDocument ] = useState(DEFAULT_ARTIFACT);
    const [ proofOfResidenceDocument, setProofOfResidenceDocument ] = useState(DEFAULT_ARTIFACT);

    // AccessData
    const [ token, setToken ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ checkPassword, setCheckPassword ] = useState('');
    const [ selectedSession, setSelectedSession ] = useState('publicData');

    function onChange_Avatar(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            Resizer.imageFileResizer(file, 100, 100, "JPEG", 100, 0,
                (uri: any) => {
                    setAvatar(uri);
                }, "base64"
            );
        } else {
            setAvatar('');
        }
    }

    async function isValidFormPublicInfo() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(username))
            emptyRequired = true;
        if (isEmpty(email))
            emptyRequired = true;
        if ((!isEmpty(email)) && (!isEmail(email))) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.email);
            result = false;
        }

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedFormPublicInfo(!result);
        return result;
    } // isValidFormPublicInfo

    async function isValidFormPrivateInfo() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(fullname))
            emptyRequired = true;
        if (isEmpty(registrationPf))
            emptyRequired = true;
        if ((!isEmpty(registrationPf)) && (!isCpf(registrationPf))) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.registration_pf);
            result = false;
        }

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedFormPrivateInfo(!result);
        return result;
    } // isValidFormPrivateInfo

    async function isValidFormAccessInfo() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(token))
            emptyRequired = true;
        if (isEmpty(password))
            emptyRequired = true;
        if ((!isEmpty(password)) && (!isEmpty(checkPassword))) {
            let isValid = true;
            if (password.length < MIN_CHAR_PASS)
                isValid = false;
            if (password.length > MAX_CHAR_PASS)
                isValid = false;
            if (password.search(/(?=.*[A-Z])/) < 0)
                isValid = false;
            if (password.search(/(?=.*[a-z])/) < 0)
                isValid = false;
            if (password.search(/([0-9])/) < 0)
                isValid = false;
            if (password.search(/(?=.*[!@#$%&? "])/) < 0)
                isValid = false;

            if (!isValid) {
                await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.password);
                result = false;
            }
        }

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedFormAccessInfo(!result);
        return result;
    } // isValidFormPrivateInfo


    async function onClick_SavePublicInfo(event: any) {
        event.preventDefault();
        
        const isValid = await isValidFormPublicInfo();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(title, display.message.confirm_record);

                if (isConfirmed) {
                    setSendingPublicInfo(true);

                    setSendingPublicInfo(false);
                    await alertContext.show(AlertMessageEnum.FAIL, title, display.message.the_record_has_been_saved_successfully);
                }
                const user = authService.currentUser()
                const updatedUserData = await cduService.userById(user.id)
                localStorage.removeItem('avatar')
                localStorage.setItem('avatar', JSON.stringify(updatedUserData.avatar))
                window.location.reload()

            } catch (error: any) {
                setSendingPublicInfo(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }

    async function onClick_SavePrivateInfo(event: any) {
        event.preventDefault();

        const isValid = await isValidFormPrivateInfo();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(title, display.message.confirm_record);

                if (isConfirmed) {
                    setSendingPrivateInfo(true);

                    const dataArtifacts = [] as any;
                    dataArtifacts.push(driversLicenseDocument);
                    dataArtifacts.push(proofOfResidenceDocument);

                    for ( let i = 0; i < dataArtifacts.length; i++) {
                        const dataArtifact = dataArtifacts[i];

                        if (dataArtifact) {
                            dataArtifact.source = 'PEOPLE';
                            dataArtifact.identy = String(peopleId);
        
                            if ((dataArtifact.id > 0) && !isEmpty(dataArtifact.document)) {
                                await cduService.saveArtifact(dataArtifact.id, dataArtifact);
                            } else if ((dataArtifact.id > 0) && isEmpty(dataArtifact.document)) {
                                await cduService.artifactDeleteById(dataArtifact.id);
                            } else if ((dataArtifact.id === 0) && !isEmpty(dataArtifact.document)) {
                                await cduService.createArtifact(dataArtifact);
                            }
                        }
                    }


                    setSendingPrivateInfo(false);
                    await alertContext.show(AlertMessageEnum.FAIL, title, display.message.the_record_has_been_saved_successfully);
                }
            } catch (error: any) {
                setSendingPrivateInfo(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }
    
    async function onClick_SaveAccessInfo(event: any) {
        event.preventDefault();

        const isValid = await isValidFormAccessInfo();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(title, display.message.confirm_record);
                if (isConfirmed) {
                    setSendingAccessInfo(true);

                    setSendingAccessInfo(false);
                    await alertContext.show(AlertMessageEnum.FAIL, title, display.message.the_record_has_been_saved_successfully);
                }
            } catch (error: any) {
                setSendingAccessInfo(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }

    function fillRecordPublicInfo(user: any) {

        if (!isEmpty(user)) {
            setUsername(user.username); 
            setBiography(user.biography); 
            setAvatar(user.avatar); 
            setEmail(user.email); 
        }
    }

    function fillRecordPrivateInfo(people: any) {

        if (!isEmpty(people)) {
            setPeopleId(people.id);
            setTratament(people.tratament);
            setFullname(people.fullname);
            setGender(people.gender);
            setBirth(dateToString(people.birth, 'dd/mm/yyyy HH:MM:ss'));
            if (!isEmpty(people.phones)) {
                const phone = people.phones[0];

                setNorm(phone.norm);
                setOperator(phone.operator);
                setConnection(phoneMask(phone.connection));
                setSms(phone.sms);
                setZap(phone.zap);
                setMajor(phone.major);
            }
            setNationality(people.nationality);
            setNaturalness(people.naturalness);

            setRegistrationPf(cpfMask(people.registrationPf));
            setRegistrationIdentity(numberMask(people.registrationIdentity));
            setIssuerIdentity(people.issuerIdentity);
            setStateIdentity(people.stateIdentity);
            setExpeditionIdentity(dateToString(people.expeditionIdentity, 'dd/mm/yyyy'));
            setDriversLicense(numberMask(people.driversLicense));
            setCategoryDriversLicense(people.categoryDriversLicense);
            setValidityDriversLicense(dateToString(people.validityDriversLicense, 'dd/mm/yyyy'));

            if (!isEmpty(people.documentsNautical)) {
                const documentNautical = people.documentsNautical[0];
                
                setCategoryNaval(documentNautical.category);
                setEnrollmentNaval(documentNautical.enrollment);
                setValidityNaval(notNullToDate(documentNautical.validity, 'dd/mm/yyyy'));
                setLimitsNaval(documentNautical.limits);
                setCommentsNaval(documentNautical.comments);
                setLocationNaval(documentNautical.location);
                setExpeditionNaval(notNullToDate(documentNautical.expedition, 'dd/mm/yyyy'));
            }

            if (!isEmpty(people.adresses)) {
                const addressPeople = people.adresses[0];

                setZipcode(zipcodeMask(addressPeople.address.zipcode));
                setState(addressPeople.address.state);
                setCity(addressPeople.address.city);
                setDistrict(addressPeople.address.district);
                setPlace(addressPeople.address.place);

                setHabitation(addressPeople.habitation);
                setComplement(addressPeople.complement);
            }
        }
    }

    function fillRecordAccessInfo(user: any) {

        if (!isEmpty(user)) {
            setToken(user.token);
            setPassword(user.password);
            setCheckPassword(user.password);
        }
    }

    async function initialize() {
        try {
            const currentUser = authService.currentUser();
            const user = await cduService.userById(currentUser.id);
            const people = await cduService.peopleByUser(currentUser.id);

            fillRecordPublicInfo(user);
            fillRecordPrivateInfo(people);
            fillRecordAccessInfo(user);
                
            cduService.artifactByNorm('PEOPLE', people.id, 'FILE_DRIVER').then((artifact: any) => {
                if (artifact)
                    setDriversLicenseDocument(artifact);
            });
            cduService.artifactByNorm('PEOPLE', people.id, 'FILE_RESIDENCE').then((artifact: any) => {
                if (artifact)
                    setProofOfResidenceDocument(artifact);
            });
        } catch (error: any) {
            alertContext.show(AlertMessageEnum.FAIL, title, error);
        }
    }

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            initialize();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []);

    function changeDriversLicenseDocument(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let artfact: ArtifactType = {
                    id : driversLicenseDocument.id,
                    norm : 'FILE_DRIVER',
                    source : 'PEOPLE',
                    identy : String(peopleId),
                    document : e.target.result,
                    filename : event.target.files[0].name
                }
                setDriversLicenseDocument(artfact);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            let artifact: ArtifactType = { id : driversLicenseDocument.id, norm : 'FILE_DRIVER', source : 'PEOPLE', identy : String(peopleId), document : '', filename : '' };
            setDriversLicenseDocument(artifact);
        }
    }

    function changeProofOfResidenceDocument(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            reader.onload = (e: any) => {
                let artfact: ArtifactType = {
                    id : proofOfResidenceDocument.id,
                    norm : 'FILE_RESIDENCE',
                    source : 'PEOPLE',
                    identy : String(peopleId),
                    document : e.target.result,
                    filename : event.target.files[0].name
                }
                setProofOfResidenceDocument(artfact);
            };
            reader.readAsDataURL(event.target.files[0]);
        } else {
            let artifact: ArtifactType = { id : proofOfResidenceDocument.id, norm : 'FILE_RESIDENCE', source : 'PEOPLE', identy : String(peopleId), document : '', filename : '' };
            setProofOfResidenceDocument(artifact);
        }
    }

    function clickViewDocument(event: any, title: string, url: any) {
        event.preventDefault();
        openDocument(title, url);
    }

    function viewPage() {

        return (
            <Container fluid className="page-body">
                <Row>
                    <Col sm="3" className="page-session">
                        <Card>
                            <Card.Header>
                                <Card.Title>{display.legend.information}</Card.Title>
                            </Card.Header>
                            <ul className={`${styles.sideMenu} list-group list-group-flush`}>
                                <li className={selectedSession === 'publicData' ? styles.active : ''}>
                                    <p className="list-group-item-action list-group-item" onClick={() => setSelectedSession('publicData')}>
                                        <IoIosCloudy size={23} /><span>{display.legend.public_data}</span>
                                    </p>
                                </li>
                                <li className={selectedSession === 'personalData' ? styles.active : ''}>
                                    <p className="list-group-item-action list-group-item" onClick={() => setSelectedSession('personalData')}>
                                        <IoDocumentsSharp size={23} /><span>{display.legend.personal_data}</span>
                                    </p>
                                </li>
                                <li className={selectedSession === 'documentation' ? styles.active : ''}>
                                    <p className="list-group-item-action list-group-item" onClick={() => setSelectedSession('documentation')}>
                                        <IoDocumentLockSharp size={23} /><span>{display.legend.personal_document}</span>
                                    </p>
                                </li>
                                <li className={selectedSession === 'maritimeLicense' ? styles.active : ''}>
                                    <p className="list-group-item-action list-group-item" onClick={() => setSelectedSession('maritimeLicense')}>
                                        <BsCreditCard2FrontFill size={23} /><span>{display.legend.maritime_license}</span>
                                    </p>
                                </li>
                                <li className={selectedSession === 'personalAddress' ? styles.active : ''}>
                                    <p className="list-group-item-action list-group-item" onClick={() => setSelectedSession('personalAddress')}>
                                        <BsHouseFill size={23} /><span>{display.legend.personal_address}</span>
                                    </p>
                                </li>
                                <li className={selectedSession === 'accessData' ? styles.active : ''}>
                                    <p className="list-group-item-action list-group-item" onClick={() => setSelectedSession('accessData')}>
                                        <IoIosLock size={23} /><span>{display.legend.access_data}</span>
                                    </p>
                                </li>
                            </ul>
                        </Card>
                    </Col>
                    <Col sm="9" className="page-sweet">
                        <Card id="session-registry-public-info" style={{ display : selectedSession === 'publicData' ? ' block' : 'none' }}>
                            <Form id="form-registry-public-info" validated={invalidatedFormPublicInfo}>
                                <Card.Header>
                                    <Card.Title>{display.legend.public_information}</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col sm="8">
                                            <FormGroup>
                                                <Form.Label htmlFor="form-username">{display.label.username}</Form.Label>
                                                <Form.Control id="form-username" name="username" required placeholder={display.example.username}
                                                    value={toString(username)}
                                                    onChange={(e: any) => setUsername(e.target.value)}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Form.Label htmlFor="form-email">{display.label.email}</Form.Label>
                                                <Form.Control id="form-email" name="email" required placeholder={display.example.email}
                                                    value={toString(email)}
                                                    onChange={(e: any) => setEmail(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="4">
                                            <ImageLightBox
                                                containerStyle={styles.imageWrapper}
                                                src={avatar ? avatar : Avatar}
                                                alt="foto de perfil"
                                                rounded={true}
                                            />
                                        <FormGroup as={Row} className={`mt-2 d-flex justify-content-center ${styles.imageControls}`}>
                                            <label htmlFor='input-file-avatar'><TooltipMaterial className="isClickable" title="Fazer upload de nova foto de perfil" ><CloudUploadIcon /></TooltipMaterial></label>
                                            <label className="text-right" onClick={(e) => onChange_Avatar(e)}><TooltipMaterial className="isClickable ms-2" title="Remover foto atual" ><HighlightOffIcon /></TooltipMaterial></label>
                                            <Form.Control type='file' id='input-file-avatar' accept="image/*" onChange={(e) => onChange_Avatar(e)} />
                                        </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Form.Label htmlFor="form-biography">{display.label.biography}</Form.Label>
                                                <Form.Control as="textarea" rows={3} id="form-biography" name="biography" placeholder={display.example.biography}
                                                    value={toString(biography)}
                                                    onChange={e=>setBiography(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer>
                                    <Row>
                                        <Col sm="12" className="text-right">
                                            <Button variant="contained" color="primary" onClick={ (e) => onClick_SavePublicInfo(e) } disabled={sendingPublicInfo}>
                                                { sendingPublicInfo ? <Spinner className='me-2' as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSave className='me-2' size={22} /> } {' '}
                                                {display.buttom.save}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Form>
                        </Card>
                        <Card style={{ display : selectedSession === 'personalData' ? ' block' : 'none' }}>
                            <Form id="form-registry-private-information" validated={invalidatedFormPrivateInfo}>
                                <Card.Header>
                                    <Card.Title>{display.legend.private_information}</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <fieldset id="session-registry-private-info-personal-data">
                                            <legend>{display.legend.general_data}</legend>
                                            <PersonalDataRecord
                                                translate={props.translate}
                                                title={title}
                                                isEdit={true}

                                                tratament={tratament}
                                                fullname={fullname}
                                                gender={gender}
                                                birth={birth}
                                                norm={norm}
                                                operator={operator}
                                                connection={connection}
                                                sms={sms}
                                                zap={zap}
                                                major={major}
                                                nationality={nationality}
                                                naturalness={naturalness}
                                                setTratament={(value: any) => { setTratament(value); }}  
                                                setFullname={(value: any) => { setFullname(value); }}  
                                                setGender={(value: any) => { setGender(value); }}  
                                                setBirth={(value: any) => { setBirth(value); }}  
                                                setNorm={(value: any) => { setNorm(value); }}
                                                setOperator={(value: any) => { setOperator(value); }}
                                                setConnection={(value: any) => { setConnection(value); }}
                                                setSms={(value: any) => { setSms(value); }}
                                                setZap={(value: any) => { setZap(value); }}
                                                setMajor={(value: any) => { setMajor(value); }}
                                                setNationality={(value: any) => { setNationality(value); }} 
                                                setNaturalness={(value: any) => { setNaturalness(value); }} 
                                                />
                                        </fieldset>
                                    </Row>
                                </Card.Body>
                                <Card.Footer>
                                    <Row>
                                        <Col sm="12" className="text-right">
                                            <Button variant="contained" color="primary" onClick={ (e) => onClick_SavePrivateInfo(e) } disabled={sendingPrivateInfo}>
                                                { sendingPrivateInfo ? <Spinner className='me-2' as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSave className='me-2' size={22} /> } {' '}
                                                {display.buttom.save}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Form>
                        </Card>
                        <Card style={{ display : selectedSession === 'documentation' ? ' block' : 'none' }}>
                            <Form id="form-registry-personal-document" validated={invalidatedFormPrivateInfo}>
                                <Card.Header>
                                    <Card.Title>{display.legend.personal_document}</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <fieldset id="session-registry-private-info-personal-document">
                                            <legend>{display.legend.documentation}</legend>
                                            <PersonalDocumentRecord
                                                translate={props.translate}
                                                title={title}
                                                isEdit={true}

                                                registrationPf={registrationPf}
                                                registrationIdentity={registrationIdentity}
                                                issuerIdentity={issuerIdentity}
                                                stateIdentity={stateIdentity}
                                                expeditionIdentity={expeditionIdentity}
                                                driversLicense={driversLicense}
                                                categoryDriversLicense={categoryDriversLicense}
                                                validityDriversLicense={validityDriversLicense}
                                                setRegistrationPf={(value: any) => { setRegistrationPf(value); }}  
                                                setRegistrationIdentity={(value: any) => { setRegistrationIdentity(value); }}  
                                                setIssuerIdentity={(value: any) => { setIssuerIdentity(value); }}  
                                                setStateIdentity={(value: any) => { setStateIdentity(value); }}  
                                                setExpeditionIdentity={(value: any) => { setExpeditionIdentity(value); }}  
                                                setDriversLicense={(value: any) => { setDriversLicense(value); }}  
                                                setCategoryDriversLicense={(value: any) => { setCategoryDriversLicense(value); }} 
                                                setValidityDriversLicense={(value: any) => { setValidityDriversLicense(value); }} 
                                            />
                                        </fieldset>
                                    </Row>
                                </Card.Body>
                                <Card.Footer>
                                    <Row>
                                        <Col sm="12" className="text-right">
                                            <Button variant="contained" color="primary" onClick={ (e) => onClick_SavePrivateInfo(e) } disabled={sendingPrivateInfo}>
                                                { sendingPrivateInfo ? <Spinner className='me-2' as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSave className='me-2' size={22} /> } {' '}
                                                {display.buttom.save}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Form>
                        </Card>
                        <Card style={{ display : selectedSession === 'maritimeLicense' ? ' block' : 'none' }}>
                            <Form id="form-registry-maritime-license" validated={invalidatedFormPrivateInfo}>
                                <Card.Header>
                                    <Card.Title>{display.legend.maritime_license}</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <fieldset id="session-registry-private-info-maritime-license">
                                            <legend>{display.legend.maritime_license}</legend>
                                            <MaritimeLicenseRecord
                                                translate={props.translate}
                                                title={title}
                                                isEdit={true}
                                                isRequired={false}

                                                categoryNaval={categoryNaval}
                                                enrollmentNaval={enrollmentNaval}
                                                validityNaval={validityNaval}
                                                limitsNaval={limitsNaval}
                                                commentsNaval={commentsNaval}
                                                locationNaval={locationNaval}
                                                expeditionNaval={expeditionNaval}
                                                setCategoryNaval={(value: any) => { setCategoryNaval(value); }} 
                                                setEnrollmentNaval={(value: any) => { setEnrollmentNaval(value); }} 
                                                setValidityNaval={(value: any) => { setValidityNaval(value); }} 
                                                setLimitsNaval={(value: any) => { setLimitsNaval(value); }} 
                                                setCommentsNaval={(value: any) => { setCommentsNaval(value); }} 
                                                setLocationNaval={(value: any) => { setLocationNaval(value); }} 
                                                setExpeditionNaval={(value: any) => { setExpeditionNaval(value); }} 

                                                driversLicenseDocument={driversLicenseDocument}
                                                changeDriversLicenseDocument={(event: any) => { changeDriversLicenseDocument(event); }}
                                                clickViewDocument={clickViewDocument}
                                            />
                                        </fieldset>
                                    </Row>
                                </Card.Body>
                                <Card.Footer>
                                    <Row>
                                        <Col sm="12" className="text-right">
                                            <Button variant="contained" color="primary" onClick={ (e) => onClick_SavePrivateInfo(e) } disabled={sendingPrivateInfo}>
                                                { sendingPrivateInfo ? <Spinner className='me-2' as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSave className='me-2' size={22} /> } {' '}
                                                {display.buttom.save}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Form>
                        </Card>
                        <Card style={{ display : selectedSession === 'personalAddress' ? ' block' : 'none' }}>
                            <Form id="form-registry-address" validated={invalidatedFormPrivateInfo}>
                                <Card.Header>
                                    <Card.Title>{display.legend.address}</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                    <fieldset id="session-registry-private-info-address">
                                        <legend>{display.legend.address}</legend>
                                        <AddressRecord 
                                            translate={props.translate}
                                            title={title}
                                            isEdit={true}

                                            zipcode={zipcode}
                                            state={state}
                                            city={city}
                                            district={district}
                                            place={place}
                                            habitation={habitation}
                                            complement={complement}
                                            setZipcode={(value: any) => { setZipcode(value); }} 
                                            setState={(value: any) => { setState(value); }} 
                                            setCity={(value: any) => { setCity(value); }} 
                                            setDistrict={(value: any) => { setDistrict(value); }} 
                                            setPlace={(value: any) => { setPlace(value); }} 
                                            setHabitation={(value: any) => { setHabitation(value); }} 
                                            setComplement={(value: any) => { setComplement(value); }}

                                            proofOfResidenceDocument={proofOfResidenceDocument}
                                            changeProofOfResidenceDocument={changeProofOfResidenceDocument}
                                            clickViewDocument={clickViewDocument}
                                        />
                                    </fieldset>
                                    </Row>
                                </Card.Body>
                                <Card.Footer>
                                    <Row>
                                        <Col sm="12" className="text-right">
                                            <Button variant="contained" color="primary" onClick={ (e) => onClick_SavePrivateInfo(e) } disabled={sendingPrivateInfo}>
                                                { sendingPrivateInfo ? <Spinner className='me-2' as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSave className='me-2' size={22} /> } {' '}
                                                {display.buttom.save}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Form>
                        </Card>
                        <Card id="session-registry-access-information"  style={{ display : selectedSession === 'accessData' ? ' block' : 'none' }}>
                            <Form id="form-registry-access-info" validated={invalidatedFormAccessInfo}>
                                <Card.Header>
                                    <Card.Title>{display.legend.access_information}</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <AccessDataRecord 
                                        translate={props.translate}
                                        title={title}
                                        isEdit={true}

                                        token={token}
                                        password={password}
                                        checkPassword={checkPassword}
                                        setToken={(value: any) => { setToken(value); }} 
                                        setPassword={(value: any) => { setPassword(value); }} 
                                        setCheckPassword={(value: any) => { setCheckPassword(value); }}
                                    />
                                </Card.Body>
                                <Card.Footer>
                                    <Row>
                                        <Col sm="12" className="text-right">
                                            <Button variant="contained" color="primary" onClick={ (e) => onClick_SaveAccessInfo(e) } disabled={sendingAccessInfo}>
                                                { sendingAccessInfo ? <Spinner className='me-2' as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSave className='me-2' size={22} /> } {' '}
                                                {display.buttom.save}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewPage

    return (
        <div className="page">
            <CadastreHeader title={title} description={description} onClick_ShowDefinedPartner={props.onClick_ShowDefinedPartner} />
            { viewPage() }
        </div>
    )
}

export default ProfilePage;
