import { useEffect, useState } from 'react';
import { Col, Form, FormControl, FormGroup, InputGroup, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { FaEnvelope, FaTrashAlt } from 'react-icons/fa';
import { useAlertMessageContext } from '../../../../contexts/alert-message.context';
import addressService from '../../../../services/address.service';
import postOfficesService from '../../../../services/post-offices.service';
import { toString } from '../../../../utilities/auxiliary-functions';
import { zipcodeMask } from '../../../../utilities/masks';
import { AlertMessageEnum } from '../../../../utilities/types';
import { isEmpty } from '../../../../utilities/validators';
import UploadFileIcon from '@mui/icons-material/UploadFile';

interface Props {
    translate: any,
    title: any,
    isEdit: boolean,

    zipcode?: any,
    state?: any,
    city?: any,
    district?: any,
    place?: any,
    habitation?: any,
    complement?: any,
    setZipcode?: any,
    setState?: any,
    setCity?: any,
    setDistrict?: any,
    setPlace?: any,
    setHabitation?: any,
    setComplement?: any,

    proofOfResidenceDocument: any,
    changeProofOfResidenceDocument: any,
    clickViewDocument: any
}

function AddressRecord(props: Props) {

    const alertContext = useAlertMessageContext();

    const display = props.translate.data;

    let reloadPage = false;
    const [ listStates, setListStates ] = useState([] as any);
    const [ listCities, setListCities ] = useState([] as any);
   
    async function fillStates() {
        try {
            if (listStates.length === 0) {
                const states = await addressService.listStates();
                setListStates(states);
            }
        } catch (error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
        }
    }

    async function fillCities(states: [], state: string) {
        let stateId = 0;
        
        let list = null;
        if ((!isEmpty(states)) && states.length > 0)
            list = states
        else
            list = listStates;

        list.forEach((iterator: any) => {
            if (iterator.sigla === state) {
                stateId = iterator.id;
            }
        });

        if (stateId > 0) {
            try {
                const cities = await addressService.listCities(stateId);
                setListCities(cities);
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
            }
        }
    }

    async function fillAddress(zipcode?: string) {
        if (zipcode) {
            var cep = zipcode.replace(/\D/g, '');
            if (!isEmpty(cep) && (cep.length >= 8)) {
                try {
                    const addrerss = await postOfficesService.findZipcode(cep);

                    props.setState(addrerss.uf);
                    await fillCities([], addrerss.uf);
                    props.setCity(addrerss.localidade);
                    props.setDistrict(addrerss.bairro);
                    props.setPlace(addrerss.logradouro);
                } catch(error: any) {
                    await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
                }
            }
        }
    }

    function onChange_State(state: string) {
        props.setState(state);
        fillCities([], state);
    }

    async function initialize() {
        await fillStates();
        //await fillAddress();
    }

    useEffect(() => {
        fillCities(listStates, props.state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.state]);

    useEffect(() => {
        if (!reloadPage) {
            initialize();
        }
        
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Row>
                <Col sm="3">
                    <FormGroup>
                        <Form.Label htmlFor="form-zipcode">{display.label.zipcode}</Form.Label>
                        <InputGroup className="mb-2">
                            <InputGroup.Text><FaEnvelope /></InputGroup.Text>
                            <FormControl id="form-zipcode" name="zipcode" placeholder={display.example.zipcode}
                                value={toString(props.zipcode)}
                                onChange={(e: any) => props.setZipcode(zipcodeMask(e.target.value))}
                                onBlur={(e: any) => fillAddress(e.target.value)}
                            />
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col sm="4">
                    <FormGroup>
                        <Form.Label htmlFor="form-state">{display.label.state}</Form.Label>
                        <Form.Select id="form-state" name="state"
                            value={toString(props.state)}
                            onChange={(e: any) => onChange_State(e.target.value)}
                        >
                            { isEmpty(props.state) ? (
                                <option value="">...</option>
                            ) : null }
                            { listStates.map((iterator: any, idx: number) => (<option key={idx} value={iterator.sigla}>{iterator.nome}</option>))
                                .sort((a : any, b : any) => { 
                                    return a.props.children > b.props.children ? 1 : -1; 
                                }) 
                            }
                        </Form.Select>
                    </FormGroup>
                </Col>
                <Col sm="5">
                    <FormGroup>
                        <Form.Label htmlFor="form-city">{display.label.city}</Form.Label>
                        <Form.Select id="form-city" name="city"
                            value={toString(props.city)}
                            onChange={(e: any) => props.setCity(e.target.value)}
                        >
                            { isEmpty(props.city) ? (
                                <option value="">...</option>
                            ) : null }
                            { listCities.map((iterator: any, idx: number) => (<option key={idx} value={iterator.nome}>{iterator.nome}</option>)) }
                        </Form.Select>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm="3">
                    <FormGroup>
                        <Form.Label htmlFor="form-district">{display.label.district}</Form.Label>
                        <FormControl type="text" id="form-district" name="district" placeholder="Bairro"
                            value={toString(props.district)}
                            onChange={e => props.setDistrict(e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col sm="3">
                    <FormGroup>
                        <Form.Label htmlFor="form-place">{display.label.place}</Form.Label>
                        <FormControl type="text" id="form-place" name="place" placeholder={display.example.place}
                            value={toString(props.place)}
                            onChange={e => props.setPlace(e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col sm="3">
                    <FormGroup>
                        <Form.Label htmlFor="form-habitation">{display.label.habitation}</Form.Label>
                        <FormControl type="text" id="form-habitation" name="habitation" placeholder={display.example.habitation}
                            value={toString(props.habitation)}
                            onChange={e => props.setHabitation(e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col sm="3">
                    <FormGroup>
                        <Form.Label htmlFor="form-complement">{display.label.complement}</Form.Label>
                        <FormControl type="text" id="form-complement" name="complement" placeholder={display.example.complement}
                            value={toString(props.complement)}
                            onChange={e => props.setComplement(e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row className='mt-4'>
                <legend>{display.legend.documentation_annex}</legend>
                <Table size="sm">
                    <tbody>
                        <tr>
                            <td className='text-right' style={{verticalAlign: 'initial'}}>
                                </td>
                            <td style={{display:  'flex', justifyContent: 'flex-end'}}>
                                <span className='me-5 mt-2' style={{verticalAlign: 'initial'}}>

                                        {display.label.proof_of_residence_document}
                                </span>

                            <InputGroup style={{width: '420px'}}>
                                    <FormControl className="input-file-text"
                                        id="form-file-proof-of-residence-document" name="proofOfResidenceDocument"
                                        value={toString(props.proofOfResidenceDocument.filename)}
                                        readOnly
                                        style={{marginBottom: '2px'}}
                                    />
                                    <Form.Control type='file' id='input-file-proof-of-residence-document' onChange={(e) => { props.changeProofOfResidenceDocument(e) }} />
                                        <OverlayTrigger overlay={<Tooltip id="tooltip">
                                            {display.tooltips.upload_documents}</Tooltip>}>
                                            <Form.Label className="input-file-button"
                                                htmlFor='input-file-proof-of-residence-document'>
                                                <UploadFileIcon />
                                            </Form.Label>
                                        </OverlayTrigger>
                                        {!isEmpty(props.proofOfResidenceDocument.document) ? (
                                            <>
                                            <Form.Label
                                                className="open-file-button input-file-button"
                                                htmlFor='file-proof-of-residence-document'
                                                onClick={ (e)=> { props.clickViewDocument(e, 
                                                    display.label.insurance_policy_document, 
                                                    props.proofOfResidenceDocument.document
                                                )
                                            }}>{display.buttom.visualize}</Form.Label>
                                            <OverlayTrigger overlay={<Tooltip id="tooltip">
                                                {display.tooltips.remove_documents}</Tooltip>}>
                                                <Form.Label className="input-file-button"
                                                    onClick={(e)=> {props.changeProofOfResidenceDocument(e)}}
                                                >
                                                    <FaTrashAlt size={18} />
                                                </Form.Label>
                                            </OverlayTrigger>
                                            </>
                                        ) : null}
                                </InputGroup>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
        </>
    );

}

export default AddressRecord;
