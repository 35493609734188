import axios from "axios";
import env from "react-dotenv";

class AddressService {
    
    API_ADDRESS = env.API_ADDRESS;

    async listStates() {
        const url = `${this.API_ADDRESS}/localidades/estados`;

        return axios
            .get(url
            ).then((response: any) => {
                return response.data; 
            }).catch((error: any) => {
                const responseMessage = (
                    error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();

                throw new Error(responseMessage);
            });
    }
    
    async listAllCities() {
        const url = `${this.API_ADDRESS}/localidades/municipios`;

        return axios
            .get(url
            ).then((response: any) => {
                return response.data;
            }).catch((error: any) => {
                const responseMessage = (
                    error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();

                throw new Error(responseMessage);
            });
    }

    async listCities(stateId: number) {
        const url = `${this.API_ADDRESS}/localidades/estados/${stateId}/municipios`;

        return axios
            .get(url
            ).then((response: any) => {
                return response.data;
            }).catch((error: any) => {
                const responseMessage = (
                    error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                    error.message ||
                    error.toString();

                throw new Error(responseMessage);
            });
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AddressService();