import env from "react-dotenv";
import { isEmpty } from "../utilities/validators";
import { ServiceCommon } from "./service.common";

class SrvService extends ServiceCommon {
    
    API_SRV = env.API_SRV;

    constructor() {
        super('SrvService');
    }

    /* MECHANIC */
    
    async mechanicById(mechanicId: number) {
        const url = `${this.API_SRV}/mechanic/${mechanicId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // mechanicById

    async mechanicList(attributes: [], where: {}, order: []) {
        const url = `${this.API_SRV}/mechanic/list`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // mechanicFilter

    async mechanicFilter(attributes: [], where: {}, order: []) {
        const url = `${this.API_SRV}/mechanic/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // mechanicFilter

    async saveMechanic(mechanicId: number, mechanic: any) {
        const url = `${this.API_SRV}/mechanic/save/${mechanicId}`;

        try {
            return await this.putReturnObject(url, mechanic);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveMechanic

    async createMechanic(mechanic: any) {
        const url = `${this.API_SRV}/mechanic/create`;

        try {
            return await this.postReturnObject(url, mechanic);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createMechanic

    async mechanicDeleteById(mechanicId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_SRV}/mechanic/delete/${mechanicId}`;
        else
            url = `${this.API_SRV}/mechanic/delete/${mechanicId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // mechanicDeleteById

    /* PURVEYOR */
    
    async purveyorById(purveyorId: number) {
        const url = `${this.API_SRV}/purveyor/${purveyorId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // purveyorById

    async purveyorList(attributes: [], where: {}, order: []) {
        const url = `${this.API_SRV}/purveyor/list`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // purveyorFilter

    async purveyorFilter(attributes: [], where: {}, order: []) {
        const url = `${this.API_SRV}/purveyor/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // purveyorFilter

    async savePurveyor(purveyorId: number, purveyor: any) {
        const url = `${this.API_SRV}/purveyor/save/${purveyorId}`;

        try {
            return await this.putReturnObject(url, purveyor);
        } catch (error: any) {
            throw new Error(error);
        }
    } // savePurveyor

    async createPurveyor(purveyor: any) {
        const url = `${this.API_SRV}/purveyor/create`;

        try {
            return await this.postReturnObject(url, purveyor);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createPurveyor

    async purveyorDeleteById(purveyorId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_SRV}/purveyor/delete/${purveyorId}`;
        else
            url = `${this.API_SRV}/purveyor/delete/${purveyorId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // purveyorDeleteById

    /* PRODUCT */
    
    async productById(productId: number) {
        const url = `${this.API_SRV}/product/${productId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // productById

    async productFilter(attributes: [], where: {}, order: []) {
        const url = `${this.API_SRV}/product/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // productFilter

    async saveProduct(productId: number, product: any) {
        const url = `${this.API_SRV}/product/save/${productId}`;

        try {
            return await this.putReturnObject(url, product);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveProduct

    async createProduct(product: any) {
        const url = `${this.API_SRV}/product/create`;

        try {
            return await this.postReturnObject(url, product);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createProduct

    async productDeleteById(productId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_SRV}/product/delete/${productId}`;
        else
            url = `${this.API_SRV}/product/delete/${productId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // productDeleteById

    /* STOCK */
    
    async stockById(stockId: number) {
        const url = `${this.API_SRV}/stock/${stockId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // stockById

    async stockList(attributes: [], where: {}, order: []) {
        const url = `${this.API_SRV}/stock/list`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // stockList

    async stockFilter(attributes: [], where: {}, order: []) {
        const url = `${this.API_SRV}/stock/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // stockFilter

    async saveStock(stockId: number, stock: any) {
        const url = `${this.API_SRV}/stock/save/${stockId}`;

        try {
            return await this.putReturnObject(url, stock);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveStock

    async createStock(stock: any) {
        const url = `${this.API_SRV}/stock/create`;

        try {
            return await this.postReturnObject(url, stock);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createStock

    async stockDeleteById(stockId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_SRV}/stock/delete/${stockId}`;
        else
            url = `${this.API_SRV}/stock/delete/${stockId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // stockDeleteById

    /* INVENTORY */
    
    async inventoryById(inventoryId: number) {
        const url = `${this.API_SRV}/inventory/${inventoryId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // inventoryById

    async inventoryFilter(attributes: [], where: {}, order: []) {
        const url = `${this.API_SRV}/inventory/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // inventoryFilter

    async saveInventory(inventoryId: number, inventory: any) {
        const url = `${this.API_SRV}/inventory/save/${inventoryId}`;

        try {
            return await this.putReturnObject(url, inventory);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveInventory

    async createInventory(inventory: any) {
        const url = `${this.API_SRV}/inventory/create`;

        try {
            return await this.postReturnObject(url, inventory);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createInventory

    async inventoryDeleteById(inventoryId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_SRV}/inventory/delete/${inventoryId}`;
        else
            url = `${this.API_SRV}/inventory/delete/${inventoryId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // inventoryDeleteById

    /* SCHEDULE */
    
    async scheduleById(scheduleId: number) {
        const url = `${this.API_SRV}/schedule/${scheduleId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // scheduleById
    
    async scheduleByVessel(vesselId: number) {
        const url = `${this.API_SRV}/schedule/vessel/${vesselId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // scheduleById

    async scheduleFilter(attributes: [], where: {}, order: []) {
        const url = `${this.API_SRV}/schedule/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // scheduleFilter

    async saveSchedule(scheduleId: number, schedule: any) {
        const url = `${this.API_SRV}/schedule/save/${scheduleId}`;

        try {
            return await this.putReturnObject(url, schedule);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveSchedule

    async createSchedule(schedule: any) {
        const url = `${this.API_SRV}/schedule/create`;

        try {
            return await this.postReturnObject(url, schedule);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createSchedule

    async scheduleDeleteById(scheduleId: number, comments?: any) {
        let url = `${this.API_SRV}/schedule/delete/${scheduleId}`;
        if (!isEmpty(comments))
            url += `/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // scheduleDeleteById

    /* CATALOG */
    
    async catalogById(purveyorId: number, productId: number) {
        const url = `${this.API_SRV}/catalog/purveyor/${purveyorId}/product/${productId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // catalogById
    
    async findByPurveyor(purveyorId: number) {
        const url = `${this.API_SRV}/catalog/purveyor/${purveyorId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // findByPurveyor

    async catalogFilter(attributes: [], where: {}, order: []) {
        const url = `${this.API_SRV}/catalog/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // catalogFilter

    async saveCatalog(purveyorId: number, productId: number, catalog: any) {
        const url = `${this.API_SRV}/catalog/save/purveyor/${purveyorId}/product/${productId}`;

        try {
            return await this.putReturnObject(url, catalog);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveCatalog

    async createCatalog(catalog: any) {
        const url = `${this.API_SRV}/catalog/create`;

        try {
            return await this.postReturnObject(url, catalog);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createCatalog

    async catalogDeleteById(purveyorId: number, productId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_SRV}/catalog/delete/purveyor/${purveyorId}/product/${productId}`;
        else
            url = `${this.API_SRV}/catalog/delete/purveyor/${purveyorId}/product/${productId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // catalogDeleteById

    async catalogDeleteByPurveyor(purveyorId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_SRV}/catalog/delete/purveyor/${purveyorId}`;
        else
            url = `${this.API_SRV}/catalog/delete/purveyor/${purveyorId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // catalogDeleteByPurveyor

    /* BUDGET */
    
    async budgetNewCode() {
        const url = `${this.API_SRV}/budget/new_code`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // budgetNewCode

    async budgetById(budgetId: number) {
        const url = `${this.API_SRV}/budget/${budgetId}`;

        try {
            return await this.getReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // budgetById

    async budgetList(attributes: [], where: {}, order: []) {
        const url = `${this.API_SRV}/budget/list`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // budgetList

    async budgetFilter(attributes: [], where: {}, order: []) {
        const url = `${this.API_SRV}/budget/filter`;

        try {
            return await this.postReturnObject(url, {attributes, where, order});
        } catch (error: any) {
            throw new Error(error);
        }
    } // budgetFilter

    async saveBudget(budgetId: number, budget: any) {
        const url = `${this.API_SRV}/budget/save/${budgetId}`;

        try {
            return await this.putReturnObject(url, budget);
        } catch (error: any) {
            throw new Error(error);
        }
    } // saveBudget

    async createBudget(budget: any) {
        const url = `${this.API_SRV}/budget/create`;

        try {
            return await this.postReturnObject(url, budget);
        } catch (error: any) {
            throw new Error(error);
        }
    } // createBudget

    async budgetDeleteById(budgetId: number, comments: any) {
        let url = '';
        if (isEmpty(comments))
            url = `${this.API_SRV}/budget/delete/${budgetId}`;
        else
            url = `${this.API_SRV}/budget/delete/${budgetId}/comments/${comments}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // budgetDeleteById

    async budgetItemDeleteById(budgetId: number) {
        const url = `${this.API_SRV}/budget_item/delete/${budgetId}`;

        try {
            return await this.deleteReturnObject(url);
        } catch (error: any) {
            throw new Error(error);
        }
    } // budgetDeleteById

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new SrvService();