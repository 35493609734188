import logoNauti from '../assets/icon_logo.png';

import React, {useContext, useRef, useState} from "react";
import Modal from "react-bootstrap/Modal";
import { TranslateContext } from "./translate.context";
import { Form, FormGroup, Image } from 'react-bootstrap';
import { Button } from '@mui/material'


type UseModalShowReturnType = {
    show: boolean;
    setShow: (value: boolean) => void;
    onHide: () => void;
}

const useModalShow = (): UseModalShowReturnType => {
    const [show, setShow] = useState(false);

    const handleOnHide = () => {
        setShow(false);
    };

    return {
        show,
        setShow,
        onHide: handleOnHide,
    }
};

type ModalContextType = {
    show: (title: string, message: string | JSX.Element, hasConments: boolean) => Promise<{result: false, message: ''}>;
};

type DeleteMessageContextProviderProps = {
    children: React.ReactNode
}

const DeleteMessageContext = React.createContext<ModalContextType>({} as ModalContextType);

const DeleteMessageContextProvider: React.FC<DeleteMessageContextProviderProps> = (props) => {

    const { translate } = useContext(TranslateContext) as any;
    const display = translate.data;

    const { setShow, show, onHide } = useModalShow();
    const [ content, setContent ] = useState<{ title: string, message: string | JSX.Element, hasConments?: boolean} | null>();
    const resolver = useRef<Function>();
    const [ comments, setComments ] = useState('');

    const onShow = (title: string, message: string | JSX.Element, hasConments: boolean): Promise<{result: false, message: ''}> => {
        setContent({
            title,
            message,
            hasConments
        });
        setShow(true);
        setComments('');
        return new Promise(function (resolve) {
            resolver.current = resolve;
        });
    };

    const modalContext: ModalContextType = {
        show : onShow
    };

    const onClick_Ok = () => {
        if (((content && content.hasConments) && (comments.trim() !== '')) || (content && content.hasConments === false)) {
            resolver.current && resolver.current({result: true, message: comments});
            setContent({
                title: '',
                message: '',
                hasConments: false
            });
            onHide();
        }
    };

    const onClick_Cancel = () => {
        resolver.current && resolver.current({result: false});
        setContent({
            title: '',
            message: '',
            hasConments: false
        });
        onHide();
    };
    
    return (
        <DeleteMessageContext.Provider value={modalContext}>
            {props.children}

            {content &&
                <Modal 
                    show={show} 
                    onHide={onHide} 
                    dialogClassName={`modal-md`}
                    backdrop="static"
                    className="message-context"
                >
                    <Modal.Header>
                        <Modal.Title className='d-flex'><Image className='me-3' src={logoNauti} style={{width: "23px", marginTop: "-5px"}} /> {content.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>{content.message}</label>
                        {content.hasConments === undefined ? true : content.hasConments &&  (
                        <FormGroup>
                            <Form.Label htmlFor="form-comments">{display.label.comments}</Form.Label>
                            <Form.Control as="textarea" rows={3} type="" id="form-comments" name="comments" placeholder={display.example.comments} required
                                value={comments}
                                onChange={e => setComments(e.target.value)}
                            />
                        </FormGroup>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="contained" color="secondary" onClick={onClick_Cancel}>{display.buttom.cancel}</Button>
                        <Button variant="contained" color="primary" onClick={onClick_Ok}>{display.buttom.okay}</Button>
                    </Modal.Footer>
                </Modal>}
        </DeleteMessageContext.Provider>
    )
};

const useDeleteMessageContext = (): ModalContextType => useContext(DeleteMessageContext);

export {
    useModalShow,
    useDeleteMessageContext,
}

export default DeleteMessageContextProvider;