import { Col, Form, FormControl, FormGroup, Row } from "react-bootstrap";
import { useAlertMessageContext } from "../../../../contexts/alert-message.context";
import { toDate, toLowerCase, toString } from "../../../../utilities/auxiliary-functions";
import { dataMask, phoneMask } from "../../../../utilities/masks";
import { AlertMessageEnum, GenderType, NationalityType, PhoneType } from "../../../../utilities/types";
import { isDate, isEmpty } from "../../../../utilities/validators";

interface Props {
    translate: any,
    title: any,
    isEdit: boolean,
    
    peopleId?: any,
    tratament?: any,
    fullname?: any,
    gender?: any,
    birth?: any,
    email?: any,
    norm?: any,
    operator?: any,
    connection?: any,
    sms?: any,
    zap?: any,
    major?: any,
    nationality?: any,
    country?: any,
    naturalness?: any,
    setPeopleId?: any,
    setTratament?: any,
    setFullname?: any,
    setGender?: any,
    setBirth?: any,
    setEmail?: any,
    setNorm?: any,
    setOperator?: any,
    setConnection?: any,
    setSms?: any,
    setZap?: any,
    setMajor?: any,
    setNationality?: any,
    setCountry?: any,
    setNaturalness?: any
}

function PersonalDataRecord(props: Props) {

    const alertContext = useAlertMessageContext();

    const display = props.translate.data;

    async function onBlur_ValidDate(event: any) {
        const { value } = event.target;

        if (!isEmpty(value)) {
            let date = undefined;
            if (value.length === 10)
                date = toDate(value, 'dd/mm/yyyy');
            else
                date = toDate(value, 'dd/mm/yyyy HH:MM:ss');

            if (!isDate(date)) {
                await alertContext.show(AlertMessageEnum.FAIL, 'Dados invalidos', 'Informe uma data válida');
                const birth = document.getElementById("form-birth");
                if (birth !== null) {
                    props.setBirth('');
                    birth.focus();
                }
            }
        }
    }

    return (
        <>
            <Row>
                <Col sm="6">
                    <FormGroup>
                        <Form.Label htmlFor="form-fullname">{display.label.fullname}</Form.Label>
                        <FormControl type="text" id="form-fullname" name="fullname" placeholder={display.example.fullname} required
                            value={toString(props.fullname)}
                            onChange={e => props.setFullname(e.target.value.replace(/[0-9]/gi, ''))}
                        />
                    </FormGroup>
                </Col>
                <Col sm="3">
                    <FormGroup>
                        <Form.Label htmlFor="form-gender">{display.label.gender}</Form.Label>
                        <Form.Select id="form-gender" name="gender" 
                            value={toString(props.gender)}
                            onChange={(e: any) => props.setGender(e.target.value)}
                        >
                            { isEmpty(props.gender) ? (
                                <option value="">...</option>
                            ) : null }
                            { GenderType.map((iterator: any, idx: number) => (<option key={idx} value={iterator.id}>{display.label[toLowerCase(iterator.description)]}</option>)) }
                        </Form.Select>
                    </FormGroup>
                </Col>
                <Col sm="3">
                    <FormGroup>
                        <Form.Label htmlFor="form-birth">{display.label.birth}</Form.Label>
                        <FormControl type="text" id="form-birth" name="birth" maxLength={10} placeholder={display.example.birth} 
                            value={props.birth.split(' ')[0]}
                            onChange={(e: any) => props.setBirth(dataMask(e.target.value))}
                            onBlur={e => onBlur_ValidDate(e)}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm="3">
                    <FormGroup>
                        <Form.Label htmlFor="form-connection">{display.label.telephone}</Form.Label>
                        <FormControl type="text" id="form-connection" name="connection" placeholder={display.example.connection} required  
                            value={toString(props.connection)}
                            onChange={e => props.setConnection(phoneMask(e.target.value))}
                        />
                    </FormGroup>
                </Col>
                <Col sm="2">
                    <FormGroup>
                        <Form.Label htmlFor="form-norm">{display.label.norm}</Form.Label>
                        <Form.Select id="form-norm" name="norm" required
                            value={toString(props.norm)}
                            onChange={e => props.setNorm(e.target.value)}>
                            { isEmpty(props.norm) ? (
                                <option value="">...</option>
                            ) : null }
                            { PhoneType.map((iterator: any, idx: number) => (<option key={idx} value={iterator.id}>{display.label[toLowerCase(iterator.name)]}</option>)) }
                        </Form.Select>
                    </FormGroup>
                </Col>
                <Col sm="2">
                    <FormGroup>
                        <Form.Label htmlFor="form-nationality">{display.label.nationality}</Form.Label>
                        <Form.Select id="form-nationality" name="nationality" 
                            value={toString(props.nationality)}
                            onChange={(e: any) => props.setNationality(e.target.value)}
                            defaultValue="Brasileiro(a)"
                        >
                            { NationalityType.map((iterator: any, idx: number) => (<option key={idx} value={iterator.description}>{iterator.description}</option>)) }
                        </Form.Select>
                    </FormGroup>
                </Col>
                <Col sm="2">
                    <FormGroup>
                        <Form.Label htmlFor="form-country">País</Form.Label>
                        <FormControl type="text" id="form-country" name="country" placeholder={display.example.country} 
                            value={props.nationality === 'Brasileiro(a)' ? 'Brasil' : ''}
                            disabled={props.nationality === 'Brasileiro(a)'}
                            onChange={e => props.setCountry(e.target.value)}
                        />
                    </FormGroup>
                </Col>
                <Col sm="3">
                    <FormGroup>
                        <Form.Label htmlFor="form-naturalness">{display.label.naturalness}</Form.Label>
                        <FormControl type="text" id="form-naturalness" name="naturalness" placeholder={display.example.naturalness} 
                            value={toString(props.naturalness)}
                            onChange={e => props.setNaturalness(e.target.value)}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    );

}

export default PersonalDataRecord;