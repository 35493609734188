import AddIcon from '@mui/icons-material/Add';
import { Button as ButtonMaterial } from '@mui/material';
import ptBR from 'date-fns/locale/pt-BR';
import { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DatePicker from "react-datepicker";
import { BsReceipt } from "react-icons/bs";
import { FaBarcode, FaMoneyBillAlt, FaSpinner } from "react-icons/fa";
import { MdLaunch } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { PickerButtom, VesselOwner } from "../../components/sub-render.component";
import TableContainer from "../../components/table-container.component";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import authService from "../../services/auth.service";
import lclService from "../../services/lcl.service";
import { dateToString, floatToString, leftPad, toDate, toInt, toLowerCase } from "../../utilities/auxiliary-functions";
import { BILLET_MODE_EDIT, BILLET_MODE_NEW, BILLET_MODE_NONE, BILLET_MODE_VIEW, ContractSituationEnum, FILTER_LAUNCHER_BY_EXPIRATION, PermissionEnum } from "../../utilities/constants";
import { cnpjMask, phoneMask, removeFormatDate, zipcodeMask } from "../../utilities/masks";
import { AlertMessageEnum } from "../../utilities/types";
import { isDate, isEmpty } from "../../utilities/validators";
import FinancialHeader from "../headers/financial.header";
import LauncherCadastreModal from "../modals/launcher.cadastre.modal";
import PaymentCadastreModal from "../modals/payment-cadastre.modal";
import DetachedReceiptReport, { TDetachedReceiptReport } from "../reports/detached-receipt.report";
import ContractReceiptReport, { TContractReceiptReport } from "../reports/contract-receipt.report";
import cduService from '../../services/cdu.service';

interface Props {
    translate: any,
    partner: any,

    onClick_ShowDefinedPartner: any
}

function ContractLaunchersFinancial(props: Props) {

    const alertContext = useAlertMessageContext();

    const navigator = useNavigate();
    const display = props.translate.data;

    const title = display.title.contract_launchers;
    const description = '';

    const showFilter = false
    
    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ sending, setSending ] = useState(false);

    const [ itemsToast, setItemsToast ] = useState([] as any);

    const dateStartInicialize = new Date();
    const firstDay = new Date(dateStartInicialize.getFullYear(), dateStartInicialize.getMonth(), 1);

    const dateStopInicialize = new Date();
    const lastDay = new Date(dateStopInicialize.getFullYear(), dateStopInicialize.getMonth() + 1, 0);
 
    const [ dateTimeStart, setDateTimeStart ] = useState(dateToString(firstDay, 'dd/mm/yyyy') + ' 00:00:00');
    const [ dateTimeStop, setDateTimeStop ] = useState(dateToString(lastDay, 'dd/mm/yyyy') + ' 23:59:59');

    const onChange_DateTimeStart = (date: any) => {
        const dateStop = toDate(dateTimeStop, 'dd/mm/yyyy HH:MM:ss');
        if (date > dateStop) {
            setDateTimeStart(dateToString(dateStop, 'dd/mm/yyyy') + ' 00:00:00');
        } else {
            setDateTimeStart(dateToString(date, 'dd/mm/yyyy HH:MM:ss'));
        }
        searchfilter();
    }

    const onChange_DateTimeStop = (date: any) => {
        const dateStart = toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss');
        if (date < dateStart) {
            setDateTimeStop(dateToString(dateStart, 'dd/mm/yyyy') + ' 23:59:59');
        } else {
            setDateTimeStop(dateToString(date, 'dd/mm/yyyy HH:MM:ss'));
        }
        searchfilter();
    }

    const [records, setRecords ] = useState([] as any);
    const columnsRecord = useMemo(() => [{
        Header: 'Pagador',
        accessor: 'ownerName',
        Cell: (row: any) => <VesselOwner translate={props.translate} values={row} />
    },{
        Header: display.label.vessel,
        accessor: 'sourceName'
    },{
        Header: display.label.num_parcel,
        accessor: 'numParcel',
        Cell: (props: any) => {
            const { numParcel, qtyParcels } = props.row.original;

            return (
                <div>{ numParcel } / { qtyParcels }</div>
            );
        }
    },{
        Header: display.label.amount_parcel,
        accessor: 'amountParcel',
        Cell: (row: any) => (
            <div className="white-space">{ floatToString(row.value) }</div>
        )
    },{
        Header: display.label.expiry_parcel,
        accessor: 'expiry',
        Cell: (row: any) => (
            <div className="white-space">{ dateToString(row.value, 'dd/mm/yyyy') }</div>
        )
    },{
        Header: display.label.payment,
        accessor: 'payment',
        Cell: (row: any) => (
            <div className="white-space">{ dateToString(row.value, 'dd/mm/yyyy') }</div>
        )
    },{
        Header: display.label.delayed,
        Cell: (props: any) => {
            const { expiry } = props.row.original;

            const dateExpiry = toDate(dateToString(expiry, 'dd/mm/yyyy'), 'dd/mm/yyyy');
            const dateNow = new Date();

            let numDays = 0;
            if (dateExpiry < dateNow) {
                numDays = Math.trunc(((dateNow.getTime() - dateExpiry.getTime()) / (1000 * 60 * 60 * 24)));
            }
            
            return (
                <div className="white-space text-center">{ numDays }</div>
            );
        }
    },{
        Header: display.label.amount_payment,
        accessor: 'amount',
        Cell: (row: any) => (
            <div className="white-space">{ floatToString(row.value) }</div>
        )
    },{
        Header: display.label.form,
        accessor: 'paymentForm',
        Cell: (row: any) => (
            <div className="white-space">{ display.label[toLowerCase(row.value)] }</div>
        )
    },{
        Header: display.legend.actions_and_receipts,
        accessor: "actions",
        Cell: (props: any) => {
            const launcherRow = props.row.original;

            let icon = <FaBarcode size={22} />
            if (isEmpty(launcherRow.paymentData))
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                icon = <FaMoneyBillAlt size={22} />

            return (
                (toInt(launcherRow.situation) === ContractSituationEnum.ACTIVED) &&
                <Row className="white-space table-actions">
                    <Col sm="4">
                        { isEmpty(launcherRow.paymentForm) ? ( 
                            authService.hasPermission(PermissionEnum.FINANCIAL_LAUNCHER_MAKE_PAYMENT) ? (
                                <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.make_payment}</Tooltip>}>
                                    <span onClick={(e) => onClick_PaymentLauncher(e, launcherRow)}>
                                        <FaMoneyBillAlt size={22} />
                                    </span>
                                </OverlayTrigger>
                            ) : null 
                        ) : null }
                    </Col>
                    <Col sm="4">
                        { isEmpty(launcherRow.paymentForm) ? (
                            !isEmpty(launcherRow.paymentData) ? (
                                authService.hasPermission(PermissionEnum.FINANCIAL_LAUNCHER_TRACK_STATUS) ? ( 
                                    <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.track_status}</Tooltip>}>
                                        <span onClick={(e) => onClick_BilletLauncher(e, launcherRow, BILLET_MODE_VIEW)}>
                                            <FaBarcode size={22} />
                                        </span>
                                    </OverlayTrigger>
                                ) : null
                            ) : (
                                authService.hasPermission(PermissionEnum.FINANCIAL_LAUNCHER_GENERATE_TICKET) ? ( 
                                    <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.generate_ticket}</Tooltip>}>
                                        <span onClick={(e) => onClick_BilletLauncher(e, launcherRow, BILLET_MODE_EDIT)}>
                                            <MdLaunch size={22} />
                                        </span>
                                    </OverlayTrigger>
                                ) : null
                            )
                        ) : null }
                    </Col>
                    <Col sm="4">
                        { !isEmpty(launcherRow.paymentForm) ? (
                            authService.hasPermission(PermissionEnum.FINANCIAL_LAUNCHER_VIEW_RECEIPT) ? ( 
                                <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.view_receipt}</Tooltip>}>
                                    <span onClick={(e) => onClick_ReceiptLauncher(e, launcherRow)}>
                                        <BsReceipt size={22} />
                                    </span>
                                </OverlayTrigger>
                            ) : null 
                        ) : null }
                    </Col>
                </Row>
            );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }], []); // columnsRecord
    
    const filterLauncher = FILTER_LAUNCHER_BY_EXPIRATION;
    const [ launcherId, setLauncherId ] = useState('');
    const [ launcher, setLauncher ] = useState({} as any);
    const [ isEditLauncher, setEditLauncher ] = useState(0);
    const [ showLauncher, setShowLauncher ] = useState(false);
    const [ showPayment, setShowPayment ] = useState(false);

    function onClick_RefreshLauncher(event: any) {
        event.preventDefault();

        setSending(true);
        searchfilter();
    } // onClick_RefreshLauncher
    
    function onClick_AddRecord(event: any) {
        event.preventDefault();

        setEditLauncher(BILLET_MODE_NEW);
        setLauncherId('new');
        setLauncher({} as any);
        setShowLauncher(true);
    } // onClick_AddRecord

    function onClick_PaymentLauncher(event: any, launcher: any) {
        event.preventDefault();

        setLauncherId(launcher.id);
        setLauncher(launcher);
        setShowPayment(true);
    }

    function onClick_BilletLauncher(event: any, launcher: any, modo: number) {
        event.preventDefault();
        
        setEditLauncher(modo);
        setLauncherId(launcher.id);
        setLauncher(launcher);
        setShowLauncher(true);
    } // onClick_EditRecord

    async function onClick_ReceiptLauncher(event: any, launcherRow: any) {
        event.preventDefault();

        let launcher = await lclService.launcherById(launcherRow.id);
        const address = await cduService.addressById(props.partner.company.addressId);
        if (launcher.sourceDocument === "CTT") {
            let receipt: TContractReceiptReport = {
                company: {
                    image: props.partner.company.image,
                    socialReason: props.partner.company.socialReason,
                    fantasy: props.partner.company.fantasy,
                    registrationPj: cnpjMask(props.partner.company.registrationPj),
                    telephone: phoneMask(props.partner.company.telephone),
                    email: props.partner.company.email,
                    address: `${address.place} - ${address.district} - ${address.city} - ${address.state} - ${zipcodeMask(address.zipcode)}`
                },
                paying: launcher.ownerName,
                amount: floatToString(launcher.amount),
                numParcel: launcher.numParcel,
                qtyParcels: launcher.qtyParcels,
                payday: dateToString(new Date(), 'dd/mm/yyyy'),
                numberDocument: launcher.sourceDocument + leftPad(launcher.numberDocument, 5, '0'),
                form: display.label[toLowerCase(launcher.paymentForm)],
                vessels: launcher?.contract?.vessels.map((vessel: any) => {
                    return {
                        enrollment: vessel.enrollment,
                        name: vessel.name
                    }
                })
            };
            ContractReceiptReport(receipt);
        } else {
            let receipt: TDetachedReceiptReport = {
                company: {
                    image: props.partner.company.image,
                    socialReason: props.partner.company.socialReason,
                    fantasy: props.partner.company.fantasy,
                    registrationPj: cnpjMask(props.partner.company.registrationPj),
                    telephone: phoneMask(props.partner.company.telephone),
                    email: props.partner.company.email,
                    address: `${address.place} - ${address.district} - ${address.city} - ${address.state} - ${zipcodeMask(address.zipcode)}`
                },
                paying: launcher.ownerName,
                amount: floatToString(launcher.amount),
                payday: dateToString(new Date(), 'dd/mm/yyyy'),
                numberDocument: launcher.sourceDocument + leftPad(launcher.numberDocument, 5, '0'),
                descriptive : launcher?.descriptive
            };
            DetachedReceiptReport(receipt);
        }
    }

    async function searchfilter() {
        if (props.partner) {
            if (isDate(toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')) && isDate(toDate(dateTimeStop, 'dd/mm/yyyy HH:MM:ss'))) {
                const attributes = [] as any;
                let where = {} as any;
                let order = [] as any;
                if (filterLauncher === FILTER_LAUNCHER_BY_EXPIRATION) {
                    where = { expiry : { start : removeFormatDate(dateTimeStart), end : removeFormatDate(dateTimeStop) } };
                    order = [ [ 'expiry', 'ASC' ] ];
                } else {
                    where = { payment : { start : removeFormatDate(dateTimeStart), end : removeFormatDate(dateTimeStop) } };
                    order = [ [ 'payment', 'ASC' ] ];
                }
                /* Sempre incluir o partner na pesquisa */
                where['partnerId'] = props.partner.id;
                if (authService.isProprietor()) {
                    const user = authService.currentUser();
                    const people = await cduService.peopleByUser(user.id);
                    where['ownerId'] = people.id;
                }

                try {
                    const launchers = await lclService.launcherList(attributes, where, order);
                    setRecords(launchers);
                    setSending(false);
                } catch(error: any) {
                    await alertContext.show(AlertMessageEnum.FAIL, title, error);
                }
                setSending(false);
            } else {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.an_invalid_date_was_reported);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Não foi definido uma Marina para exibir os lançamentos !');
            navigator(`/portal`);
        }
    } // searchfilter

    useEffect(() => {
        if (!isLoading && !reloadPage) {            
            searchfilter();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
    }, []); // useEffect


    function viewPage() {

        return (
            <Container fluid className="page-body movements">
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card style={{ minHeight : 500 }}>
                            <Card.Header>
                                <div className="card-actions d-flex justify-content-between">
                                    <div className="d-flex">
                                        <div className="card-actions-time">
                                            <DatePicker
                                                locale={ptBR}
                                                selected={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                                onChange={(date: any) => onChange_DateTimeStart(date) }
                                                dateFormat="dd/MM/yyyy"
                                                customInput={<PickerButtom />}
                                                selectsStart
                                                startDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                                endDate={toDate(dateTimeStop, 'dd/mm/yyyy HH:MM:ss')}

                                                popperClassName="some-custom-class"
                                                popperPlacement="top-end"
                                                popperModifiers={[
                                                    {
                                                        name : "offset",
                                                        options : {
                                                            offset : [5, 10]
                                                        }
                                                    },{
                                                        name : "preventOverflow",
                                                        options : {
                                                            rootBoundary : "viewport",
                                                            tether : false,
                                                            altAxis : true
                                                        }
                                                    }
                                                ]}
                                            />
                                            <DatePicker
                                                locale={ptBR}
                                                selected={toDate(dateTimeStop, 'dd/mm/yyyy HH:MM:ss')}
                                                onChange={(date: any) => onChange_DateTimeStop(date) }
                                                dateFormat="dd/MM/yyyy"
                                                customInput={<PickerButtom />}
                                                selectsEnd
                                                startDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}
                                                endDate={toDate(dateTimeStop, 'dd/mm/yyyy HH:MM:ss')}
                                                minDate={toDate(dateTimeStart, 'dd/mm/yyyy HH:MM:ss')}

                                                popperClassName="some-custom-class"
                                                popperPlacement="top-end"
                                                popperModifiers={[
                                                    {
                                                        name : "offset",
                                                        options : {
                                                            offset : [5, 10]
                                                        }
                                                    },{
                                                        name : "preventOverflow",
                                                        options : {
                                                            rootBoundary : "viewport",
                                                            altAxis : true
                                                        }
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <Button variant="secondary" className='ms-2' onClick={ (e) => onClick_RefreshLauncher(e) } disabled={sending}>
                                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <FaSpinner size={22} /> } {' '}
                                            {display.buttom.refresh}
                                        </Button>
                                    </div>
                                    {authService.hasPermission(PermissionEnum.FINANCIAL_LAUNCHER_ADD) && 
                                        <ButtonMaterial size='small' variant='contained' onClick={(e) => onClick_AddRecord(e) } ><AddIcon />Cobrança</ButtonMaterial>
                                    }
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <TableContainer columns={columnsRecord} data={records} viewFilter={!showFilter && records.length > 10} viewPagination={true} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewCadastreList

    return (
        <div className="page financial">
            <FinancialHeader title={title} description={description} itemsToast={itemsToast}
                setItemsToast={(items: any) => {
                    setItemsToast(items);
                }} onClick_ShowDefinedPartner={props.onClick_ShowDefinedPartner} />
            { viewPage() }
            <LauncherCadastreModal 
                translate={props.translate} 
                itemsToast={itemsToast}
                setItemsToast={(items: any) => {
                    setItemsToast(items);
                }}
                partner={props.partner}

                isEditMode={isEditLauncher}

                show={showLauncher}
                launcherId={launcherId}
                record={launcher}
                onSubmitModal={async (event: any, record: any) => {
                    event.preventDefault();

                    setLauncher(record);
                    if (isEditLauncher) {
                        const updatedLaunchers = Array.from(records) as any;
                        for ( var idx_Edit = 0; idx_Edit < updatedLaunchers.length; idx_Edit++) {
                            if (updatedLaunchers[idx_Edit].id === record.id) {
                                updatedLaunchers[idx_Edit] = record;
                            }
                        }
                        setRecords(updatedLaunchers);
                    } else {
                        let existLauncher = false;

                        for ( var idx_New = 0; idx_New < records.length; idx_New++) {
                            const launcher = records[idx_New];
                            if (launcher.id === record.id) {
                                existLauncher = true;
                                await alertContext.show(AlertMessageEnum.FAIL, title, display.message.the_informed_record_is_already_linked);
                                break;
                            }
                        }
                        if (!existLauncher)
                            setRecords([...records, record]);
                    }

                    setShowLauncher(false);
                    setEditLauncher(BILLET_MODE_NONE);
                    searchfilter();
                }}
                onCancelModal={(event: any) => {
                    // event.preventDefault();

                    setEditLauncher(BILLET_MODE_NONE);
                    setShowLauncher(false);
                }}
            />
            <PaymentCadastreModal 
                translate={props.translate} 
                partner={props.partner}

                show={showPayment}
                launcherId={launcherId}
                record={launcher}
                onSubmitModal={(event: any, record: any) => {
                    event.preventDefault();

                    setShowPayment(false);
                    searchfilter();
                }}
                onCancelModal={(event: any) => {
                    // event.preventDefault();

                    setShowPayment(false);
                }}
            />
        </div>
    );

}

export default ContractLaunchersFinancial;
