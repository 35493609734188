import { useEffect, useState } from "react";
import { Col, Form, FormControl, FormGroup, Row } from "react-bootstrap";
import addressService from "../../../../services/address.service";
import { toString } from "../../../../utilities/auxiliary-functions";
import { cpfMask, dataMask } from "../../../../utilities/masks";
import { isEmpty } from "../../../../utilities/validators";
import { AlertMessageEnum, CategoriesDriversLicenseType, IssuerIdentityType } from "../../../../utilities/types";
import { useAlertMessageContext } from "../../../../contexts/alert-message.context";

interface Props {
    translate: any,
    title: any,
    isEdit: boolean,

    registrationPf?: any,
    registrationIdentity?: any,
    issuerIdentity?: any,
    stateIdentity?: any,
    expeditionIdentity?: any,
    driversLicense?: any,
    categoryDriversLicense?: any,
    validityDriversLicense?: any,
    setRegistrationPf?: any,
    setRegistrationIdentity?: any,
    setIssuerIdentity?: any,
    setStateIdentity?: any,
    setExpeditionIdentity?: any,
    setDriversLicense?: any,
    setCategoryDriversLicense?: any,
    setValidityDriversLicense?: any
}

function PersonalDocumentRecord(props: Props) {

    const alertContext = useAlertMessageContext();

    const display = props.translate.data;
    
    const [ listStates, setListStates ] = useState([] as any);
   
    async function fillStates() {
        try {
            const states = await addressService.listStates();
            setListStates(states);
        } catch (error: any) {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
        }
    }

    async function initialize() {
        await fillStates();
    }

    useEffect(() => {
        initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Row>
                <Col sm="4">
                    <FormGroup>
                        <Form.Label htmlFor="form-registration_pf">{display.label.registration_pf}</Form.Label>
                        <FormControl type="text" id="form-registration_pf" name="registration_pf" placeholder={display.example.registration_pf} required
                            value={toString(props.registrationPf)}
                            onChange={e => props.setRegistrationPf(cpfMask(e.target.value))}
                            readOnly={props.isEdit}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm="4">
                    <FormGroup>
                        <Form.Label htmlFor="form-registration_identity">{display.label.registration_identity}</Form.Label>
                        <FormControl type="text" id="form-registration_identity" name="registration_identity" placeholder={display.example.registration_identity}
                            value={toString(props.registrationIdentity)}
                            onChange={e => props.setRegistrationIdentity(e.target.value.toUpperCase())}
                        />
                    </FormGroup>
                </Col>
                <Col sm="2">
                    <FormGroup>
                        <Form.Label htmlFor="form-issuer_identity">{display.label.issuer_identity}</Form.Label>
                        <Form.Select id="form-issuer_identity" name="issuer_identity"
                             value={toString(props.issuerIdentity)}
                             onChange={e => props.setIssuerIdentity(e.target.value)}
                        >
                            { isEmpty(props.stateIdentity) ? (
                                <option value="">...</option>
                            ) : null }
                            {  IssuerIdentityType.map((iterator: any, idx: number) => (<option key={idx} value={iterator.name}>{iterator.name}</option>)) }
                        </Form.Select>
                    </FormGroup>
                </Col>
                <Col sm="3">
                    <FormGroup>
                        <Form.Label htmlFor="form-state_identity">{display.label.state}</Form.Label>
                        <Form.Select id="form-state_identity" name="state_identity" 
                            value={toString(props.stateIdentity)}
                            onChange={(e: any) => props.setStateIdentity(e.target.value)}
                        >
                            { isEmpty(props.stateIdentity) ? (
                                <option value="">...</option>
                            ) : null }
                            { listStates.map((iterator: any, idx: number) => (<option key={idx} value={iterator.sigla}>{iterator.nome}</option>))
                                .sort((a : any, b : any) => { 
                                    return a.props.children > b.props.children ? 1 : -1; 
                                }) 
                            }
                        </Form.Select>
                    </FormGroup>
                </Col>
                <Col sm="3">
                    <FormGroup>
                        <Form.Label htmlFor="form-expedition_identity">{display.label.expedition}</Form.Label>
                        <FormControl type="text" id="form-expedition_identity" name="expedition_identity" maxLength={10} placeholder={display.example.expedition}
                            value={toString(props.expeditionIdentity)}
                            onChange={e => props.setExpeditionIdentity(dataMask(e.target.value))}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col sm="5">
                    <FormGroup>
                        <Form.Label htmlFor="form-drivers_license">{display.label.drivers_license}</Form.Label>
                        <FormControl type="text" id="form-drivers_license" name="drivers_license" placeholder={display.example.drivers_license}
                            value={toString(props.driversLicense)}
                            onChange={e => props.setDriversLicense(e.target.value.toUpperCase())}
                        />
                    </FormGroup>
                </Col>
                <Col sm="4">
                    <FormGroup>
                        <Form.Label htmlFor="form-category_drivers_license">{display.label.category_drivers_license}</Form.Label>
                        <Form.Select id="form-category_drivers_license" name="category_drivers_license" 
                            value={toString(props.categoryDriversLicense)}
                            onChange={(e: any) => props.setCategoryDriversLicense(e.target.value)}
                        >
                            { isEmpty(props.stateIdentity) ? (
                                <option value="">...</option>
                            ) : null }
                            { CategoriesDriversLicenseType.map((iterator: any, idx: number) => (<option key={idx} value={iterator.name}>{iterator.name}</option>)) }
                        </Form.Select>
                    </FormGroup>
                </Col>
                <Col sm="3">
                    <FormGroup>
                        <Form.Label htmlFor="form-validity_drivers_license">{display.label.validity_drivers_license}</Form.Label>
                        <FormControl type="text" id="form-validity_drivers_license" name="validity_drivers_license"  maxLength={10} placeholder={display.example.validity_drivers_license}
                            value={toString(props.validityDriversLicense)}
                            onChange={e => props.setValidityDriversLicense(dataMask(e.target.value))}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
}

export default PersonalDocumentRecord;