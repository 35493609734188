import { useContext, useEffect, useState } from "react";
import { Container, Image } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Logo from '../assets/logoname_high_resolution.png';
import ImageLightBox from "../components/image-lightbox/ImageLightbox";
import { TranslateContext } from "../contexts/translate.context";
import lclService from "../services/lcl.service";
import { CategoriesNavalType, getColorDefault, getFuel, getHarbor, getMaker } from "../utilities/types";
import styles from './card.page.module.scss';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import GradingIcon from '@mui/icons-material/Grading';
import QRCode from "react-qr-code";
import { Button, IconButton, InputAdornment, TextField, ThemeProvider, createTheme } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import authService from "../services/auth.service";
import { arrival, dateToString, toDate, toInt, toLowerCase } from "../utilities/auxiliary-functions";
import { cnpjMask, cpfCnpjMask, phoneMask, removeFormatDate, zipcodeMask } from "../utilities/masks";
import { isEmpty } from "../utilities/validators";
import PlanMovementReport, { TPlanMovementReport } from "./reports/plan-movement.report";
import marcadguant from '../assets/marcadguant.png';
import { Button as ButtonMaterial } from '@mui/material'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import NearMeIcon from '@mui/icons-material/NearMe';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { MOVE_DOWN, MOVE_UP, MOVE_WAIT } from "../utilities/constants";
import cduService from "../services/cdu.service";

function CardPage() {
    
    let { enrollment } = useParams();
    const { enqueueSnackbar } = useSnackbar()

    const { translate } = useContext(TranslateContext) as any;
    const display = translate.data;
    const windowQrcode = `${window.location.origin}/card/vessel/${enrollment}`;

    const [ signinForm, setSigininForm ] = useState({
        user: '',
        password: ''
    });
    const [ loggingIn, setLoggingIn ] = useState(false);
    const [ showPassword, setShowPassword ] = useState(false);
    const [ showCompleteData, setShowCompleteData ] = useState(false);
    const [ expandedProperty, setExpandedProperty ] = useState(false);
    const [ expandedPropulsion, setExpandedPropulsion ] = useState(false);
    const [ expandedPlans, setExpandedPlans ] = useState(false);
    const [ record, setRecord ] = useState({} as any);
    const [ move, setMove ] = useState(MOVE_WAIT);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    }

    const login = async (e: { preventDefault: () => void }) => {
        e.preventDefault();
        const { user, password } = signinForm;

        setLoggingIn(true);
        if (user && password) {
            try {
                const response = await authService.login(user, password);
                if (response && response?.situation !== 1) {
                    setShowCompleteData(true);
                    searchFilter(true);
                } 
            } catch (error: any) {
                enqueueSnackbar(error?.message, { variant: 'error'});
                setLoggingIn(false)
            }
        }
    };

    const theme = createTheme({
        palette: {
            primary: {
                main: '#3366FF'
            },
            secondary: {
                main: '#4E5F78'
            },
            error: {
                main: '#D32E2E'
            },
            warning: {
                main: '#FFC000'
            },
            mode: 'dark'
        },
        typography: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            button: {
                textTransform: 'none'
            }
        },
    });

    const searchFilter = async (force: boolean) => {
        let isCompleData = force || showCompleteData;
        try {
            if (!isEmpty(enrollment)) {
                const card = await lclService.vesselInCard(enrollment ||'', isCompleData);
console.log(card)
                if (!isEmpty(card)) {
                    setRecord(card);
                    setMove(card?.movement?.move);
                } else {
                    enqueueSnackbar('Embarcação não encontrada', { variant: 'error'});
                    setLoggingIn(false)  
                }
            } else {
                enqueueSnackbar('Registro da embacação não informado', { variant: 'error'});
                setLoggingIn(false)  
            }
        } catch (error: any) {
            enqueueSnackbar(error?.message, { variant: 'error'});
            setLoggingIn(false)  
        }
    } // initialize

    useEffect(() => {
        searchFilter(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     return () => {
    //         // eslint-disable-next-line react-hooks/exhaustive-deps
    //         reloadPage = !reloadPage;
    //     }
    // }, []);

    async function onClick_Movement(event: any, movementId: number) {
        event.preventDefault();

        const currentMovement = await lclService.movementVesselById(movementId);
        const currentPartner = await lclService.partnerById(record.partner.id);
        const address = await cduService.addressById(currentPartner.company.addressId);
        
        const planMovement: TPlanMovementReport = {
            company: {
                image: currentPartner.company.image,
                socialReason: currentPartner.company.socialReason,
                fantasy: currentPartner.company.fantasy,
                registrationPj: cnpjMask(currentPartner.company.registrationPj),
                telephone: phoneMask(currentPartner.company.telephone),
                email: currentPartner.company.email,
                address: `${address.place} - ${address.district} - ${address.city} - ${address.state} - ${zipcodeMask(address.zipcode)}`
            },
            movement: {
                departure: dateToString(currentMovement.departure, 'dd/mm/yyyy'),
                estimated: dateToString(currentMovement.departure, 'HH:MM'),
                arrival: dateToString(toDate(removeFormatDate(arrival(currentMovement.departure, currentMovement.estimated)), 'yyyy-mm-ddTHH:MM:ss.sssZ'), 'HH:MM'),
                navigationPlan: currentMovement.navigationPlan
            },
            vessels: [{
                identifyKey: currentMovement.vessel.identifyKey,
                enrollment: currentMovement.vessel.enrollment,
                name: currentMovement.vessel.name,
                color: display.label[toLowerCase(getColorDefault(currentMovement.vessel.color).name)],
                brand: currentMovement.vessel.brand,
                model: currentMovement.vessel.model
            }],
            captain: {
                name: currentMovement?.crews[0]?.conductor?.people?.fullname,
                enrollment: currentMovement?.crews[0]?.conductor?.people?.documentsNautical[0]?.enrollment,
                expedition: dateToString(currentMovement?.crews[0]?.conductor?.people?.documentsNautical[0]?.expedition, 'dd/mm/yyyy'),
                validity: dateToString(currentMovement?.crews[0]?.conductor?.people?.documentsNautical[0]?.validity, 'dd/mm/yyyy'),
                limits: currentMovement?.crews[0]?.conductor?.people?.documentsNautical[0]?.limits
            },
            passengers: currentMovement.passengers.map((passenger: any) => {
                return {
                    name: passenger.people.fullname
                }
            }),
            payday: dateToString(new Date(), 'dd/mm/yyyy'),
        }
        PlanMovementReport(planMovement);
    }

    function viewCategories(category: string) {
        let names = '';
        if (!isEmpty(category)) {
            const categories = category.split(';');
            for (let i = 0; i < categories.length; i++) {
                // eslint-disable-next-line array-callback-return, no-loop-func
                CategoriesNavalType.map((iterator: any) => {
                    if (toInt(categories[i]) === iterator.id) {
                        names += (isEmpty(names) ? '' : ' - ') + display.label[toLowerCase(iterator.initial)];
                    }
                })
            }
        }
        return names;
    }

    const expandProperty = () => {
        setExpandedProperty(!expandedProperty)
    }

    const expandPropulsion = () => {
        setExpandedPropulsion(!expandedPropulsion)
    }
    const expandPlans = () => {
        setExpandedPlans(!expandedPlans)
    }

    async function onClick_Move(event: any, move: number) {
        event.preventDefault();
        setMove(move);
        await lclService.movementMove(record.movement.id, { move });
    }

    function onClick_Url(event: any, url: string) {
        if (!isEmpty(url))
            window.open(url);
    }

    function viewKeep() {

        return (
            <div className="card-drawer mb-2" style={{ backgroundColor: record.keep?.colorName }}>
                <div className="card-drawer-title">{record.keep?.bookcaseName}</div>
                <div className="card-drawer-group">{record.keep?.shelfName}</div>
                <div className="card-drawer-number">{record.keep?.drawerName}</div>
            </div>
        );
    }

    function viewPage () {
        return (
            <Container className={`cardboard ${styles.container}`}>
                <section className={styles.logoWrapper}>
                    <Image src={Logo} className="cardboard-image" />
                    <div className=" w-100 d-flex justify-content-between align-items-center">
                        <ImageLightBox
                            containerStyle={styles.imageWrapper}
                            src={record.vessel?.image || marcadguant}
                            alt="foto da embarcação"
                            rounded={true}
                        />
                        <div className="d-flex flex-column align-items-center">
                            <h3 className="mt-3">{record.vessel?.name}</h3>
                            <p className={`mb-0 ${styles.enrollment}`}>REG: {record.vessel?.enrollment}</p>
                            <p className={styles.enrollment}>ID: {record.vessel?.identifyKey}</p>
                        </div>
                        <div className="d-flex flex-column align-items-start ms-1">
                            <div className={styles.vesselName}>
                                <div className="d-flex justify-content-center flex-column">
                                    {record.vessel && record.vessel?.indAlert !== 0 && (
                                        <div className={`${styles.theftIndicator}`}>
                                            <p>Alerta de Roubo</p>
                                        </div>
                                    )}
                                    {record.vessel && record.vessel?.indRestriction !== 0 && (
                                        <div className={styles.financialIndicator}>
                                            <p>Restrição Financeira</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className={styles.vesselInfo}>
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className='w-100 d-flex justify-content-between'>Dados da Embarcação
                        <GradingIcon className="isClickable"/>
                        </h4>
                    </div>
                    <section className="d-flex justify-content-between mt-2">
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-column">
                                <p>Marca</p>
                                <h5>{record.vessel?.brand || ' -- '}</h5>
                            </div>
                            {showCompleteData && (
                                <div className="d-flex flex-column mt-2">
                                    <p>UF</p>
                                    <h5>{getHarbor(record.vessel?.harbor).uf}</h5>
                                </div>
                            )}
                            <div className="d-flex flex-column mt-2">
                                <p>Validade</p>
                                <h5>{dateToString(record.vessel?.validityTie, 'dd/mm/yyyy')}</h5>
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-column">
                                <p>Modelo</p>
                                <h5>{record.vessel?.model || ' -- '}</h5>
                            </div>
                            {showCompleteData && (
                                <div className="d-flex flex-column mt-2">
                                    <p>DPC</p>
                                    <h5>{getHarbor(record.vessel?.harbor).initials}</h5>
                                </div>
                            )}
                            <div className="d-flex flex-column mt-2">
                                <p>Potência (HP)</p>
                                <h5>{record.vessel?.potency}</h5>
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <div className="d-flex flex-column">
                                <p>Ano</p>
                                <h5>{record.vessel?.year}</h5>
                            </div>
                            {showCompleteData && (
                                <div className="d-flex flex-column mt-2">
                                    <p>TIE</p>
                                    <h5>{record.vessel?.numberTie}</h5>
                                </div>
                            )}
                            <div className="d-flex flex-column mt-2">
                                <p>Combustível</p>
                                <h5>{getFuel(record.vessel?.fuel).description}</h5>
                            </div>
                        </div>
                    </section>
                </section>
                    <section className={styles.vesselInfo}>
                        <div className={`d-flex justify-content-between ${styles.header}`}>
                            <div></div>
                            <h4 className={`text-center ${showCompleteData ? 'ms-4' : 'ms-1'}`}>Propulsores
                            </h4>
                            {showCompleteData && (
                                <KeyboardArrowDownIcon
                                    onClick={expandPropulsion}
                                    className={`${styles.arrow} ${
                                        expandedPropulsion ? styles.rotate : ''
                                    }`}
                                />

                            )}
                            {!showCompleteData && (
                                <div></div>

                            )}

                        </div>
                        {showCompleteData && expandedPropulsion && (
                        <section className={`d-flex flex-column mt-2 ${expandedPropulsion ? styles.fadeIn : styles.fadeOut}`}>
                            {record.engines && record.engines.map((engine: any, index: number) => {
                            return (
                                <div key={`engine-${index}`} className="d-flex justify-content-between">
                                    <div className="d-flex flex-column">
                                        <p>Número</p>
                                        <h5>{engine.serialNumber}</h5>
                                    </div>
                                    <div className="d-flex flex-column mt-2">
                                        <p>Fabricante</p>
                                        <h5>{getMaker(engine.maker).description}</h5>
                                    </div>
                                    <div className="d-flex flex-column mt-2">
                                        <p>Ano</p>
                                        <h5>{engine.year}</h5>
                                    </div>
                                </div>
                            );
                            })}
                        </section>
                        )}
                    </section>
                    {showCompleteData && (
                        <section className={`${styles.vesselInfo} pb-2`}>
                            <h4>Movimentação</h4>
                            {!isEmpty(record.movement) && (
                                <div className={`d-flex justify-content-between align-items-center ${styles.controls}`}>
                                    <div className="d-flex flex-column align-items-center">
                                    {(move === MOVE_WAIT || move === MOVE_UP) ? (
                                        <KeyboardDoubleArrowDownIcon color="success" className="isClickable" onClick={e => { onClick_Move(e, MOVE_DOWN) }} />
                                    ) : (
                                        <KeyboardDoubleArrowDownIcon color="success" className="isClickable" onClick={e => { onClick_Move(e, MOVE_WAIT) }}
                                            style={{ filter: 'invert(80%) sepia(100%) saturate(2%) hue-rotate(234deg) brightness(200%) contrast(11%)'}}
                                        />
                                    ) }
                                        <h5>DESCIDA</h5>
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                        <h5>GARAGEM</h5>
                                        {viewKeep()}
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                    {(move === MOVE_WAIT || move === MOVE_DOWN) ? (
                                        <KeyboardDoubleArrowUpIcon color="error" className="isClicable" onClick={e => { onClick_Move(e, MOVE_UP) }}/>
                                    ) : (
                                        <KeyboardDoubleArrowUpIcon color="error" className="isClicable" onClick={e => { onClick_Move(e, MOVE_WAIT) }}
                                            style={{ filter: 'invert(80%) sepia(100%) saturate(2%) hue-rotate(234deg) brightness(200%) contrast(11%)'}}
                                        />
                                    )}
                                        <h5>SUBIDA</h5>
                                    </div>
                                </div>
                            )}
                        </section>
                    )}
                    <section className={styles.vesselInfo}>
                        <div className={`d-flex justify-content-between ${styles.header}`}>
                            <div></div>
                            <h4 className={`text-center ${showCompleteData ? 'ms-4' : 'ms-1'}`}>Propriedade
                            </h4>
                            {showCompleteData && (
                                <KeyboardArrowDownIcon
                                    onClick={expandProperty}
                                    className={`${styles.arrow} ${
                                    expandedProperty ? styles.rotate : ''
                                    }`}
                                />

                            )}
                            {!showCompleteData && (
                                <div></div>

                            )}

                        </div>
                {showCompleteData && expandedProperty && (
                    <>
                    {record.responsibles && record.responsibles.map((responsible: any, index: number) => {
                            return (
                        <section key={`responsible-${index}`} className={`d-flex flex-column mt-2 ${styles.owners}`}>
                            <div className="d-flex justify-content-between">
                                <h5>{responsible.name}</h5>
                                <h5>{cpfCnpjMask(responsible.registration)}</h5>
                            </div>

                            <div className="d-flex justify-content-between mt-2">
                                <div className="d-flex flex-column">
                                    <p>Habilitação</p>
                                    <h5><FingerprintIcon className="isClickable mb-1"/>{responsible.enrollment}</h5>
                                </div>
                                <div className="d-flex flex-column">
                                    <p>Categoria</p>
                                    <h5>{viewCategories(responsible.category)}</h5>
                                </div>
                                <div className="d-flex flex-column">
                                    <p>Validade</p>
                                    <h5>{dateToString(responsible.validity, 'dd/mm/yyyy')}</h5>
                                </div>
                            </div>
                        </section>
                            );
                        })}
                        </>
                        )}
                    </section>

                    <section className={styles.vesselInfo}>
                        <div className={`d-flex justify-content-between ${styles.header}`}>
                            <div></div>
                            <h4 className={`text-center ${showCompleteData ? 'ms-4' : 'ms-1'}`}>Planos de Navegação
                            </h4>
                            {showCompleteData && (
                                <KeyboardArrowDownIcon
                                    onClick={expandPlans}
                                    className={`${styles.arrow} ${
                                    expandedPlans? styles.rotate : ''
                                    }`}
                                />

                            )}
                            {!showCompleteData && (
                                <div></div>

                            )}

                        </div>
                {showCompleteData && expandedPlans && (
                        <section className="d-flex flex-column mt-2">
                            <div className="d-flex justify-content-between align-items-center mt-2">
                            {record.navigations && record.navigations.map((navigation: any, index: number) => {
                            return (
                                <h5 key={`h5-${index}`}><PendingActionsIcon className="isClickable mb-1" onClick={e => onClick_Movement(e, navigation.id)}/> {dateToString(navigation.dateMovement, 'dd/mm/yyyy')}</h5>
                            );
                            })}
                            </div>
                        </section>
                )}
                    </section>
                    <section className={styles.vesselInfo}>
                        <h4>Marina</h4>
                        {showCompleteData && (
                            <div className="p-2">
                                <div className="d-flex flex-column w-100">
                                    <h5 style={{textAlign: 'center' }}>{record.partner?.fantasy}</h5>
                                    <p>Latitude: {record.partner?.latitude} | Longitude: {record.partner?.longitude}</p>
                                </div>
                                <div className="d-flex justify-content-between w-100 p-2">
                                    <ButtonMaterial variant="contained" color='primary' size="small"
                                        sx={{minWidth: 106,  maxWidth: 106}}
                                    >
                                        <a href={`tel:${record.partner?.telephone}`}>
                                            Chamar
                                        </a>
                                        <PhoneInTalkIcon className="ms-2" />
                                    </ButtonMaterial>
                                    <ButtonMaterial variant="contained" color='success' size="small"
                                        sx={{minWidth: 106,  maxWidth: 106}} 
                                        onClick={e => onClick_Url(e, `https://api.whatsapp.com/send?phone=55${record.partner?.whatsapp}`)}
                                    >
                                        WhatsApp
                                        <WhatsAppIcon className="ms-2" />
                                    </ButtonMaterial>
                                    <ButtonMaterial variant="contained" color='primary' size="small"
                                        sx={{minWidth: 106,  maxWidth: 106}} 
                                        onClick={e => onClick_Url(e, record.partner?.url)}
                                    >
                                        Direção
                                        <NearMeIcon className="ms-2" />
                                    </ButtonMaterial>
                                </div>
                            </div>
                        )}
                    </section>
                    {!showCompleteData && (
                    <section className="ms-4 me-4">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex w-100 justify-content-center align-items-center mb-4">
                                <QRCode bgColor={'#303A4A'} fgColor={'#FFFFFF'} value={windowQrcode} size={100} />
                            </div>
                            <ThemeProvider theme={theme}>

                                <form className="login-form mb-5" autoComplete="off">
                                    <TextField
                                    color="primary"
                                    fullWidth
                                    label="E-mail"
                                    variant="standard"
                                    className="mb-2"
                                    value={signinForm.user}
                                    onChange={e =>
                                        setSigininForm({
                                            ...signinForm,
                                            user: e.target.value
                                        })
                                    }
                                    />

                                    <TextField
                                    color="primary"
                                    fullWidth
                                    label="Senha"
                                    variant="standard"
                                    type={showPassword ? 'text' : 'password'}
                                    value={signinForm.password}
                                    onChange={e =>
                                        setSigininForm({
                                            ...signinForm,
                                            password: e.target.value
                                        })
                                    }
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                        )
                                    }}
                                    />

                                    <div className="CTA">
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={`${styles.submit} mt-4`}
                                        onClick={login}
                                        size="large"
                                        disabled={
                                        loggingIn ||
                                        signinForm.user === '' ||
                                        signinForm.password === ''
                                        }
                                    >
                                        {loggingIn ? 'Aguarde...' : 'Entrar'}
                                    </Button>
                                    
                                    </div>
                                </form>
                        </ThemeProvider>
                        </div>
                    </section>
                )}
                
            </Container>
        );
     } // viewPage

    return (
        <div className="page">
            { viewPage() }
        </div>
    )

}

export default CardPage;
