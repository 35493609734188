import { useEffect, useState } from "react";
import { Col, Form, FormControl, FormGroup, InputGroup, Modal, Row, Spinner } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import cduService from "../../services/cdu.service";
import { dateToString, notNullToDate, toString } from "../../utilities/auxiliary-functions";
import { cpfMask, numberMask, phoneMask, removeFormatDate, removeMask, zipcodeMask } from "../../utilities/masks";
import { isCpf, isEmpty } from "../../utilities/validators";
import { FaTimes } from "react-icons/fa";
import { useAlertMessageContext } from "../../contexts/alert-message.context";
import { useConfirmMessageContext } from "../../contexts/confirm-message.context";
import PersonalDataRecord from "../cadastres/forms/records/personal-data.record";
import PersonalDocumentRecord from "../cadastres/forms/records/personal-document.record";
import srvService from "../../services/srv.service";
import { AlertMessageEnum } from "../../utilities/types";
import { Button } from '@mui/material'


interface Props {
    translate: any,

    isEdit: boolean,
    show: boolean,
    peopleId: any,
    record: any,

    registration?: any,

    onSubmitModal: any,
    onCancelModal: any
}

function PeopleModal( props : Props ) {
    
    const alertContext = useAlertMessageContext();
    const confirmContext = useConfirmMessageContext();

    const display = props.translate.data;
    const title : string = 'Dados da Pessoa Fisíca';

    const [ invalidatedForm, setInvalidatedForm ] = useState(false);
    const [ sending, setSending ] = useState(false);

    // People
    const [ peopleId, setPeopleId ] = useState(0);
    const [ created, setCreated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));
    const [ updated, setUpdated ] = useState(dateToString(new Date(), 'dd/mm/yyyy HH:MM:ss'));

    // PersonalData
    const [ tratament, setTratament ] = useState(''); 
    const [ fullname, setFullname ] = useState(''); 
    const [ gender, setGender ] = useState('');
    const [ birth, setBirth ] = useState('');

    // Phone
    const [ phoneId, setPhoneId ] = useState(0);
    const [ norm, setNorm ] = useState('');
    const [ operator, setOperator ] = useState('');
    const [ connection, setConnection ] = useState('');
    const [ sms, setSms ] = useState(0);
    const [ zap, setZap ] = useState(0);
    const [ major, setMajor ] = useState(0);

    const [ nationality, setNationality ] = useState('');
    const [ naturalness, setNaturalness ] = useState('');

    // PersonalDocument
    const [ registrationPf, setRegistrationPf ] = useState('');
    const [ registrationIdentity, setRegistrationIdentity ] = useState('');
    const [ issuerIdentity, setIssuerIdentity ] = useState('');
    const [ stateIdentity, setStateIdentity ] = useState('');
    const [ expeditionIdentity, setExpeditionIdentity ] = useState('');
    const [ driversLicense, setDriversLicense ] = useState('');
    const [ categoryDriversLicense, setCategoryDriversLicense ] = useState('');
    const [ validityDriversLicense, setValidityDriversLicense ] = useState('');

    // Address
    const [ addressId, setAddressId ] = useState(0);
    const [ zipcode, setZipcode ] = useState('');
    const [ state, setState ] = useState('');
    const [ city, setCity ] = useState('');
    const [ district, setDistrict ] = useState('');
    const [ place, setPlace ] = useState('');
    const [ habitation, setHabitation ] = useState('');
    const [ complement, setComplement ] = useState('');

    function fillRecord(people: any) {

        if (!isEmpty(people)) {
            setPeopleId(people.id);
            setCreated(notNullToDate(people.created, 'dd/mm/yyyy HH:MM:ss'));  
            setUpdated(notNullToDate(people.updated, 'dd/mm/yyyy HH:MM:ss'));  

            setTratament(people.tratament);
            setFullname(people.fullname);
            setGender(people.gender);
            setBirth(notNullToDate(people.birth, 'dd/mm/yyyy HH:MM:ss'));
            if (!isEmpty(people.phones)) {
                const phone = people.phones[0];

                setPhoneId(phone.id);
                setNorm(phone.norm);
                setOperator(phone.operator);
                setConnection(phoneMask(phone.connection));
                setSms(phone.sms);
                setZap(phone.zap);
                setMajor(phone.major);
            }
            setNationality(people.nationality);
            setNaturalness(people.naturalness);

            setRegistrationPf(cpfMask(people.registrationPf));
            setRegistrationIdentity(numberMask(people.registrationIdentity));
            setIssuerIdentity(people.issuerIdentity);
            setStateIdentity(people.stateIdentity);
            setExpeditionIdentity(notNullToDate(people.expeditionIdentity, 'dd/mm/yyyy'));
            setDriversLicense(numberMask(people.driversLicense));
            setCategoryDriversLicense(people.categoryDriversLicense);
            setValidityDriversLicense(notNullToDate(people.validityDriversLicense, 'dd/mm/yyyy'));
            
            if (!isEmpty(people.adresses)) {
                const addressPeople = people.adresses[0];

                setAddressId(addressPeople.addressId);
                setZipcode(zipcodeMask(addressPeople.address.zipcode));
                setState(addressPeople.address.state);
                setCity(addressPeople.address.city);
                setDistrict(addressPeople.address.district);
                setPlace(addressPeople.address.place);

                setHabitation(addressPeople.habitation);
                setComplement(addressPeople.complement);
            }

        }
    } // fillRecord

    async function isValidForm() {
        let result = true;
        let emptyRequired = false;

        if (isEmpty(fullname))
            emptyRequired = true;
        if (isEmpty(registrationPf))
            emptyRequired = true;
        if ((!isEmpty(registrationPf)) && (!isCpf(registrationPf))) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.registration_pf);
            result = false;
        }

        if (emptyRequired) {
            await alertContext.show(AlertMessageEnum.FAIL, title, display.message.invalid.required);
            result = false;
        }

        setInvalidatedForm(!result);
        return result;
    } // isValidForm

    async function onClick_Confirm(event : any) {
        event.preventDefault();
        
        const isValid = await isValidForm();
        if (isValid) {
            try {
                const isConfirmed = await confirmContext.show(title, display.message.confirm_the_data_entered);
                if (isConfirmed) {
                    setSending(true);
                
                    const people = fillPeople();
                    let returned = null;
                    if (people.id > 0)
                        returned = await cduService.savePeople(people.id, people);
                    else
                        returned = await cduService.createPeople(people);
                    people.id = returned.id;
                    setSending(false);
                    await alertContext.show(AlertMessageEnum.SUCCESS, title, display.message.the_record_has_been_saved_successfully);
                    props.onSubmitModal(event, people);
                }
            } catch (error: any) {
                setSending(false);
                await alertContext.show(AlertMessageEnum.FAIL, title, error);
            }
        }
    }

    async function onClick_Cancel(event : any) {
        event.preventDefault();

        setInvalidatedForm(false);
        props.onCancelModal(event);
    }

    function clearForm() {
        setPeopleId(0);
        setCreated(notNullToDate(new Date(), 'dd/mm/yyyy HH:MM:ss'));  
        setUpdated(notNullToDate(new Date(), 'dd/mm/yyyy HH:MM:ss'));  

        setTratament('');
        setFullname('');
        setGender('');
        setBirth('');
    
        // Phone
        setPhoneId(0);
        setNorm('');
        setOperator('');
        setConnection('');
        setSms(0);
        setZap(0);
        setMajor(0);
    
        setNationality('');
        setNaturalness('');
    
        // PersonalDocument
        setRegistrationPf(props.registration);
        setRegistrationIdentity('');
        setIssuerIdentity('');
        setStateIdentity('');
        setExpeditionIdentity('');
        setDriversLicense('');
        setCategoryDriversLicense('');
        setValidityDriversLicense('');

        // Address
        setAddressId(0);
        setZipcode('');
        setState('');
        setCity('');
        setDistrict('');
        setPlace('');
        setHabitation('');
        setComplement('');
    }

    function fillPeople() {

        const adresses = [];
        adresses[0] = {
            peopleId : peopleId,
            addressId : addressId,
            address : {
                id : addressId,
                zipcode : removeMask(zipcode),
                state : state,
                city : city,
                district : district,
                place : place
            },
            correspondence : 1,
            norm : 'residential',
            habitation,
            complement
        };

        const phones = [];
        phones[0] = {
            id : phoneId,
            norm, 
            operator,
            connection : removeMask(connection),
            sms,
            zap,
            major
        };

        const dataPeople = {
            id : peopleId,
            created : removeFormatDate(created), 
            updated : removeFormatDate(updated),
            tratament,
            fullname, 
            gender, 
            birth : removeFormatDate(birth), 
            phones, 
            nationality, 
            naturalness, 
            registrationPf : removeMask(registrationPf),
            registrationIdentity : removeMask(registrationIdentity),
            issuerIdentity,
            stateIdentity,
            expeditionIdentity : removeFormatDate(expeditionIdentity), 
            driversLicense : removeMask(driversLicense),
            categoryDriversLicense,
            validityDriversLicense : removeFormatDate(validityDriversLicense), 
            document,
            adresses
        };

        return dataPeople;
    }

    async function onClick_SearchPeople(event: any) {
        event.preventDefault();

        if (!isEmpty(registrationPf)) {
            const registration = removeMask(registrationPf);
            if (isCpf(registration)) {
                const people = await cduService.peopleByRegistration(registration);
                fillRecord(people);

                if (!isEmpty(people)) {
                    const attributes = [] as any;
                    const where = { ownerType : 'PF', ownerId : people.id };
                    const order = [] as any;
                    const purveyors = await srvService.purveyorFilter(attributes, where, order);
                    if (purveyors.length > 0) {
                        await alertContext.show(AlertMessageEnum.SUCCESS, title, 'CPF já cadastrado.');

                        setInvalidatedForm(false);
                        props.onCancelModal(event);
                    }
                } else 
                    await alertContext.show(AlertMessageEnum.SUCCESS, title, 'CPF não localizado. Efetue o cadastro completo.');

                } else {
                await alertContext.show(AlertMessageEnum.FAIL, title, 'CPF Inválido !');
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, title, 'Insira um CPF válido !');
        }
    }

    async function searchFilter() {
        clearForm();
        fillRecord(props.record);
    }

    useEffect(() => {
        if (props.show)
            searchFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    /* */

    function viewForm() {
        return (
            <>
                <Modal id="modal-cadastre-launcher-form" show={props.show}
                    size="lg"
                    aria-labelledby="modal-cadastre-launcher-form"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title >{title}</Modal.Title>
                        <div className="float-right d-flex justify-content-end">
                            <FaTimes className="isClickable" onClick={ onClick_Cancel } size={26}/>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Form className="form" id="form-cadastre-launcher" validated={invalidatedForm} style={{ minHeight : 300 }}>
                            { !props.isEdit && <>
                                <Row>
                                    <Col sm="5">
                                        <FormGroup>
                                            <Form.Label htmlFor="form-registration_pf">{display.label.registration_pf}</Form.Label>
                                            <InputGroup className="mb-2">
                                                <FormControl type="text" id="form-registration_pf" name="registration_pf" placeholder={'Digite o CPF'} required readOnly={props.isEdit}
                                                    value={toString(registrationPf)}
                                                    onChange={e => setRegistrationPf(cpfMask(e.target.value))}
                                                />
                                                <InputGroup.Text>
                                                    <a href="/#" onClick={onClick_SearchPeople}>
                                                        <FaSearch />
                                                    </a>
                                                </InputGroup.Text>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                </Row> 
                                <Row><Col><hr /></Col></Row>
                            </>}
                            <Row>
                                <Col sm="12">
                                    <fieldset>
                                        <legend>{display.legend.general_data}</legend>
                                        <PersonalDataRecord
                                            translate={props.translate}
                                            title={title}
                                            isEdit={props.isEdit}

                                            tratament={tratament}
                                            fullname={fullname}
                                            gender={gender}
                                            birth={birth}
                                            norm={norm}
                                            operator={operator}
                                            connection={connection}
                                            sms={sms}
                                            zap={zap}
                                            major={major}
                                            nationality={nationality}
                                            naturalness={naturalness}
                                            setTratament={(value: any) => { setTratament(value); }}  
                                            setFullname={(value: any) => { setFullname(value); }}  
                                            setGender={(value: any) => { setGender(value); }}  
                                            setBirth={(value: any) => { setBirth(value); }}  
                                            setNorm={(value: any) => { setNorm(value); }}
                                            setOperator={(value: any) => { setOperator(value); }}
                                            setConnection={(value: any) => { setConnection(value); }}
                                            setSms={(value: any) => { setSms(value); }}
                                            setZap={(value: any) => { setZap(value); }}
                                            setMajor={(value: any) => { setMajor(value); }}
                                            setNationality={(value: any) => { setNationality(value); }} 
                                            setNaturalness={(value: any) => { setNaturalness(value); }} 
                                            />
                                    </fieldset>
                                    <fieldset>
                                        <legend>{display.legend.documentation}</legend>
                                        <PersonalDocumentRecord
                                            translate={props.translate}
                                            title={title}
                                            isEdit={props.isEdit}

                                            registrationPf={registrationPf}
                                            registrationIdentity={registrationIdentity}
                                            issuerIdentity={issuerIdentity}
                                            stateIdentity={stateIdentity}
                                            expeditionIdentity={expeditionIdentity}
                                            driversLicense={driversLicense}
                                            categoryDriversLicense={categoryDriversLicense}
                                            validityDriversLicense={validityDriversLicense}
                                            setRegistrationPf={(value: any) => { setRegistrationPf(value); }}  
                                            setRegistrationIdentity={(value: any) => { setRegistrationIdentity(value); }}  
                                            setIssuerIdentity={(value: any) => { setIssuerIdentity(value); }}  
                                            setStateIdentity={(value: any) => { setStateIdentity(value); }}  
                                            setExpeditionIdentity={(value: any) => { setExpeditionIdentity(value); }}  
                                            setDriversLicense={(value: any) => { setDriversLicense(value); }}  
                                            setCategoryDriversLicense={(value: any) => { setCategoryDriversLicense(value); }} 
                                            setValidityDriversLicense={(value: any) => { setValidityDriversLicense(value); }} 
                                        />
                                    </fieldset>

                                </Col>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={ onClick_Cancel } color="secondary" variant="contained">{display.buttom.cancel}</Button>
                        <Button variant="contained" color="primary" onClick={ onClick_Confirm } disabled={sending}>
                            { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null }{'  '}
                            { display.buttom.save }
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    return (
        <>
        { viewForm() }
        </>
    )
}

export default PeopleModal
