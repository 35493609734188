import { Link } from "react-router-dom";

interface Props {
}

function PartnerPage(props: Props) {

    return (
        <>
            <div>Partners</div>
            <Link to="/">retornar a página inicial</Link>
        </>
    )
}

export default PartnerPage;
