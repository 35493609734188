import { createTheme } from '@mui/material'
import { ptBR } from '@mui/x-data-grid'
import { bgBG as corePTBr } from '@mui/material/locale'
import authService from '../services/auth.service';

const originalTheme = createTheme()

export const createMyTheme = () => {
  const user = authService.currentUser();

  return createTheme(
    {
      components: {
        MuiFab: {
          styleOverrides: {
            root: {
              '&.Mui-primary': {
                fontWeight: 'bold'
              }
            }
          }
        },
        MuiCheckbox: {
          styleOverrides: {
            root: {
              '&.Mui-checked': {}
            }
          }
        },
        MuiRadio: {
          styleOverrides: {
            root: {
              '&.Mui-checked': {}
            }
          }
        },
        MuiButton: {
          variants: [
            {
              props: { variant: 'text', color: 'primary' },
              style: {
                border: `none`,
                '&.Mui-disabled': {
                  opacity: 0.3
                }
              }
            }
          ]
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              '&.Mui-disabled': {
                opacity: 0.3
              }
            }
          }
        },
        MuiTooltip: {
          styleOverrides: {
            tooltip: {
              backgroundColor: 'white',
              color: 'rgba(0, 0, 0, 0.87)',
              boxShadow: originalTheme.shadows[1],
              fontSize: 14,
              textAlign: 'center'
            }
          }
        }
      },
      palette: {
        primary: {
          main: '#3366FF'
        },
        secondary: {
          main: '#4E5F78'
        },
        error: {
          main: '#D32E2E'
        },
        warning: {
          main: '#FFC000'
        },
        mode: user.id ? 'dark' : 'light'
      },
      typography: {
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
        button: {
          textTransform: 'none'
        }
      },
    },
    ptBR,
    corePTBr
  )
}

const theme = createMyTheme()

export default theme