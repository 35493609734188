import { isEmpty } from "./validators";
import { floatMask, numberMask, removeFormatDate } from "./masks";

import dateformat from 'dateformat';

export function toUpperCase(value: string) {
    return String(value).toUpperCase();
}

export function toLowerCase(value: string) {
    return String(value).toLowerCase();
}

export function stringFormat(value: string, ...attr: string[]) {
    let result = value;
    if (!isEmpty(value)) {
        for (let index = 0; index < result.length; index++) {
            result = result.replace(`{${index}}`, attr[index]);
        }
    }
    return result;
}

export function toHex(value: string) {
    var data = [];
    for (var i = 0; i < value.length; i++)
        data.push(value.charCodeAt(i));

    var toHex = '';
    data.forEach(e => { toHex += (e).toString(16); });
    return toHex;
}

export function fromHex(value: string) {
    var result = '';
    for (var n = 0; n < value.length; n += 2)
        result += String.fromCharCode(parseInt(value.substr(n, 2), 16));
    return result;
}

export function binaryToDecimal(x: string) {
    const parsed = parseInt(x, 2);

    if (isNaN(parsed)) { return 0; }
        return parsed;
}

export function decimalToHex(value: number) {
    var parsed = value;

    let hexstr = ""
    while(parsed > 0) {
        var valid = parsed % 16;

        hexstr = (
            valid === 10 ? "A" : (
            valid === 11 ? "B" : (
            valid === 12 ? "C" : (
            valid === 13 ? "D" : (
            valid === 14 ? "E" : (
            valid === 15 ? "F": valid)))))
        ) + hexstr;
        parsed = parseInt(String(parsed / 16));
    }

    return '0x'+ hexstr;
}

export function binaryToHex(value: string) {
    return decimalToHex(binaryToDecimal(value));
}

export function hexToDecimal(value: string) {
    const parsed = parseInt(value, 16);
    if (isNaN(parsed)) { return 0; }
    return parsed;
}

export function decimalToBinario(decimal: string) {
    return parseInt(decimal, 10).toString(2);
}

export function leftPad(value: string, totalWidth: number, paddingChar: string = '') {
    var length = totalWidth - value.toString().length + 1;
    if (length < 0)
        return value;
    return Array(length).join(paddingChar || '0') + value;
}

export function rightPad(value: string, totalWidth: number, paddingChar: string = '') {
    var length = totalWidth - value.toString().length + 1;
    if (length < 0)
        return value;
    return value + Array(length).join(paddingChar || '0' );
}

export function toSimpleName(value: string) {
    let array;
    if (value !== null) {
        array = value.split(' ');
        let surname = array[0]; 
        for(let i = 1; i < array.length - 1; i++) {
            surname += ' '+ array[ i ].substr(0, 1) +'.'; 
        }
        if (array.length > 0)
            surname += ' '+ array[ array.length - 1 ];
        return surname;
    }
    return value;
}

export function floatToString(value: number): string {
    let ret = toStringFloat(value);
    return ret;
}

export function intToString(value: number): string {
    return numberMask(String(value));
}

export function toInt(value: any): number {
    if (isNaN(parseInt(value))) {
        return 0;
    }
    return parseInt(value);
}

export function toFloat(value: any): number {
    if (isNaN(parseFloat(value))) {
        return 0.00;
    }
    if (typeof value === 'string') {
        if (value.substring(value.length - 3, value.length - 2) === ',') {
            let aux = value.replace('.','');
                value = aux.replace(',','.');
            return parseFloat(value);
        } else
            return parseFloat(value);
    }
    return parseFloat(value.toFixed(2));
}

export function toBoolean(value: any): boolean {
    return (value === "true") || (value === "1") || (value === 1);
}

export function toStringFloat(value: any): string {

    if (!isEmpty(value)) {
        if (isNaN(value)) {
            const valueFloat = toFloat(value);
            if (!isNaN(valueFloat))
                return value;
            else
                return floatMask('9999990.00');
        }

        if (typeof value === 'number')
            value = value.toFixed(2)

        var stringNumber = String(value);
        var arrayNumber = stringNumber.split('.');

        stringNumber = arrayNumber[0];
        if (arrayNumber.length > 1) {
            stringNumber = arrayNumber[0] +'.'+ rightPad(arrayNumber[1], 2, '0');
        } else { 
            stringNumber = arrayNumber[0] +'.00';
        }
        return floatMask(stringNumber);
    }
    return floatMask('0.00');
}

export function toString(value: string) {
    let result = '';

    if (typeof value !== 'undefined') {
        result = value !== null ? value : '';
    }
    return result;
}

export function notNullToDate(value: Date, format: string) {
    /*
    let result = ''
    let dateBase = '';

    if (value != null) {
        let datePrior = dateformat(new Date(0), 'UTC:'+ format, false);
        dateBase = dateformat(value, 'UTC:'+ format, true);
        if (dateBase > datePrior)
            result = dateBase;
    }
    */
    if (value != null) {
        if (String(value) === '1970-01-01T00:00:00.000Z')
            return '';
        else
            return dateformat(value, format, true);
    }
    return '';
}

/* 
    INPUT:  departure -> dd/mm/yyyy HH:MM:ss
            estimated -> HH:MM
*/
export function arrival(departure: string, estimated: string): string {
    let result = '';
    if ((typeof departure === 'string') && (departure !== '')) {
        let date = new Date(removeFormatDate(departure));
        let dateHour = date.setHours(date.getHours() + toInt(estimated.substring(0, 2)));
            date = new Date(dateHour);
        let dateMinutes = date.setMinutes(date.getMinutes() + toInt(estimated.substring(3, 5)));
            date = new Date(dateMinutes);

        result = notNullToDate(date, 'dd/mm/yyyy HH:MM:ss');
    }
    return result;
}

/* 
    INPUT:  baseDate -> dd/mm/yyyy
            departure -> HH:MM
            estimated -> HH:MM
*/
export function arrivalForecast(baseDate: string, departure: string, estimated: string): string {
    let result = '';
    if ((typeof baseDate === 'string') && (baseDate !== '')) {
        let date = new Date(removeFormatDate(baseDate +' '+ departure +':00'));
        let dateHour = date.setHours(date.getHours() + toInt(estimated.substring(0, 2)));
            date = new Date(dateHour);
        let dateMinutes = date.setMinutes(date.getMinutes() + toInt(estimated.substring(3, 5)));
            date = new Date(dateMinutes);

        result = notNullToDate(date, 'dd/mm/yyyy HH:MM:ss');
    }
    return result;
}

export function estimate(baseDate: string, departure: string, forecast: string): string {
    let result = '';
    if ((typeof baseDate === 'string') && (baseDate !== '')) {
        let departureDate = new Date(removeFormatDate(baseDate +' '+ departure +':00')); 
        let forecastDate = new Date(removeFormatDate(baseDate +' '+ forecast +':00'));
        let diference = Math.ceil(Math.abs(departureDate.getTime() - forecastDate.getTime()) / (1000 * 60));
    
        let hours = Math.floor(diference / 60);
        let minutes = Math.round(((diference / 60) - hours) * 60);

        let hoursResult = '';
        let minutesResult = '';

        (hours <= 9) ? hoursResult = `0${hours}` : hoursResult = `${hours}`;
        (minutes <= 9) ? minutesResult = `0${minutes}` : minutesResult = `${minutes}`

        result = `${hoursResult}:${minutesResult}`;
    }
    return result;
}

export function browsingDelay(status: string, baseDate: string, departure: string, estimated: string): boolean {

    if (status === "5") {
        const forecast = arrivalForecast(baseDate, departure, estimated);
        const dateForecast = new Date(removeFormatDate(forecast));
        const now = new Date();

        return (now > dateForecast);
    } else {
        return false;
    }
}

export function now() {
    return new Date();
}

export function dateToString(value: Date, format: string): string {
    let result = '';

    let day = 0;
    let month = 0;
    let year = 0;
    let hour = 0;
    let minute = 0;
    let second = 0;

    if (typeof value === 'string') { // 2022-04-27T00:00:00.000Z
        const dateString = String(value);
        if (format === 'yyyymm') {
            year = toInt(dateString.substring(0, 4));
            month = toInt(dateString.substring(5, 7)) - 1;

            const date = new Date(year, month, day, hour, minute, second);
            result = dateformat(date, format, true);
        } else if (format === 'yyyy-mm-ddTHH:MM:ss.sssZ') {
            year = toInt(dateString.substring(0, 4));
            month = toInt(dateString.substring(5, 7)) - 1;
            day = toInt(dateString.substring(8, 10));

            hour = toInt(dateString.substring(11, 13));
            minute = toInt(dateString.substring(14, 16));
            second = toInt(dateString.substring(17, 19));

            const date = new Date(year, month, day, hour, minute, second);
            result = dateformat(date, format, true);
        } else if (format === 'dd/mm/yyyy') {
            year = toInt(dateString.substring(0, 4));
            month = toInt(dateString.substring(5, 7)) - 1;
            day = toInt(dateString.substring(8, 10));

            const date = new Date(year, month, day, hour, minute, second);
            result = dateformat(date, format, true);
        } else {
            const date = new Date(value);
            result = dateformat(date, format, true);
        }
    } else {
        if ((typeof format !== 'undefined') && (format != null)) {
            if ((typeof value !== 'undefined') && (value != null)) {
                if (format === 'HH') {
                    result = leftPad(String(value.getHours()), 2, '0');
                } else {
                    result = dateformat(value, format, false, true);
                }
            }
        }
    }
    return result;
}

export function formatDate(value: string): string {
    const array = value.split('-');
    if (array[0].length === 4) { // YYYY-MM-DD
        return array[2] +'/'+ array[1] +'/'+ array[0]
    }
    return value;
}

export function toDate(value: string, format: string): Date {
    let result = new Date();

    let day = 0;
    let month = 0;
    let year = 0;
    let hour = 0;
    let minute = 0;
    let second = 0;

    if ((typeof value !== 'undefined') && (value != null)) {
        if ((value.length === 24) && (format === 'yyyy-mm-ddTHH:MM:ss.sssZ')) {
            year = toInt(value.substring(0, 4));
            month = toInt(value.substring(5, 7)) - 1;
            day = toInt(value.substring(8, 10));

            hour = toInt(value.substring(11, 13));
            minute = toInt(value.substring(14, 16));
            second = toInt(value.substring(17, 19));

            result = new Date(year, month, day, hour, minute, second);
        //} else if ((value.length === 10) && (format === 'dd/mm/yyyy')) {
        } else if (format === 'dd/mm/yyyy HH:MM') {
            year = toInt(value.substring(6, 10));
            month = toInt(value.substring(3, 5)) - 1;
            day = toInt(value.substring(0, 2));

            hour = toInt(value.substring(11, 13));
            minute = toInt(value.substring(14, 16));

            result = new Date(year, month, day, hour, minute, 0);
        } else if (format === 'dd/mm/yyyy') {
            year = toInt(value.substring(6, 10));
            month = toInt(value.substring(3, 5)) - 1;
            day = toInt(value.substring(0, 2));

            result = new Date(year, month, day, 0, 0, 0);
        } else if (format === 'dd/mm/yyyy HH:MM:ss') {
            year = toInt(value.substring(6, 10));
            month = toInt(value.substring(3, 5)) - 1;
            day = toInt(value.substring(0, 2));

            hour = toInt(value.substring(11, 13));
            minute = toInt(value.substring(14, 16));
            second = toInt(value.substring(17, 19));

            result = new Date(year, month, day, hour, minute, second);
        } else if ((format === 'dd/mm/yyyy 00:00:00') || (format === 'dd/mm/yyyy 23:59:59')) {
            year = toInt(value.substring(6, 10));
            month = toInt(value.substring(3, 5)) - 1;
            day = toInt(value.substring(0, 2));

            hour = toInt(format.substring(11, 13));
            minute = toInt(format.substring(14, 16));
            second = toInt(format.substring(17, 19));

            result = new Date(year, month, day, hour, minute, second);
        } else {
            result = new Date(value);
        }
    }

    return result;
}

export function lastDayOdMonth(value: Date): Date {
    return new Date(value.getFullYear(), value.getMonth() + 1, 0);
}

export function dateAdd(type: string, value: Date, amount: number): Date {
    let updatedDate = value;

    if (type === 'd')
        updatedDate.setDate(value.getDate() + amount);
    if (type === 'm')
        updatedDate.setMonth(value.getMonth() + amount);
    if (type === 'y')
        updatedDate.setFullYear(value.getFullYear() + amount);
    if (type === 'H')
        updatedDate.setHours(value.getHours() + amount);
    if (type === 'M')
        updatedDate.setMinutes(value.getMinutes() + amount);
    if (type === 's')
        updatedDate.setSeconds(value.getSeconds() + amount);
        
    return updatedDate;
}

export function maxString(value: string, max: number) : string {
    if (!isEmpty(value))
        return value.substring(0, max) +'...';
    return value;
}

export function maxNumber(value: string, max: number) : string {
    let result 
   (Number(value) <= max) ? result = String(value) : result = String(max) 

    return result;
}

export function treatError(error: any): string {
    let result: string = '';
    if (!isEmpty(error)) {
        if (error !== null) {
            if (typeof(error) === 'object') {
                //console.log('Rotina de tratamento para OBJETO');
                if ((typeof error.request !== 'undefined') && (error.request != null)) {
                    if ((error.request.readyState === 4) && (error.request.responseText === '')) {
                        result = error.toString();
                    } else {
                        result = error.request.responseURL +' - '+ error.request.statusText;
                    }
                } else { // Considerando (error instanceof Error)
                    result = error.toString();
                }
            } else if (typeof(error) === 'undefined') {
                result = '';
            } else {
                result = error.toString();
            }
        }
    }
    result = result.replaceAll('Error: ', '');
    return result;
}

export function errorResponse(response: any): string {
    let result: string = '';
    if (response.status === 500) {
        result = response.status +' - '+ response.statusText;
    } else {
        const message = response.data.message;
        if (typeof message === 'undefined') {
            result = response.data; /* a informação está em HTML, remover */
        } else if (typeof message === 'string') {
            result = message;
        } else {
            if (Array.isArray(message)) {
                let messages: string = '';
                message.forEach(item => {
                    messages += item.msg +'\n'
                });
                result = messages;
            } else {
                throw new Error(`Message '${typeof message} ' not implemented.`);
            }
        }
    }
    return removeHTML(result);
}

function removeHTML(text: string) {
    let html = text;
    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/gi, '\n');
    html = html.replace(/<\/li>/gi, '\n');
    html = html.replace(/<li>/gi, '  *  ');
    html = html.replace(/<\/ul>/gi, '\n');
    html = html.replace(/<\/p>/gi, '\n');
    html = html.replace(/<br\s*[\\/]?>/gi, '\n');
    html = html.replace(/<[^>]+>/gi, '');
    return html;
}

export function calculateAge(value: Date) : number {

    var year_birth = value.getFullYear(),
        month_birth = value.getMonth() + 1,
        day_birth = value.getDate();

    var date = new Date(),
        year_now = date.getFullYear(),
        month_now = date.getMonth() + 1,
        day_now = date.getDate();

    var age = year_now - year_birth;

    if ((month_now < month_birth || month_now === month_birth) && (day_now < day_birth)) {
        age--;
    }
    
    return age < 0 ? 0 : age;
}

export async function copyTextToClipboard(text: any) {
    return await navigator.clipboard.writeText(text);
}


export function converToTlv(tlv: any) {
    let text = '';
    tlv.forEach((item: any) => {
        text += item.source;
        text += leftPad(String(item.sourceId).length.toString(), 3);
        text += item.sourceId;
    });

    return text;
}

export function convertFromTlv(text: any) {
    const item = [] as any;
    let textTvl = text;
    while (textTvl.length > 0) {
        let key = textTvl.substring(0, 3);
            textTvl = textTvl.substring(3);
        let tam = textTvl.substring(0, 3);
            textTvl = textTvl.substring(3);
        let value = textTvl.substring(0, Number(tam));
            textTvl = textTvl.substring(Number(tam));

        const idx =item.length;
        item[idx] = { source: key, value }
    }

    return item;
}

export const parseCityName = (cityName: string): string => {
    const parsedCityName = cityName.replaceAll(' ', '_')
    return parsedCityName
}

export const validEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/

export const validPhone = /^\(?[1-9]{2}\)? ?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}$/

export const getValueDatapath = (data: any, keyPath: any) => {
    const keys = keyPath.split('.');
    const levels = keys.length;

    let output = '' ;

    if (levels === 1) {
        output = data[keys[0]];
    } else if (levels === 2) {
        const subData = data[keys[0]][keys[1]];
        if (typeof subData === 'string')
            output = subData;
        else
            output = levels +': '+ keyPath;
    } else if (levels === 3) {
        const subData = data[keys[0]][keys[1]][keys[2]];
        if (typeof subData === 'string')
            output = subData;
        else
            output = levels +': '+ keyPath;
    } else if (levels === 4) {
        const subData = data[keys[0]][keys[1]][keys[2]][keys[3]];
        if (typeof subData === 'string')
            output = subData;
        else
            output = levels +': '+ keyPath;
    } else if (levels === 5) {
        const subData = data[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]];
        if (typeof subData === 'string')
            output = subData;
        else
            output = levels +': '+ keyPath;
    } else if (levels === 6) {
        const subData = data[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]][keys[5]];
        if (typeof subData === 'string')
            output = subData;
        else
            output = levels +': '+ keyPath;
    } else if (levels === 7) {
        const subData = data[keys[0]][keys[1]][keys[2]][keys[3]][keys[4]][keys[5]][keys[6]];
        if (typeof subData === 'string')
            output = subData;
        else
            output = levels +': '+ keyPath;
    }

    return output;
}

export const formatCurrency = (value: string) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(Number(value))
    // -> R$ 123.456,79
}

export const showActualMonth = () => {
    return new Intl.RelativeTimeFormat( 'pt-BR', {
        numeric: 'auto'
        }).format(1, 'month')
}
