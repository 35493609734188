import { useEffect, useMemo, useState } from "react";
import { ButtonGroup, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Phones } from "../../../components/sub-render.component";
import TableContainer from "../../../components/table-container.component";
import srvService from "../../../services/srv.service";
import { dateToString, toLowerCase } from "../../../utilities/auxiliary-functions";
import { AlertMessageEnum, getGender, getMechanicSituation } from "../../../utilities/types";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import authService from "../../../services/auth.service";
import { PermissionEnum } from "../../../utilities/constants";
import { useDeleteMessageContext } from "../../../contexts/delete-message.context";
import { useAlertMessageContext } from "../../../contexts/alert-message.context";
import { cpfMask } from "../../../utilities/masks";
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';


interface Props {
    translate: any,
    partner: any,

    title: string,
    setRecord: any
}

function MechanicCadastreList(props: Props) {
    
    const alertContext = useAlertMessageContext();
    const deleteContext = useDeleteMessageContext();

    const navigator = useNavigate();
    const display = props.translate.data;
    
    let reloadPage = false;
    const [ isLoading, setLoading ] = useState(false);
    const [ showFilter, setShowFilter]  = useState(false);
    const [ sending, setSending ] = useState(false);

    const [records, setRecords ] = useState([]);
    const columnsRecord = useMemo(() => [{
        Header: display.label.fullname,
        accessor: "fullname"
    },{
        Header: display.label.gender,
        accessor: "gender",
        Cell: (row: any) => (<div className="white-space">{display.label[toLowerCase(getGender(row.value).name)]}</div>)
    },{
        Header: display.label.registration_pf,
        accessor: "registrationPf",
        Cell: (row: any) => (<div className="white-space">{cpfMask(row.value)}</div>)
    },{
        Header: display.label.situation,
        accessor: "situation",
        Cell: (row: any) => (<div className="white-space">{display.label[toLowerCase(getMechanicSituation(row.value).name)]}</div>)
    },{
        Header: display.label.telephone,
        accessor: 'phones',
        Cell: (row: any) => <Phones translate={props.translate} values={row.value} />
    },{
        Header: display.label.created,
        accessor: "created",
        Cell: (row: any) => (<div className="white-space">{dateToString(row.value, 'dd/mm/yyyy HH:MM:ss')}</div>)
    }, {
        Header: display.label.updated,
        accessor: "updated",
        Cell: (row: any) => (<div className="white-space">{dateToString(row.value, 'dd/mm/yyyy HH:MM:ss')}</div>)
    }, {
        Header: display.legend.actions,
        accessor: "actions",
            Cell: (props: any) => {
                let mechanicRows = [] as any;
                const originals = props.rows;
                for (var o = 0; o < originals.length; o++) {
                    mechanicRows[mechanicRows.length] = originals[o].original;
                }
                const mechanicRow = props.row.original;

                return (
                    <div className="white-space table-actions">
                        {authService.hasPermission(PermissionEnum.CADASTRE_MECHANIC_EDIT) && 
                            <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.toEdit}</Tooltip>}>
                                <span onClick={(e) => onClick_EditRecord(e, mechanicRow)}>
                                    <FaEdit size={18} />
                                </span>
                            </OverlayTrigger>
                        }
                        {authService.hasPermission(PermissionEnum.CADASTRE_MECHANIC_REMOVE) && 
                            <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.delete}</Tooltip>}>
                                <span onClick={(e) => onClick_DeleteRecord(e, mechanicRows, mechanicRow)}>
                                    <FaTrash size={18} />
                                </span>
                            </OverlayTrigger>
                        }
                    </div>
                );
            }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }], []); // columnsRecord

    function onClick_Filter(event: any) {
        event.preventDefault();
        
        setSending(false);
        setShowFilter(false);
        searchfilter();
    } // onClick_Filter

    function onClick_AddRecord(event: any) {
        event.preventDefault();

        setShowFilter(false);
        setSending(true);
        props.setRecord(event, 'new');
    } // onClick_AddRecord

    function onClick_EditRecord(event: any, record: any) {
        event.preventDefault();
        props.setRecord(event, record.id);
    } // onClick_EditRecord

    async function onClick_DeleteRecord(event: any, records: any, record: any) {
        event.preventDefault();
        const isConfirmed = await deleteContext.show(props.title, display.message.delete_record, true);
        if (isConfirmed && isConfirmed.result) {
            try {
                await srvService.mechanicDeleteById(record.id, isConfirmed.message);
                searchfilter();
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
            }
        }
    } // onClick_DeleteRecord
    
    async function searchfilter() {
        const attributes = [] as any;
        /* Sempre incluir o partner na pesquisa */
        if (props.partner) {
            const where = { partnerId : props.partner.id } as any;
            const order = [] as any;

            try {
                const mechanics = await srvService.mechanicList(attributes, where, order);
                setRecords(mechanics);
                setSending(false);
                setShowFilter(false);
            } catch(error: any) {
                await alertContext.show(AlertMessageEnum.FAIL, props.title, error);
            }
        } else {
            await alertContext.show(AlertMessageEnum.FAIL, props.title, 'Não foi definido uma Marina para exibir o cadastro !');
            navigator(`/portal`);
        }
    } // searchfilter

    useEffect(() => {
        if (!isLoading && !reloadPage) {
            searchfilter();
            setLoading(true);
        }

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            reloadPage = !reloadPage;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // useEffect

    function viewCadastreList() {
        return (
            <Container fluid className="page-body mechanic">
                <Row>
                    <Col md={12} className="page-sweet">
                        <Card>
                            <Card.Header>
                                <div className="card-actions float-right">
                                    <ButtonGroup>
                                        {authService.hasPermission(PermissionEnum.CADASTRE_MECHANIC_ADD) && 
                                            <OverlayTrigger overlay={<Tooltip id="tooltip">{display.tooltips.register} {display.tooltips.mechanic}</Tooltip>}>
                                                <Button size="small" onClick={ onClick_AddRecord } disabled={sending} variant="contained" color="primary">
                                                    { sending ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : <AddIcon /> }
                                                    Mecânico
                                                </Button>
                                            </OverlayTrigger>
                                        }
                                    </ButtonGroup>
                                </div>
                                <Card.Title>{display.legend.listing}</Card.Title>
                            </Card.Header>
                            <Card.Body style={{ minHeight : 500 }}>
                                <div className={`card-filter ${showFilter ? "active" : ""}`} >
                                    <div className="card-filter-header">{display.legend.filter}</div>
                                    <div className="card-filter-body">
                                        <Container>
                                            <Row>
                                                <Col>{display.legend.filter_scheme}</Col>
                                            </Row>
                                        </Container>
                                    </div>
                                    <div className="card-filter-footer">
                                        <Button onClick={ onClick_Filter } disabled={sending} variant="contained" color="primary">
                                            { sending ? <Spinner className="me-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : null } {' '}
                                            {display.buttom.filter}
                                        </Button>
                                    </div>
                                </div>
                                <TableContainer className={'table-list-record'} columns={columnsRecord} data={records} viewFilter={!showFilter && records.length > 10}/>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    } // viewCadastreList

    return (
        <>
            { viewCadastreList() }
        </>
    );

}

export default MechanicCadastreList;
