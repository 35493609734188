import React, { Component } from 'react';

import languageEN from '../assets/languages/en.json';
import languagePtBr from '../assets/languages/pt-br.json';

// Create new context
export const TranslateContext = React.createContext({
    translate: {},
    changeLanguage: {} as any
});

// Then create a Provider Component
export class TranslateProvider extends Component<any, any> {
    
    state = {
        language: 'pt-br',
        data: languagePtBr
    }

    constructor(props: any) {
        super(props);

        let locaData = {} as any;
        let localLanguage = localStorage.getItem('language');
        if (!localLanguage)
            localLanguage = 'pt-br';

        if (localLanguage === 'pt-br') {
            locaData = languagePtBr;
        } else {
            locaData = languageEN;
        }

        this.state = {
            language : localLanguage,
            data : locaData
        }
    }

    changeLanguage = (event: any) => {
        let language = event.target.value;
        let data = {};

        if (language === 'en') {
            data = languageEN;
        } else {
            data = languagePtBr;
        }

        localStorage.setItem("language", language)
        this.setState({
            language: language,
            data: data
        })
    }

    render() {
        return (
            <TranslateContext.Provider value={{
                translate: this.state,
                changeLanguage: this.changeLanguage
            }}>
                {this.props.children}
            </TranslateContext.Provider>
        )
    }
}
